import { AuthServices } from '../../../../../_services/auth.service';
import { PanelServices } from '../../../../../_services/panel.service';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { AppConfig } from '../../../../../_config';
const { perPage } = AppConfig;
import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { PriceService } from '../../../../../_services/price.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from '../select-local-market/dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

import { DropdownFilterComponent } from '../../../../dropdown-filter/dropdown-filter.component';
import { FilterServices } from '../../../../../_services/filter.service';
import { SavefilterServices } from '../../../../../_services/save-filter.service';
import { SaveFiltersComponent } from '../../../../../global-module/save-filters/save-filters.component';
import * as  moment from 'moment';
import { Subscription } from 'rxjs';
import { WarningComponent } from 'src/app/global-module/warning/warning.component';


/**
 *Component listing the existing price agreements for Ola
 *
 * @export
 * @class ConfrimSelectEditExistComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-confrim-select-edit-exist',
  templateUrl: './confrim-select-edit-exist.component.html',
  styleUrls: ['./confrim-select-edit-exist.component.scss']
})
export class ConfrimSelectEditExistComponent implements OnInit {
  public dropdownType: string;
  public dropdownNumber: any = 0;
  public dropdownTempData: any;
  public searchText = '';
  public spnMpnSearch = '';
  public lookup: any = "";
  emptyText = false
  createPaPageConfig
  public pageIndex: number;
  public recomended = false;
  @Input() currency;

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild('dropDownFilter', { static: true }) public dropDownFilter: DropdownFilterComponent;
  @Output("createNewPriceAgreement") createNewPriceAgreement: EventEmitter<any> = new EventEmitter();
  public heroes: any;
  public animal: string;
  public name: string;
  public setPageSizeOptions;
  public sourceFilterCount: number;
  // public columns: any[] = [
  //   { field: 'referenceName', name: 'Price Agreement ID' },
  //   { field: 'supplierName', name: 'Supplier' },
  //   { field: 'opcoName', name: 'Local Market' },
  //   { field: 'tradingCurrencyKey', name: 'Currency' },
  //   { field: 'validFromDate', name: 'Valid From' },
  //   { field: 'validToDate', name: 'Valid To' },
  //   { field: 'paStatus', name: 'Status' },
  //   { field: 'vpcOwner', name: 'Vodafone Owner' },
  //   { field: 'supplierOwner', name: 'Supplier Owner' }
  // ];
  public columns: any[] = [
    { field: 'referenceName', name: 'Price Agreement Reference', enableFilter: true, enableSort: true },
    { field: 'validFromDate', name: 'Valid From', enableFilter: true, enableSort: true },
    { field: 'validToDate', name: 'Valid To', enableFilter: true, enableSort: true },
    { field: 'paStatus', name: 'Status', enableFilter: true, enableSort: false },
    { field: 'vpcOwner', name: 'Vodafone Owner', enableFilter: true, enableSort: true },
    { field: 'tradingCurrencyKey', name: 'Currency', enableFilter: true, enableSort: true }
  ];
  public paStatusArr = [
    {
      key: 'COMPLETED',
      value: 'Completed',
      icon: 'approved.svg',
      type: 'paStatus'
    },
    {
      key: 'IN-PROGRESS',
      value: 'In-Progress',
      icon: 'draft.svg',
      type: 'paStatus'
    },
    {
      key: 'PENDING',
      value: 'Pending',
      icon: 'under-review.svg',
      type: 'paStatus'
    },
    {
      key: 'ITEMS_EXPIRED',
      value: 'All Items Expired',
      icon: 'expired-red.svg',
      type: 'paStatus'
    },
    {
      key: 'ITEMS_DELETED',
      value: 'All Items Deleted',
      icon: 'items-deleted.svg',
      type: 'paStatus'
    },
    {
      key: 'INACTIVE',
      value: 'All Items Inactive',
      icon: 'items-inactive.svg',
      type: 'paStatus'
    },
    {
      key: 'OLA_EXPIRED',
      value: 'OLA Expired',
      icon: 'items-expired.svg',
      type: 'paStatus'
    },
    {
      key: 'ARCHIVED',
      value: 'Archived',
      icon: 'archived-black.svg',
      type: 'paStatus'
    },
    {
      key: 'DELETED',
      value: 'Deleted',
      icon: 'deleted-pa.svg',
      type: 'paStatus'
    },
    {
      key: 'SYNCHRONIZATION_ERROR',
      value: 'Sync Failed',
      icon: 'pending-synch.svg',
      type: 'paStatus'
    },
  ];
  public filterCondition: any = [];
  public sortKey: any = null;
  public sortType: any = null;
  public showColumn = false;
  public filterSub: Subscription;
  public isBuyer: any;
  public paStatusFilter: any[] = [];
  public paSsearchText: any[] = [];
  public selectedFilterType: any[] = [];
  public sortDir = "asc";
  public openDropdownType = false;
  public openDropdownName = "";
  constructor(
    public authService: AuthServices,
    public panelService: PanelServices,
    private priceService: PriceService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private filterService: FilterServices,
    public savefilterService: SavefilterServices
  ) { }

  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild(MatTable, { static: true }) public table: MatTable<any>;
  public data = [];
  public displayedColumns: string[] = [];
  public dataSeries: any = [];
  public previousIndex: number;
  public dataSource: any = [];
  public olaNumber: string;
  public projectId: string;
  public pagination: any = {};
  public filterDisplay = {};
  public sortObj: any = {};
  public page = 0;
  public pageNumber = 1;
  public pageSizeOptions = [10, 20, 50, 100];
  public itemsPerPage: number;

  public filterRecord: any = [];
  public filterObjToSave: {
    filters: Array<any>,
    filterName: string,
    componentName: string
  } = {
      filters: [],
      filterName: '',
      componentName: ''
    };
  public filterArr: any[] = [];
  public userFilters: any[];
  public savedFilterValue: any;
  public savedFilter: string;

  public listObj: any = {};
  public listArr: any = [];
  public totalCount = 0;
  isSupplierUser: boolean = false;
  public role: any;

  public ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
    this.role = this.authService.getRole();
    if (
      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER")
    ) {
      this.isSupplierUser = true;
    }
    this.filterDisplay = {
      paStatus: false,
      catalogueStatus: true,
      material: false,
      opco: false,
      catalogue: false,
      paPage: true
    };
    this.itemsPerPage = perPage;
    this.route.paramMap.subscribe(params => {
      this.olaNumber = params.get('outlineAgreementNumber');
      this.projectId = params.get('projectId');
    });

    // if (!this.isSupplierUser) {
    //   this.columns.splice(1, 0, { field: 'opcoName', name: 'Local Market', enableFilter: true, enableSort: true });
    // }
    this.columns.forEach((data) => {
      if (
        data.field === "validFromDate" ||
        data.field === "validToDate"
      ) {
        this.selectedFilterType[data.field] = "equal";
      } else {
        this.selectedFilterType[data.field] = "contains";
      }
    })
    this.existingPAForOla();
    this.setDisplayedColumns();
    this.getFilters();
    this.filterSub = this.filterService.getFilter().subscribe(res => {
      // VOD-1652
      const alreadyExist = this.userFilters.find(o => o.filterName === res['result']['result']['filterName']);
      if (!alreadyExist) {
        this.userFilters.push(res['result']['result']);
      }
      // VOD-1686
      else {
        alreadyExist.updatedAt = res['result']['result'].updatedAt || alreadyExist.updatedAt;
      }
      // VOD-1686
    });

    // this.columns = this.columns.filter((data) => {
    //   if (this.isSupplierUser && (data.field == 'supplierName' || data.field == 'supplierOwner')) {

    //   } else {
    //     return data;
    //   }
    // })
  }

  public ngOnDestroy() {
    this.filterSub.unsubscribe();
  }

  /**
   *Function to get the confirm selection filter list
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public getFilters() {
    this.filterService.getUserFilters('confirm-selection').subscribe(response => {
      this.userFilters = response['result'];
      // tslint:disable-next-line:align
    }, error => {
      console.log(error);
    });
  }
  /**
   *Emit the function to the parent component when create new pa button clicked
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public ProceedClick() {
    this.createNewPriceAgreement.emit();
  }

  /**
   *Function to set the column for existing price agreement table
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public setDisplayedColumns() {
    this.columns.forEach((col, index) => {
      col.index = index;
      this.displayedColumns[index] = col.field;
    });
  }

  /**
   *Table drag function
   *
   * @param {CdkDragStart} event
   * @param {number} index
   * @memberof ConfrimSelectEditExistComponent
   */
  public dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
    console.log(event);
  }

  /**
   *Table drop function
   *
   * @param {CdkDropList} event
   * @param {number} index
   * @memberof ConfrimSelectEditExistComponent
   */
  public dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.columns = [...this.columns];
      this.setDisplayedColumns();
    }
  }

  /**
   *Function to get the existing price agreement data for the selected ola number
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public existingPAForOla() {
    this.createPaPageConfig = JSON.parse(
      localStorage.getItem("CreatePA_PageConfig")
    );
    const payload = this.getPayload();
    this.priceService.getExistingPriceAgreementsForProjectId(
      this.projectId,
      {
        pageNumber: this.pageNumber,
        perPage: this.itemsPerPage,
        sortType: this.sortObj.sortType,
        sortKey: this.sortObj.attribName,
        searchText: this.searchText.length ? this.searchText : null
      },
      { filters: (this.filterCondition.length == 0) ? [{}] : this.filterCondition } // send the filter object
    ).subscribe(
      data => {
        if (data && data['result'] && data['result'].rows && data['result'].rows.length) {
          data['result'].rows.forEach(obj => {
            const priceAgreementId: string = obj && obj.priceAgreementId;
            const opcoName: string = obj && obj.localMarket;
            const referenceName: string = obj && obj.priceAgreementId;
            const currency: string = (this.currency) ? this.currency : "";
            const projectIdCurrency = obj && obj.projectIdDetails.currency
            const status: any = obj && obj.status;
            const supplierName: string = obj && obj.supplierName;
            const tradingModel: string = obj && obj.projectIdDetails && obj.projectIdDetails.outlineAgreement && obj.projectIdDetails.outlineAgreement.tradingModel;
            const validFromDate: Date = obj && obj.projectIdDetails && obj.projectIdDetails.validFromDate
            const validToDate: Date = obj && obj.projectIdDetails && obj.projectIdDetails.validToDate;
            // tslint:disable-next-line: max-line-length
            const vpcOwner: any = obj && obj.buyerOwner || 'Administrator';
            // tslint:disable-next-line: max-line-length
            const supplierOwner: any = obj && obj.supplierOwner || 'Supplier Administrator';
            const actions: any = obj && obj.actions;

            const itemexpiryStatus = this.isExpiringItem(validToDate);

            const bellIcon = this.isExpiringPA(validToDate, status);

            let actionsArr: any = [];
            let actionData = '';
            if (actions != null && actions.length) {
              actionsArr = Object.keys(actions).map(k => {
                return actions[k].data;
              });
              actionData = actionsArr.join(', ');
            }
            const opcoDetails = (opcoName == null && obj.opcoCode == null) || (tradingModel === 'Buy From') ? '' : `${opcoName} - ${obj.opcoCode}`;
            this.listObj = {
              // tslint:disable-next-line:max-line-length
              referenceName: { name: referenceName, value: tradingModel, pId: priceAgreementId },
              tradingCurrencyKey: { name: currency },
              supplierName: { name: supplierName },
              opcoName: { name: opcoDetails },
              validFromDate: { name: validFromDate },
              validToDate: { name: validToDate },
              // tslint:disable-next-line:max-line-length
              paStatus: { paStatus: status.toLowerCase() },
              vpcOwner: { name: vpcOwner },
              supplierOwner: { name: supplierOwner, actionData },
              actionData: { name: actionData },
              currency: { name: currency }
            };
            this.listArr.push(this.listObj);
          });
        }
        this.data = this.listArr;
        this.totalCount = data['result'].count;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.sourceFilterCount = this.dataSource.filteredData.length;
        this.listArr = [];
      },
      err => {
        console.log(err);
      });
  }

  /**
   *Function to check the expiring items
   *
   * @private
   * @param {*} validToDate
   * @return {*} 
   * @memberof ConfrimSelectEditExistComponent
   */
  private isExpiringItem(validToDate: any) {
    validToDate = new Date(validToDate);
    validToDate.setHours(0, 0, 0, 0);
    validToDate = moment(validToDate);
    let today: any = new Date();
    today.setHours(0, 0, 0, 0);
    today = moment(today);
    const diff = +(validToDate.diff(today, 'days'));
    if (diff >= 0 && diff <= 100) {
      return true;
    }
    return false;
  }

  /**
   *Function to check the expiring Price agreement
   *
   * @private
   * @param {*} validToDate
   * @param {*} paStatus
   * @return {*} 
   * @memberof ConfrimSelectEditExistComponent
   */
  private isExpiringPA(validToDate: any, paStatus: any) {
    validToDate = new Date(validToDate);
    validToDate.setHours(0, 0, 0, 0);
    validToDate = moment(validToDate);
    let today: any = new Date();
    today.setHours(0, 0, 0, 0);
    today = moment(today);
    const diff = +(validToDate.diff(today, 'days'));
    let bellIcon = '';
    if (diff >= 0 && diff <= 100) {
      bellIcon = 'enabledTooltip';
    } else if (diff < 0 || paStatus === 'EXPIRED') {
      bellIcon = 'expired';
    } else if (diff > 100) {
      bellIcon = 'disabled';
    }
    return { iconStatus: bellIcon, days: diff };
  }

  public downloadPAFilterConfirmation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'waring-dialog-container';
    dialogConfig.data = {
      header: "Export Existing Price Agreement",
      message: `Do you want to download the Price Agreement with applied filters or without applied filters.`,
      type: "dwlPa",
      confirmText: "With Filters",
      cancelText: "Without Filters",
    };
    if (this.filterCondition.length == 0) {
      this.downloadExistingPAOla(false);
    } else {
      const dialogRef = this.dialog.open(WarningComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "close") {
          return;
        }
        if (result && result === true) {
          this.downloadExistingPAOla(true);
        } else {
          this.downloadExistingPAOla(false);
        }
      });
    }

  }

  /**
   *Function to download the existing pa list 
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public downloadExistingPAOla(filterValue) {
    // tslint:disable-next-line:max-line-length
    this.priceService.downloadExistingPriceAgreementsForProjectId(this.projectId,
      {
        filters: filterValue ? this.filterCondition : [],
        sortKey: filterValue ? this.sortObj : {},
        lookup: filterValue ? this.lookup : '',
        isRecommendedOnly: this.recomended
      },
      { filters: this.filterCondition }).subscribe(
        data => {
          if (window.navigator.msSaveBlob) {
            // IE11 & Edge
            window.navigator.msSaveBlob(data, `ExistingPAFor-${this.projectId}.xlsx`);
            return;
          }
          const url = window.URL.createObjectURL(data);
          // create hidden dom element (so it works in all browsers)
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          // create file, attach to hidden element and open hidden element
          a.href = url;
          a.download = `ExistingPAFor-${this.projectId}.xlsx`;
          a.click();
        },
        err => {
          console.log(err);
          // this.router.navigate(['/analytics']);
        });
  }

  public openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: 'Angular For Beginners'
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  /**
   *Pagination function
   *
   * @param {*} data
   * @memberof ConfrimSelectEditExistComponent
   */
  public pageEvent(data) {
    this.pageNumber = +data.pageIndex + 1;
    this.sourceFilterCount = data.length;
    this.itemsPerPage = data.pageSize;
    // this.totalCount = data.length;
    this.existingPAForOla();
  }

  /**
   *Function for sort the table row data
   *
   * @param {*} event
   * @memberof ConfrimSelectEditExistComponent
   */
  public sortData(event) {
    const { active, direction } = event;
    this.paginator.pageIndex = 0;
    this.pageNumber = 1;
    if (!direction.length) {
      this.sortKey = null;
      this.sortType = null;
    } else {
      this.sortKey = active;
      this.sortType = direction;
    }
    this.existingPAForOla();
  }

  /**
   *Search function
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public onSearch() {
    this.paginator.pageIndex = 0;
    this.pageNumber = 1;
    this.existingPAForOla();
  }

  /**
   *Navigate to edit pa page
   *
   * @param {*} object
   * @memberof ConfrimSelectEditExistComponent
   */
  public onClickAgreement(object: any) {
    if (object && object.referenceName) {
      this.router.navigate([`/dashboard/price-agreements/edit-price-agreement/${object.referenceName.pId}`]);
    }
  }

  /**
   *Function to open/close the filter dropdown
   *
   * @param {*} type
   * @param {*} num
   * @memberof ConfrimSelectEditExistComponent
   */
  public dropdownToggle(type, num) {
    this.searchText = '';
    this.dropdownType = type;
    if (this.dropdownNumber === 0 || this.dropdownTempData !== type) {
      this.dropdownNumber += num;
      if (this.dropdownNumber > 1) {
        this.dropdownNumber--;
      }
      this.dropdownTempData = type;
    } else {
      this.dropdownNumber = 0;
    }
  }

  /**
   **This function create filter objects for applied filter
   *and create objects for save that filters
   *
   * @param {*} data
   * @memberof ConfrimSelectEditExistComponent
   */
  public getChildFilter(data) {
    if (data['clicked']) {
      this.savedFilterValue = undefined;
    }
    const filters = this.filterService.dataFilter(data, 'equal');

    if (data['filterObj']) {
      this.filterArr = data['filterObj'];
      this.filterObjToSave = this.filterService.prepairFilterObjToSave(this.filterArr, 'confirm-selection');
    }
    this.filterCondition = filters;
    this.pageNumber = 1;
    this.itemsPerPage = perPage;
    this.paginator.pageIndex = 0;
    this.existingPAForOla();
  }

  /**
   **Function to remove the selected filter value in the filter list
   *and re create the filter objects
   *
   * @param {*} index
   * @memberof ConfrimSelectEditExistComponent
   */
  public onRemoveFilter(index) {

    this.savedFilterValue = undefined;
    const removeObj = this.filterArr[index];
    if (removeObj.type === 'referenceName') {
      let svalue = this.paSsearchText['referenceName'].split(',');
      this.paSsearchText['referenceName'] = svalue.filter(data => data !== removeObj.value).join(',');
      if (this.paSsearchText['referenceName'] === '') {
        this.selectedFilterType['referenceName'] = 'contains';
      }
    }
    if (removeObj.type === 'validFromDate') {
      this.paSsearchText['validFromDate'] = '';
      this.selectedFilterType['validFromDate'] = 'equal';
    }
    if (removeObj.type === 'validToDate') {
      this.paSsearchText['validToDate'] = '';
      this.selectedFilterType['validToDate'] = 'equal';
    }
    if (removeObj.type === 'vpcOwner') {
      let svalue = this.paSsearchText['vpcOwner'].split(',');
      this.paSsearchText['vpcOwner'] = svalue.filter(data => data !== removeObj.value).join(',');
      if (this.paSsearchText['vpcOwner'] === '') {
        this.selectedFilterType['vpcOwner'] = 'contains';
      }
    }

    if (removeObj.type === 'tradingCurrencyKey') {
      let svalue = this.paSsearchText['tradingCurrencyKey'].split(',');
      this.paSsearchText['tradingCurrencyKey'] = svalue.filter(data => data !== removeObj.value).join(',');
      if (this.paSsearchText['tradingCurrencyKey'] === '') {
        this.selectedFilterType['tradingCurrencyKey'] = 'contains';
      }
    }

    if (removeObj.type === 'paStatusFilter') {
      this.paStatusFilter = this.paStatusFilter.filter(data => data !== removeObj.value)
    }
    this.filterArr.splice(index, 1);
    this.dropDownFilter.GetOutputVal(removeObj['type'], false, removeObj['key']);
    this.getChildFilter(this.filterService.prepairSearchObj(this.filterArr));
    this.filterObjToSave = this.filterService.prepairFilterObjToSave(this.filterArr, 'confirm-selection');
  }

  /**
   **Function called when user select filter name from saved filter list
   *Based on the selection filters applied
   *
   * @param {string} filterName
   * @memberof ConfrimSelectEditExistComponent
   */
  public onSelect(filterName: string) {
    this.clearFilter();
    this.savedFilterValue = filterName;
    this.filterService.getUserFilter({ componentName: 'confirm-selection', filterName })
      .subscribe(response => {
        this.filterRecord = response['result']['filters'];
        this.savedFilter = response['result']['filter_name'];
        this.getChildFilter(this.filterService.prepairSearchObj(this.filterService.prepairSerchObjFromRes(response['result']['filters'])));
        this.filterArr = this.filterService.prepairSerchObjFromRes(response['result']['filters']);
        this.filterArr.forEach(element => {
          this.dropDownFilter.GetOutputVal(element['type'], true, element['key']);
        });
        // tslint:disable-next-line:align
      }, error => {
        console.log(error);
      });
  }

  /**
   *Function to open the save filter popup
   *
   * @return {*} 
   * @memberof ConfrimSelectEditExistComponent
   */
  public saveFilterDialog() {
    if (this.filterRecord.length) {
      this.savefilterService.savedFilterName = this.savedFilter;
    }
    if (!this.filterObjToSave['filters'].length) {
      return;
    }
    this.savefilterService.saveData = this.filterObjToSave;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: '',
      filterList: this.userFilters // [VOD-1656]
    };
    this.dialog.open(SaveFiltersComponent, dialogConfig);
  }

  /**
   *Function to delete the filters from saved filter list
   *
   * @param {string} filterName
   * @memberof ConfrimSelectEditExistComponent
   */
  public deleteSavedUserFilter(filterName: string) {
    this.clearFilter();
    this.filterService.deleteSavedUserFilter({ componentName: 'create-price-agreement', filterName }).subscribe(response => {
      // this.getFilters();
      this.userFilters = this.userFilters.filter(f => {
        return f.filterName !== filterName
      })
    }, error => {
      console.log(error);
    });
  }

  /**
   *Function to clear all filter 
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public clearFilter() {
    this.savedFilterValue = undefined;
    this.filterArr = [];
    this.getChildFilter({
      opcoId: [],
      materialId: [],
      paStatusId: [],
      validity: null
    });
    this.filterObjToSave = {
      filters: [],
      filterName: '',
      componentName: ''
    };
    this.dropDownFilter.clearFilter();
  }

  /**
   *Function to do search again
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public searchAgain() {
    this.searchText = '';
    if (!this.spnMpnSearch.length) {
    }
    document.getElementById('paSearch').focus();
    this.paginator.pageIndex = 0;
    this.pageNumber = 1;
    if (this.filterCondition.length > 0) {
      const filterWithourSearch = this.filterCondition.filter(function (el) { return el.name != "search"; });
      if (filterWithourSearch.length > 0) {
        this.filterCondition = filterWithourSearch;
      } else {
        this.filterCondition = [];
      }
    }
    this.existingPAForOla();
  }

  /**
   *search function on click
   *
   * @memberof ConfrimSelectEditExistComponent
   */
  public onClickSearch(type) {
    this.paginator.pageIndex = 0;
    this.pageNumber = 1;
    const searchArr: any = [];
    if (type === 'search' && this.searchText.length) {
      searchArr.push({
        name: 'search',
        type: 'search',
        value: [this.searchText]
      });
    }
    if (type === 'spnMpnSearch' && this.spnMpnSearch.length) {
      searchArr.push({
        name: 'spnMpnSearch',
        type: 'all',
        value: this.spnMpnSearch
      });
    }
    if (this.filterCondition.length > 0) {
      const filterWithourSearch = this.filterCondition.filter((el) => { return el.name !== type; });
      if (filterWithourSearch.length > 0) {
        filterWithourSearch.push(...searchArr);
        this.filterCondition = filterWithourSearch;
      } else {
        this.filterCondition = [];
        this.filterCondition.push(...searchArr);
      }
    } else {
      this.filterCondition = searchArr;
    }
    this.existingPAForOla();
  }

  /**
   *search function on enter key
   *
   * @param {KeyboardEvent} event
   * @memberof ConfrimSelectEditExistComponent
   */
  public globalSearch(event, type) {
    if (event.keyCode === 13) {
      this.paginator.pageIndex = 0;
      this.pageNumber = 1;
      const searchArr: any = [];
      if (type === 'search' && this.searchText.length) {
        searchArr.push({
          name: 'search',
          type: 'search',
          value: [this.searchText]
        });
      }
      if (type === 'spnMpnSearch' && this.spnMpnSearch.length) {
        searchArr.push({
          name: 'spnMpnSearch',
          type: 'all',
          value: this.spnMpnSearch
        });
      }

      if (this.filterCondition.length > 0) {
        const filterWithourSearch = this.filterCondition.filter(function (el) { return el.name !== type; });
        if (filterWithourSearch.length > 0) {
          filterWithourSearch.push(...searchArr);
          this.filterCondition = filterWithourSearch;
        } else {
          this.filterCondition = [];
          this.filterCondition.push(...searchArr);
        }
      } else {
        this.filterCondition = searchArr;
      }
      this.existingPAForOla();
    }
  }

  public spnMpnSearchAgain() {
    this.spnMpnSearch = '';
    if (!this.searchText.length) {
    }
    document.getElementById('spnMpnSearch').focus();
    this.paginator.pageIndex = 0;
    this.pageNumber = 1;
    if (this.filterCondition.length > 0) {
      const filterWithourSearch = this.filterCondition.filter(function (el) { return el.name != "spnMpnSearch"; });
      if (filterWithourSearch.length > 0) {
        this.filterCondition = filterWithourSearch;
      } else {
        this.filterCondition = [];
      }
    }

    // this.clearFilter.emit();
    this.existingPAForOla();
  }

  public onChangepaStatus(status, colField) {
    let filterObj = {};
    this.paStatusFilter = status;
    this.dropDownFilter.fromTableFilterCreatePa("paStatusFilter", "equals", status);
  }

  public headerFilterSort(sortHeader) {
    if (this.sortObj.sortType === "asc") {
      this.sortDir = "desc";
    } else if (this.sortObj.sortType === "desc") {
      this.sortDir = "asc";
    }

    this.sortObj = {
      attribName: sortHeader,
      sortType: this.sortDir || "asc",
    };
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.existingPAForOla();
  }

  public headerFilter(type) {
    this.openDropdownType = !this.openDropdownType;
    this.openDropdownName = type;
  }

  public checkText(event, filterName) {
    if (
      this.paSsearchText[filterName] &&
      this.convertNumberFields(this.paSsearchText[filterName]).trim() == ""
    ) {
      this.emptyText = true;
    } else {
      this.emptyText = false;
    }
    if (event.keyCode === 13) {
      this.searchPaFilter(filterName);
    }
  }
  public convertNumberFields(obj) {
    if (typeof obj === "number") {
      return obj.toString();
    } else {
      return obj;
    }
  }

  public searchPaFilter(filterName) {
    let dateFilterValue: any = [];
    if (
      this.paSsearchText[filterName] === undefined ||
      this.selectedFilterType[filterName] === undefined
    ) {
      return;
    }
    if (
      filterName !== "validFromDate" &&
      filterName !== "validToDate" &&
      this.paSsearchText[filterName] !== undefined &&
      this.convertNumberFields(this.paSsearchText[filterName]).trim() == ""
    ) {
      return;
    }
    let filterObj = {};
    if (
      filterName === "validFromDate" ||
      filterName === "validToDate"
    ) {
      const searchDate = moment(this.paSsearchText[filterName]).format(
        "YYYY-MM-DD"
      );
      filterObj = {
        name: filterName,
        type: this.selectedFilterType[filterName], // "equal",
        value: searchDate,
      };
      dateFilterValue.push(searchDate);
    } else {
      const search = this.convertNumberFields(
        this.paSsearchText[filterName === "length" ? "lengths" : filterName]
      ); // for multi search spliting (,) separated value
      let searchValue: any = ""
      const dataPaSearch = search.trim().replace(/\s+/g, ' ');

      if (dataPaSearch.includes(" ") && !dataPaSearch.includes(",")) {
        const search = this.checkSpaceAllowed(filterName) ? [dataPaSearch] : dataPaSearch.split(' ');  // for multi search spliting (,) separated value
        searchValue = search.filter(y => y !== '').map(x => x.trim());
      } else {
        const search = this.paSsearchText[filterName].trim().split(',');  // for multi search spliting (,) separated value
        searchValue = search.filter(y => y !== '').map(x => x.trim());
      }
      filterObj = {
        name:
          filterName === "masterAgreementName" ? "referenceName" : filterName,
        type: this.selectedFilterType[
          filterName === "length" ? "lengths" : filterName
        ],
        value: searchValue,
      };
    }
    // this.tableFilterSearch.emit(filterObj);
    if (filterObj["value"] && filterObj["value"] != "") {
      this.dropDownFilter.fromTableFilterCreatePa(
        filterObj["name"],
        filterObj["type"],
        filterObj["name"] == "validFromDate" || filterObj["name"] == "validToDate"
          ? dateFilterValue
          : filterObj["value"]
      );
    }
  }
  public displayApplierFilters(data) {
    //console.log("displayApplierFilters = ", data);
    if (data.type === "referenceName") {
      return `Price Agreement Reference ${data.filterType === "notEqual"
        ? "Not Equals: "
        : data.filterType === "contains"
          ? "Contains: "
          : "Equals: "
        } ${data.value}`;
    } else if (data.type === "paStatus") {
      return `Status ${data.filterType === "notEqual"
        ? "Not Equals: "
        : data.filterType === "contains"
          ? "Contains: "
          : "Equals: "
        } ${data.value}`;
    } else if (data.type === "vpcOwner") {
      return `Vodafone Owner ${data.filterType === "notEqual"
        ? "Not Equals: "
        : data.filterType === "contains"
          ? "Contains: "
          : "Equals: "
        } ${data.value}`;
    } else if (data.type === "validFromDate") {
      return `Valid From ${data.filterType === "lt"
        ? "Less than: "
        : data.filterType === "lte"
          ? "Less than or Equal: "
          : data.filterType === "gt"
            ? "Greater than: "
            : data.filterType === "gte"
              ? "Greater than or Equal: "
              : "Equals: "
        } ${data.value}`;
    } else if (data.type === "validToDate") {
      return `Valid To ${data.filterType === "lt"
        ? "Less than: "
        : data.filterType === "lte"
          ? "Less than or Equal: "
          : data.filterType === "gt"
            ? "Greater than: "
            : data.filterType === "gte"
              ? "Greater than or Equal: "
              : "Equals: "
        } ${data.value}`;
    } if (data.type === "opcoName") {
      return `Local Market ${data.filterType === "notEqual"
        ? "Not Equals: "
        : data.filterType === "contains"
          ? "Contains: "
          : "Equals: "
        } ${data.value}`;
    } if (data.type === "tradingCurrencyKey") {
      return `Currency ${data.filterType === "notEqual"
        ? "Not Equals: "
        : data.filterType === "contains"
          ? "Contains: "
          : "Equals: "
        } ${data.value}`;
    } else {
      return `${data.filterType === "notEqual"
        ? "Not Equal :"
        : data.filterType === "contains"
          ? "Contains :"
          : "Equals :"
        } ${data.value}`;
    }
  }
  getPayload() {
    this.createPaPageConfig = JSON.parse(
      localStorage.getItem("CreatePA_PageConfig")
    );
    let payload: any = {};
    if (this.createPaPageConfig) {
      this.itemsPerPage = this.createPaPageConfig.itemsPerPage;
      this.pageIndex = this.createPaPageConfig.pageNumber;
      payload = {
        pageNumber:
          this.filterCondition.length > 0
            ? 0
            : this.createPaPageConfig.pageNumber,
        perPage: this.createPaPageConfig.itemsPerPage,
        filters: this.filterCondition,
        sortKey: this.sortObj,
        lookup: this.lookup,
        isRecommendedOnly: this.recomended,
      };
    } else {
      payload = {
        tableStatus: "inProgress",
        pageNumber: this.filterCondition.length > 0 ? 0 : this.pageNumber,
        perPage: this.itemsPerPage,
        filters: this.filterCondition,
        sortKey: this.sortObj,
        lookup: this.lookup,
        isRecommendedOnly: this.recomended,
      };
    }
    return payload;
  }

  checkSpaceAllowed(filter) {
    const filtersWithoutSpace = [
      'vpcOwner'
    ]

    const search = (element) => element == filter;

    return filtersWithoutSpace.some(search);
  }

}

