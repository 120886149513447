import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { TaskServices } from '../../_services/task.service';
import { UsersServices } from '../../_services/users.service';
import { ToggleItemService } from '../../_services/toggle-item.service';
import { LoaderService } from './../../_services/loader.service';
import { PriceManagerServices } from '../../_services/price-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { PriceMessagesComponent } from '../price-messages/price-messages.component';
import { AuthServices } from '../../_services/auth.service';


/**
 *Delegation dialog component
 *
 * @export
 * @class DelegateDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-delegate-dialog',
  templateUrl: './delegate-dialog.component.html',
  styleUrls: ['./delegate-dialog.component.scss']
})
export class DelegateDialogComponent implements OnInit {
  constructor(
    private taskService: TaskServices,
    private userInfoService: UsersServices,
    public toggleItemService: ToggleItemService,
    private loaderService: LoaderService,
    private priceService: PriceManagerServices,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DelegateDialogComponent>,
    private authServices: AuthServices
  ) {
    this.priceAgreementId = data && data.priceAgreementId;
    this.actionType = data && data.actionType;
    this.ngOnInit();
  }

  private priceAgreementId: string;
  public actionType: string;
  public myControl: FormControl = new FormControl();
  public taskId;
  public pageNumber = 1;
  public perPage = 50;
  public search: string;
  public result: any = [];
  public selectedValue: any;
  public selectedData: any;
  public option: any;
  public loggedInEmail: any;
  public errorMessage = '';

  public filteredOptions: Observable<string[]>;
  public dialogData = {
    header: 'Delegate Task',
    message: 'Specify the user you want to delegate this task to.',
    searchBoxMessage: 'Search user to delegate task'
  };

  public ngOnInit() {
    this.loggedInEmail = this.authServices.getEmail();
    if (this.actionType && this.actionType === 'PriceAgreement') {
      this.dialogData = {
        header: 'Delegate Price Agreement',
        message: 'Specify the user you want to delegate this task to.',
        searchBoxMessage: 'Search user to delegate price agreement'
      };
    }
    if (this.actionType && this.actionType === 'DelegateAll') {
      this.dialogData = {
        header: 'Delegate All Pending Tasks',
        // tslint:disable-next-line: max-line-length
        message: 'Specify the user you want to delegate these tasks to. This action will only delegate all your current pending tasks. All new tasks will still be owned by you.',
        searchBoxMessage: 'Search user to delegate price agreement'
      };
    }
  }

  public searchUser(data) {
    if (data.target.value.length >= 3) {
      this.userInfoService.getUserList({
        pageNumber: this.pageNumber,
        perPage: this.perPage,
        searchByName: data.target.value
      }).subscribe(res => {
        this.result = res['result'].rows.filter((o1) => {
          return o1.loginId !== this.loggedInEmail;
        });
      });
    }
  }

  public selectAndSetValue(option) {
    this.errorMessage = '';
    this.selectedData = this.result.find(obj => {
      return obj.loginId === this.selectedValue;
    });
  }

  public delegate() {
    if (this.actionType === 'PriceAgreement') {
      this.delegatePriceAgreement();
    }
    // else if (this.actionType === 'DelegateAll') {
    //   this.delegateAllTask();
    // } else if (this.actionType === 'Delegate') {
    //   this.delegateTask();
    // }
  }

  // public delegateTask() {
  //   if (this.result.length && this.selectedData) {
  //     const user = {
  //       id: this.selectedData.uuid,
  //       //roleId: this.selectedData.role,
  //       name: `${this.selectedData.firstName} ${this.selectedData.lastName}`,
  //       loginId: this.selectedData.loginId
  //     };
  //     this.loaderService.startLoading();
  //     this.taskService.delegateTheTask({ taskId: this.toggleItemService.taskId, user })
  //       .subscribe(() => {
  //         this.taskService.isDelegateRefresh(true);
  //         this.loaderService.stopLoading();
  //         this.dialogRef.close(true);
  //         this.onSaveErrorPopup({
  //           header: 'Success',
  //           message: `You have successfully delegated this task to <span class="blue-span">${this.selectedData.loginId}.</span>`
  //         });
  //       }, err => {
  //         this.loaderService.stopLoading();
  //         this.onSaveErrorPopup({
  //           header: 'Delegate Task',
  //           message: err.error.message
  //         });
  //       });
  //   } else {
  //     this.errorMessage = 'Invalid user email';
  //   }
  // }

  public delegatePriceAgreement() {
    const params = {
      priceAgreementId: this.priceAgreementId,
      userId: this.selectedData.uuid,
      username: `${this.selectedData.firstName} ${this.selectedData.lastName}`
    };

    // this.loaderService.startLoading();
    // this.priceService.delegatePriceAgreement(params).subscribe(() => {
    //   this.loaderService.stopLoading();
    //   this.onSaveErrorPopup({ header: 'Delegate Price Agreement',
    //   message: 'Price agreement has been delegated' });
    // },
    //   // tslint:disable-next-line: align
    //   err => {
    //     this.loaderService.stopLoading();
    //     this.dialogRef.close(err.error.message);
    //     this.onSaveErrorPopup({
    //       header: 'Delegate Price Agreement',
    //       message: err.error.message
    //     });
    //   });
  }

  // public delegateAllTask() {
  //   if (this.result.length && this.selectedData) { // [VPC-122]
  //     const user = {
  //       id: this.selectedData.uuid,
  //       //roleId: this.selectedData.role,
  //       name: `${this.selectedData.firstName} ${this.selectedData.lastName}`,
  //       loginId: this.selectedData.loginId
  //     };
  //     this.loaderService.startLoading();
  //     this.taskService.delegateAllTasks({ user }).subscribe(() => {
  //       this.taskService.isDelegateRefresh(true);
  //       this.dialogRef.close(true);
  //       this.onSaveErrorPopup({
  //         header: 'Delegate All Tasks',
  //         message: `All pending tasks are delegated to <span class="blue-span">${this.selectedData.loginId}</span> successfully.`
  //       });
  //       this.loaderService.stopLoading();
  //       this.taskService.setTasks('success');
  //     }, err => {
  //       this.loaderService.stopLoading();
  //       this.dialogRef.close(false);
  //       this.onSaveErrorPopup({
  //         header: 'Delegate All Task',
  //         message: err.error.message
  //       });
  //     });
  //   } else {
  //     this.errorMessage = 'Invalid user email';  // [VPC-122]
  //   }
  // }

  private onSaveErrorPopup(obj?: { header: string, message: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message
      };
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }

}
