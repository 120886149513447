import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, } from '@angular/material';
import { AppConfig } from '../../../../../_config';
import { AuthServices } from '../../../../../_services/auth.service';
import { PriceManagerServices } from '../../../../../_services/price-manager.service';

@Component({
  selector: 'app-counter-party',
  templateUrl: './counter-party.component.html',
  styleUrls: ['./counter-party.component.scss']
})
export class CounterPartyComponent implements OnInit {
  public isChecked = false;
  public counterEmail = '';
  public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,8}$';
  public isSupplierOnboardedCheck = true;

  public isBuyer: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CounterPartyComponent>,
    public dialog: MatDialog,
    private authService: AuthServices,
    public priceManagerServices: PriceManagerServices,
  ) { }

  public ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
    const paObject = (this.priceManagerServices.getPriceAgreement1()).result;
    if (!this.isBuyer) {
      this.isSupplierOnboardedCheck = true;
    } else {
      this.isSupplierOnboardedCheck = paObject.masterAgreement.isSupplierOnboarded;
    }
  }

  public submit() {
    let result = {};
    if (!this.isChecked && !RegExp(this.emailPattern).test(this.counterEmail)) {
      return;
    }
    result = {
      counterParty: this.counterEmail,
      submitTo: this.data.submittedTo
    };
    this.dialogRef.close(result);
  }

  public cancel() {
    this.dialogRef.close();
  }

  public onChangeCheckBox() {
    this.counterEmail = '';
  }
}
