import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { SavefilterServices } from './../../_services/save-filter.service';
import { FilterServices } from './../../_services/filter.service';
import { PriceMessagesComponent } from '../price-messages/price-messages.component';
import { WarningComponent } from '../warning/warning.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthServices } from "./../../_services/auth.service";
import { PriceManagerServices } from '../../_services/price-manager.service';

/**
 *Popup component for show the save filter form
 *
 * @export
 * @class SaveFiltersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-save-filters',
  templateUrl: './save-filters.component.html',
  styleUrls: ['./save-filters.component.scss']
})
export class SaveDataComponent implements OnInit {

  public savedFilterName: string;
  public success = 'null';
  public filterList: any;
  public isVFUser: boolean = false;
  public role: any;
  public emails: any = [];
  public email: any = '';
  public itemId: any = [];
  approveRole: any = [];


  constructor(public savefilterService: SavefilterServices,
    public dialog: MatDialog,
    private authService: AuthServices,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SaveDataComponent>,
    public pricemanagerServices: PriceManagerServices,
    private filterService: FilterServices) {

  }

  public ngOnInit() {
    this.role = this.authService.getRole();
    if (
      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER")
    ) {
      this.isVFUser = false;
    } else {
      this.isVFUser = true;
    }

    if (this.data && this.data.lineItemData.length > 0) {
      let dataFromEdit = this.data.lineItemData[0];
      for(let i = 0; i < dataFromEdit.length; i++){
        let result = dataFromEdit[i];
        this.itemId.push(result);
      }
      if(this.data.approverMaterialList){
        this.approveRole = this.data.approverMaterialList.flat(Infinity)
      }
    }


    this.pricemanagerServices.getVfUsersDropdown().subscribe((res: any) => {      
      let vodafoneUserInfo = res.result.vodafoneUserInfo;

      // filter
      // this.emails = vodafoneUserInfo.filter(value => value.role===this.role);   
      if(this.role=="SUPER_ADMIN"){
      this.emails = vodafoneUserInfo.filter(obj1 => {
        return   this.approveRole.find(x => x.role === obj1.role);
      });
      } else{
        this.emails = vodafoneUserInfo.filter(value => value.role===this.role); 
      }
   
      })
    // this.savedFilterName = this.savefilterService.savedFilterName;
  }



  /* [VOD-1656] */
  public checkFilterName() {
   // console.log("this.savedFilterName = ", this.savedFilterName)
    this.filterService.setDialogData({
      name: this.savedFilterName
    })
  }
  /* [VOD-1656] */

  public emailAddress() {
    let payload = {
      loginId: this.email,
      materialId: this.itemId
    }

    this.pricemanagerServices.getReassignUser(payload).subscribe((res: any) => {
      let resultAssign = res.result;
      if ((resultAssign.mst.length != 0) || (resultAssign.stg.length != 0)) {
        const obj = {
          header: 'Success',
          message: 'You have successfully reassigned this material/materials.',
          icon: 'success-icon.svg',
          buttonText: 'Done'
        };
        this.onSaveErrorPopup(obj);
      } else {
        const obj = {
          header: 'Save Reassign',
          message: 'Material not reassigned please try again..',
          icon: 'dismiss-success.svg',
          buttonText: 'OK'
        };
        this.onSaveErrorPopup(obj);
      }

    })
  }

  public saveFilters() {
    this.savefilterService.saveData.filterName = this.savedFilterName;
    this.filterService.addUserFilters(this.savefilterService.saveData).subscribe(response => {
      if (response.result['filter_name'] || response.result['filterName']) {
        const obj = {
          header: 'Success',
          message: 'You have successfully saved the applied filters.',
          icon: 'success-icon.svg',
          buttonText: 'Done'
        };
        this.onSaveErrorPopup(obj);
      } else if (!response.result['filter_name']) {
        // this.success = 'false';
        const obj = {
          header: 'Save Filters',
          message: 'Filter not saved please try again..',
          icon: 'dismiss-success.svg',
          buttonText: 'OK'
        };
        this.onSaveErrorPopup(obj);
      }
      this.filterService.setFilter(response);
    },
      error => {
        // console.log(error);
      }
    );
  }
  private onSaveErrorPopup(obj?: { header: string; message: string, icon?: string, buttonText?: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        icon: obj.icon,
        buttonText: obj.buttonText
      };
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }
}
