import { Component } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { AppConfig } from "../app/_config";
import { Router } from "@angular/router";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { PriceMessagesComponent } from "./global-module/price-messages/price-messages.component";
import { AuthServices } from "./_services/auth.service";
import { PriceManagerServices } from "./_services/price-manager.service";
import { ToggleItemService } from "./_services/toggle-item.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private bnIdle: BnNgIdleService,
    public router: Router,
    public dialog: MatDialog,
    private authService: AuthServices,
    private priceManagerServices: PriceManagerServices,
    private toggleItemService: ToggleItemService
  ) {
    this.bnIdle.startWatching(AppConfig.idleLogout).subscribe((res) => {
      if (res) {
        if (this.authService.getToken()) {
          this.dialog.closeAll();
          if (this.toggleItemService.isEditable) {
            this.toggleItemService.showEditedItemOnly = false;
            this.toggleItemService.hideDeletedItems = false;
            this.toggleItemService.showExpiringItems = false;
            const result = this.priceManagerServices.getPriceAgreement1();
            const paId =
              result && result.result && result.result.priceAgreementId;
            if (paId) {
              this.closeActionPriceAgreement(paId);
            } else {
              this.sessionOutPopup();
            }
          } else {
            this.sessionOutPopup();
          }
        }
      }
    });
  }

  private sessionOutPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "price-success-dialog-container";

    dialogConfig.data = {
      header: "Session Timeout",
      message: "Your session has been expired due to inactivity",
      icon: "info-pophover-blue.svg",
      buttonText: "OK",
    };
    this.dialog.open(PriceMessagesComponent, dialogConfig);
    const popupData = this.priceManagerServices.getFormData();
    console.log("datachklog", popupData);
    if (popupData != undefined && popupData.length > 0) {
      this.autoSaveData(popupData);
    }
    this.logout();
  }

  public logout() {
    this.authService.logout().subscribe(
      () => {
        localStorage.clear();
        this.authService.clearUserData();
        this.router.navigate(["/login"]);
      },
      () => {
        localStorage.clear();
        this.authService.clearUserData();
        this.router.navigate(["/login"]);
      }
    );
  }

  private closeActionPriceAgreement(paId: string) {
    this.priceManagerServices
      .lockUnlockPriceAgreement({ priceAgreementId: paId, status: false })
      .subscribe(
        () => {
          this.sessionOutPopup();
        },
        () => {
          this.sessionOutPopup();
        }
      );
  }
  public autoSaveData(popupData: any) {
    // this.loaderService.startLoading();
    const payloadData = {
      materialData: [
        {
          sapMaterialId:
            popupData.sapMaterialId === undefined
              ? null
              : popupData.sapMaterialId,
          materialType: popupData.materialType,
          materialGroup: popupData.materialGroup,
          materialCategory: popupData.materialCategory,
          nature: popupData.nature,
          manufacturerPartNumber: popupData.manufacturerPartNumber,
          manufacturer: popupData.manufacturer,
          materialDescription: popupData.materialDescription,
          materialLongDescription: popupData.materialLongDescription,
          buom: popupData.buom,
          commodityCode: popupData.commodityCode,
          ean: popupData.eanUpc,
          endOfLife: popupData.endOfLife,
          endOfSupport: popupData.endOfSupport,
          usefulLife: parseInt(popupData.usefulLife),
          grossWeight: parseFloat(popupData.grossWeight),
          netWeight: parseFloat(popupData.netWeight),
          height: parseInt(popupData.height),
          length: parseInt(popupData.length),
          width: parseInt(popupData.width),
          hlwUnit: popupData.hlwUnit == "" ? null : popupData.hlwUnit,
          volume: parseInt(popupData.volume),
          volumeUnit: popupData.volumeUnit == "" ? null : popupData.volumeUnit,
          gam: popupData.gam,
          productFamilyCode: popupData.productFamily,
          eccn: popupData.eccn,
          approver: popupData.vodafoneApprover,
          isBomParent: popupData.isBomParent,
          stackingNumber: parseInt(popupData.stackingNumber),
          stackingNorms: parseInt(popupData.stackingNorms),
          supplierPartNumber: popupData.supplierPartNumber,
          isUser: this.isUserSupplierOrVodafoneInMaterial(
            this.authService.getRole()
          ),
        },
      ],
      action: "save",
    };
    if (popupData.serializationProfile == "") {
      payloadData["serializationProfile"] = null;
    } else if (popupData.serializationProfile != "") {
      payloadData["serializationProfile"] = popupData.serializationProfile;
    }
    /*if (this.data != null) {
      payloadData["itemId"] = this.data.itemId;
    }*/
    if (payloadData["isBomParent"] == true) {
      this.priceManagerServices.validateMaterial(payloadData).subscribe(
        (res) => {
          //this.loaderService.stopLoading();
          const materialData = payloadData;
          this.priceManagerServices.bobParentData({ materialData });
        },
        (error) => {
          //  this.loaderService.stopLoading();
        }
      );
    } else {
      this.priceManagerServices.createMaterialData(payloadData).subscribe(
        (res) => {
          //  this.loaderService.stopLoading();

          const materialData = res["result"];

          this.priceManagerServices.bobParentData({ materialData });
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = "succes-dialog-div";
          dialogConfig.data = {
            id: 1,
            header: "Success",
            message: "Successfully saved material data.",
            buttonText: "OK",
          };

          /* const dialogRef = this.dialog.open(
              PriceMessagesComponent,
              dialogConfig
            );
            dialogRef.afterClosed().subscribe((data) => {
              if (
                payloadData["isBomParent"] == false ||
                payloadData["isBomParent"] == null
              ) {
                this.dialogRef.close(true);
              }
            });*/
        },
        (error) => {}
      );
    }
  }
  public isUserSupplierOrVodafoneInMaterial(userrole: any) {
    const user = {
      role: userrole,
    };
    let isUser = "";

    let vodafoneUserRole = [
      "LOCAL_MARKET_MANAGER",
      "CATALOGUE_SPECIALIST",
      "CATEGORY_MANAGER",
      "SOURCING_SPECIALIST",
      "SUPER_ADMIN",
      "LOCAL_MARKET_OVERSEER",
      "DATA_MIGRATION_SPECIALIST",
    ];
    const supplierRole = ["SUPPLIER_ADMIN", "SUPPLIER_MANAGER"];
    const mdmRole = ["MDM_TEAM"];
    if (supplierRole.includes(user.role)) {
      isUser = "supplier";
    }
    vodafoneUserRole = vodafoneUserRole.filter(
      (item) => item !== "SUPER_ADMIN"
    );
    if (vodafoneUserRole.includes(user.role)) {
      isUser = "vodafone";
    }
    if (mdmRole.includes(user.role)) {
      isUser = "MDM_TEAM";
    }
    return isUser;
  }
}
