import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../_config";
import { AuthServices } from "./auth.service";
const { priceUrl, priceUrl1 } = AppConfig;

import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TaskServices {
  private taskTypeFilter = new Subject<any>();
  private taskIdData = new Subject<any>();
  private topMenutaskFilter = new Subject<any>();
  private taskList = new Subject<any>();
  private delegatedRef = new Subject<any>();
  private notificationSocket: any = null;
  private gloabNotificationCall: any = false;
  public activeColumns: any;

  constructor(private http: HttpClient, private authService: AuthServices) { }

  private taskListUrl: string = priceUrl1 + "network/user-task/list";
  private downloadTaskListUrl: string = priceUrl1 + "network/user-task/download";
  private getTaskByAgreementId: string =
    priceUrl1 + "network/price-manager/get-task-id";
  private getNotificationListUrl: string = priceUrl1 + "network/notification";
  private readNotificationUrl: string = priceUrl1 + "network/notification/read";
  private taskListMaterilUrl: string = priceUrl1 + "network/user-task/material-list";
  private pendingTaskListMaterilUrl: string =
    priceUrl1 + "network/user-task/material-list";
  private getAllTaskListUrl: string = priceUrl1 + "network/user-task/get-all-list";
  private getTaskDropdownsUrl: string =
    priceUrl1 + "network/user-task/get-dropdown-list";
  /**
   *Api function to get all task list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; }} data
   * @return {*}
   * @memberof TaskServices
   */
  public getTaskList(data: {
    status: any;
    pageNumber: number;
    perPage: number;
    filters: any;
    sortKey: string;
    lookup: string;
  }) {
    return this.http.post(
      this.taskListUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get all task material list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; }} data
   * @return {*}
   * @memberof TaskServices
   */
  public getTaskMaterialList(data: {
    pageNumber: number;
    perPage: number;
    filters: any;
    sortKey: string;
    lookup: string;
  }) {
    return this.http.post(
      this.taskListMaterilUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get all task list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; }} data
   * @return {*}
   * @memberof TaskServices
   */
  public getAllTaskList(data) {
    return this.http.post(
      this.getAllTaskListUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to call dropdown list task
   *
   * @return {*}
   * @memberof TaskServices
   */
  public getTaskDropdowns() {
    return this.http.get(
      this.getTaskDropdownsUrl,
      this.authService.getAccessHeaders()
    );
  }
  /**
   *Api function to get all pending task material list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; }} data
   * @return {*}
   * @memberof TaskServices
   */
  public getPendingTaskMaterialList(data: any) {
    return this.http.post(
      this.pendingTaskListMaterilUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to download the task list
   *
   * @param {*} data
   * @return {*}
   * @memberof TaskServices
   */
  public downloadTaskList(data) {
    return this.http.post(this.downloadTaskListUrl, data, {
      ...this.authService.getAccessHeaders(),
      responseType: "blob",
    });
  }

  /**
   *Function that set the task type filter
   *
   * @param {*} data
   * @memberof TaskServices
   */
  public setTaskTypeFilter(data: any) {
    this.taskTypeFilter.next({ result: data });
  }

  /**
   *Function that returns the task type filter
   *
   * @return {*}  {Observable<any>}
   * @memberof TaskServices
   */
  public getTaskTypeFilter(): Observable<any> {
    return this.taskTypeFilter.asObservable();
  }

  /**
   *Function to set the task active status
   *
   * @param {*} data
   * @memberof TaskServices
   */
  public setTaskActiveStatus(data: any) {
    this.activeColumns = data;
  }

  /**
   *Function that returns teh task active status
   *
   * @return {*}
   * @memberof TaskServices
   */
  public getTaskActiveStatus() {
    return this.activeColumns;
  }

  /**
   *Function that set the task filter for top menu
   *
   * @param {*} data
   * @memberof TaskServices
   */
  public setTopMenuTaskFilter(data: any) {
    this.topMenutaskFilter.next({ result: data });
  }

  /**
   *Function that return the task filter for top menu as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof TaskServices
   */
  public getTopMenuTaskFilter(): Observable<any> {
    return this.topMenutaskFilter.asObservable();
  }

  /**
   *Function that set the task id
   *
   * @param {*} data
   * @memberof TaskServices
   */
  public setTaskId(data: any) {
    this.taskIdData.next({ result: data });
  }

  /**
   *Function that return the task id as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof TaskServices
   */
  public getTaskId(): Observable<any> {
    return this.taskIdData.asObservable();
  }

  /**
   *Function that return the tasks data
   *
   * @param {*} data
   * @memberof TaskServices
   */
  public setTasks(data: any) {
    this.taskList.next({ result: data });
  }

  /**
   *Function that return the task list as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof TaskServices
   */
  public getTasks(): Observable<any> {
    return this.taskList.asObservable();
  }

  /**
   *Api function that returns task list based on price agreement id
   *
   * @param {*} priceAgreementId
   * @return {*}
   * @memberof TaskServices
   */
  public getTaskIdWithAgreementId(priceAgreementId) {
    const url = `${this.getTaskByAgreementId
      }?priceAgreementId=${encodeURIComponent(priceAgreementId)}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Api function that returns notification list
   *
   * @param {*} params
   * @return {*}
   * @memberof TaskServices
   */
  public getNotificationList(params) {
    const url = `${this.getNotificationListUrl}`;
    return this.http.get(url, {
      ...this.authService.getAccessHeaders(),
      params,
    });
  }

  /**
   *Api function to mark notification as read
   *
   * @param {*} data
   * @return {*}
   * @memberof TaskServices
   */
  public readNotification(data) {
    return this.http.post(
      this.readNotificationUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Function that set the delegated task status
   *
   * @param {boolean} data
   * @memberof TaskServices
   */
  public isDelegateRefresh(data: boolean) {
    this.delegatedRef.next(data);
  }

  /**
   *Function that returns the delegated task status as Observable
   *
   * @return {*}  {Observable<boolean>}
   * @memberof TaskServices
   */
  public getIsDelegateRefresh(): Observable<boolean> {
    return this.delegatedRef.asObservable();
  }

  /**
   *Function to set the notification socket
   *
   * @param {*} socket
   * @memberof TaskServices
   */
  public setNotificationSocket(socket: any) {
    this.notificationSocket = socket;
  }

  /**
   *Function to get the notification socket
   *
   * @return {*}  {*}
   * @memberof TaskServices
   */
  public getNotificationSocket(): any {
    return this.notificationSocket;
  }

  /**
   *Function to set the global notification flag
   *
   * @param {boolean} value
   * @memberof TaskServices
   */
  public setGlobalNotificationCallFlag(value: boolean) {
    this.gloabNotificationCall = value;
  }

  /**
   *Function to get the global notification flag
   *
   * @return {*}  {boolean}
   * @memberof TaskServices
   */
  public getGlobalNotificationCallFlag(): boolean {
    return this.gloabNotificationCall;
  }
}
