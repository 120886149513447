import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-by-breakdown',
  templateUrl: './analytics-by-breakdown.component.html',
  styleUrls: ['./analytics-by-breakdown.component.scss']
})
export class AnalyticsByBreakdownComponent implements OnInit {

  constructor() { }

  public ngOnInit() {
  }
}
