// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PriceManagerServices } from '../../../../../_services/price-manager.service';
import { ToggleItemService } from '../../../../../_services/toggle-item.service';
import { PublisherService } from '../../../../../_services/publisher.service';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-catalogue-group',
  templateUrl: './catalogue-group.component.html',
  styleUrls: ['./catalogue-group.component.scss']
})
export class CatalogueGroupComponent implements OnInit {

  public masterAgreement: any;
  public isVisible = false;
  public currencyData;
  public data: any;
  public finalData;
  public currencyObj = '';
  public catalogueGroupList = [];
  public selectedCatalogueGroup: string;
  public originalCatalogueGroup: string;
  public previouslySelected: string;
  public isShowNote = true;
  @Output() public updateCatalogueGroup = new EventEmitter<any>();
  public isClickedEdit = false;
  public transactionDifference: any;
  public previousVersionDate: Date;
  public catalogueListSubscriber: Subscription = null;
  public catalogueSelectedSubscriber: Subscription = null;
  constructor(
    public priceManagerServices: PriceManagerServices,
    public toggleItemService: ToggleItemService,
    public publisherService: PublisherService
  ) { }
  public ngOnInit() {
    const response = this.priceManagerServices.getPriceAgreementVersionDifferenceResponse1();
    this.transactionDifference = response ? response.catalogueGroup : null;
    this.priceManagerServices.getPriceAgreementVersionDifferenceResponse().subscribe(data => {
      this.transactionDifference = data ? data['result'].catalogueGroup : null;
    });
    const objList = this.publisherService.getCatalogueGroupObjList();
    const objGroup = this.publisherService.getCatalogueGroupObj();
    if (objList) {
      this.catalogueGroupList = objList;
      if (this.catalogueGroupList.length === 1) {
        this.selectedCatalogueGroup = this.catalogueGroupList[0];
        this.originalCatalogueGroup = this.selectedCatalogueGroup;
        this.previouslySelected = this.selectedCatalogueGroup;
        this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
      }
    }

    if (objGroup) {
      this.selectedCatalogueGroup = objGroup;
      this.originalCatalogueGroup = objGroup;
      this.isClickedEdit = false;
      this.previouslySelected = this.selectedCatalogueGroup;
      this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
    } else {
      this.isClickedEdit = true;
      this.selectedCatalogueGroup = null;
      this.originalCatalogueGroup = null;
      this.previouslySelected = null;
    }

    this.catalogueListSubscriber = this.publisherService.getCatalogueGroupList().subscribe((data) => {
      this.catalogueGroupList = data;
      if (this.catalogueGroupList.length === 1) {
        this.selectedCatalogueGroup = this.catalogueGroupList[0];
        this.originalCatalogueGroup = this.selectedCatalogueGroup;
        this.previouslySelected = this.selectedCatalogueGroup;
        this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
      }
    });
    this.catalogueSelectedSubscriber = this.publisherService.getCatalogueGroup().subscribe((data) => {
      if (!data) {
        this.isClickedEdit = true;
        this.selectedCatalogueGroup = null;
        this.originalCatalogueGroup = null;
        this.previouslySelected = null;
        return;
      }
      this.isClickedEdit = false;
      this.selectedCatalogueGroup = data;
      this.originalCatalogueGroup = data;
      this.previouslySelected = this.selectedCatalogueGroup;
      this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
    });
  }

  /**
   **Function called when click from price agreement grid
   *open/close the tab
   *
   * @memberof CatalogueGroupComponent
   */
  public resetCatalogueGroup() {
    if (this.isClickedEdit && this.originalCatalogueGroup) {
      this.isClickedEdit = false;
    } else {
      document.getElementById('collapse-8').classList.remove('show');
    }
    this.selectedCatalogueGroup = this.originalCatalogueGroup;
    this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
    this.publisherService.setCatalogueGroup(this.selectedCatalogueGroup);
  }

  /**
   *Function to apply the catalogue group to the selected line items
   *
   * @memberof CatalogueGroupComponent
   */
  public applyCatalogueGroup() {
    this.updateCatalogueGroup.emit(this.selectedCatalogueGroup);
    this.publisherService.setCatalogueGroup(this.selectedCatalogueGroup);
    this.previouslySelected = this.selectedCatalogueGroup;
    this.originalCatalogueGroup = this.selectedCatalogueGroup;
    this.isClickedEdit = false;
  }

  public showNote() {
    this.isShowNote = true;
  }
  public hideNote() {
    this.isShowNote = false;
  }

  public onClickEdit() {
    this.isClickedEdit = true;
  }

  ngOnDestroy() {
    if (this.catalogueListSubscriber) {
      this.catalogueListSubscriber.unsubscribe();
    }
    if (this.catalogueSelectedSubscriber) {
      this.catalogueSelectedSubscriber.unsubscribe();
    }
  }
}
