import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { PriceManagerServices } from "../../../../../_services/price-manager.service";
import { LoaderService } from "../../../../../_services/loader.service";
import { ExcelService } from "../../../../../_services/excel.service";

@Component({
  selector: "agreement-save-error-list-popup",
  templateUrl: "agreement-save-error-list-popup.component.html",
  styleUrls: ["agreement-save-error-list-popup.component.scss"],
})
export class AgreementSaveErrorListMatComponent implements OnInit {
  public errorArray: any;
  public actionFor: string | null = null;
  public comments: string | null = null;
  public materialLock: boolean = false;
  public ValidationErrorPopup: boolean = true;
  public businessJustificationPopup: boolean = false;
  public isExpectionErrorPresent = false;
  public isMandatoryPresent = false;
  public isObjectLockPresent = true;
  public businessJustification: string | null = null;
  public isCreateMaterialSubmit: boolean = false;
  public readyForSaveDraft: boolean = false;
  public serverErrorMsg: string = "";
  public serverFlag: boolean = false;
  notValid: boolean = false;
  public titleforserverAndOla: string | null = null;
  notbeZero: boolean = false;
  exsistingMaterialCheck: boolean = false;
  maxLength: boolean = false;
  public headers: any[] = [
    {
      field: "materialCategory",
      name: "Physical Type"
    },
    {
      field: "manufacturerPartNumber",
      name: "Manufacturer Part Number"
    },
    {
      field: "supplierPartNumber",
      name: "Supplier Part Number"
    },
    {
      field: "productFamilyCode",
      name: "Product Family"
    },
    {
      field: "materialDescription",
      name: "Material Description"
    },
    {
      field: "materialLongDescription",
      name: "Material Long Description"
    },
    {
      field: "oldMaterial",
      name: "Old Material/Substitute"
    },
    {
      field: "buom",
      name: "Base Unit Of Measure"
    },
    {
      field: "materialGroup",
      name: "Material Group (L4)"
    },
    {
      field: "commodityCode",
      name: "Commodity Code Taric"
    },
    {
      field: "grossWeight",
      name: "Gross Weight (KG)"
    },
    {
      field: "netWeight",
      name: "Net Weight (KG)"
    },
    {
      field: "characteristicValue",
      name: "Asset Value"
    },
    {
      field: "subCharacteristicValue",
      name: "Sub Asset Value"
    },
    {
      field: "length",
      name: "Length"
    },
    {
      field: "width",
      name: "Width"
    },
    {
      field: "height",
      name: "Height"
    },
    {
      field: "hlwUnit",
      name: "LWH Unit"
    },
    {
      field: "volume",
      name: "Volume"
    },
    {
      field: "volumeUnit",
      name: "Volume Unit"
    },
    {
      field: "nature",
      name: "Nature"
    },
    {
      field: "serializationProfile",
      name: "Serialization"
    },
    {
      field: "endOfLife",
      name: "End of Life"
    },
    {
      field: "endOfSupport",
      name: "End of Support"
    },
    {
      field: "usefulLife",
      name: "Useful Life (In years)"
    },
    {
      field: "ean",
      name: "EAN/UPC"
    },
    {
      field: "eccn",
      name: "ECCN"
    },
    {
      field: "gam",
      name: "Global Account Manager"
    },
    {
      field: "auom1",
      name: "AUOM - 1"
    },
    {
      field: "unitConversion1",
      name: "Unit Conversion-1"
    },
    {
      field: "auomQty1",
      name: "AUOM Quantity - 1"
    },
    {
      field: "stackingNumber",
      name: "Stacking Number"
    },
    {
      field: "stackingNorms",
      name: "Stacking Norms"
    },
    {
      field: "auom2",
      name: "AUOM - 2"
    },
    {
      field: "unitConversion2",
      name: "Unit Conversion-2"
    },
    {
      field: "auomQty2",
      name: "AUOM Quantity - 2"
    },
    {
      field: "auom3",
      name: "AUOM - 3"
    },
    {
      field: "unitConversion3",
      name: "Unit Conversion-3"
    },
    {
      field: "auomQty3",
      name: "AUOM Quantity - 3"
    },
    {
      field: "childComponent",
      name: "Child Component"
    },
    {
      field: "percentageOfParent",
      name: "% Child On Father"
    },
    {
      field: "childComponentQuantity",
      name: "Child Component Quantity"
    },
    {
      field: "bomParentMpn",
      name: "BOM Parent MPN"
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<AgreementSaveErrorListMatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public priceManagerService: PriceManagerServices,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    public excelService: ExcelService
  ) {
    this.actionFor = data.actionFor;
    this.comments = data.comments
    if (this.actionFor === "materialEditChangeLockError") {
      this.materialLock = true;
    } else if (this.actionFor === "projectIdEditChangeLockError") {
      this.materialLock = false;
    }
    //this.module = data.module;
    const displayArray: any = data.errorArray;
    const finalDisplayArray: any = [];
    // if (this.actionFor && this.actionFor === 'synchronizeApprovedItemsPAErrors') {
    //   displayArray.map((item) => {
    //     finalDisplayArray.push({
    //       error: item.error,
    //       errorDetails: item.errorDetails,
    //     })
    //   })
    // } else {
    //   const uniqueIndexes = {}
    //   let index = 0
    //   displayArray.map((item) => {
    //     let key = `${item.supplierPartNumber}_${item.manufacturerPartNumber || null}_${item.projectId || null}`
    //     if (item && (item.lineNumber === 0 || item.lineNumber)) {
    //       key = item.lineNumber
    //     }
    //     if (uniqueIndexes && (uniqueIndexes[key] === 0 || uniqueIndexes[key])) {
    //       item.error = `${finalDisplayArray[uniqueIndexes[key]].error} <br /> ${item.error}`
    //       finalDisplayArray[uniqueIndexes[key]] = item;
    //     } else {
    //       finalDisplayArray.push(item);
    //       uniqueIndexes[key] = index
    //       index++
    //     }
    //     return true
    //   })
    // }

    if (this.actionFor == "inCreate") {
      this.isCreateMaterialSubmit = true;
    }

    if (this.actionFor && this.actionFor === "materialEditChangeLockError") {
      displayArray.map((item) => {
        finalDisplayArray.push({
          manufacturerPartNumber: item.manufacturerPartNumber,
          supplierPartNumber: item.supplierPartNumber,
          errorDetails: item.msg,
        });
      });
    } else if (
      this.actionFor &&
      this.actionFor === "projectIdEditChangeLockError"
    ) {
      displayArray.map((item) => {
        finalDisplayArray.push({
          projectId: item.projectId,
          supplierPartNumber: item.supplierPartNumber,
          errorDetails: item.msg,
        });
      });
    } else if (this.actionFor === "inCreate") {
      var hasExceptional = false;
      displayArray.forEach((item) => {
        if (item.errorMessages) {
          item.errorMessages.forEach((errorMessage) => {
            var neStr = errorMessage.message.replace("Error: Fields [", "");
            if (neStr.includes("are exceptional")) {
              neStr = neStr.replace("] are exceptional.", "");
              hasExceptional = true;
              this.notValid = false;
              this.isExpectionErrorPresent = true;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
            } else if (neStr.includes("not valid")) {
              hasExceptional = false;
              this.notValid = true;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
              // neStr = neStr.replace("] not valid", "");
            } else if (neStr.includes("zero or null")) {
              this.notbeZero = true;
              hasExceptional = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
              neStr = neStr.replace("] should not be zero or null", "");
            } else if (neStr.includes("] are mandatory")) {
              neStr = neStr.replace("] are mandatory", "");
              hasExceptional = false;
              this.isMandatoryPresent = true;
              this.notValid = false;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
            } else if (neStr.includes("] Material Description")) {
              neStr = neStr.replace("] Maximum of 40 characters limit has been exceeded", "");
              hasExceptional = false;
              this.isMandatoryPresent = false;
              this.notValid = false;
              this.notbeZero = false;
              this.exsistingMaterialCheck = false;
              this.maxLength = true
            } else if (neStr.includes("Alternate material")) {
              neStr = neStr.replace("Alternate material for Child", " ")
              neStr = neStr.replace("alternateMaterialForChild", "Alternate Material for Child-");
              hasExceptional = false;
              this.notValid = false;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = true;
            }
            else {
              hasExceptional = false;
              this.notValid = false;
              this.notbeZero = false;
              this.exsistingMaterialCheck = false;
              this.isMandatoryPresent = false;
              this.maxLength = false;
            }

            neStr = neStr.split(",");
            neStr.forEach((itemvalueField) => {
              let matchedObj = this.headers.find(obj => obj.field.trim() == itemvalueField.trim())

              let itemvalue = matchedObj ? matchedObj.name : itemvalueField

              if (hasExceptional && !this.notValid && !this.notbeZero && !this.maxLength) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " is Exceptional",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && this.isMandatoryPresent && !this.maxLength && !this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " is Mandatory",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && this.notValid && !this.notbeZero && !this.maxLength && !this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue,
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && this.notbeZero && !this.maxLength && !this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " should not be zero or null",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && this.maxLength && !this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field – Material Description character limit of 40 has been exceeded",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && !this.maxLength && this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + ".",
                  errorDetails: item.material,
                });
              } else {
                finalDisplayArray.push({
                  error: itemvalue + ".",
                  errorDetails: item.material,
                });
              }
            });
          });
        }
        if (
          item.errorSerializationMessages &&
          item.errorSerializationMessages.length > 0 &&
          item.errorSerializationMessages !== null
        ) {
          finalDisplayArray.push({
            error: item.errorSerializationMessages[0].message,
            //errorDetails: item.errorSerializationMessages[0].message
          });
        }
      });
    } else if (this.actionFor === null) {
      var hasExceptional = false;
      displayArray.forEach((item: any) => {
        if (item.errorMessages) {
          for (let i = 0; i < item.errorMessages.length; i++) {
            var neStr = item.errorMessages[i].message.replace(
              "Error: Fields [",
              ""
            );
            if (neStr.includes("are exceptional")) {
              neStr = neStr.replace("] are exceptional.", "");
              hasExceptional = true;
              this.notValid = false;
              this.isExpectionErrorPresent = true;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
            } else if (neStr.includes("not valid")) {
              hasExceptional = false;
              this.notValid = true;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
              // neStr = neStr.replace("] not valid", "");
            } else if (neStr.includes("zero or null")) {
              this.notbeZero = true;
              hasExceptional = false;
              this.exsistingMaterialCheck = false;
              this.maxLength = false;
              neStr = neStr.replace("] should not be zero or null", "");
            } else if (neStr.includes("] are mandatory")) {
              neStr = neStr.replace("] are mandatory", "");
              hasExceptional = false;
              this.isMandatoryPresent = true;
              this.notValid = false;
              this.notbeZero = false;
              this.exsistingMaterialCheck = false;
              this.maxLength = false
            } else if (neStr.includes("] Material Description")) {
              neStr = neStr.replace("] Material Description – Maximum of 40 characters limit has been exceeded", "");
              hasExceptional = false;
              this.isMandatoryPresent = false;
              this.notValid = false;
              this.exsistingMaterialCheck = false;
              this.notbeZero = false;
              this.maxLength = true
            } else if (neStr.includes("Alternate material")) {
              neStr = neStr.replace("Alternate material for Child", " ")
              neStr = neStr.replace("alternateMaterialForChild", "Alternate Material for Child -");
              hasExceptional = false;
              this.notValid = false;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = true;
            }
            else {
              hasExceptional = false;
              this.notValid = false;
              this.notbeZero = false;
              this.maxLength = false;
              this.exsistingMaterialCheck = false;
            }


            neStr = neStr.split(",");
            neStr.forEach((itemvalueField) => {
              let matchedObj = this.headers.find(obj => obj.field.trim() == itemvalueField.trim())

              let itemvalue = matchedObj ? matchedObj.name : itemvalueField

              if (hasExceptional && !this.notValid && !this.notbeZero && !this.maxLength) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " is Exceptional",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && this.isMandatoryPresent && !this.maxLength && !itemvalue.includes("%Child") && !itemvalue.includes("Minimum")) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " is Mandatory",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && !this.maxLength && itemvalue.includes("%Child")) {
                if (itemvalue.includes('%Child of Father') && item.material.bomMaterial == "Child") {
                  //do nothing
                } else {
                  finalDisplayArray.push({
                    error: itemvalue,
                    errorDetails: item.material,
                  });
                }
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && this.isMandatoryPresent && !this.maxLength && itemvalue.includes("Minimum Two Children")) {
                finalDisplayArray.push({
                  error: itemvalue,
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && this.notValid && !this.notbeZero && !this.maxLength) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue,
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && this.notbeZero) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + " should not be zero or null",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && this.maxLength) {
                finalDisplayArray.push({
                  error: "Field – Material Description character limit of 40 has been exceeded",
                  errorDetails: item.material,
                });
              } else if (!hasExceptional && !this.notValid && !this.notbeZero && !this.maxLength && this.exsistingMaterialCheck) {
                finalDisplayArray.push({
                  error: "Field - " + itemvalue + ".",
                  errorDetails: item.material,
                });
              } else {
                finalDisplayArray.push({
                  error: itemvalue + ".",
                  errorDetails: item.material,
                });


              }
            });
          }
        }
        if (
          item.errorSerializationMessages &&
          item.errorSerializationMessages.length > 0 &&
          item.errorSerializationMessages !== null
        ) {
          finalDisplayArray.push({
            error: item.errorSerializationMessages[0].message,
            //errorDetails: item.errorSerializationMessages[0].message
          });
        }
      });
    } else if (this.actionFor && this.actionFor === "serevrError") {
      this.titleforserverAndOla = "Error";
      this.serverFlag = true;
      this.serverErrorMsg = "Error Occurred : Kindly contact system Admin";
    } else if (this.actionFor && this.actionFor === "olaexpire") {
      this.titleforserverAndOla = "Error";
      this.serverFlag = true;
      this.serverErrorMsg =
        "The selected OLA's validity is expired. Please select a valid OLA to create Project ID";
    } else {
      displayArray.forEach((item) => {
        if (item.errorMessages) {
          var neStr = item.errorMessages[0].message.replace(
            "Error: Fields [",
            ""
          );

          neStr = neStr.replace("] are mandatory", "");
          neStr = neStr.split(",");
          neStr.forEach((itemvalue) => {
            finalDisplayArray.push({
              error: "Field - " + itemvalue + " is mandatory",
              errorDetails: item.material,
            });
          });
        }
        if (
          item.errorSerializationMessages &&
          item.errorSerializationMessages.length > 0 &&
          item.errorSerializationMessages !== null
        ) {
          finalDisplayArray.push({
            error: item.errorSerializationMessages[0].message,
            //errorDetails: item.errorSerializationMessages[0].message
          });
        }
      });
    }


    const uniqueArray = finalDisplayArray.reduce((accumulator, current) => {
      accumulator.push(current);
      return accumulator;
    }, []);

    this.errorArray = uniqueArray
    //this.errorArray = finalDisplayArray;
    //console.log(this.errorArray, uniqueArray, finalDisplayArray)

    if (
      this.data.isObjectLockPresent != null &&
      this.data.isObjectLockPresent == false
    ) {
      this.isObjectLockPresent = false;
    } else {
      this.isObjectLockPresent = true;
    }
  }

  public ngOnInit() { }

  public onDoneClick(e) {
    if (this.isExpectionErrorPresent && !this.isMandatoryPresent) {
      this.ValidationErrorPopup = false;
      this.businessJustificationPopup = true;
    } else {
      let result = {
        businessJustification: null,
        cancelClicked: true,
      };
      this.dialogRef.close(result);
      //Close the popup
    }
  }

  public returnBusinessJustification(cancelClicked) {
    if (this.comments) {
      let result = {
        businessJustification: this.comments,
        cancelClicked: cancelClicked,
      };
      this.dialogRef.close(result);
    } else {
      let result = {
        businessJustification: this.businessJustification,
        cancelClicked: cancelClicked,
      };
      this.dialogRef.close(result);
    }
  }

  public downloadErrData() {
    const headers = [
      {
        id: "manufacturerPartNumber",
        header: "Manufacturer Part Number",
        width: 20,
        style: { alignment: { wrapText: true } },
      },
      // { id: 'supplierPartNumber', header: 'Supplier Part Number', width: 20, style: { alignment: { wrapText: true } } },
      {
        id: "materialDescription",
        header: "Material Description",
        width: 20,
        style: { alignment: { wrapText: true } },
      },
      // { id: 'manufacturerPartNumber', header: 'Manufacturer Part Number', width: 20, style: { alignment: { wrapText: true } } },
      // { id: 'projectId', header: 'Project Id', width: 20, style: { alignment: { wrapText: true } } },
      {
        id: "error",
        header: "Error Detected",
        width: 20,
        style: { alignment: { wrapText: true } },
      },
    ];
    const errData = this.errorArray.map((obj) => {
      const row = headers.map((column) => {
        if (column.id === "supplierPartNumber") {
          return obj.supplierPartNumber;
        } else if (column.id === "materialDescription") {
          return obj.errorDetails.materialDescription;
        } else if (column.id === "projectId") {
          return obj.projectId;
        } else if (column.id === "error") {
          return obj.error;
        } else if (column.id === "manufacturerPartNumber") {
          return obj.errorDetails.manufacturerPartNumber;
        }
      });
      return row;
    });
    this.excelService.exportAsExcelFile(
      errData,
      headers,
      `Material-Error-${new Date()}`
    );
  }
}
