import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import { MaterialErrorPopupComponent } from "../../../material-and-bom/add-new-material/material-add/material-error-popup/material-error-popup.component";
import { PopupCommentsComponent } from "../../../material-and-bom/add-new-material/material-add/popup-comments/popup-comments.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material";
import { SuccessDialogComponent } from "../../../../global-module/success-dialog/success-dialog.component";
import { WarningComponent } from "../../../../global-module/warning/warning.component";
import { PriceMessagesComponent } from "../../../../global-module/price-messages/price-messages.component";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { AuthServices } from "../../../../_services/auth.service";
import { PriceManagerServices } from "../../../../_services/price-manager.service";
import { LoaderService } from "../../../../_services/loader.service";
import { Module, AllModules, GridApi } from "@ag-grid-enterprise/all-modules";
import {
  ActivatedRoute,
  Router,
  RouterLinkActive,
  Routes,
} from "@angular/router";
import { Subscription } from "rxjs";
import { PublisherService } from "src/app/_services/publisher.service";
import { DatePipe } from "@angular/common";
import { RejectPopupProjectidComponent } from "./create-project-id/reject-popup-projectid/reject-popup-projectid.component";
import { AgreementSaveErrorListMatComponent } from "src/app/dashboard/material-and-bom/create-new-material/shared-components/agreement-save-error-list-popup/agreement-save-error-list-popup.component";
import * as moment from 'moment';
@Component({
  selector: "app-add-new-project-id",
  templateUrl: "./add-new-project-id.component.html",
  styleUrls: ["./add-new-project-id.component.scss"],
})
export class AddNewProjectIdComponent implements OnInit, AfterViewInit {
  @ViewChild("createProjectIdComp", { static: false }) createProjectIdComp;
  @ViewChild("priceErosionProjectId", { static: false }) priceErosionProjectId;
  public createOption;
  public hide: boolean;
  selectedIndex;
  projectIdEdit = false;
  showPriceErosionTab = false;
  hidshowFlagPriceErosion = false;
  public fromEdit = false;
  isPrcErosEditable = true;
  public selectedPriceItemForView: any;
  public selectedTimePeriod = 'financial';
  public periods = [];
  private contractValidFrom: Date;
  private contractValidTo: Date;

  actionType = ''
  subscription$ = new Subscription();
  selectedIndexChange(val: number) {
    this.selectedIndex = val;
  }
  public isApproved: boolean = false;
  isViewInitialized = false;
  public projectIdForm: FormGroup;
  public submitted = false;
  public role: string;
  public roleDisply: string;
  disableSelect = new FormControl(false);
  formDataValueSelected: any;
  disabled: boolean;
  editable: boolean;
  dropDownData: any;
  maxLength: number = 16;
  public api: GridApi;
  isRequired: boolean = false;
  changeedit: boolean = false;
  datePipe = new DatePipe("en-US");
  dataProjectId: any;
  tradingModel: any;
  canEditProjectOwner = true;
  validationErrormsg: string = '';
  btnType: string = ''
  dateDisabledate: boolean = false;
  btnTxt: boolean = false;
  buttonText: boolean = false
  headerProjectId: any = '';
  afterRejectCreaterFlag: boolean = false;
  navLinks: any = [];
  inUpdate: boolean = false;

  headerName: any = "";
  headerStatus: any = "";
  headerDesc: any = "";
  public paStatusArr = [
    {
      key: "APPROVED",
      value: "Approved",
      icon: "approved-new.svg",
      type: "paStatus",
    },
    {
      key: "MANDATORY_FIELD_PENDING",
      value: "Pending",
      icon: "draft.svg",
      type: "paStatus",
    },
    {
      key: "APPROVAL_PENDING",
      value: "Approval Pending",
      icon: "approval-pending.svg",
      type: "paStatus",
    },
    {
      key: "ITEMS_EXPIRED",
      value: "Expired",
      icon: "expired-red.svg",
      type: "paStatus",
    },
    {
      key: "ITEM_EXPIRED",
      value: "Expired",
      icon: "expired-red.svg",
      type: "paStatus",
    },
    {
      key: "ITEMS_DELETED",
      value: "All Items Deleted",
      icon: "items-deleted.svg",
      type: "paStatus",
    },
    {
      key: "INACTIVE",
      value: "All Items Inactive",
      icon: "items-inactive.svg",
      type: "paStatus",
    },
    {
      key: "DELETED",
      value: "Deleted Material And BOM",
      icon: "deleted-pa.svg",
      type: "paStatus",
    },
    {
      key: "SYNCHRONIZATION_ERROR",
      value: "Sync Failed",
      icon: "pending-synch.svg",
      type: "paStatus",
    },
    {
      key: "PENDING",
      value: "Pending",
      icon: "under-review.svg",
      type: "paStatus",
    },
    {
      key: "ITEM_DELETED",
      value: "ITEM_DELETED",
      icon: "items-deleted.svg",
      type: "paStatus",
    },
    {
      key: "ITEM_REJECTED",
      value: "ITEM_REJECTED",
      icon: "inactive.svg",
      type: "paStatus",
    },
    {
      key: "CREATION_REJECTED",
      value: "Creation Rejected",
      icon: "inactive.svg",
      type: "paStatus",
    },
    {
      key: "CHANGE_REJECTED",
      value: "Change Rejected",
      icon: "inactive.svg",
      type: "paStatus",
    },
    {
      key: "IN_PROGRESS",
      value: "inProgress",
      icon: "draft.svg",
      type: "paStatus",
    },
  ];
  // Static Date foramt list
  private dateFormats = [
    'MM/DD/YYYY', 'dd/MM/yyyy', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD',
    'YYYY/MM/DD', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'DD MMM YYYY', 'DD/MM/YYYY'
  ];
  isSave: boolean = true;
  showChange: boolean = true;
  showEdit: boolean = true;
  showUpdate: boolean = true;
  userEmail: string = "";
  projectIdDataOp;
  constructor(
    public _router: Router,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddNewProjectIdComponent>,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public pricemanagerServices: PriceManagerServices,
    private publisherServices: PublisherService,
    public authServices: AuthServices,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService
  ) {
    if (data.projectId) {
      // this.headerName = "Edit/Change Project ID"; commented by ankita
      this.headerName = data.projectId;
      this.headerStatus = data.itemStatus;
      this.headerDesc = data.projectDescription;
      PublisherService.editProjectId = true;
    } else {
      this.headerStatus = "IN_PROGRESS";
      this.headerDesc = '';
      PublisherService.editProjectId = false;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.role = this.authServices.getRole();
    this.isSubmitVisible();
    if (this.data.projectId == null) {
      this.disabled = false;
      this.editable = true;
    } else {
      this.disabled = true;
      this.editable = false;
    }
    this.navLinks =
      this.route.routeConfig && this.route.routeConfig.children
        ? this.buildNavItems(this.route.routeConfig.children)
        : [];
    console.warn("----nav links founded: ", this.navLinks);
    this.userEmail = this.authServices.getEmail();
    this.pricemanagerServices
      .getSingleProjectId(this.data.projectId)
      .subscribe((resp: any) => {
        if (resp) {
          this.dataProjectId = resp.result;

          if (this.dataProjectId && this.dataProjectId.createdBy !== undefined && this.authServices.getUserId() === this.dataProjectId.createdBy) {
            this.afterRejectCreaterFlag = true;
          }
        }
      });
    if (this.data.btnType !== undefined) {
      this.validationErrormsg = this.data.validationErrormsg;
    }
    /// Validation failed then show Submit button
    if (this.data && this.data.buttonText !== undefined) {
      this.buttonText = this.data.buttonText;
    }
    if (this.data && this.data.btnTxt !== undefined) {
      this.btnTxt = this.data.btnTxt;
    }
    if (this.data.btnType !== undefined) {
      this.editable = false;
      this.disabled = false
      this.changeedit = true;
      if (this.data.btnType === "Change") {
        this.isSave = false;
        this.showChange = false;
      } else if (this.data.btnType === "Edit") {
        this.showEdit = false;
        this.isSave = true;
      } else if (this.data.btnType === "Update") {
        this.showUpdate = false;
        this.isSave = true;
      }
    }
  }

  headerProjectIdHandler(data) {
    this.headerName = data;
  }

  public onSave() {
    let createFrom = this.createOption;
    this.dialogRef.close(true);
  }
  ngOnDestroy() {
    this.subscription$.unsubscribe();
    console.warn("---- Dialog was destroyed ----");
    //   this.router.navigate(['']);
  }

  ngAfterViewInit() {
    this.isViewInitialized = true;
    this.changeDetector.detectChanges();
  }

  buildNavItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = "", data }) => ({
        path: path,
        label: data.label,
        icon: data.icon,
      }));
  }
  //
  public async selectedIsProjectId(id: any): Promise<void> {
    this.pricemanagerServices.isProjectId.next(id);
  }

  isLinkActive(rla: RouterLinkActive): boolean {
    const routerLink = rla.linksWithHrefs.first;

    return this.router.isActive(routerLink.urlTree, false);
  }

  tabClick(event) {
    if (event.index == 1) {
      // console.log("validfrom = ", PublisherService.projectIdValidFrom, " valid to = ", PublisherService.projectIdValidTo)
      if (this.createProjectIdComp.projectIdForm.value.validFrom != PublisherService.projectIdValidFrom ||
        this.createProjectIdComp.projectIdForm.value.validTo != PublisherService.projectIdValidTo
      ) {
        // if (this.createProjectIdComp.projectIdForm.value.validFrom.includes("/") ||
        //   this.createProjectIdComp.projectIdForm.value.validTo.includes("/")) {
        //   PublisherService.projectIdValidFrom =
        //     moment(this.createProjectIdComp.projectIdForm.value.validFrom,
        //       'DD/MM/YYYY'
        //     )
        //   PublisherService.projectIdValidTo = moment(this.createProjectIdComp.projectIdForm.value.validTo,
        //     'DD/MM/YYYY'
        //   )
        // } else {
        PublisherService.projectIdValidFrom = this.createProjectIdComp.projectIdForm.value.validFrom
        PublisherService.projectIdValidTo = this.createProjectIdComp.projectIdForm.value.validTo
        // }

        this.priceErosionProjectId.ngOnInit();
      }

      this.showPriceErosionTab = true;
      this.hidshowFlagPriceErosion = true
      if (!this.showChange && this.priceErosionProjectId) {
        this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
      }

      if (!this.showEdit && this.priceErosionProjectId) {
        this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
      }

      if (!this.showUpdate && this.priceErosionProjectId) {
        this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
      }

      // this.priceErosionProjectId.financialYearPeriodCalculation();
    } else {
      this.hidshowFlagPriceErosion = false
      if (
        this.createProjectIdComp.projectIdForm.value.validTo &&
        this.createProjectIdComp.projectIdForm.value.validFrom
      ) {
        // if valid to and valid from is present then do nothing
      } else {
        this.showPriceErosionTab = false;
      }
    }
  }

  async onEdit(btnType) {
    this.buttonText = true
    this.isPrcErosEditable = true;
    this.changeedit = true;
    this.btnType = btnType;
    let payload = [
      {
        itemType: "ProjectId",
        moduleItemId: this.data.projectId,
      },
    ];
    let projectIdLockResponse: any = await this.checkProjectIdLock(payload);
    if (
      projectIdLockResponse &&
      projectIdLockResponse.result &&
      projectIdLockResponse.result.error.length > 0
    ) {
      this.onSaveErrorConstraintPopupForLock(
        projectIdLockResponse.result.error,
        "projectIdEditChangeLockError"
      );
    } else {
      if (btnType === "Change") {
        this.changeedit = true;
        this.isSave = false;
        this.inUpdate = false;
        this.showChange = false;
        if (this.priceErosionProjectId) {
          this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
        }
        // alert(btnType);
      } else if (btnType === "Edit") {
        this.changeedit = true;
        this.inUpdate = false;
        this.showEdit = false;
        this.isSave = true;
        this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
        // alert(btnType);
      } else if (btnType === "Update") {
        this.inUpdate = true;
        this.showUpdate = false;
        this.isSave = true;
        this.priceErosionProjectId.isPrcErosEditable = this.projectIdDataOp.isPaExist ? false : true;
      } else {
        this.priceErosionProjectId.isPrcErosEditable = false;
      }
      this.actionType = btnType;
      if (
        this.role == "SUPER_ADMIN" ||
        this.role == "SOURCING_SPECIALIST" ||
        this.data.projectOwner == this.authServices.getEmail()
      ) {
        this.canEditProjectOwner = false;
      } else {
        this.canEditProjectOwner = true;
      }
      this.disableSelect = new FormControl(false);
      this.disabled = false;
      this.createProjectIdComp.projectIdForm = this.formBuilder.group({
        priceErosin: [
          { value: this.data ? this.data.pacr : "", disabled: this.disabled },
        ],
        projectDescription: [
          {
            value: this.data ? (this.data.projectDescription !== undefined) ? this.data.projectDescription : this.dataProjectId.projectDescription : "",
            disabled: this.disabled,
          },
        ],
        projectId: [
          {
            value: this.data ? (this.data.projectId !== undefined) ? this.data.projectId : this.dataProjectId.projectId : "",
            disabled: this.disabled,
          },
        ],
        mgola: [
          { value: this.data ? (this.data.mgo !== undefined) ? this.data.mgo : this.dataProjectId.mgo : "", disabled: this.disabled },
        ],
        paymentTerms: [
          {
            value: this.data ? (this.data.paymentTerms !== undefined) ? this.data.paymentTerms : this.dataProjectId.paymentTerms : "",
            disabled: this.disabled,
          },
        ],
        currency: [
          { value: this.data ? (this.data.currency !== undefined) ? this.data.currency : this.dataProjectId.currency : "", disabled: this.disabled },
        ],
        supplier: [
          {
            value: this.data ? (this.data.supplierName !== undefined) ? this.data.supplierName : this.dataProjectId.outlineAgreement.supplierDetails.supplierName : "",
            disabled: this.disabled,
          },
        ],
        companyCode: [
          {
            value: this.data ? (this.data.companyCode !== undefined) ? this.data.companyCode : this.dataProjectId.companyCode : "",
            disabled: this.disabled,
          },
        ],
        projectOwner: [
          {
            value: this.data ? (this.data.projectOwner !== undefined) ? this.data.projectOwner : this.dataProjectId.projectOwner : "",
            disabled: this.disabled, //this.canEditProjectOwner, commented by ankita bcz it was creating issue while edit and change
          },
        ],

        warranty: [
          {
            value: this.data ? (this.data.warranty !== undefined) ? this.data.warranty : this.dataProjectId.warranty : "",
            disabled: this.isApproved,
          },
        ],

        validFrom: [
          {
            value: this.data ? (this.data.validFromDate !== undefined) ? this.data.validFromDate : this.dataProjectId.validFromDate : "",
            disabled: false,
          },
        ],
        validTo: [
          {
            value: this.data ? (this.data.validToDate !== undefined) ? this.data.validToDate : this.dataProjectId.validToDate : "",
            disabled: false,
          },
        ],
        l2Code: [
          { value: this.data ? (this.data.l2Code !== undefined) ? this.data.l2Code : this.dataProjectId.l2Code : "", disabled: this.disabled },
        ],
        tradingModel: [
          {
            value: this.data ? (this.data.tradingModel !== undefined) ? this.data.tradingModel : this.dataProjectId.outlineAgreement.tradingModel : "",
            disabled: this.disabled,
          },
        ],
        supplierId: [
          {
            value: this.data ? (this.data.supplierId !== undefined) ? this.data.supplierId : this.dataProjectId.outlineAgreement.supplierDetails.supplierId : "",
            disabled: this.disabled,
          },
        ],
      }, {
        validator: (formGroup: FormGroup) => this.dateRangeValidator(formGroup, this.dataProjectId)
      });
      const initialValue = this.data;
      this.createProjectIdComp.projectIdForm.valueChanges.subscribe((value) => {
        this.createProjectIdComp.hasChange = Object.keys(initialValue).some(
          (key) =>
            this.createProjectIdComp.projectIdForm.value[key] != initialValue[key]
        );
      });
    }
  }

  flagto = '';
  flagfrom = '';

  // this function date from and date to validation
  dateRangeValidator(formGroup: FormGroup, defaultOla: any): ValidationErrors | null {
    let oldTo: any
    let oldFrom: any
    oldTo = this.datePipe.transform(
      defaultOla.outlineAgreement.validTo,
      "dd/MM/yyyy"
    )
    oldFrom = this.datePipe.transform(defaultOla.outlineAgreement.validFrom, "dd/MM/yyyy");
    const validFromControl = formGroup.get('validFrom');
    const validToControl = formGroup.get('validTo');
    if (validFromControl && validToControl) {
      let validFromDate: any;
      let validToDate: any
      // if (validFromControl.value.includes('-')) {
      //   validFromDate = this.datePipe.transform(
      //     validFromControl.value,
      //     "dd/MM/yyyy"
      //   );
      //   validToDate = this.datePipe.transform(
      //     validToControl.value,
      //     "dd/MM/yyyy"
      //   );
      // } else {
      validFromDate = validFromControl.value
      validToDate = validToControl.value;
      // }
      let flagvalid = false;
      if (this.flagfrom === validFromDate) {
        flagvalid = true;
      }
      this.flagto = validToDate;
      this.flagfrom = validFromDate;
      const validFrmDtLclFmt = (typeof (validFromDate) == "string" && validFromDate.includes("-")) ? validFromDate.split("-").reverse().join("/") : validFromDate;
      const validToDtLclFmt = (typeof (validToDate) == "string" && validToDate.includes("-")) ? validToDate.split("-").reverse().join("/") : validToDate;
      if (moment(validFrmDtLclFmt, 'DD/MM/YYYY').startOf('day').isBefore(moment(oldFrom, 'DD/MM/YYYY').startOf('day'))) {
        this.dateDisabledate = true;
        validToControl.setErrors({ oladatefrom: true });
      } else if (moment(validToDtLclFmt, 'DD/MM/YYYY').startOf('day').isAfter(moment(oldTo, 'DD/MM/YYYY').startOf('day'))) {
        this.dateDisabledate = true;
        validToControl.setErrors({ oladateto: true });
      } else if (validFrmDtLclFmt && validToDtLclFmt && moment(validFrmDtLclFmt, 'DD/MM/YYYY').startOf('day').isSameOrAfter(moment(validToDtLclFmt, 'DD/MM/YYYY').startOf('day')) && !flagvalid) {
        this.dateDisabledate = true;
        validToControl.setErrors({ dateRangefrom: true });
      } else if (validFrmDtLclFmt && validToDtLclFmt && moment(validFrmDtLclFmt, 'DD/MM/YYYY').startOf('day').isSameOrAfter(moment(validToDtLclFmt, 'DD/MM/YYYY').startOf('day')) && flagvalid) {
        this.dateDisabledate = true;
        validToControl.setErrors({ dateRangeto: true });
      } else {
        this.dateDisabledate = false;
        validToControl.setErrors(null);
      }
    }
    return null;
  }
  // Disable button for invalid ola date
  dateDisable(event) {
    this.dateDisabledate = event;
  }

  /**
   *Popup for showing import error
   *
   * @param {*} [header]
   * @param {*} [message]
   * @memberof MaterialAddComponent
   */
  public onSubmit(item) {
    this.submitted = true;
    if (this.createProjectIdComp.hasChange === true || this.checkPriceErosionChanges()) {
      this.loaderService.startLoading();
      let checkPriceErosionData
      if (this.priceErosionProjectId) {
        checkPriceErosionData = this.priceErosionProjectId.periods.filter((data: any) => {
          if (data.priceErosion) {
            return data;
          }
        })
      }
      let companyCode = this.createProjectIdComp.projectIdForm.value.companyCode;
      companyCode = companyCode.split(" - ")[0].trim();
      const payloadData = {
        outlineAgreementNumber:
          this.createProjectIdComp.projectIdForm.value.mgola != ""
            ? this.createProjectIdComp.projectIdForm.value.mgola.toString()
            : this.createProjectIdComp.projectIdForm.value.mgola,
        projectId: this.createProjectIdComp.projectIdForm.value.projectId,
        projectOwner: this.createProjectIdComp.projectIdForm.value.projectOwner,
        projectDescription:
          this.createProjectIdComp.projectIdForm.value.projectDescription,
        mgo: this.createProjectIdComp.projectIdForm.value.mgola,
        warranty: parseInt(
          this.createProjectIdComp.projectIdForm.value.warranty
        ),
        // pacr: parseInt(this.createProjectIdComp.projectIdForm.value.priceErosin),
        pacr: 20,
        validFromDate: this.convertToYYYYMMDD(this.createProjectIdComp.projectIdForm.value.validFrom),
        validToDate: this.convertToYYYYMMDD(this.createProjectIdComp.projectIdForm.value.validTo),
        action: item,
        paymentTerms: this.createProjectIdComp.projectIdForm.value.paymentTerms,
        currency: this.createProjectIdComp.projectIdForm.value.currency,
        supplierId: this.createProjectIdComp.projectIdForm.value.supplierId,
        priceErosionDetails: this.priceErosionProjectId
          ? checkPriceErosionData
          : [],
        companyCode: companyCode
        //l2Code: this.createProjectIdComp.projectIdForm.value.l2Code,
      };
      const lockPayload = {
        moduleItemId: payloadData.projectId
      }
      this.pricemanagerServices.createProjectId(payloadData).subscribe(
        (res) => {
          this.loaderService.stopLoading();
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = "succes-dialog-div";
          if (item == "submit") {
            console.log("email = ", payloadData.projectOwner, this.authServices.getEmail())
            dialogConfig.data = {
              id: 1,
              header: "Success",
              message: payloadData.projectOwner !== this.authServices.getEmail() ?
                "Project ID Successfully Submitted For Approval." :
                "Project ID Successfully Created.",
              buttonText: "OK",
            };
            dialogConfig.width = '200px';
            this.pricemanagerServices.unlockProjectId(lockPayload).subscribe((res) => {
              //console.log("in lock api", res);
            });

          }
          else {
            dialogConfig.data = {
              id: 1,
              header: "Success",
              message: "All updated to this Project ID have been saved as draft successfully.",
              buttonText: "Done",
            };
            dialogConfig.width = '250px';
            this.pricemanagerServices.unlockProjectId(lockPayload).subscribe((res) => {
              //console.log("in lock api", res);
            });
          }
          // this.dialogRef.close();
          const dialogRef = this.dialog.open(
            PriceMessagesComponent,
            dialogConfig
          );
          dialogRef.afterClosed().subscribe((data) => {
            if (item == "submit") {
              this.router.navigate(["/dashboard/project-id"]);
              this.dialogRef.close(true);
            }
            this.pricemanagerServices.supplierApprovalUpdate(true);
          });
          this.disabled = false;
          this.editable = true;
        },
        (error) => {
          this.loaderService.stopLoading();
          this.data['warranty'] = this.createProjectIdComp.projectIdForm.value.warranty;
          this.data['projectDescription'] = this.createProjectIdComp.projectIdForm.value.projectDescription;
          this.data['projectOwner'] = this.createProjectIdComp.projectIdForm.value.projectOwner;
          this.data['validFrom'] = this.createProjectIdComp.projectIdForm.value.validFrom;
          this.data['validTo'] = this.createProjectIdComp.projectIdForm.value.validTo;
          this.data['checkPriceErosionData'] = checkPriceErosionData;
          this.data['btnType'] = this.btnType;
          this.data['validationErrormsg'] = error.error.message
          this.data['buttonText'] = this.buttonText;
          this.data['btnTxt'] = this.btnTxt
          const dialogConfig1 = new MatDialogConfig();
          dialogConfig1.disableClose = true;
          dialogConfig1.autoFocus = true;
          dialogConfig1.data = this.data;
          this.dialog.open(AddNewProjectIdComponent, dialogConfig1);

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = "waring-dialog-container";
          dialogConfig.data = {
            id: 1,
            header: "Validation Error",
            message: error.error.message.replaceAll(",", "<br>").replaceAll("projectDescription", "Project Description").replaceAll("warranty", "Warranty (days)"),
          };
          dialogConfig.width = "200px";
          this.dialog.open(SuccessDialogComponent, dialogConfig);
          // const validationErrorList: any = [];
          // Object.keys(this.createProjectIdComp.projectIdForm.value).forEach(key => {
          //   validationErrorList.push({
          //     error: key,
          //     errorDetails: error.error.message,
          //     detailsMpn: this.createProjectIdComp.projectIdForm.value.manufacturerPartNumber,
          //     detailsdesc: this.createProjectIdComp.projectIdForm.value.materialDescription
          //   })
        }
      );

      // this.onSaveErrorConstraintPopup(validationErrorList);

      // }
      // );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "price-success-dialog-container";
      dialogConfig.data = {
        header: "Save Material",
        message: "There is no change in the Project Id to save.",
        icon: "info-pophover-blue.svg",
        buttonText: "OK",
      };
      dialogConfig.width = '250px';
      this.dialog.open(PriceMessagesComponent, dialogConfig);
      // const dialogRef = this.dialog.open(PriceMessagesComponent, dialogConfig);
    }
  }
  /// Convert Any date format to 'dd/MM/yyyy' as per required payload
  convertToYYYYMMDD(date: string): string {
    for (const format of this.dateFormats) {
      const parsedDate = moment(date, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
    throw new Error('Invalid date format');
  }

  // approve reject synch button display

  public isActionVisible() {
    if (
      this.role.includes("LOCAL_MARKET_MANAGER") ||
      // || this.role.includes('LOCAL_MARKET_OVERSEER')
      this.role.includes("CATEGORY_MANAGER")
      //||
      // this.role.includes("DATA_MIGRATION_SPECIALIST")
      // || this.role.includes("MDM_TEAM")
    ) {
      return true;
    }
    return false;
  }

  //onClose
  onClose() {
    // const lockPayload = {
    //   moduleItemId: this.createProjectIdComp.projectIdForm.value.projectId
    // }
    // this.pricemanagerServices.unlockProjectId(lockPayload).subscribe((res) => {
    //   //console.log("in lock api", res);
    // });
  }

  // save
  public hasSavePermission() {
    if (
      this.role.includes("LOCAL_MARKET_MANAGER") ||
      this.role.includes("LOCAL_MARKET_OVERSEER") ||
      this.role.includes("CATEGORY_MANAGER") ||
      this.role.includes("SOURCING_SPECIALIST") ||
      this.role.includes("TECHNOLOGY_USER") ||
      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER") ||
      this.role.includes("SUPER_ADMIN") ||
      this.role.includes("GLOBAL_BUSINESS_USER") ||
      this.role.includes("DATA_MIGRATION_SPECIALIST")
    ) {
      return true;
    }
    return false;
  }

  //Button label Change 
  public isSubmitVisible() {
    if (
      this.role.includes("CATEGORY_MANAGER") || this.role.includes("LOCAL_MARKET_MANAGER")
    ) {
      this.buttonText = false;
      this.btnTxt = true; //Create as CM can create 
    } else {
      this.btnTxt = false;
    }
  }

  // edit
  public hasEditPermission() {
    this.role = this.authServices.getRole();

    if (
      this.role.includes("LOCAL_MARKET_MANAGER") ||
      this.role.includes("LOCAL_MARKET_OVERSEER") ||
      this.role.includes("CATEGORY_MANAGER") ||
      //this.role.includes("SUPER_ADMIN") ||
      this.role.includes("SOURCING_SPECIALIST")
      // this.role.includes("GLOBAL_BUSINESS_USER") ||
      // this.role.includes("DATA_MIGRATION_SPECIALIST")
      // || this.role.includes('TECHNOLOGY_USER')
      // || this.role.includes('SUPPLIER_ADMIN')
      // || this.role.includes("SUPPLIER_MANAGER")
      // || this.role.includes("MDM_TEAM")
    ) {
      return true;
    }
    return false;
  }
  // delete
  public hasDeletePermission() {
    if (
      this.role.includes("LOCAL_MARKET_MANAGER") ||
      // || this.role.includes('LOCAL_MARKET_OVERSEER')
      this.role.includes("CATEGORY_MANAGER") ||
      this.role.includes("SOURCING_SPECIALIST") ||
      // || this.role.includes('SUPER_ADMIN')
      // || this.role.includes('SUB_ADMIN')
      // || this.role.includes('MDM_TEAM')
      // || this.role.includes('TECHNOLOGY_USER')

      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER") ||
      this.role.includes("DATA_MIGRATION_SPECIALIST")
    ) {
      return true;
    }
    return false;
  }

  // sync button
  public isSyncActionVisible() {
    if (
      this.role.includes("LOCAL_MARKET_MANAGER") ||
      this.role.includes("LOCAL_MARKET_OVERSEER") ||
      this.role.includes("CATEGORY_MANAGER") ||
      this.role.includes("SOURCING_SPECIALIST") ||
      this.role.includes("SUB_ADMIN") ||
      this.role.includes("SUPER_ADMIN") ||
      this.role.includes("MDM_TEAM") ||
      this.role.includes("DATA_MIGRATION_SPECIALIST")
    ) {
      return true;
    }
    return false;
  }

  public openWithdrawPopup() {
    this.changeedit = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "waring-dialog-container";
    dialogConfig.data = {
      header: "Withdraw Warning",
      message: "Are you sure you want to withdraw the selected Project ID(s)?",
      type: "approverDelete",
      quickTip:
        "On clicking on Withdraw, the Project ID will go back to the original Project Id details",
      confirmText: "Confirm",
    };
    const dialogRef = this.dialog.open(WarningComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.withdrawItems();
      }
    });
  }

  // on withdraw
  public withdrawItems() {
    // @ts-ignore

    this.loaderService.startLoading();
    // const reqBody = { projectId: [this.data.projectId] };
    const reqBody = {
      type: "approve",
      comments: "",
      itemIds: [
        this.data.projectId
      ]
    };
    this.pricemanagerServices.withdrawProjectId(reqBody).subscribe(
      (res) => {
        // this.getDataList();
        this.loaderService.stopLoading();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "succes-dialog-div";
        dialogConfig.data = {
          id: 1,
          header: "Withdrawn Successfully",
          message:
            "Selected Project ID has been withdrawn and deleted successfully.",
          buttonText: "OK",
        };
        dialogConfig.width = '250px';
        const dialogRef = this.dialog.open(
          PriceMessagesComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
          this.loaderService.stopLoading();
          this.dialogRef.close(true);
          this.pricemanagerServices.supplierApprovalUpdate(true);
        });

        // this.dialogRef.close();
      },
      (error) => {
        this.loaderService.stopLoading();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "waring-dialog-container";
        dialogConfig.data = {
          id: 1,
          header: " Error",
          message: error.error.message,
        };
        this.dialog.open(SuccessDialogComponent, dialogConfig);
      }
    );
  }
  // approve
  approve() {
    this.loaderService.startLoading();
    const payload = {
      type: "approve",
      projectId: this.data.projectId,
      itemType: "PROJECT_ID_TYPE",
    };
    this.pricemanagerServices.actionProjectId(payload).subscribe(
      (res) => {
        this.loaderService.stopLoading();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "succes-dialog-div";
        dialogConfig.data = {
          id: 1,
          header: "Success",
          message: "Project ID Successfully Approved.",
          buttonText: "OK",
        };
        dialogConfig.width = '250px';
        const dialogRef = this.dialog.open(
          PriceMessagesComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
          // this.dialog.closeAll();
          this.dialogRef.close(true);
          this.pricemanagerServices.supplierApprovalUpdate(true);
        });

        // this.dialogRef.close();
      },
      (error) => {
        this.loaderService.stopLoading();
        // this.onSaveErrorConstraintPopup(error.error.result || [])
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "waring-dialog-container";
        dialogConfig.data = {
          id: 1,
          header: "Validation Error",
          message: error.error.message,
        };
        this.dialog.open(PriceMessagesComponent, dialogConfig);
      }
    );
  }
  // reject
  reject() {

    const dialogConf = new MatDialogConfig();
    dialogConf.disableClose = true;
    dialogConf.autoFocus = true;
    dialogConf.panelClass = "waring-dialog-container";
    dialogConf.data = {
      header: "Rejection Warning",
      message: "Are you sure you want to reject the Project ID?",
      type: "approverDelete",
      // quickTip:
      //   "Deleted items will be completely removed from this Material and cannot be restored later.",
      confirmText: "Confirm",
    };
    const dialogReference = this.dialog.open(WarningComponent, dialogConf);
    dialogReference.afterClosed().subscribe((response) => {
      if (response) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "400px";
        dialogConfig.panelClass = "custom-dialog-container";
        dialogConfig.data = {
          selectedUsersList: this.data,
        };
        const dialogRef = this.dialog.open(
          RejectPopupProjectidComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
          this.loaderService.stopLoading();
          this.dialogRef.close(true);
          this.pricemanagerServices.supplierApprovalUpdate(true);
        });
      }
    });
  }

  private onSaveErrorConstraintPopup(uniqueItemsErrorList = []) {
    const dialogConf = new MatDialogConfig();
    dialogConf.width = "500px";
    dialogConf.data = {
      errorArray: uniqueItemsErrorList,
    };
    this.dialog.open(MaterialErrorPopupComponent, dialogConf);
  }
  checkProjectIdLock(payload) {
    return new Promise((resolve) => {
      this.pricemanagerServices
        .checkMaterialProjIdLock(payload)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  private onSaveErrorConstraintPopupForLock(
    uniqueItemsErrorList = [],
    actionFor: string | null | boolean = false
  ) {
    const dialogConf = new MatDialogConfig();
    dialogConf.width = "500px";
    dialogConf.data = {
      errorArray: uniqueItemsErrorList,
      actionFor: actionFor,
    };
    this.dialog.open(AgreementSaveErrorListMatComponent, dialogConf);
  }

  projectIdData(event) {
    this.projectIdDataOp = event;
  }

  checkPriceErosionChanges() {
    if (PublisherService.editProjectId) {
      let checkChangedData = this.priceErosionProjectId.periods.filter((data: any) => {
        if (data.priceErosion) {
          return data;
        }
      });
      let currentPacr = JSON.stringify(checkChangedData)
      let oldPacr = this.priceErosionProjectId.oldPriceErosionDetails;
      // console.log("current pacr = ", checkChangedData)
      // console.log("old pacr = ", this.priceErosionProjectId.oldPriceErosionDetails)
      if (currentPacr == oldPacr) {
        return false
      } else {
        return true
      }

    } else {
      if (this.priceErosionProjectId && this.priceErosionProjectId.periods) {
        let checkChangedData = this.priceErosionProjectId.periods.filter((data: any) => {
          if (data.priceErosion) {
            return data;
          }
        })
        if (checkChangedData.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
  buttonTextHandler(flag) {
    if (this.role == 'LOCAL_MARKET_OVERSEER') {
      this.btnTxt = false
    } else {
      this.btnTxt = !flag
    }

  }
  buttonTextCSSHandler(flag) {
    this.dateDisabledate = flag
  }
  priceErosionAddRemoveHandle(data) {
    this.fromEdit = data.fromEdit;
    this.isPrcErosEditable = data.isPrcErosEditable;
    this.selectedPriceItemForView = data.selectedPriceItemForView;
    this.selectedTimePeriod = data.selectedTimePeriod;
    this.periods = data.periods;
    this.contractValidFrom = data.contractValidFrom;
    this.contractValidTo = data.contractValidTo;
  }

  /**
   *Add/Remove the periods
   *
   * @param {*} status
   * @return {*} 
   * @memberof PriceErosionComponent
   */
  public onAddOrRemovePeriod(status) {
    if (!this.contractValidFrom || !this.contractValidTo) {
      const obj = {
        header: ' Price Erosion Error',
        message: 'Please Select Valid Project ID Date Range!'
      };
      this.onSaveErrorPopup(obj);
      return
    }
    const length = this.periods.length - 1;
    this.contractValidTo.setHours(0, 0, 0, 0);
    if (status) {
      this.periods[length].validToDate.setHours(0, 0, 0, 0);
      if (this.periods[length].validToDate.getTime() >= this.contractValidTo.getTime()) {
        this.noMorePeriodPopup();
        return;
      }
      /* [VPC-96] start */
      if (this.selectedTimePeriod === 'financial') {
        let start = moment(this.periods.slice(-1)[0].validToDate);
        const end = moment(this.contractValidTo);
        let startMonth = start.month() + 1;
        let nextMarch = moment(
          `31-03-${startMonth >= 4 ? start.year() + 1 : start.year()}`,
          'DD-MM-YYYY'
        );
        start = nextMarch.add(1, 'days');
        nextMarch.add(1, 'days');
        const diff = Math.ceil(end.diff(nextMarch, 'year', true));
        nextMarch.add(-1, 'days');
        const noOfYear = diff <= 0 ? 1 : diff + 1;
        let i = this.periods.length;
        let currentCount = i - 10;
        this.periods.push({
          period: i + 1,
          validFromDate: start.toDate(),
          validToDate: currentCount <= noOfYear ? moment(`31-03-${start.year() + 1}`, 'DD-MM-YYYY').toDate() : moment(this.contractValidTo).toDate(),
          priceErosion: null
        });
        /* [VPC-96] end */
      } else {
        const period = {
          period: length + 2,
          validFromDate: moment(this.periods[length].validToDate).add(1, 'days').toDate(),
          validToDate: moment(this.contractValidTo).toDate(),
          min: moment(this.periods[length].validToDate).add(1, 'days').toDate(),
          max: moment(this.contractValidTo).toDate(),
          priceErosion: null
        };
        this.periods[length].max = moment(period.validFromDate).add(-1, 'days').toDate();
        this.periods.push(period);
      }

    } else {
      if (length === 0) {
        return;
      }
      /* [VPC-96] start */
      if (this.selectedTimePeriod === 'financial') {
        if (length + 1 == 10) {
          return;
        } else {
          this.periods.splice(length);
        }
        /* [VPC-96] end */
      } else {
        this.periods.splice(length);
        this.periods[length - 1].max = moment(this.contractValidTo).toDate();
      }

    }
  }
  private onSaveErrorPopup(obj?: { header: string, message: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        icon: 'info-pophover-blue.svg',
        buttonText: 'OK'
      };
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }
  private noMorePeriodPopup() {
    const obj = {
      header: ' Price Erosion Error',
      message: 'No more period can be added!'
    };
    this.onSaveErrorPopup(obj);
  }

  public deleteProjectId() {
    // @ts-ignore
    this.loaderService.startLoading()
    const reqBody = {
      projectId: [this.createProjectIdComp.data.projectId]
    };
    this.pricemanagerServices.deleteProjectId(reqBody).subscribe(
      (res) => {
        this.loaderService.stopLoading()
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "succes-dialog-div";
        dialogConfig.data = {
          id: 1,
          header: "Success",
          message: "Successfully deleted selected data.",
        };
        const dialogRef = this.dialog.open(
          PriceMessagesComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((data) => {          
          this.loaderService.stopLoading();
          this.dialogRef.close(true);
          this.pricemanagerServices.supplierApprovalUpdate(true);
        });
      },
      (error) => {
        this.loaderService.stopLoading()
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "waring-dialog-container";
        dialogConfig.data = {
          id: 1,
          header: "Error",
          message: error.error.message,
        };
        this.dialog.open(SuccessDialogComponent, dialogConfig);
      }
    );
  }
}
