import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { LoaderService } from '../../_services/loader.service';
import { Subscription } from 'rxjs';

/**
 *Loader component
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy, AfterContentInit {

  public loading = false;
  public loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService, private cdr: ChangeDetectorRef) { }

  public ngOnInit() {
    this.loaderSubscription = this.loaderService.loadingStatus.subscribe((value) => {
      this.loading = value;
    });
  }
  ngAfterContentInit() {
    this.cdr.detectChanges();
  }
  public ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

}
