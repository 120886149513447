import { Component, OnInit, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { UsersServices } from "../../_services/users.service";
import { ToggleItemService } from "../../_services/toggle-item.service";
import { LoaderService } from "../../_services/loader.service";
import { PriceManagerServices } from "../../_services/price-manager.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
} from "@angular/material";
import { PriceMessagesComponent } from "../price-messages/price-messages.component";
import { AuthServices } from "../../_services/auth.service";
import * as moment from "moment";
import { applicationRoles } from "../../_config/constants";

/**
 *Popup component for Price agreement/Approval rights delegation
 *
 * @export
 * @class WatcherOwnerActionPopupComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-watcher-owner-action-popup",
  templateUrl: "./watcher-owner-action-popup.component.html",
  styleUrls: ["./watcher-owner-action-popup.component.scss"],
})
export class WatcherOwnerActionPopupComponent implements OnInit {
  public priceAgreement: any;
  public priceAgreementIdList: any;
  public projectIdList: any;
  public actionType: string;
  public myControl: FormControl = new FormControl();
  public pageNumber = 1;
  public perPage = 50;
  public search: string;
  public delegate: string;
  public result: any = [];
  public resultUser: any = [];
  public selectedValue: any;
  public selectedChangeOwnershipVal: any;
  public selectedDelegatedBy: any;
  public selectedData: any;
  public selectedDelegatedByData: any;
  public option: any;
  public loggedInEmail: any;
  public errorMessage = "";
  public startDate;
  public endDate;
  public minDate: Date = moment().toDate();
  public minEndDate: Date = moment().toDate();
  public maxDate = moment().add(90, "days").toDate();
  public maxStartDate = moment().add(60, "days").toDate();
  public userRole: any;
  public tradingModel:any;
  public filteredOptions: Observable<string[]>;
  userId: string;
  constructor(
    private userInfoService: UsersServices,
    public toggleItemService: ToggleItemService,
    private loaderService: LoaderService,
    private priceService: PriceManagerServices,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WatcherOwnerActionPopupComponent>,
    private authServices: AuthServices
  ) {
    this.priceAgreement = data && data.priceAgreementId;
    this.delegate = data && data.delegate;
    this.actionType = data && data.actionType;
  }
  ngOnInit() {    
    this.userId = this.authServices.getUserId();
    this.userRole = this.authServices.getRole();
    this.loggedInEmail = this.authServices.getEmail();
    if (this.delegate === "approverRights") {
      this.priceAgreementIdList = this.priceAgreement.map(
        (d: any) => d.l4Code.split(" - ")[0]
      );
    } else if (this.delegate === "changeOwner_ProjectId") {
      this.projectIdList = this.priceAgreement.map((d: any) => d.projectId);
      this.tradingModel = this.priceAgreement.map((d: any) => d.tradingModel);
    } else {
      this.priceAgreementIdList = this.priceAgreement.map(
        (d: any) => d.masterAgreementName.pId
      );
    }
  }

  public onDateChange(date) {
    this.endDate = "";
    this.minEndDate = moment(date.value).toDate();
    this.maxDate = moment(date.value).add(90, "days").toDate();
  }
 
  public searchUser(data) {   
    let email = this.validateEmail();
    if(!email){
      this.errorMessage = "Invalid user email";
    }     
    if (data.target.value.length >= 3) {
      let userRole = [];
      let filtersMod = {};
      if (this.delegate === "approverRights") {
        if (this.userRole && this.userRole.includes("LOCAL_MARKET")) {
          userRole = [
            applicationRoles.LOCAL_MARKET_MANAGER,
            applicationRoles.LOCAL_MARKET_OVERSEER,
          ];
        } else if (
          this.userRole &&
          this.userRole === applicationRoles.CATEGORY_MANAGER
        ) {
          userRole = [applicationRoles.CATEGORY_MANAGER];
        }
        filtersMod = {
          loginId: {
            filterType: "text",
            type: "contains",
            filter: data.target.value,
          },
          role: { values: userRole, filterType: "set" },
        };
      } else {
        filtersMod = {
          loginId: {
            filterType: "text",
            type: "contains",
            filter: data.target.value,
          },
        };
      }
      this.userInfoService
        .getUsersForAGGrid({
          startRow: 0,
          endRow: 1000,
          rowGroupCols: [],
          valueCols: [],
          pivotCols: [],
          pivotMode: false,
          groupKeys: [],
          filterModel: filtersMod,
          sortModel: [],
        })
        .subscribe((res) => {          
          this.result = res["result"].rows.filter((o1) => {
            return o1.loginId !== this.loggedInEmail;
          });        
        });
    }
  }
  public searchUserDelegate(data) {
    if (data.target.value.length >= 3) {
      this.userInfoService
        .getUserList({
          pageNumber: this.pageNumber,
          perPage: this.perPage,
          searchByName: data.target.value,
        })
        .subscribe((res) => {
          this.resultUser = res["result"].rows.filter((o1) => {
            return o1.loginId !== this.loggedInEmail;
          });
        });
    }
  }
  public searchUserForProjectOwnerChange(data) {
    if (data.target.value.length >= 3) {
      let payload = {};
      payload = {
        role: "CATEGORY_MANAGER",
      };
      this.priceService
        .getUsersForProjectOwnerChange(payload)
        .subscribe((res) => {
          this.result = res["result"].filter((o1) => {
            return o1.loginId !== this.loggedInEmail;
          });          
        });
    }
  }

  public selectAndSetValue(option) {
    this.errorMessage = "";
    this.selectedData = this.result.find((obj) => {
      return obj.loginId === this.selectedValue;
    });
  }
  public selectAndSetValueDelegatedBy(option) {
    this.errorMessage = "";
    this.selectedDelegatedByData = this.resultUser.find((obj) => {
      return obj.loginId === this.selectedDelegatedBy;
    });
  }
  public selectAndSetChangeOwnerValue(option) {    
    this.errorMessage = "";
    this.selectedData = this.result.find((obj) => {
      return obj.loginId === this.selectedChangeOwnershipVal;
    });    
  }
  //
  /**
   *Function to delegate the price agreement / approval rights delegation
   *
   * @return {*}
   * @memberof WatcherOwnerActionPopupComponent
   */
  public onDelegate() {
    if(this.selectedValue !==undefined){      
      this.selectAndSetValue('');
    } 
    if (!this.selectedData) {
      this.errorMessage = "Invalid user email";
      return;
    }
    if (!this.priceAgreementIdList) {
      this.errorMessage = "Invalid";
      return;
    }
    this.loaderService.startLoading();

    const payload = {
      email: this.selectedData.loginId,
      priceAgreementIds: this.priceAgreementIdList || [],
      action: this.data.action,
    };
    this.priceService.delegateAgreementOwnerWatcher(payload).subscribe(
      (response: any) => {
        this.loaderService.stopLoading();
        this.dialogRef.close(true);
        let watcherMessage = `You have successfully changed the watcher of the selected Price Agreement(s) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> `;
        let ownerMessage = `You have successfully changed the owner of the selected Price Agreement(s) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> `;
        this.onSaveErrorPopup({
          header: "Success",
          message:
            this.data.action == "watcher" ? watcherMessage : ownerMessage,
        });
      },
      (err) => {
        this.loaderService.stopLoading();
        this.onSaveErrorPopup({
          header: "Invalid Action",
          message:
            err.error.message || "Unable to delegate this price agreements",
          icon: "info-pophover-blue.svg",
          buttonText: "OK",
        });
      }
    );
  }
  /**
   *Function to validate email
   *
   * @return {*}
   * @memberof WatcherOwnerActionPopupComponent
   */
 
  public validateEmail() {    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;      
    if (!emailRegex.test(this.selectedChangeOwnershipVal)) { 
      return false;
    } else {
      return true
    }
  }

  /**
   *Function to change the owner ship of the project id(s)
   *
   * @return {*}
   * @memberof WatcherOwnerActionPopupComponent
   */
  public onChangeOwnership() {
    if(this.selectedChangeOwnershipVal !==undefined){      
      this.selectAndSetChangeOwnerValue('');
    }   
    if (!this.selectedData) {
      this.errorMessage = "Invalid user email";
      return;
    }
    if (!this.projectIdList) {
      this.errorMessage = "Invalid";
      return;
    }
    this.loaderService.startLoading();

    const payload = {
      newOwner: this.selectedData.loginId,
      projectIds: this.projectIdList || [],
      tradingModel: this.tradingModel || [],
    };
    this.priceService.reassignProjectOwnership(payload).subscribe(
      (response: any) => {
        this.loaderService.stopLoading();
        this.dialogRef.close(true);
        this.priceService.supplierApprovalUpdate(true);
        let ownerMessage = `You have successfully changed the owner of the selected Project Id(s) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> `;
        this.onSaveErrorPopup({
          header: "Success",
          message: ownerMessage,
        });
      },
      (err) => {
        this.loaderService.stopLoading();
        this.onSaveErrorPopup({
          header: "Invalid Action",
          message:
            err.error.result.error ||
            "Unable to change the owner of the selected Project Id(s)",
          icon: "info-pophover-blue.svg",
          buttonText: "OK",
        });
      }
    );
  }
  private onSaveErrorPopup(obj?: {
    header: string;
    message: string;
    icon?: string;
    buttonText?: string;
  }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px';
    dialogConfig.panelClass = "price-success-dialog-container";
    if (obj && obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        buttonText: obj.buttonText ? obj.buttonText : "Done",
      };
      if (obj.icon) {
        dialogConfig.data.icon = obj.icon;
      }
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }
}
