import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PanelServices {

    // tslint:disable-next-line: no-empty
    public constructor() { }

    public activeTab = 'Tools';
    public activeUserVal = 'userAccount';
    public activePAVal = 'AllPriceAgree';
    public leftPanelStatus =
        localStorage.getItem('leftPanelStatus') === null
            ? true
            : localStorage.getItem('leftPanelStatus') === 'true';

    public rightPanelStatus =
        localStorage.getItem('rightPanelStatus') === null
            ? true
            : localStorage.getItem('rightPanelStatus') === 'true';


    /**
     *Function to store the collapse panel status to the local storage
     *
     * @param {string} panel
     * @memberof PanelServices
     */
    public collapseBar(panel: string) {
        if (panel === 'leftHidePanel') {
            this.leftPanelStatus =
                localStorage.getItem('leftPanelStatus') === null
                    ? false
                    : (localStorage.getItem('leftPanelStatus') === 'false');
            localStorage.setItem('leftPanelStatus', this.leftPanelStatus.toString());
        }

        if (panel === 'rightHidePanel') {
            this.rightPanelStatus =
                localStorage.getItem('rightPanelStatus') === null
                    ? false
                    : !(localStorage.getItem('rightPanelStatus') === 'true');
            localStorage.setItem(
                'rightPanelStatus',
                this.rightPanelStatus.toString()
            );
        }
    }


    /**
     *Function to change the active tab status to the specified tab
     *
     * @param {string} activeTabVal
     * @memberof PanelServices
     */
    public changeContent(activeTabVal: string) {
        this.activeTab = activeTabVal;
        if (!this.rightPanelStatus) {
            this.rightPanelStatus =
                localStorage.getItem('rightPanelStatus') === null
                    ? false
                    : !(localStorage.getItem('rightPanelStatus') === 'true');
            localStorage.setItem(
                'rightPanelStatus',
                this.rightPanelStatus.toString()
            );
        }
    }

    /**
     *Function to change the active tab status to the specified user page tab
     *
     * @param {string} activeUser
     * @memberof PanelServices
     */
    public userContent(activeUser: string) {
        this.activeUserVal = activeUser;
        if (!this.rightPanelStatus) {
            this.rightPanelStatus =
                localStorage.getItem('rightPanelStatus') === null
                    ? false
                    : !(localStorage.getItem('rightPanelStatus') === 'true');
            localStorage.setItem(
                'rightPanelStatus',
                this.rightPanelStatus.toString()
            );
        }
    }

    public getUserTab() {
        return this.activeUserVal
    }

    /**
     *Function to change the active tab status to the specified price agreement page tab
     *
     * @param {string} activePA
     * @memberof PanelServices
     */
    public PriceAgreementContent(activePA: string) {
        this.activePAVal = activePA;
        if (!this.rightPanelStatus) {
            this.rightPanelStatus =
                localStorage.getItem('rightPanelStatus') === null
                    ? false
                    : !(localStorage.getItem('rightPanelStatus') === 'true');
            localStorage.setItem(
                'rightPanelStatus',
                this.rightPanelStatus.toString()
            );
        }
    }
}
