import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AnalyticsDialogComponent } from './../analytics-dialog/analytics-dialog.component';
import { AnalyticsService } from '../../../_services/analytics.service';
import { ChartsData } from '../../../_models/analytics';
import { FilterServices } from '../../../_services/filter.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { LoaderService } from '../../../_services/loader.service';
import { PriceMessagesComponent } from '../../../global-module/price-messages/price-messages.component';
import { AnalyticsNntwkService } from 'src/app/_services_nntwk/analytics-nntwk.service';
import { FilterNntwkServices } from 'src/app/_services_nntwk/filter-nntwk.service';
@Component({
  selector: 'app-analytics-blocks',
  templateUrl: './analytics-blocks.component.html',
  styleUrls: ['./analytics-blocks.component.scss']
})
export class AnalyticsBlocksComponent implements OnInit {
  public chartData: ChartsData[];
  public loader = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  public showLoader = true;
  public filtersApplied: boolean = false;
  public dateSelected: boolean = false;
  public kpiCardNames = ['CATALOGUES', 'activePriceAgreements', 'activeItems', 'orderValues', 'valueLeakageIdntifiers', 'orderIssueds',
    'invoicedValues', 'voucherValues', 'remainigVoucherValues', 'valueLeakageRecovered'];
  public styles = {
    activePriceAgreements: {
      bgcolor: 'pa',
      chartColor: '#0890FF'
    },
    activeItems: {
      bgcolor: 'it',
      chartColor: '#0890FF'
    },
    voucherValues: {
      bgcolor: 'vo',
      chartColor: '#0890FF'
    },
    valueLeakageIdntifiers: {
      bgcolor: 'vl',
      chartColor: '#0890FF'
    },
    orderValues: {
      bgcolor: 'ov',
      chartColor: '#0890FF'
    },
    orderIssueds: {
      bgcolor: 'po',
      chartColor: '#0890FF'
    },
    invoicedValues: {
      bgcolor: 'iv',
      chartColor: '#0890FF'
    },
    remainigVoucherValues: {
      bgcolor: 'va',
      chartColor: '#0890FF'
    },
    valueLeakageRecovered: {
      bgcolor: 'vr',
      chartColor: '#0890FF'
    },
    CATALOGUES: {
      bgcolor: 'ca',
      chartColor: '#0890FF'
    }
  };

  constructor(
    public dialog: MatDialog,
    public analyticsService: AnalyticsNntwkService,
    private filterService: FilterNntwkServices,
    private loaderService: LoaderService
  ) { }

  public ngOnInit() {
    this.analyticsService.getAnalytics().subscribe(
      res => {
        if (res.result) {
          this.rederCharts(res.result);
        }
      },
      err => console.log(err)
    );
  }

  public async rederCharts(data) {
    await this.assignHeadings(data);
    const sortedData = await data.sort((a, b) => {
      let fa = a.cardPosition,
        fb = b.cardPosition;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    this.chartData = await sortedData;
    this.showLoader = false;
  }


  public openDialog(cardName, chartColor, title): void {
    this.loaderService.startLoading();
    this.analyticsService.getKpiCardOneYearGraph(cardName).subscribe(res => {
      this.loaderService.stopLoading();
      // if (res.result) {
      let months = [];
      let chartData = [];
      if (res.result) {
        res.result.forEach(element => {
          months.push(element.attributeName);
          chartData.push(element.attributeValue);
        });
      }
      this.dialog.open(AnalyticsDialogComponent, {
        width: '1000px',
        data: { chartData, months, chartColor, title, cardName }
      });
      // }
    }, err => {
      this.loaderService.stopLoading();
      console.log(err);
    });
  }

  public applyFilter(data) {
    this.loaderService.startLoading();
    const body: any = {};
    const filters = this.filterService.dataFilter(data, 'equal');
    if (data.selectedDate) {
      this.analyticsService.setDatesFilter(data.selectedDate);
      body.datesFilter = data.selectedDate;
      this.dateSelected = true;
    } else {
      this.analyticsService.setDatesFilter([]);
      this.dateSelected = false;
    }
    if (filters.length > 0) {
      this.filtersApplied = true;
      body.filters = filters;
      this.analyticsService.setAnalyticsFilter(filters);
    } else {
      this.filtersApplied = false;
      this.analyticsService.setAnalyticsFilter([]);
    }

    let subs = [];
    this.kpiCardNames.forEach(card => {
      let reqBody = Object.assign({ kpiCardType: card }, body);
      subs.push(this.analyticsService.getChartsData(reqBody));
    });

    forkJoin(subs).subscribe(data => {
      data = data.map(card => {
        return card['result'][0];
      });
      this.analyticsService.setAnalytics(data);
      this.loaderService.stopLoading();
    }, err => {
      this.loaderService.stopLoading();
      console.log(err);
    });
  }

  downloadData() {
    this.loaderService.startLoading();
    let body = {
      downloadType: 'kpiCards',
      filters: this.analyticsService.getAnalyticsFilter(),
      datesFilter: this.analyticsService.getFilteredDates()
    }
    this.analyticsService.getDownloadFile(body).subscribe(data => {
      if (data) {
        this.analyticsService.downloadFile(data, 'Analytics-kpiCards');
        this.loaderService.stopLoading();
      } else {
        this.onSaveErrorPopup({
          header: 'Invalid Action',
          message: 'Unable to download, Please try again later'
        });
      }
      this.loaderService.stopLoading();
    }, err => {
      this.loaderService.stopLoading();
      this.onSaveErrorPopup({
        header: 'Invalid Action',
        message: 'Unable to download, Please try again later'
      });
      console.log(err);
    });
  }
  private onSaveErrorPopup(obj: { header: string; message: string, icon?: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        icon: obj.icon ? obj.icon : 'info-pophover-blue.svg',
        buttonText: 'OK'
      };
    }
    const dialogRef = this.dialog.open(PriceMessagesComponent, dialogConfig);
  }
  public assignHeadings(data) {
    data.forEach(element => {
      //assigning card and graph colors
      element = Object.assign(element, this.styles[element.kpiCardName]);

      //calculating share value
      if (this.filtersApplied || this.dateSelected) {
        if (element.filteredValue) {
          element.share = Math.round((element.filteredValue / element.totalValue) * 100);
        } else {
          element.share = 0;
        }
      } else {
        element.share = 100;
      }
      element.filteredValue = Math.floor(element.filteredValue)
      element.totalValue = Math.floor(element.totalValue)
      // assigning header names
      if (element.kpiCardName == 'activePriceAgreements') {
        element.cardName = 'Price Agreement';
        element.cardPosition = 3;
      } else if (element.kpiCardName == 'CATALOGUES') {
        element.cardName = 'Catalogues';
        element.cardPosition = 2;
      } else if (element.kpiCardName == 'activeItems') {
        element.cardName = 'Line-Items';
        element.cardPosition = 1;
      } else if (element.kpiCardName == 'voucherValues') {
        element.cardName = 'Voucher Values (EUR)';
        element.cardPosition = 4;
      } else if (element.kpiCardName == 'valueLeakageIdntifiers') {
        element.cardName = 'Value Leakage Identified (EUR)';
        element.cardPosition = 9;
      } else if (element.kpiCardName == 'orderValues') {
        element.cardName = 'Order Value (EUR)';
        element.cardPosition = 7;
      } else if (element.kpiCardName == 'orderIssueds') {
        element.cardName = 'Orders Issued';
        element.cardPosition = 6;
      } else if (element.kpiCardName == 'invoicedValues') {
        element.cardName = 'Invoiced Value (EUR)';
        element.cardPosition = 8;
      } else if (element.kpiCardName == 'remainigVoucherValues') {
        element.cardName = 'Remaining Voucher Values (EUR)';
        element.cardPosition = 5;
      } else if (element.kpiCardName == 'valueLeakageRecovered') {
        element.cardName = 'Value Leakage Settled (EUR)';
        element.cardPosition = 10;
      }
    });
  }

  ngOnDestroy() {
    this.analyticsService.setDatesFilter([]);
    this.analyticsService.setAnalyticsFilter([]);
  }
}
