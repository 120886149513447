import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class LineItemsDifferenceService {
  constructor(
  ) {

  }


  /**
   *Function that compares the new line items with old items and adds 'isNew' (true/false) attribute to that
   * line items based on the comparison
   * @param {*} oldLineItems
   * @param {*} newLineItems
   * @return {*} 
   * @memberof LineItemsDifferenceService
   */
  public getUpdatedLineItems(oldLineItems, newLineItems, isSelected) {

    if (!oldLineItems || !oldLineItems.length) {
      // VOD-1696
      return newLineItems.filter((data) => {
        if (data.manufacturerPartNumber || data.supplierPartNumber) {
          return data
        }
      }).map(item => {
        item.isNew = true;
        return item;
      });
      // VOD-1696
    }

    const oldItemId = oldLineItems.reduce((acc, item) => {
      let key = `${item.itemId}`
      acc[key] = item;
      return acc;
    }, {});

    const newItemId = newLineItems.reduce((acc, item) => {
      let key = `${item.itemId}`
      acc[key] = (item.manufacturerPartNumber || item.supplierPartNumber) ?
        true : false;
      return acc;
    }, {});
    let updatedItems = newLineItems.filter(item => {
      if (item.manufacturerPartNumber || item.supplierPartNumber) {
        const {
          itemId
        } = item;

        let uniqueKey = `${itemId}`
        const oldItem = oldItemId[uniqueKey];
        if (!oldItem) {
          return true;
        }

        if (!_.isEqual(item, oldItem)) {
          return true;
        }
        return false;
      }

    });

    if (!isSelected) {
      const deletedItems = Object.keys(oldItemId).filter(spn => {
        return !newItemId[spn];
      });

      deletedItems.forEach(spn => {
        const item = oldItemId[spn];
        item.isDeleted = true;
        updatedItems.push(item);
      });
    }


    updatedItems = updatedItems.map((item) => {
      if (item.manufacturerPartNumber || item.supplierPartNumber) {
        item.isNew = false;
        const { itemId } = item;
        let uniqueKey = `${itemId}`;
        let oldItem = oldItemId[uniqueKey];
        let localItem = Object.assign({}, item);
        if (oldItem) {
          oldItem = this.processLineItem(oldItem);
        }
        localItem = this.processLineItem(localItem);
        if (!oldItemId[uniqueKey]) {
          item.isNew = true;
        } else {
          item.isNew = false;
          item.hasChanges = false
        }
        delete localItem.tradingModel
        delete localItem.isNew
        delete localItem.hasChanges
        if (oldItem && oldItem.itemStatus) {
          localItem.itemStatus = oldItem.itemStatus;
        }
        if (oldItem && oldItem.catalogueStatus) {
          localItem.catalogueStatus = oldItem.catalogueStatus;
        } else {
          delete localItem.catalogueStatus
        }

        console.log("local item = ", JSON.stringify(localItem))
        console.log("old item = ", JSON.stringify(oldItem))
        if (!_.isEqual(localItem, oldItem)) {
          item.hasChanges = true
        }
        return item;
      }

    }).filter((data) => {
      if (data && (data.isNew || data.hasChanges || data.isDeleted)) {
        return data
      }
    });
    return updatedItems;
  }

  processLineItem(lineItem) {
    let item: any = {}
    item.physicalCategory = lineItem.materialCategory
    item.manufacturerPartNumber = lineItem.manufacturerPartNumber
    item.supplierPartNumber = lineItem.supplierPartNumber
    item.basePrice = lineItem.basePrice
    item.priceUnit = lineItem.priceUnit
    item.validFrom = lineItem.validFrom
    item.validTo = lineItem.validTo
    item.plannedDeliveryTime = lineItem.plannedDeliveryTime
    item.warranty = lineItem.warranty
    item.incoterms1 = lineItem.incoterms1
    item.warrantyEffectiveFrom = lineItem.warrantyEffectiveFrom
    item.serializationProfile = lineItem.serializationProfile
    item.commodityCode = lineItem.commodityCode
    item.netPrice = lineItem.netPrice
    item.basePrice = lineItem.basePrice
    if (lineItem.opcoCode && lineItem.opcoCode.length > 0) {
      item.opcoCode = _.map(lineItem.opcoCode, (opco) => {
        let localOpco: any = {}
        localOpco.opcoCode = opco.opcoCode
        localOpco.companyCode = opco.companyCode
        return localOpco;
      })
    } else {
      item.opcoCode = lineItem.opcoCode
    }

    return item
  }
}