import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BreakdownBlockChartComponent } from './../breakdown-block-chart/breakdown-block-chart.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AnalyticsService } from '../../../../_services/analytics.service';

@Component({
  selector: 'app-breakdown-blocks',
  templateUrl: './breakdown-blocks.component.html',
  styleUrls: ['./breakdown-blocks.component.scss']
})
export class BreakdownBlocksComponent implements OnInit {
  @Input() public cateList: any;
  @Input() public index: number;
  @Input() public page: number;
  @Input() public vrHide: boolean;  // [VOD-1714] 
  public math = Math;
  public data = [{
    colors: '#00D61D',
    data: [20, 20, 50, 30, 80, 60, 20, 50, 45, 90, 10, 45]
  }];
  // public highcharts = Highcharts;
  // public chartOptions = {
  //   chart: {
  //     type: 'line',
  //   },
  //   plotOptions: {
  //     series: {
  //       marker: {
  //         enabled: false
  //       }
  //     }
  //   },
  //   credits: {
  //     enabled: false
  //   },
  //   xAxis: {
  //     visible: false
  //   },
  //   yAxis: {
  //     visible: false
  //   },
  //   labels: {
  //     visible: false
  //   },
  //   ticks: {
  //     visible: false
  //   },
  //   legend: { enabled: false },
  //   title: {
  //     text: ''
  //   },
  //   series: [{}]
  // };
  public chart;
  public chartCallback;
  public updateFromInput = false;
  public chartDialogData = [];
  public orderValue = [];
  public invoiceValue = [];
  constructor(public dialog: MatDialog, public analyticsService: AnalyticsService) {
    this.chartCallback = chart => {
      this.chart = chart;
    };
  }

  public ngOnInit() {
    // if (this.cateList && this.cateList.chartData) {
    //   this.chartOptions.series = [{
    //     color: this.cateList.chartData[0].color,
    //     data: this.cateList.chartData[0].data,
    //     connectNulls: true
    //   }];
    //   this.updateFromInput = true;
    //   try {
    //     setTimeout(() => {
    //       this.chart.reflow();
    //     }, 0);
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }

  public openDialog(): void {
    let dialogData = {
      chart:this.cateList.chartData,
       title: `${this.cateList.subTitleValue} : ${this.cateList.title}`,
      months:this.cateList.months
    }
    const dialogRef = this.dialog.open(BreakdownBlockChartComponent, {
      width: '1200px',
      data: dialogData
    });
  }

}
