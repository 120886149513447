import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSelect, MatDialogRef } from '@angular/material';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-analytics-dialog',
  templateUrl: './analytics-dialog.component.html',
  styleUrls: ['./analytics-dialog.component.scss']
})
export class AnalyticsDialogComponent implements OnInit {
  public highcharts = Highcharts;
  public chartOptions = {
    chart: {
      type: 'line'
    },
    title: {
      text: '',
      align: 'left',
      style: {
        color: '#434343',
        fontFamily: 'Vodafone-Bold',
        fontSize: '14px'
      }
    },
    subtitle: {
      text: 'Showing trend based on applied filters.',
      align: 'left',
      style: {
        color: '#888888',
        fontFamily: 'Vodafone-Bold',
        fontSize: '10px'
      }
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      // min: 0,
      allowDecimals: false,
      title: {
        enabled: false
      },
      labels: {
        formatter() {
          // let val = this.cardName == 'activeItems' || this.cardName == 'activePriceAgreements' ? this.value : `${this.value}k`;
          return this.value;
        }
      }
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      style: {
        color: '#5B5B5B',
        fontSize: '11px',
        fontFamily: 'Vodafone-Bold'
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'horizontal ',
      align: 'right',
      verticalAlign: 'top',
      itemMarginTop: 8,
      itemMarginBottom: 8,
      borderColor: '#f0f0f0',
      borderRadius: 5,
      borderWidth: 1,
      itemStyle: {
        color: '#434343',
        fontFamily: 'Vodafone-Bold',
        fontSize: '12px',
        boxShadow: '0px 1px 1px 2px rgba(0,0,0,0.3)'
      }
    },
    series: []
  };
  public cardName;
  public chart;
  public chartCallback;
  public yValue: any;
  public updateFromInput = false;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
    const self = this;
    this.chartCallback = chart => {
      self.chart = chart;
    };
  }
  // activeItems activePriceAgreements
  public ngOnInit() {
    this.cardName = this.dialogData.cardName;
    this.yValue = this.dialogData.chartData.map(data => {
      let temp = { y: data, cardName: this.cardName };
      return temp;
    })
    const self = this;
    self.chartOptions.series = [{
      name: this.dialogData.title,
      color: this.dialogData.chartColor,
      data: this.yValue
    }];
    this.chartOptions.xAxis.categories = this.dialogData.months;
    this.chartOptions.title.text = this.dialogData.title;
    self.updateFromInput = true;
  }

  Last7Days() {
    var result = [];
    for (var i = 0; i < 7; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(this.formatDate(d))
    }
    return result;
  }

  formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = dd + '/' + mm + '/' + yyyy;
    return date
  }
}
