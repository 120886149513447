import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators, NgForm } from '@angular/forms';
import { AuthServices } from '../../_services/auth.service';
import { passwordRegex } from 'src/app/_config/constants';
import { AppConfig } from '../../_config';
import { LoaderService } from '../../_services/loader.service';
const { contactSupportLink } = AppConfig;
/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

/**
 *Component for change/add new password page
 *
 * @export
 * @class ChangePasswordComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public passwordForm: FormGroup;
  public submitted = false;
  public hidePassword: boolean;
  public hideConfirmPassword: boolean;
  public success: boolean;
  public errorMsg = '';
  public passwordRegex = passwordRegex;
  public errorMatcher = new CrossFieldErrorMatcher();
  public contactLink: any;
  public email: any;
  public verificationCode: any;
  public termsConditions: boolean;
  public checkConditions: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authServices: AuthServices,
    public route: ActivatedRoute,
    public router: Router,
    private loaderService: LoaderService,
  ) {
    this.checkConditions = true;
    this.route.queryParams.subscribe(params => {
      if (params.isTermsAndConditionAccepted === 'false') {
        this.checkConditions = false;
      }
    });
    this.email = this.route.snapshot.params.email;
    this.verificationCode = this.route.snapshot.params.token;
  }

  public ngOnInit() {
    this.contactLink = contactSupportLink;
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
      // tslint:disable-next-line:align
    }, {
      // tslint:disable-next-line: no-unbound-method
      validator: this.passwordValidator
    });

    this.termsConditions = this.checkConditions;

    this.hidePassword = true;
    this.hideConfirmPassword = true;
    this.success = false;
  }
  // convenience getter for easy access to form fields
  get f() { return this.passwordForm.controls; }

  public passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('confirmPassword').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }
  public submit(data: { password: string; }) {
    this.submitted = true;
    const password = data.password;
    this.authServices.setPassword({ email: this.email, password, verificationCode: this.verificationCode }).subscribe(
      () => {
        this.success = true;
      },
      error => {
        this.errorMsg = error.error.message;
      }
    );
  }

  public goToLogin() {
    this.router.navigate(['/']);
  }

  public onClickTermsButton(status) {
    if (status) {
      this.authServices.acceptTermsAndCondition({
        email: this.email,
        verificationCode: this.verificationCode,
        status
      }).subscribe(res => {
        if (res['result']) {
          this.termsConditions = res['result']['isTermsAndConditionAccepted'];
          this.verificationCode = res['result']['verificationCode'];
          localStorage.setItem('tnc', res['result']['isTermsAndConditionAccepted'].toString())
          this.errorMsg = '';
        }
        // tslint:disable-next-line: align
      }, (error) => {
        this.errorMsg = error.error.message;
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

}
