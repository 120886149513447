import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // tslint:disable-next-line:variable-name
  private _loading = false;
  public loadingStatus: Subject<any> = new Subject();

  /**
   *Function to get the page loading status
   *
   * @type {boolean}
   * @memberof LoaderService
   */
  public get loading(): boolean {
    return this._loading;
  }

  /**
   *Function to set the page loading status
   *
   * @memberof LoaderService
   */
  public set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  /**
   *Function to set the page loading status = true (loading start)
   *
   * @memberof LoaderService
   */
  public startLoading() {
    this.loading = true;
  }
  /**
   *Function to set the page loading status = false (loading end)
   *
   * @memberof LoaderService
   */
  public stopLoading() {
    this.loading = false;
  }
}
