import { Pipe, PipeTransform } from '@angular/core';
import * as  moment from 'moment';

@Pipe({ name: "minute" })
export class MinutePipe implements PipeTransform {
  public transform(value: string): string {
    let purchaseDate: any = new Date(value);
    let diffMins = moment.duration(moment().diff(moment(purchaseDate)));
    let minutes = Math.round(diffMins.asMinutes());
    if (minutes < 60) {
      return minutes + " mins";
    } else if (minutes < 1140) {
      return Math.round(diffMins.asHours()) + " hours";
    } else if (minutes < 10080) {
      return Math.round(diffMins.asDays()) + " days";
    } else {
      return Math.round(diffMins.asWeeks()) + " weeks";
    }
  }
}
