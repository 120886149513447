import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PriceManagerServices } from '../../_services/price-manager.service';


/**
 *Custom component for showing dynamic message
 *
 * @export
 * @class PriceMessagesComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-price-messages',
  templateUrl: './price-messages.component.html',
  styleUrls: ['./price-messages.component.scss']
})
export class PriceMessagesComponent implements OnInit {

  public header: string;
  public message: string;
  public icon: string;
  public redirection: string;
  public buttonText: string;
  public showBusJust: boolean = false;
  public bussJustComment: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PriceMessagesComponent>,
    public priceManagerService: PriceManagerServices,
  ) {
    this.header = data && data.header;
    this.message = data && data.message;
    this.icon = data && data.icon;
    this.redirection = data && data.redirection;
    this.buttonText = data && data.buttonText;
    if (data && data.isCompleteTab) {
      this.showBusJust = true
    } else {
      this.showBusJust = false;
    }
  }

  public ngOnInit() {


  }
  public submitBussJust() {
    this.priceManagerService.setBussJustDialogData(
      this.bussJustComment
    )
    this.priceManagerService.popUpSubmit = true;
  }
  public cancel(){
    this.priceManagerService.popUpSubmit = false;
  }

}
