import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

/**
 *Custom component for showing dynamic success messages
 *
 * @export
 * @class SuccessDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
  public header = '';
  public message = '';
  public quickTip = '';
  public buttonTxt: string;
  public icon: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SuccessDialogComponent>
  ) {
    this.header = data && data.header;
    this.message = data && data.message;
    this.buttonTxt = data && data.buttonTxt;
    this.icon = data && data.icon;
    this.quickTip = data.quickTip;
  }

  public ngOnInit() {
  }

}
