import { Component, OnInit } from '@angular/core';
import { PriceManagerServices } from '../../_services/price-manager.service';
import { catalogueStatusConst, agreementStatus } from '../../_config/constants';

/**
 *Price agreement page navication component
 *
 * @export
 * @class NavPriceAgreementsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-nav-price-agreements',
  templateUrl: './nav-price-agreements.component.html',
  styleUrls: ['./nav-price-agreements.component.scss']
})
export class NavPriceAgreementsComponent implements OnInit {

  public filterData: any = {};
  public total: number;
  public delegated: number;
  public updateRequired: number;
  public expiringSoon: number;
  public clickStatus = 'allAgreement';
  public status: any = {
    DRAFT: 0,
    APPROVED: 0,
    UNDER_REVIEW: 0,
    ARCHIVED: 0,
    EXPIRED: 0
  };

  public catalogueStatus: any = {
    ACTIVE: 0,
    INACTIVE: 0,
    CATALOGUE_PENDING: 0,
    COMMERCIAL_PENDING: 0
  };

  public catalogueStatusConst = catalogueStatusConst;
  public agreementStatus = agreementStatus;

  // tslint:disable-next-line:no-empty
  constructor(private priceManagerService: PriceManagerServices) { }

  public ngOnInit() {
    this.getPriceAgreementsCount();
  }

  public getPriceList(type: string, status: string) {
    //const filters = [];
    this.clickStatus = status;
    if (status === 'update_required' && type === 'allAgreement') {
      this.filterData.name = status;
      this.filterData.type = 'json';
      this.filterData.value = true;
      //filters.push(this.filterData);
    }
    if (status === 'expire_soon' && type === 'allAgreement') {
      this.filterData.name = status;
      this.filterData.type = 'json';
      this.filterData.value = true;
      //filters.push(this.filterData);
    }
    if (status === 'isDelegated' && type === 'allAgreement') {
      this.filterData.name = status;
      this.filterData.type = 'equal';
      this.filterData.value = true;
      //filters.push(this.filterData);
    }
    if (type === 'priceAgreement') {
      this.filterData.name = 'status';
      this.filterData.type = 'equal';
      this.filterData.value = [status];
      //filters.push(this.filterData);
    }
    if (type === 'catalogue') {
      this.filterData.name = 'catalogueStatus';
      this.filterData.type = 'equal';
      this.filterData.value = [status];
      //filters.push(this.filterData);
    }
    if (status === 'allAgreement' && type === 'ALL_TASK') {
      //filters.push();
      this.filterData.name = '';
      this.filterData.type = '';
      this.filterData.value = '';
    }
    this.priceManagerService.setTopMenuFilter(this.filterData);
    //this.priceManagerService.setPriceFilter(filters);
  }

  public getPriceAgreementsCount() {
    this.priceManagerService.getPriceAgreementsCount().subscribe(data => {
      if (data) {
        this.total = data['result'].total;
        this.delegated = data['result'].delegated;
        this.updateRequired = data['result'].updateRequired || 0;
        this.expiringSoon = data['result'].expirySoon || 0;
        let status = data['result'].status.find(obj => obj.status === 'DRAFT');
        this.status.DRAFT = status ? status.count : 0;
        status = data['result'].status.find(obj => obj.status === 'APPROVED');
        this.status.APPROVED = status ? status.count : 0;
        status = data['result'].status.find(obj => obj.status === 'ARCHIVED');
        this.status.ARCHIVED = status ? status.count : 0;
        status = data['result'].status.find(obj => obj.status === 'UNDER_REVIEW');
        this.status.UNDER_REVIEW = status ? status.count : 0;
        status = data['result'].status.find(obj => obj.status === 'EXPIRED');
        this.status.EXPIRED = status ? status.count : 0;
        data['result'].catalogueStatus.forEach(element => {
          const { catalogueStatus, count } = element;
          this.catalogueStatus[catalogueStatus] = count;
        });
      }
    });
  }
}
