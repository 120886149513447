import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from '../../_services/auth.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';


/**
 *Component for forgot password page
 *
 * @export
 * @class ForgotPasswordComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotForm: FormGroup;
  public errorSubmitMsg: any;
  public displayFlg = false;
  public notFocused = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authServices: AuthServices
  ) {
  }

  public ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')
      ])
    });
  }

  public forgotPassword(email: string) {
    this.displayFlg = false;
    this.authServices.forgetPassword(email).subscribe(
      () => {
        this.displayFlg = true;
        this.forgotForm.reset();
      },
      error => {
        this.errorSubmitMsg = error.error.message;
      }
    );
  }

  public onInputEmail() {
    this.displayFlg = false;
  }

}
