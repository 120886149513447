import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../_config';
import { IServerResponse } from '../_models/response';
import { AuthServices } from '../_services/auth.service';
import * as  moment from 'moment';

const { priceUrl, priceUrl1 } = AppConfig;
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterNntwkServices {
  private priceFilter = new Subject<any>();
  private dropFilter = new Subject<any>();
  constructor(
    private http: HttpClient,
    private authService: AuthServices
  ) { }

  private userFilterUrl: string = priceUrl1 + 'user-filter';
  private userFilterListUrl: string = priceUrl1 + 'user-filter/list';

  /**
   *Api function that save the user added filters to the specified component name
   *
   * @param {{
   *     filters: Array<any>,
   *     filterName: string,
   *     componentName: string
   *   }} filterObj
   * @return {*} 
   * @memberof FilterServices
   */
  public addUserFilters(filterObj: {
    filters: Array<any>,
    filterName: string,
    componentName: string
  }) {
    return this.http.post<IServerResponse<any>>(this.userFilterUrl, filterObj, this.authService.getAccessHeaders());
  }

  /**
   *Api function that returns the user filters data based on the component name
   *
   * @param {string} componentName
   * @return {*} 
   * @memberof FilterServices
   */
  public getUserFilters(componentName: string) {
    const query = {
      componentName
    };
    const url = this.userFilterListUrl + `?componentName=${componentName}`;
    return this.http.get<IServerResponse<any>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *
   *Api function that returns the user filter data based on the component name & filter name
   * @param {object} filterObj
   * @return {*} 
   * @memberof FilterServices
   */
  public getUserFilter(filterObj: object) {
    const url = this.userFilterUrl + `?componentName=${encodeURIComponent(filterObj['componentName'])}&filterName=${encodeURIComponent(filterObj['filterName'])}`;
    return this.http.get<IServerResponse<any>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function that delete the saved filter data
   *
   * @param {object} filterObj
   * @return {*} 
   * @memberof FilterServices
   */
  public deleteSavedUserFilter(filterObj: object) {
    const url = this.userFilterUrl + `?componentName=${encodeURIComponent(filterObj['componentName'])}&filterName=${encodeURIComponent(filterObj['filterName'])}`;
    return this.http.delete(
      url,
      this.authService.getAccessHeaders()
    );
  }
  /**
   *Observable function that set the filter values
   *
   * @param {*} data
   * @memberof FilterServices
   */
  public setFilter(data: any) {
    this.priceFilter.next({ result: data });
  }

  /**
   *Observable function that return the filter values
   *
   * @return {*}  {Observable<any>}
   * @memberof FilterServices
   */
  public getFilter(): Observable<any> {
    return this.priceFilter.asObservable();
  }



  /**
   *Function that creates a filter objects which contains filter name, filter type and filter value
   *It's creata a array of objects based on input filter values
   * @param {*} data
   * @param {*} [types=null]
   * @return {*} 
   * @memberof FilterServices
   */
  public dataFilter(data: any, types = null) {
    if (types === 'equal') {
      types = types;
    } else {
      types = ''; // new Array();
    }
    const filters = [];
    if (data && data.amountId && data.amountId.length) {
      const amountIdObj = {
        name: 'amountId',
        type: types,
        value: data.amountId
      };
      filters.push(amountIdObj);
    }
    if (data && data.contractId && data.contractId.length) {
      const contractIdObj = {
        name: 'contractId',
        type: 'search',
        value: data.contractId
      };
      filters.push(contractIdObj);
    }
    if (data && data.olaId && data.olaId.length) {
      const olaIdObj = {
        name: 'outlineAgreementNumber',
        type: 'search',
        value: data.olaId
      };
      filters.push(olaIdObj);
    }
    if (data && data.physicalNonphysicalId && data.physicalNonphysicalId.length) {
      const physicalNonphysicalIdObj = {
        name: 'physicalNonphysicalId',
        type: types,
        value: data.physicalNonphysicalId
      };
      filters.push(physicalNonphysicalIdObj);
    }
    if (data && data.tradingId && data.tradingId.length) {
      const tradingObj = {
        name: 'tradingModel',
        type: types,
        value: data.tradingId
      };
      filters.push(tradingObj);
    }
    if (data && data.opcoId && data.opcoId.length) {
      const opcoObj = {
        name: 'opcoCode',
        type: types,
        value: data.opcoId
      };
      filters.push(opcoObj);
    }
    if (data && data.catalogueId && data.catalogueId.length) {
      const catalogueObj = {
        name: 'catalogueType',
        type: types,
        value: data.catalogueId
      };
      filters.push(catalogueObj);
    }
    if (data && data.materialId && data.materialId.length) {
      const materialObj = {
        name: 'materialGroup',
        type: types,
        value: data.materialId
      };
      filters.push(materialObj);
    }
    if (data && data.catalogueStatusId && data.catalogueStatusId.length) {
      const catalogueStatusObj = {
        name: 'catalogueStatus',
        type: types,
        value: data.catalogueStatusId
      };
      filters.push(catalogueStatusObj);
    }
    if (data && data.paStatusId && data.paStatusId.length) {
      if (data.paStatusId.includes('ITEM_EXPIRED')) {
        const index = data.paStatusId.indexOf('ITEM_EXPIRED');
        if (index > -1) {
          data.paStatusId.splice(index, 1);
        }
        data.paStatusId.push('EXPIRED');
      }
      if (data.paStatusId.includes('ITEM_DELETED')) {
        const index = data.paStatusId.indexOf('ITEM_DELETED');
        if (index > -1) {
          data.paStatusId.splice(index, 1);
        }
        data.paStatusId.push('ARCHIVED');
      }
      const paStatusObj = {
        name: 'status',
        type: types,
        value: data.paStatusId
      };
      filters.push(paStatusObj);
    }
    if (data && data.currencyId && data.currencyId.length) {
      const currencyObj = {
        name: 'tradingCurrencyKey',
        type: types,
        value: data.currencyId
      };
      filters.push(currencyObj);
    }
    if (data && data.parentSupplierId && data.parentSupplierId.length) {
      const parentSupplierObj = {
        name: 'parentSupplier',
        type: types,
        value: data.parentSupplierId
      };
      filters.push(parentSupplierObj);
    }
    if (data && data.suplierId && data.suplierId.length) {
      const supplierObj = {
        name: 'supplier',
        type: types,
        value: data.suplierId
      };
      filters.push(supplierObj);
    }
    if (data && data.integrationStatusId && data.integrationStatusId.length) {
      const integrationStatusObj = {
        name: 'integrationStatus',
        type: types,
        value: data.integrationStatusId
      };
      filters.push(integrationStatusObj);
    }
    if (data && data['validity'] && data['validity'].length) {
      const datesFilt = data['validity'][0].split(' - ');
      data.startDate = datesFilt[0] ? moment(datesFilt[0], 'DD-MM-YYYY').add(1, 'days').toDate() : '';
      data.endDate = datesFilt[1] ? moment(datesFilt[1], 'DD-MM-YYYY').add(1, 'days').toDate() : '';
    }

    if (data.startDate !== 'Invalid Date' && data.startDate) {
      const startDateObj = {
        name: 'validFrom',
        type: 'gte',
        value: data.startDate
      };
      filters.push(startDateObj);
    }
    if (data.endDate !== 'Invalid Date' && data.endDate) {
      const endDateObj = {
        name: 'validTo',
        type: 'lte',
        value: data.endDate
      };
      filters.push(endDateObj);
    }
    if (data && data.monthID) {
      const monthObj = {
        name: 'monthFilter',
        type: types,
        value: data.monthID
      };
      filters.push(monthObj);
    }
    if (data && data.masterAgreementNameId && data.masterAgreementNameId.length) {
      const masterAgreementNameObj = {
        name: 'referenceName',
        type: data.masterAgreementNameType ? data.masterAgreementNameType : 'equal',
        value: data.masterAgreementNameId
      };
      filters.push(masterAgreementNameObj);
    }
    if (data && data.supplierNameId && data.supplierNameId.length) {
      const supplierNameObj = {
        name: 'supplierName',
        type: data.supplierNameType ? data.supplierNameType : 'equal',
        value: data.supplierNameId
      };
      filters.push(supplierNameObj);
    }
    if (data && data.opcoNameId && data.opcoNameId.length) {
      const opcoNameObj = {
        name: 'opcoName',
        type: data.opcoNameType ? data.opcoNameType : 'equal',
        value: data.opcoNameId
      };
      filters.push(opcoNameObj);
    }
    if (data && data.tradingCurrencyKeyId && data.tradingCurrencyKeyId.length) {
      const tradingCurrencyKeyObj = {
        name: 'tradingCurrencyKey',
        type: data.tradingCurrencyKeyType ? data.tradingCurrencyKeyType : 'equal',
        value: data.tradingCurrencyKeyId
      };
      filters.push(tradingCurrencyKeyObj);
    }
    if (data && data.validFromDateId && data.validFromDateId.length) {
      const validFromDateObj = {
        name: 'validFrom',
        type: data.validFromDateType ? data.validFromDateType : 'equal',
        value: data.validFromDateId
      };
      filters.push(validFromDateObj);
    }
    if (data && data.validToDateId && data.validToDateId.length) {
      const validToDateObj = {
        name: 'validTo',
        type: data.validToDateType ? data.validToDateType : 'equal',
        value: data.validToDateId
      };
      filters.push(validToDateObj);
    }
    if (data && data.secondaryStartDateId && data.secondaryStartDateId.length) {
      const secondaryStartDateObj = {
        name: 'secondaryStartDate',
        type: data.secondaryStartDateType ? data.secondaryStartDateType : 'equal',
        value: data.secondaryStartDateId
      };
      filters.push(secondaryStartDateObj);
    }
    if (data && data.secondaryEndDateId && data.secondaryEndDateId.length) {
      const secondaryEndDateObj = {
        name: 'secondaryEndDate',
        type: data.secondaryEndDateType ? data.secondaryEndDateType : 'equal',
        value: data.secondaryEndDateId
      };
      filters.push(secondaryEndDateObj);
    }
    if (data && data.vpcOwnerId && data.vpcOwnerId.length) {
      const vpcOwnerObj = {
        name: 'vpcOwner',
        type: data.vpcOwnerType ? data.vpcOwnerType : 'equal',
        value: data.vpcOwnerId
      };
      filters.push(vpcOwnerObj);
    }
    if (data && data.supplierOwnerId && data.supplierOwnerId.length) {
      const supplierOwnerObj = {
        name: 'supplierOwner',
        type: data.supplierOwnerType ? data.supplierOwnerType : 'equal',
        value: data.supplierOwnerId
      };
      filters.push(supplierOwnerObj);
    }
    if (data && data.createdAtId && data.createdAtId.length) {
      const createdAtObj = {
        name: 'createdAt',
        type: data.createdAtType ? data.createdAtType : 'equal',
        value: data.createdAtId
      };
      filters.push(createdAtObj);
    }
    if (data && data.createdById && data.createdById.length) {
      const createdByObj = {
        name: 'createdBy',
        type: data.createdByType ? data.createdByType : 'equal',
        value: data.createdById
      };
      filters.push(createdByObj);
    }
    if (data && data.updatedAtId && data.updatedAtId.length) {
      const updatedAtObj = {
        name: 'updatedAt',
        type: data.updatedAtType ? data.updatedAtType : 'equal',
        value: data.updatedAtId
      };
      filters.push(updatedAtObj);
    }
    if (data && data.updatedById && data.updatedById.length) {
      const updatedByObj = {
        name: 'updatedBy',
        type: data.updatedByType ? data.updatedByType : 'equal',
        value: data.updatedById
      };
      filters.push(updatedByObj);
    }
    if (data && data.isVisibleId && data.isVisibleId.length) {
      const isVisibleObj = {
        name: 'isVisible',
        type: data.isVisibleType ? data.isVisibleType : 'equal',
        value: data.isVisibleId[0]   // send value as string
      };
      filters.push(isVisibleObj);
    }
    if (data && data.isSupplierOnboardedId && data.isSupplierOnboardedId.length) {
      const isSupplierOnboardedObj = {
        name: 'isSupplierOnboarded',
        type: data.isSupplierOnboardedType ? data.isSupplierOnboardedType : 'equal',
        value: data.isSupplierOnboardedId[0]   // send value as string
      };
      filters.push(isSupplierOnboardedObj);
    }
    if (data && data.paStatusFilterId && data.paStatusFilterId.length) {
      const paStatusFilterObj = {
        name: 'status',
        type: data.paStatusFilterType ? data.paStatusFilterType : 'equal',
        value: data.paStatusFilterId
      };
      filters.push(paStatusFilterObj);
    }
    if (data && data.lineItemStatusFilterId && data.lineItemStatusFilterId.length) {
      const lineItemStatusFilterObj = {
        name: 'catalogueStatus',
        type: data.lineItemStatusFilterType ? data.lineItemStatusFilterType : 'equal',
        value: data.lineItemStatusFilterId
      };
      filters.push(lineItemStatusFilterObj);
    }
    if (data && data.integrationStatusFilterId && data.integrationStatusFilterId.length) {
      const integrationStatusFilterObj = {
        name: 'integrationStatus',
        type: data.integrationStatusFilterType ? data.integrationStatusFilterType : 'equal',
        value: data.integrationStatusFilterId
      };
      filters.push(integrationStatusFilterObj);
    }
    if (data && data.lastIntegratedVersionId && data.lastIntegratedVersionId.length) {
      const lastIntegratedVersionObj = {
        name: 'lastIntegratedVersion',
        type: data.lastIntegratedVersionFilterType ? data.lastIntegratedVersionFilterType : 'contains',
        value: data.lastIntegratedVersionId
      };
      filters.push(lastIntegratedVersionObj);
    }
    if (data && data.outlineAgreementId && data.outlineAgreementId.length) {
      const outlineAgreementNumberObj = {
        name: 'outlineAgreementNumber',
        type: data.outlineAgreementNumberType ? data.outlineAgreementNumberType : 'contains',
        value: data.outlineAgreementId
      };
      filters.push(outlineAgreementNumberObj);
    }
    if (data && data.supplierIdVal && data.supplierIdVal.length) {
      const supplierIdObj = {
        name: 'supplierId',
        type: data.supplierIdType ? data.supplierIdType : 'contains',
        value: data.supplierIdVal
      };
      filters.push(supplierIdObj);
    }
    if (data && data.catalogueIdentifier && data.catalogueIdentifier.length) {
      const catalogueIdentifierObj = {
        name: 'catalogueIdentifier',
        type: data.catalogueIdentifierFilterType ? data.catalogueIdentifierFilterType : 'contains',
        value: data.catalogueIdentifier
      };
      filters.push(catalogueIdentifierObj);
    }
    if (data && data.catalogueName && data.catalogueName.length) {
      const catalogueNameObj = {
        name: 'catalogueName',
        type: data.catalogueNameFilterType ? data.catalogueNameFilterType : 'contains',
        value: data.catalogueName
      };
      filters.push(catalogueNameObj);
    }
    if (data && data.primaryDelegatorId && data.primaryDelegatorId.length) {
      const primaryDelegatorObj = {
        name: 'delegatedByUser',
        type: data.primaryDelegatorType ? data.primaryDelegatorType : 'contains',
        value: data.primaryDelegatorId
      };
      filters.push(primaryDelegatorObj);
    }
    if (data && data.primaryDelegateId && data.primaryDelegateId.length) {
      const primaryDelegateObj = {
        name: 'delegatedToUser',
        type: data.primaryDelegateType ? data.primaryDelegateType : 'contains',
        value: data.primaryDelegateId
      };
      filters.push(primaryDelegateObj);
    }
    if (data && data.secondaryDelegatorId && data.secondaryDelegatorId.length) {
      const secondaryDelegatorObj = {
        name: 'secondaryDelegatedBy',
        type: data.secondaryDelegatorType ? data.secondaryDelegatorType : 'contains',
        value: data.secondaryDelegatorId
      };
      filters.push(secondaryDelegatorObj);
    }
    if (data && data.secondaryDelegateId && data.secondaryDelegateId.length) {
      const secondaryDelegateObj = {
        name: 'secondaryDelegatedTo',
        type: data.secondaryDelegateType ? data.secondaryDelegateType : 'contains',
        value: data.secondaryDelegateId
      };
      filters.push(secondaryDelegateObj);
    }
    if (data && data.delegationTypeId && data.delegationTypeId.length) {
      const delegationTypeObj = {
        name: 'delegationType',
        type: data.delegationTypeType ? data.delegationTypeType : 'contains',
        value: data.delegationTypeId
      };
      filters.push(delegationTypeObj);
    }
    if (data && data.primaryDelegatedFromId && data.primaryDelegatedFromId.length) {
      const primaryDelegatedFromObj = {
        name: 'primaryDelegatedFrom',
        type: data.primaryDelegatedFromType ? data.primaryDelegatedFromType : 'contains',
        value: data.primaryDelegatedFromId
      };
      filters.push(primaryDelegatedFromObj);
    }
    if (data && data.secondaryDelegatedFromId && data.secondaryDelegatedFromId.length) {
      const secondaryDelegatedFromObj = {
        name: 'secondaryDelegatedFrom',
        type: data.secondaryDelegatedFromType ? data.secondaryDelegatedFromType : 'contains',
        value: data.secondaryDelegatedFromId
      };
      filters.push(secondaryDelegatedFromObj);
    }
    if (data && data.materialCategoryId && data.materialCategoryId.length) {
      const materialCategoryObj = {
        name: 'materialCategory',
        type: data.materialCategoryType ? data.materialCategoryType : 'contains',
        value: data.materialCategoryId
      };
      filters.push(materialCategoryObj);
    }
    if (data && data.paymentTermsId && data.paymentTermsId.length) {
      const paymentTermsObj = {
        name: 'paymentTerms',
        type: data.materialCategoryType ? data.paymentTermsType : 'contains',
        value: data.paymentTermsId
      };
      filters.push(paymentTermsObj);
    }
    if (data && data.contractIdId && data.contractIdId.length) {
      const contractIdObj = {
        name: 'contractId',
        type: data.contractIdType ? data.contractIdType : 'contains',
        value: data.contractIdId
      };
      filters.push(contractIdObj);
    }

    return filters;
  }


  /**
   *Function to create a filter objects to save the user added filters
   *
   * @param {*} array
   * @param {string} componentNames
   * @return {*} 
   * @memberof FilterServices
   */
  public prepairFilterObjToSave(array, componentNames: string) {
    const filterObjToSave: { filters: Array<any>, filterName: string, componentName: string } = {
      filters: [],
      filterName: '',
      componentName: componentNames
    };

    const supplierFilters = { name: 'supplier', value: [], filterType: '' };
    const tradingFilters = { name: 'trading', value: [], filterType: '' };
    const catalogueFilters = { name: 'catalogue', value: [], filterType: '' };
    const opcoFilters = { name: 'opco', value: [], filterType: '' };
    const materialFilters = { name: 'material', value: [], filterType: '' };
    const validityFilters = { name: 'validity', value: [], filterType: '' };
    const paStatusFilters = { name: 'paStatus', value: [], filterType: '' };
    const catalogueStatusFilters = { name: 'catalogueStatus', value: [], filterType: '' };
    const taskTypeFilters = { name: 'taskType', value: [], filterType: '' };
    const taskStatusFilters = { name: 'taskStatus', value: [], filterType: '' };
    const parentSupplierFilters = { name: 'parentSupplier', value: [], filterType: '' };
    const integrationStatusFilters = { name: 'integrationStatus', value: [], filterType: '' };
    const currencyFilters = { name: 'currency', value: [], filterType: '' };
    const amountFilter = { name: 'amountFilter', value: [], filterType: '' };
    /* VP3-3 start */
    const physicalNonphysical = { name: 'physicalNonphysical', value: [], filterType: '' }; //vp3-3
    const masterAgreementName = { name: 'referenceName', value: [], filterType: '' }; //vp3-3
    const primaryDelegator = { name: 'delegatedByUser', value: [], filterType: '' }; //vp3-3
    const primaryDelegate = { name: 'delegatedToUser', value: [], filterType: '' }; //vp3-3
    const secondaryDelegator = { name: 'secondaryDelegatedBy', value: [], filterType: '' }; //vp3-3
    const secondaryDelegate = { name: 'secondaryDelegatedTo', value: [], filterType: '' }; //vp3-3
    const delegationType = { name: 'delegationType', value: [], filterType: '' }; //vp3-3
    const primaryDelegatedFrom = { name: 'primaryDelegatedFrom', value: [], filterType: '' }; //vp3-3
    const secondaryDelegatedFrom = { name: 'secondaryDelegatedFrom', value: [], filterType: '' }; //vp3-3
    const materialCategory = { name: 'materialCategory', value: [], filterType: '' }; //vp3-3
    const paymentTerms = { name: 'paymentTerms', value: [], filterType: '' }; //vp3-3
    const contractIdId = { name: 'contractIdId', value: [], filterType: '' }; //vp3-3
    const supplierName = { name: 'supplierName', value: [], filterType: '' }; //vp3-3
    const opcoName = { name: 'opcoName', value: [], filterType: '' }; //vp3-3
    const tradingCurrencyKey = { name: 'tradingCurrencyKey', value: [], filterType: '' }; //vp3-3
    const validFromDate = { name: 'validFrom', value: [], filterType: '' }; //vp3-3
    const validToDate = { name: 'validTo', value: [], filterType: '' }; //vp3-3
    const secondaryStartDate = { name: 'secondaryStartDate', value: [], filterType: '' }; //vp3-3
    const secondaryEndDate = { name: 'secondaryEndDate', value: [], filterType: '' }; //vp3-3
    const vpcOwner = { name: 'vpcOwner', value: [], filterType: '' }; //vp3-3
    const supplierOwner = { name: 'supplierOwner', value: [], filterType: '' }; //vp3-3
    const createdAt = { name: 'createdAt', value: [], filterType: '' }; //vp3-3
    const createdBy = { name: 'createdBy', value: [], filterType: '' }; //vp3-3
    const updatedAt = { name: 'updatedAt', value: [], filterType: '' }; //vp3-3
    const updatedBy = { name: 'updatedBy', value: [], filterType: '' }; //vp3-3
    const isVisible = { name: 'isVisible', value: [], filterType: '' }; //vp3-3
    const isSupplierOnboarded = { name: 'isSupplierOnboarded', value: [], filterType: '' }; //vp3-3
    const paStatusFilter = { name: 'paStatusFilter', value: [], filterType: '' }; //vp3-3
    const lineItemStatusFilter = { name: 'lineItemStatusFilter', value: [], filterType: '' }; //vp3-3
    const integrationStatusFilter = { name: 'integrationStatusFilter', value: [], filterType: '' }; //vp3-3
    const lastIntegratedVersion = { name: 'lastIntegratedVersion', value: [], filterType: '' }; //vp3-284
    const supplierId = { name: 'supplirId', value: [], filterType: '' };
    const outlineAgreementNumber = { name: 'outlineAgreementNumber', value: [], filterType: '' };
    const contractId = { name: 'contractId', value: [], filterType: '' };
    const catalogueIdentifier = { name: 'catalogueIdentifier', value: [], filterType: '' };
    const catalogueName = { name: 'catalogueName', value: [], filterType: '' };
    /* VP3-3 */

    // VOD-1652
    const catalogueIdFilter = { name: 'l1Category', value: [], filterType: '' };
    const l4GroupFilter = { name: 'l4Group', value: [], filterType: '' };
    if (array.length) {
      array.forEach(element => {
        if (element.type === 'supplier') {
          supplierFilters.value.push({ key: element.key, value: element['value'] });
          supplierFilters.filterType = element.filterType;
        } else if (element.type === 'trading') {
          tradingFilters.value.push({ key: element.key, value: element['value'] });
          tradingFilters.filterType = element.filterType;
        } else if (element.type === 'catalogue') {
          catalogueFilters.value.push({ key: element.key, value: element['value'] });
          catalogueFilters.filterType = element.filterType;
        } else if (element.type === 'opco') {
          opcoFilters.value.push({ key: element.key, value: element['value'] });
          opcoFilters.filterType = element.filterType;
        } else if (element.type === 'material') {
          materialFilters.value.push({ key: element.key, value: element['value'] });
          materialFilters.filterType = element.filterType;
        } else if (element.type === 'validity') {
          validityFilters.value.push({ key: element.key, value: element['value'] });
          validityFilters.filterType = element.filterType;
        } else if (element.type === 'paStatus') {
          paStatusFilters.value.push({ key: element.key, value: element['value'] });
          paStatusFilters.filterType = element.filterType;
        } else if (element.type === 'catalogueStatus') {
          catalogueStatusFilters.value.push({ key: element.key, value: element['value'] });
          catalogueStatusFilters.filterType = element.filterType;
        } else if (element.type === 'taskType') {
          taskTypeFilters.value.push({ key: element.key, value: element['value'] });
          taskTypeFilters.filterType = element.filterType;
        } else if (element.type === 'taskStatus') {
          taskStatusFilters.value.push({ key: element.key, value: element['value'] });
          taskStatusFilters.filterType = element.filterType;
        } else if (element.type === 'parentSupplier') {
          parentSupplierFilters.value.push({ key: element.key, value: element['value'] });
          parentSupplierFilters.filterType = element.filterType;
        } else if (element.type === 'integrationStatus') {
          integrationStatusFilters.value.push({ key: element.key, value: element['value'] });
          integrationStatusFilters.filterType = element.filterType;
        } else if (element.type === 'currency') {
          currencyFilters.value.push({ key: element.key, value: element['value'] });
          currencyFilters.filterType = element.filterType;
        } else if (element.type === 'amount') {
          amountFilter.value.push({ key: element.key, value: element['value'] });
          amountFilter.filterType = element.filterType;
        } else if (element.type === 'physicalStatus') {
          physicalNonphysical.value.push({ key: element.key, value: element['value'] });
          physicalNonphysical.filterType = element.filterType;
        }
        // VOD-1652
        else if (element.type === 'l1Category') {
          catalogueIdFilter.value.push({ key: element.key, value: element['value'] });
          catalogueIdFilter.filterType = element.filterType;
        } else if (element.type === 'l4Group') {
          l4GroupFilter.value.push({ key: element.key, value: element['value'] });
          l4GroupFilter.filterType = element.filterType;
        } else if (element.type === 'referenceName') {
          masterAgreementName.value.push({ key: element.key, value: element['value'] });
          masterAgreementName.filterType = element.filterType;
        } else if (element.type === 'supplierName') {
          supplierName.value.push({ key: element.key, value: element['value'] });
          supplierName.filterType = element.filterType;
        } else if (element.type === 'opcoName') {
          opcoName.value.push({ key: element.key, value: element['value'] });
          opcoName.filterType = element.filterType;
        } else if (element.type === 'tradingCurrencyKey') {
          tradingCurrencyKey.value.push({ key: element.key, value: element['value'] });
          tradingCurrencyKey.filterType = element.filterType;
        } else if (element.type === 'validFrom') {
          validFromDate.value.push({ key: element.key, value: element['value'] });
          validFromDate.filterType = element.filterType;
        } else if (element.type === 'validTo') {
          validToDate.value.push({ key: element.key, value: element['value'] });
          validToDate.filterType = element.filterType;
        } else if (element.type === 'secondaryStartDate') {
          secondaryStartDate.value.push({ key: element.key, value: element['value'] });
          secondaryStartDate.filterType = element.filterType;
        } else if (element.type === 'secondaryEndDate') {
          secondaryEndDate.value.push({ key: element.key, value: element['value'] });
          secondaryEndDate.filterType = element.filterType;
        } else if (element.type === 'vpcOwner') {
          vpcOwner.value.push({ key: element.key, value: element['value'] });
          vpcOwner.filterType = element.filterType;
        } else if (element.type === 'supplierOwner') {
          supplierOwner.value.push({ key: element.key, value: element['value'] });
          supplierOwner.filterType = element.filterType;
        } else if (element.type === 'createdAt') {
          createdAt.value.push({ key: element.key, value: element['value'] });
          createdAt.filterType = element.filterType;
        } else if (element.type === 'createdBy') {
          createdBy.value.push({ key: element.key, value: element['value'] });
          createdBy.filterType = element.filterType;
        } else if (element.type === 'updatedAt') {
          updatedAt.value.push({ key: element.key, value: element['value'] });
          updatedAt.filterType = element.filterType;
        } else if (element.type === 'updatedBy') {
          updatedBy.value.push({ key: element.key, value: element['value'] });
          updatedBy.filterType = element.filterType;
        } else if (element.type === 'isVisible') {
          isVisible.value.push({ key: element.key, value: element['value'] });
          isVisible.filterType = element.filterType;
        } else if (element.type === 'isSupplierOnboarded') {
          isSupplierOnboarded.value.push({ key: element.key, value: element['value'] });
          isSupplierOnboarded.filterType = element.filterType;
        } else if (element.type === 'paStatusFilter') {
          paStatusFilter.value.push({ key: element.key, value: element['value'] });
          paStatusFilter.filterType = element.filterType;
        } else if (element.type === 'lineItemStatusFilter') {
          lineItemStatusFilter.value.push({ key: element.key, value: element['value'] });
          lineItemStatusFilter.filterType = element.filterType;
        } else if (element.type === 'integrationStatusFilter') {
          integrationStatusFilter.value.push({ key: element.key, value: element['value'] });
          integrationStatusFilter.filterType = element.filterType;
        } else if (element.type === 'lastIntegratedVersion') {
          lastIntegratedVersion.value.push({ key: element.key, value: element['value'] });
          lastIntegratedVersion.filterType = element.filterType;
        } else if (element.type === 'supplierId') {
          supplierId.value.push({ key: element.key, value: element['value'] });
          supplierId.filterType = element.filterType;
        } else if (element.type === 'outlineAgreementNumber') {
          outlineAgreementNumber.value.push({ key: element.key, value: element['value'] });
          outlineAgreementNumber.filterType = element.filterType;
        } else if (element.type === 'contractId') {
          contractId.value.push({ key: element.key, value: element['value'] });
          contractId.filterType = element.filterType;
        } else if (element.type === 'olaId') {
          outlineAgreementNumber.value.push({ key: element.key, value: element['value'] });
          outlineAgreementNumber.filterType = element.filterType;
        } else if (element.type === 'catalogueIdentifier') {
          catalogueIdentifier.value.push({ key: element.key, value: element['value'] });
          catalogueIdentifier.filterType = element.filterType;
        } else if (element.type === 'catalogueName') {
          catalogueName.value.push({ key: element.key, value: element['value'] });
          catalogueName.filterType = element.filterType;
        } else if (element.type === 'delegatedByUser') {
          primaryDelegator.value.push({ key: element.key, value: element['value'] });
          primaryDelegator.filterType = element.filterType;
        } else if (element.type === 'delegatedToUser') {
          primaryDelegate.value.push({ key: element.key, value: element['value'] });
          primaryDelegate.filterType = element.filterType;
        } else if (element.type === 'secondaryDelegatedBy') {
          secondaryDelegator.value.push({ key: element.key, value: element['value'] });
          secondaryDelegator.filterType = element.filterType;
        } else if (element.type === 'secondaryDelegatedTo') {
          secondaryDelegate.value.push({ key: element.key, value: element['value'] });
          secondaryDelegate.filterType = element.filterType;
        } else if (element.type === 'delegationType') {
          delegationType.value.push({ key: element.key, value: element['value'] });
          delegationType.filterType = element.filterType;
        } else if (element.type === 'primaryDelegatedFrom') {
          primaryDelegatedFrom.value.push({ key: element.key, value: element['value'] });
          primaryDelegatedFrom.filterType = element.filterType;
        } else if (element.type === 'secondaryDelegatedFrom') {
          secondaryDelegatedFrom.value.push({ key: element.key, value: element['value'] });
          secondaryDelegatedFrom.filterType = element.filterType;
        } else if (element.type === 'materialCategory') {
          materialCategory.value.push({ key: element.key, value: element['value'] });
          materialCategory.filterType = element.filterType;
        } else if (element.type === 'paymentTerms') {
          paymentTerms.value.push({ key: element.key, value: element['value'] });
          paymentTerms.filterType = element.filterType;
        } else if (element.type === 'contractIdId') {
          contractIdId.value.push({ key: element.key, value: element['value'] });
          contractIdId.filterType = element.filterType;
        }

      });
    }

    if (supplierFilters.value.length) {
      filterObjToSave.filters.push(supplierFilters);
    }
    if (tradingFilters.value.length) {
      filterObjToSave.filters.push(tradingFilters);
    }
    if (catalogueFilters.value.length) {
      filterObjToSave.filters.push(catalogueFilters);
    }
    if (opcoFilters.value.length) {
      filterObjToSave.filters.push(opcoFilters);
    }
    if (materialFilters.value.length) {
      filterObjToSave.filters.push(materialFilters);
    }
    if (validityFilters.value.length) {
      filterObjToSave.filters.push(validityFilters);
    }
    if (paStatusFilters.value.length) {
      filterObjToSave.filters.push(paStatusFilters);
    }
    if (catalogueStatusFilters.value.length) {
      filterObjToSave.filters.push(catalogueStatusFilters);
    }
    if (taskTypeFilters.value.length) {
      filterObjToSave.filters.push(taskTypeFilters);
    }
    if (taskStatusFilters.value.length) {
      filterObjToSave.filters.push(taskStatusFilters);
    }
    if (parentSupplierFilters.value.length) {
      filterObjToSave.filters.push(parentSupplierFilters);
    }
    if (integrationStatusFilters.value.length) {
      filterObjToSave.filters.push(integrationStatusFilters);
    }
    if (currencyFilters.value.length) {
      filterObjToSave.filters.push(currencyFilters);
    }
    if (amountFilter.value.length) {
      filterObjToSave.filters.push(amountFilter);
    }
    if (physicalNonphysical.value.length) {
      filterObjToSave.filters.push(physicalNonphysical);
    }
    // VOD-1652
    if (catalogueIdFilter.value.length) {
      filterObjToSave.filters.push(catalogueIdFilter);
    }
    // VOD-1652
    if (l4GroupFilter.value.length) {
      filterObjToSave.filters.push(l4GroupFilter);
    }
    /* VP3-3 start*/
    if (masterAgreementName.value.length) {
      filterObjToSave.filters.push(masterAgreementName);
    }
    if (supplierName.value.length) {
      filterObjToSave.filters.push(supplierName);
    }
    if (opcoName.value.length) {
      filterObjToSave.filters.push(opcoName);
    }
    if (tradingCurrencyKey.value.length) {
      filterObjToSave.filters.push(tradingCurrencyKey);
    }
    if (validFromDate.value.length) {
      filterObjToSave.filters.push(validFromDate);
    }
    if (validToDate.value.length) {
      filterObjToSave.filters.push(validToDate);
    }
    if (vpcOwner.value.length) {
      filterObjToSave.filters.push(vpcOwner);
    }
    if (supplierOwner.value.length) {
      filterObjToSave.filters.push(supplierOwner);
    }
    if (createdAt.value.length) {
      filterObjToSave.filters.push(createdAt);
    }
    if (createdBy.value.length) {
      filterObjToSave.filters.push(createdBy);
    }
    if (updatedAt.value.length) {
      filterObjToSave.filters.push(updatedAt);
    }
    if (updatedBy.value.length) {
      filterObjToSave.filters.push(updatedBy);
    }
    if (isVisible.value.length) {
      filterObjToSave.filters.push(isVisible);
    }
    if (isSupplierOnboarded.value.length) {
      filterObjToSave.filters.push(isSupplierOnboarded);
    }
    if (paStatusFilter.value.length) {
      filterObjToSave.filters.push(paStatusFilter);
    }
    if (lineItemStatusFilter.value.length) {
      filterObjToSave.filters.push(lineItemStatusFilter);
    }
    if (integrationStatusFilter.value.length) {
      filterObjToSave.filters.push(integrationStatusFilter);
    }
    if (lastIntegratedVersion.value.length) {
      filterObjToSave.filters.push(lastIntegratedVersion);
    }
    if (supplierId.value.length) {
      filterObjToSave.filters.push(supplierId);
    }
    if (outlineAgreementNumber.value.length) {
      filterObjToSave.filters.push(outlineAgreementNumber);
    }
    if (contractId.value.length) {
      filterObjToSave.filters.push(contractId);
    }
    if (catalogueIdentifier.value.length) {
      filterObjToSave.filters.push(catalogueIdentifier);
    }
    if (catalogueName.value.length) {
      filterObjToSave.filters.push(catalogueName);
    }
    if (primaryDelegator.value.length) {
      filterObjToSave.filters.push(primaryDelegator);
    }
    if (primaryDelegate.value.length) {
      filterObjToSave.filters.push(primaryDelegate);
    }
    if (secondaryDelegator.value.length) {
      filterObjToSave.filters.push(secondaryDelegator);
    }
    if (secondaryDelegate.value.length) {
      filterObjToSave.filters.push(secondaryDelegate);
    }
    if (secondaryStartDate.value.length) {
      filterObjToSave.filters.push(secondaryStartDate);
    }
    if (secondaryEndDate.value.length) {
      filterObjToSave.filters.push(secondaryEndDate);
    }
    if (delegationType.value.length) {
      filterObjToSave.filters.push(delegationType);
    }
    if (primaryDelegatedFrom.value.length) {
      filterObjToSave.filters.push(primaryDelegatedFrom);
    }
    if (secondaryDelegatedFrom.value.length) {
      filterObjToSave.filters.push(secondaryDelegatedFrom);
    }
    if (materialCategory.value.length) {
      filterObjToSave.filters.push(materialCategory);
    }
    if (paymentTerms.value.length) {
      filterObjToSave.filters.push(paymentTerms);
    }
    if (contractIdId.value.length) {
      filterObjToSave.filters.push(contractIdId);
    }
    /* VP3-3 end*/
    /* VP3-3 end*/
    return filterObjToSave;
  }


  /**
   *Function that create objects for search filters
   *
   * @param {*} data
   * @return {*} 
   * @memberof FilterServices
   */
  public prepairSerchObjFromRes(data: any) {
    const filterObjArr = [];
    data.forEach(element => {
      if (element['name'] === 'trading') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'trading', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'catalogue') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'catalogue', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'supplier') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'supplier', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'opco') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'opco', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'material') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'material', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'validity') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'validity', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'paStatus') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'paStatus', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'catalogueStatus') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'catalogueStatus', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'taskType') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'taskType', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'taskStatus') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'taskStatus', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'parentSupplier') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'parentSupplier', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'integrationStatus') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'integrationStatus', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'currency') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'currency', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'amountFilter') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'amount', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'physicalNonphysical') {  //vp3-3
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'physicalStatus', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      }
      // VOD-1652
      else if (element['name'] === 'l4Group') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'l4Group', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'l1Category') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'l1Category', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'referenceName') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'referenceName', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'supplierName') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'supplierName', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'opcoName') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'opcoName', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'tradingCurrencyKey') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'tradingCurrencyKey', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'validFrom') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'validFrom', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'validTo') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'validTo', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'secondaryStartDate') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'secondaryStartDate', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'secondaryEndDate') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'secondaryEndDate', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'vpcOwner') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'vpcOwner', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'supplierOwner') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'supplierOwner', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'createdAt') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'createdAt', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'createdBy') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'createdBy', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'updatedAt') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'updatedAt', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'updatedBy') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'updatedBy', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'isVisible') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'isVisible', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'isSupplierOnboarded') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'isSupplierOnboarded', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'paStatusFilter') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'paStatusFilter', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'lineItemStatusFilter') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'lineItemStatusFilter', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'integrationStatusFilter') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'integrationStatusFilter', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'lastIntegrtedVersion') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'lastIntegrtedVersion', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'supplierId') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'supplierId', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'outlineAgreementNumber') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'outlineAgreementNumber', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'contractId') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'contractId', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'olaId') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'outlineAgreementNumber', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'delegatedByUser') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'delegatedByUser', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'delegatedToUser') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'delegatedToUser', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'secondaryDelegatedBy') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'secondaryDelegatedBy', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'secondaryDelegatedTo') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'secondaryDelegatedTo', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'delegationType') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'delegationType', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'primaryDelegatedFrom') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'primaryDelegatedFrom', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'secondaryDelegatedFrom') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'secondaryDelegatedFrom', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'materialCategory') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'materialCategory', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'paymentTerms') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'paymentTerms', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      } else if (element['name'] === 'contractIdId') {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: 'contractIdId', key: valElement['key'], value: valElement['value'], filterType: element['filterType'] });
        });
      }

    });
    return filterObjArr;
  }


  /**
   *Function that create objects for search filters
   *
   * @param {*} data
   * @return {*} 
   * @memberof FilterServices
   */
  public prepairSearchObj(data: any) {
    const removedFilterObj = {
      suplierId: [],
      opcoId: [],
      catalogueId: [],
      materialId: [],
      tradingId: [],
      paStatusId: [],
      catalogueStatusId: [],
      validity: [],
      taskTypeId: [],
      taskStatusId: [],
      parentSupplierId: [],
      integrationStatusId: [],
      currencyId: [],
      amountId: [],
      /* VP3-3 start */
      physicalNonphysicalId: [],
      masterAgreementNameId: [],
      masterAgreementNameType: '',
      primaryDelegatorId: [],
      primaryDelegatorType: '',
      primaryDelegateId: [],
      primaryDelegateType: '',
      secondaryDelegatorId: [],
      secondaryDelegatorType: '',
      secondaryDelegateId: [],
      secondaryDelegateType: '',
      delegationTypeId: [],
      delegationTypeType: '',
      primaryDelegatedFromId: [],
      primaryDelegatedFromType: '',
      secondaryDelegatedFromId: [],
      secondaryDelegatedFromType: '',
      supplierNameId: [],
      supplierNameType: '',
      opcoNameId: [],
      opcoNameType: '',
      tradingCurrencyKeyId: [],
      tradingCurrencyKeyType: '',
      validFromDateId: [],
      validFromDateType: '',
      validToDateId: [],
      validToDateType: '',
      secondaryEndDateId: [],
      secondaryEndDateType: '',
      secondaryStartDateId: [],
      secondaryStartDateType: '',
      vpcOwnerId: [],
      vpcOwnerType: '',
      materialCategoryId: [],
      materialCategoryType: '',
      paymentTermsId: [],
      paymentTermsType: '',
      contractIdId: [],
      contractIdType: '',
      supplierOwnerId: [],
      supplierOwnerType: '',
      createdAtId: [],
      createdAtType: '',
      createdById: [],
      createdByType: '',
      updatedAtId: [],
      updatedAtType: '',
      updatedById: [],
      updatedByType: '',
      isVisibleId: [],
      isVisibleType: '',
      isSupplierOnboardedId: [],
      isSupplierOnboardedType: '',
      paStatusFilterId: [],
      paStatusFilterType: '',
      lineItemStatusFilterId: [],
      lineItemStatusFilterType: '',
      integrationStatusFilterId: [],
      integrationStatusFilterType: '',
      lastIntegratedVersionId: [],
      lastIntegratedVersionFilterType: '',
      supplierId: [],
      supplierIdType: '',
      outlineAgreementId: [],
      outlineAgreementNumberType: '',
      contractId: [],
      catalogueIdentifier: [],
      catalogueName: []
      /* VP3-3 end */
    };
    if (data.length) {
      data.forEach(element => {
        if (element.type === 'supplier') {
          removedFilterObj.suplierId.push(element.key);
        } else if (element.type === 'trading') {
          removedFilterObj.tradingId.push(element.key);
        }
        // VOD-1652
        else if (element.type === 'catalogue' || element.type === 'l1Category') {
          removedFilterObj.catalogueId.push(element.key);
        } else if (element.type === 'opco') {
          removedFilterObj.opcoId.push(element.key);
        } else if (element.type === 'material' || element.type === 'l4Group') {
          removedFilterObj.materialId.push(element.key);
        } else if (element.type === 'paStatus') {
          removedFilterObj.paStatusId.push(element.key);
        } else if (element.type === 'catalogueStatus') {
          removedFilterObj.catalogueStatusId.push(element.key);
        } else if (element.type === 'validity') {
          removedFilterObj.validity.push(element.key);
        } else if (element.type === 'taskType') {
          removedFilterObj.taskTypeId.push(element.key);
        } else if (element.type === 'taskStatus') {
          removedFilterObj.taskStatusId.push(element.key);
        } else if (element.type === 'parentSupplier') {
          removedFilterObj.parentSupplierId.push(element.key);
        } else if (element.type === 'integrationStatus') {
          removedFilterObj.integrationStatusId.push(element.key);
        } else if (element.type === 'currency') {
          removedFilterObj.currencyId.push(element.key);
        } else if (element.type === 'amount') {
          removedFilterObj.amountId.push(element.key);
        } else if (element.type === 'physicalStatus') {
          removedFilterObj.physicalNonphysicalId.push(element.key); //vp3-3
        } else if (element.type === 'referenceName') {
          removedFilterObj.masterAgreementNameId.push(element.key); //vp3-3
          removedFilterObj.masterAgreementNameType = element.filterType
        } else if (element.type === 'supplierName') {
          removedFilterObj.supplierNameId.push(element.key); //vp3-3
          removedFilterObj.supplierNameType = element.filterType
        } else if (element.type === 'opcoName') {
          removedFilterObj.opcoNameId.push(element.key); //vp3-3
          removedFilterObj.opcoNameType = element.filterType
        } else if (element.type === 'tradingCurrencyKey') {
          removedFilterObj.tradingCurrencyKeyId.push(element.key); //vp3-3
          removedFilterObj.tradingCurrencyKeyType = element.filterType
        } else if (element.type === 'validFrom') {
          removedFilterObj.validFromDateId.push(element.key); //vp3-3
          removedFilterObj.validFromDateType = element.filterType
        } else if (element.type === 'validTo') {
          removedFilterObj.validToDateId.push(element.key); //vp3-3
          removedFilterObj.validToDateType = element.filterType
        } else if (element.type === 'vpcOwner') {
          removedFilterObj.vpcOwnerId.push(element.key); //vp3-3
          removedFilterObj.vpcOwnerType = element.filterType
        } else if (element.type === 'supplierOwner') {
          removedFilterObj.supplierOwnerId.push(element.key); //vp3-3
          removedFilterObj.supplierOwnerType = element.filterType
        } else if (element.type === 'createdAt') {
          removedFilterObj.createdAtId.push(element.key); //vp3-3
          removedFilterObj.createdAtType = element.filterType
        } else if (element.type === 'createdBy') {
          removedFilterObj.createdById.push(element.key); //vp3-3
          removedFilterObj.createdByType = element.filterType
        } else if (element.type === 'updatedAt') {
          removedFilterObj.updatedAtId.push(element.key); //vp3-3
          removedFilterObj.updatedAtType = element.filterType
        } else if (element.type === 'updatedBy') {
          removedFilterObj.updatedById.push(element.key); //vp3-3
          removedFilterObj.updatedByType = element.filterType
        } else if (element.type === 'isVisible') {
          removedFilterObj.isVisibleId.push(element.key); //vp3-3
          removedFilterObj.isVisibleType = element.filterType
        } else if (element.type === 'isSupplierOnboarded') {
          removedFilterObj.isSupplierOnboardedId.push(element.key); //vp3-3
          removedFilterObj.isSupplierOnboardedType = element.filterType
        } else if (element.type === 'paStatusFilter') {
          removedFilterObj.paStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.paStatusFilterType = element.filterType
        } else if (element.type === 'lineItemStatusFilter') {
          removedFilterObj.lineItemStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.lineItemStatusFilterType = element.filterType
        } else if (element.type === 'integrationStatusFilter') {
          removedFilterObj.integrationStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.integrationStatusFilterType = element.filterType
        } else if (element.type === 'lastIntegratedVersion') {
          removedFilterObj.lastIntegratedVersionId.push(element.key); //vp3-284
          removedFilterObj.lastIntegratedVersionFilterType = element.filterType
        } else if (element.type === 'supplierId') {
          removedFilterObj.supplierId.push(element.key); //vp3-284
          removedFilterObj.supplierIdType = element.filterType
        } else if (element.type === 'outlineAgreementNumber') {
          removedFilterObj.outlineAgreementId.push(element.key); //vp3-284
          removedFilterObj.outlineAgreementNumberType = element.filterType
        } else if (element.type === 'contractId') {
          removedFilterObj.contractId.push(element.key);
        } else if (element.type === 'olaId') {
          removedFilterObj.outlineAgreementId.push(element.key);
        } else if (element.type === 'catalogueIdentifier') {
          removedFilterObj.catalogueIdentifier.push(element.key);
        } else if (element.type === 'catalogueName') {
          removedFilterObj.catalogueName.push(element.key);
        } else if (element.type === 'delegatedByUser') {
          removedFilterObj.primaryDelegatorId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegatorType = element.filterType
        } else if (element.type === 'delegatedToUser') {
          removedFilterObj.primaryDelegateId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegateType = element.filterType
        } else if (element.type === 'secondaryDelegatedBy') {
          removedFilterObj.secondaryDelegatorId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegatorType = element.filterType
        } else if (element.type === 'secondaryDelegatedTo') {
          removedFilterObj.secondaryDelegateId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegateType = element.filterType
        } else if (element.type === 'secondaryStartDate') {
          removedFilterObj.secondaryStartDateId.push(element.key); //vp3-3
          removedFilterObj.secondaryStartDateType = element.filterType
        } else if (element.type === 'secondaryEndDate') {
          removedFilterObj.secondaryEndDateId.push(element.key); //vp3-3
          removedFilterObj.secondaryEndDateType = element.filterType
        } else if (element.type === 'delegationType') {
          removedFilterObj.delegationTypeId.push(element.key); //vp3-3
          removedFilterObj.delegationTypeType = element.filterType
        } else if (element.type === 'primaryDelegatedFrom') {
          removedFilterObj.primaryDelegatedFromId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegatedFromType = element.filterType
        } else if (element.type === 'secondaryDelegatedFrom') {
          removedFilterObj.secondaryDelegatedFromId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegatedFromType = element.filterType
        } else if (element.type === 'materialCategory') {
          removedFilterObj.materialCategoryId.push(element.key); //vp3-3
          removedFilterObj.materialCategoryType = element.filterType
        } else if (element.type === 'contractIdId') {
          removedFilterObj.contractIdId.push(element.key); //vp3-3
          removedFilterObj.contractIdType = element.filterType
        } else if (element.type === 'paymentTerms') {
          removedFilterObj.paymentTermsId.push(element.key); //vp3-3
          removedFilterObj.paymentTermsType = element.filterType
        }
      });
      return removedFilterObj;
    }
    return removedFilterObj;
  }


  /**
   *Prepairing excel sheet data for download
   *
   * @param {*} configObj
   * @return {*} 
   * @memberof FilterServices
   */
  public prepairSheetData(configObj) {
    const data = [];
    let length = 0;
    Object.keys(configObj).map(key => {
      if (configObj[key].length > length) {
        length = configObj[key].length;
      }
    });
    for (let i = 0; i < length; i++) {
      const array = ['', '', '', ''];
      Object.keys(configObj).map(key => {
        switch (key) {
          case 'tradingModel':
            configObj[key][i] !== undefined ? array[0] = configObj[key][i]['tradingModel'] : array[0] = '';
            break;
          case 'suppliers':
            configObj[key][i] !== undefined ? array[1] = configObj[key][i]['supplierName'] : array[1] = '';
            break;
          case 'localMarket':
            configObj[key][i] !== undefined ? array[2] = configObj[key][i]['opcoId'] + '-' + configObj[key][i]['opcoName'] : array[2] = '';
            break;
          case 'materialGroups':
            // tslint:disable-next-line:max-line-length
            configObj[key][i] !== undefined ? array[3] = configObj[key][i]['materialGroupId'] + '-' + configObj[key][i]['materialGroupName'] : array[3] = '';
            break;
        }
      });
      data.push(array);
    }
    return data;
  }
}
