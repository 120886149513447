import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatSelect, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

interface IMarket {
  id: string;
  value: string;
}
/**
 *Dialog component for select the local market
 *
 * @export
 * @class DialogComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  public market;
  public marketList: Array<IMarket>;
  public selectedMarket: IMarket;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>,
  ) {
    this.marketList = this.json2array(data.list);
  }

  public marketCtrl: FormControl = new FormControl();

  public marketFilterCtrl: FormControl = new FormControl();

  public filteredMarkets: ReplaySubject<IMarket[]> = new ReplaySubject<IMarket[]>(1);

  @ViewChild(MatSelect, { static: true }) public singleSelect: MatSelect;

  // tslint:disable-next-line: variable-name
  protected _onDestroy = new Subject<void>();

  public ngOnInit() {
    this.filteredMarkets.next(this.marketList.slice());
    this.marketFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMarkets();
      });
  }

  private json2array(json: any) {
    const result = [];
    const keys = Object.keys(json);
    keys.forEach(key => {
      result.push({
        id: key,
        value: json[key]
      });
    });

    return result;
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  protected setInitialValue() {
    this.filteredMarkets.pipe(take(1), takeUntil(this._onDestroy));
  }

  protected filterMarkets() {
    if (!this.marketList) {
      return;
    }
    // get the search keyword
    let search = this.marketFilterCtrl.value;
    if (!search) {
      this.filteredMarkets.next(this.marketList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the market
    this.filteredMarkets.next(
      this.marketList.filter(market => (market.value.toLowerCase().indexOf(search) > -1) || (market.id.toLowerCase().indexOf(search) > -1))
    );
  }

  public onSelect(value: IMarket) {
    this.selectedMarket = value;
  }

  public getData() {
    this.dialogRef.close(this.selectedMarket);
  }
}
