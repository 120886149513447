export function customSort(text: any, param): any {

  let textA, textB
  const sortedText = text ? text.sort((a: any, b: any) => {
    // if (!a[param]) {
    //   console.log(a, param)
    // }
    if(param !== 'commodityCode'){  
      if (typeof (a[param]) !== 'number' && a[param]) {
      textA = a[param].toLowerCase();
      textB = b[param].toLowerCase();
    } else{
     if(a[param]) textA = a[param];
     if(b[param]) textB = b[param];
    }
    
   } else {
      if (!Number.isNaN(Number(a[param])) && a[param] !== '-') textA = Number(a[param]);
      if (!Number.isNaN(Number(b[param]))  && b[param] !== '-') textB = Number(b[param]);
    }
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  }) : '';
}

  