import { Component, OnInit, Input } from '@angular/core';
import { AuthServices } from '../../_services/auth.service';
import { PriceManagerServices } from "../../_services/price-manager.service";
import { LoaderService } from '../../_services/loader.service';

/**
 *Legend component to display the icons with description used in the application 
 *
 * @export
 * @class LegendComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  public dropdownType: string;
  public dropdownNumber: any = 0;
  public dropdownTempData: any;
  public isBuyer: boolean;
  @Input() typeId: any;
  public matInProgressLegend: boolean = false;
  public matCompleteLegend: boolean = false;
  public projectId: boolean = false;

  constructor(private authService: AuthServices, public priceManagerService: PriceManagerServices,
    private loaderService: LoaderService) { }

  public ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
    if (this.typeId == 'In Progress Materials & BOM Sub tab Status') {
      this.matInProgressLegend = true;
    } else if (this.typeId == 'Completed Materials & BOM Sub tab Status') {
      this.matCompleteLegend = true;
    } else if (this.typeId == 'ProjectId Status') {
      this.projectId = true;
    }
    // await this.getLegendsApi()

  }
  
  getLegendsApi() {
    var payload_ = {
      "type": this.typeId

    }
    this.loaderService.startLoading();
    return new Promise((resolve) => {
      this.priceManagerService
        .getAllLegends(payload_)
        .subscribe(
          (data: any) => {
            this.loaderService.stopLoading();
            resolve(true);
          },
          (error) => {
            this.loaderService.stopLoading();
          });
    })
  }

  public dropdownToggle(type, num) {
    this.dropdownType = type;
    if (this.dropdownNumber === 0 || this.dropdownTempData !== type) {
      this.dropdownNumber += num;
      if (this.dropdownNumber > 1) {
        this.dropdownNumber--;
      }
      this.dropdownTempData = type;
    } else {
      this.dropdownNumber = 0;
    }
  }

}
