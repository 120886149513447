import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-legend-bar',
  templateUrl: './legend-bar.component.html',
  styleUrls: ['./legend-bar.component.scss']
})
export class LegendBarComponent implements OnInit {
@Input() public vrHide: boolean; //  [VOD-1714] 
  constructor() { }

  public ngOnInit() {
  }
}
