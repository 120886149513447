import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../_config';
import { AuthServices } from '../../_services/auth.service';

/**
 *Component to show the terms and conditons details
 *
 * @export
 * @class TermsAndConditionsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  public urlLink = 'https://spm.vodafone.com';

  public isBuyer: boolean;

  // tslint:disable-next-line: no-empty
  constructor(private authService: AuthServices) { }

  public ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
    if (this.isBuyer) {
      this.urlLink = 'https://vpm.vodafone.com';
    }
  }
}
