import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../_config";
import { IServerResponse } from "../_models/response";
import { AuthServices } from "./auth.service";
import * as moment from "moment";

const { priceUrl, priceUrl1 } = AppConfig;
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterServices {
  private priceFilter = new Subject<any>();
  private dialogData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private dropFilter = new Subject<any>();
  constructor(private http: HttpClient, private authService: AuthServices) { }

  private userFilterUrl: string = priceUrl1 + "network/user-filter";
  private userFilterListUrl: string = priceUrl1 + "network/user-filter/list";

  /**
   *Api function that save the user added filters to the specified component name
   *
   * @param {{
   *     filters: Array<any>,
   *     filterName: string,
   *     componentName: string
   *   }} filterObj
   * @return {*}
   * @memberof FilterServices
   */
  public addUserFilters(filterObj: {
    filters: Array<any>;
    filterName: string;
    componentName: string;
  }) {
    return this.http.post<IServerResponse<any>>(
      this.userFilterUrl,
      filterObj,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function that returns the user filters data based on the component name
   *
   * @param {string} componentName
   * @return {*}
   * @memberof FilterServices
   */
  public getUserFilters(componentName: string) {
    const query = {
      componentName,
    };
    const url = this.userFilterListUrl + `?componentName=${componentName}`;
    return this.http.get<IServerResponse<any>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *
   *Api function that returns the user filter data based on the component name & filter name
   * @param {object} filterObj
   * @return {*}
   * @memberof FilterServices
   */
  public getUserFilter(filterObj: object) {
    const url =
      this.userFilterUrl +
      `?componentName=${encodeURIComponent(
        filterObj["componentName"]
      )}&filterName=${encodeURIComponent(filterObj["filterName"])}`;
    return this.http.get<IServerResponse<any>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function that delete the saved filter data
   *
   * @param {object} filterObj
   * @return {*}
   * @memberof FilterServices
   */
  public deleteSavedUserFilter(filterObj: object) {
    const url =
      this.userFilterUrl +
      `?componentName=${encodeURIComponent(
        filterObj["componentName"]
      )}&filterName=${encodeURIComponent(filterObj["filterName"])}`;
    return this.http.delete(url, this.authService.getAccessHeaders());
  }
  /**
   *Observable function that set the filter values
   *
   * @param {*} data
   * @memberof FilterServices
   */
  public setFilter(data: any) {
    this.priceFilter.next({ result: data });
  }

  public setDialogData(data) {
    this.dialogData.next(data);
  }

  /**
   *Observable function that return the filter values
   *
   * @return {*}  {Observable<any>}
   * @memberof FilterServices
   */
  public getFilter(): Observable<any> {
    return this.priceFilter.asObservable();
  }

  public getDialogData(): Observable<string> {
    return this.dialogData;
  }

  /**
   *Function that creates a filter objects which contains filter name, filter type and filter value
   *It's creata a array of objects based on input filter values
   * @param {*} data
   * @param {*} [types=null]
   * @return {*}
   * @memberof FilterServices
   */
  public dataFilter(data: any, types = null) {
    if (types === "equal" || types === "equals") {
      types = types;
    } else {
      types = ""; // new Array();
    }
    const filters = [];
    if (data && data.amountId && data.amountId.length) {
      const amountIdObj = {
        name: "amountId",
        type: types,
        value: data.amountId,
      };
      filters.push(amountIdObj);
    }
    if (data && data.contractId && data.contractId.length) {
      const contractIdObj = {
        name: "contractId",
        type: "search",
        value: data.contractId,
      };
      filters.push(contractIdObj);
    }
    if (data && data.olaId && data.olaId.length) {
      const olaIdObj = {
        name: "outlineAgreementNumber",
        type: "search",
        value: data.olaId,
      };
      filters.push(olaIdObj);
    }
    if (
      data &&
      data.physicalNonphysicalId &&
      data.physicalNonphysicalId.length
    ) {
      const physicalNonphysicalIdObj = {
        name: "physicalNonphysicalId",
        type: types,
        value: data.physicalNonphysicalId,
      };
      filters.push(physicalNonphysicalIdObj);
    }
    if (data && data.tradingId && data.tradingId.length) {
      const tradingObj = {
        name: "tradingModel",
        type: "equal",
        value: data.tradingId,
      };
      filters.push(tradingObj);
    }
    if (data && data.opcoId && data.opcoId.length) {
      const opcoObj = {
        name: "opcoCode",
        type: types,
        value: data.opcoId,
      };
      filters.push(opcoObj);
    }
    if (data && data.catalogueId && data.catalogueId.length) {
      const catalogueObj = {
        name: "catalogueType",
        type: types,
        value: data.catalogueId,
      };
      filters.push(catalogueObj);
    }
    if (data && data.materialId && data.materialId.length) {
      const materialObj = {
        name: "materialGroup",
        type: types,
        value: data.materialId,
      };
      filters.push(materialObj);
    }
    if (data && data.catalogueStatusId && data.catalogueStatusId.length) {
      const catalogueStatusObj = {
        name: "catalogueStatus",
        type: types,
        value: data.catalogueStatusId,
      };
      filters.push(catalogueStatusObj);
    }
    if (data && data.projectId && data.projectId.length) {
      const projectIdObj = {
        name: "projectId",
        type: data.projectIdType || types,
        value: data.projectId,
      };
      filters.push(projectIdObj);
    }
    if (data && data.proProjectIdId && data.proProjectIdId.length) {
      const proProjectIdIdObj = {
        name: "projectId",
        type: "equal",
        value: data.proProjectIdId,
      };
      filters.push(proProjectIdIdObj);
    }
    if (data && data.proMGOLAId && data.proMGOLAId.length) {
      const proMGOLAIdObj = {
        name: "mgo",
        type: "equal",
        value: data.proMGOLAId,
      };
      filters.push(proMGOLAIdObj);
    }
    if (data && data.olaIds && data.olaIds.length) {
      const proMGOLAIdObj = {
        name: "outlineAgreementNumber",
        type: "equal",
        value: data.olaIds,
      };
      filters.push(proMGOLAIdObj);
    }
    if (data && data.proSupplierIDId && data.proSupplierIDId.length) {
      const proSupplierIDIdObj = {
        name: "supplierId",
        type: "equal",
        value: data.proSupplierIDId,
      };
      filters.push(proSupplierIDIdObj);
    }
    if (data && data.projectIdCol && data.projectIdCol.length) {
      const projectIdColObj = {
        name: "projectId",
        type: data.projectIdColType ? data.projectIdColType : "equal",
        value: data.projectIdCol,
      };
      filters.push(projectIdColObj);
    }
    if (data && data.projectIdStatusId && data.projectIdStatusId.length) {
      const projectIdStatusIdObj = {
        name: "itemStatus",
        type: "equal",
        value: data.projectIdStatusId,
      };
      filters.push(projectIdStatusIdObj);
    }
    if (data && data.projectDescription && data.projectDescription.length) {
      const projectDescriptionObj = {
        name: "projectDescription",
        type: data.projectDescriptionType
          ? data.projectDescriptionType
          : "equal",
        value: data.projectDescription,
      };
      filters.push(projectDescriptionObj);
    }
    if (data && data.projectOwner && data.projectOwner.length) {
      const projectOwnerObj = {
        name: "projectOwner",
        type: data.projectOwnerType ? data.projectOwnerType : "equal",
        value: data.projectOwner,
      };
      filters.push(projectOwnerObj);
    }
    if (data && data.paStatusId && data.paStatusId.length) {
      if (data.paStatusId.includes("ITEM_EXPIRED")) {
        const index = data.paStatusId.indexOf("ITEM_EXPIRED");
        if (index > -1) {
          data.paStatusId.splice(index, 1);
        }
        data.paStatusId.push("EXPIRED");
      }
      if (data.paStatusId.includes("ITEM_DELETED")) {
        const index = data.paStatusId.indexOf("ITEM_DELETED");
        if (index > -1) {
          data.paStatusId.splice(index, 1);
        }
        data.paStatusId.push("ARCHIVED");
      }
      const paStatusObj = {
        name: "status",
        type: types,
        value: data.paStatusId,
      };
      filters.push(paStatusObj);
    }
    if (data && data.currencyId && data.currencyId.length) {
      const currencyObj = {
        name: "tradingCurrencyKey",
        type: data.currencyIdType ? data.currencyIdType : "equal",
        value: data.currencyId,
      };
      filters.push(currencyObj);
    }
    if (data && data.parentSupplierId && data.parentSupplierId.length) {
      const parentSupplierObj = {
        name: "parentSupplier",
        type: types,
        value: data.parentSupplierId,
      };
      filters.push(parentSupplierObj);
    }
    if (data && data.suplierId && data.suplierId.length) {
      const supplierObj = {
        name: "supplier",
        type: types,
        value: data.suplierId,
      };
      filters.push(supplierObj);
    }
    if (data && data.integrationStatusId && data.integrationStatusId.length) {
      const integrationStatusObj = {
        name: "integrationStatus",
        type: types,
        value: data.integrationStatusId,
      };
      filters.push(integrationStatusObj);
    }
    if (data && data["validity"] && data["validity"].length) {
      const datesFilt = data["validity"][0].split(" - ");
      data.startDate = datesFilt[0]
        ? moment(datesFilt[0], "DD-MM-YYYY").toDate()
        : "";
      data.endDate = datesFilt[1]
        ? moment(datesFilt[1], "DD-MM-YYYY").toDate()
        : "";
    } // why r we adding dates, its giving incorrect data

    if (data.startDate !== "Invalid Date" && data.startDate) {
      const startDateObj = {
        name: "validFrom",
        type: "gte",
        value: data.startDate,
      };
      filters.push(startDateObj);
    }
    if (data.endDate !== "Invalid Date" && data.endDate) {
      const endDateObj = {
        name: "validTo",
        type: "lte",
        value: data.endDate,
      };
      filters.push(endDateObj);
    }
    if (data && data.monthID) {
      const monthObj = {
        name: "monthFilter",
        type: types,
        value: data.monthID,
      };
      filters.push(monthObj);
    }
    if (
      data &&
      data.masterAgreementNameId &&
      data.masterAgreementNameId.length
    ) {
      const masterAgreementNameObj = {
        name: "referenceName",
        type: data.masterAgreementNameType
          ? data.masterAgreementNameType
          : "equal",
        value: data.masterAgreementNameId,
      };
      filters.push(masterAgreementNameObj);
    }
    if (data && data.supplierNameId && data.supplierNameId.length) {
      const supplierNameObj = {
        name: "supplierName",
        type: data.supplierNameType ? data.supplierNameType : "equal",
        value: data.supplierNameId,
      };
      filters.push(supplierNameObj);
    }
    if (data && data.opcoNameId && data.opcoNameId.length) {
      const opcoNameObj = {
        name: "opcoName",
        type: data.opcoNameType ? data.opcoNameType : "equal",
        value: data.opcoNameId,
      };
      filters.push(opcoNameObj);
    }
    if (data && data.tradingCurrencyKeyId && data.tradingCurrencyKeyId.length) {
      const tradingCurrencyKeyObj = {
        name: "tradingCurrencyKey",
        type: data.tradingCurrencyKeyType
          ? data.tradingCurrencyKeyType
          : "equals",
        value: data.tradingCurrencyKeyId,
      };
      filters.push(tradingCurrencyKeyObj);
    }
    if (data && data.validFromDateId && data.validFromDateId.length) {
      const validFromDateObj = {
        name: "validFrom",
        type: data.validFromDateType ? data.validFromDateType : "equal",
        value: data.validFromDateId,
      };
      filters.push(validFromDateObj);
    }
    if (data && data.validToDateId && data.validToDateId.length) {
      const validToDateObj = {
        name: "validTo",
        type: data.validToDateType ? data.validToDateType : "equal",
        value: data.validToDateId,
      };
      filters.push(validToDateObj);
    }
    if (data && data.secondaryStartDateId && data.secondaryStartDateId.length) {
      const secondaryStartDateObj = {
        name: "secondaryStartDate",
        type: data.secondaryStartDateType
          ? data.secondaryStartDateType
          : "equal",
        value: data.secondaryStartDateId,
      };
      filters.push(secondaryStartDateObj);
    }
    if (data && data.secondaryEndDateId && data.secondaryEndDateId.length) {
      const secondaryEndDateObj = {
        name: "secondaryEndDate",
        type: data.secondaryEndDateType ? data.secondaryEndDateType : "equal",
        value: data.secondaryEndDateId,
      };
      filters.push(secondaryEndDateObj);
    }
    if (data && data.vpcOwnerId && data.vpcOwnerId.length) {
      const vpcOwnerObj = {
        name: "vpcOwner",
        type: data.vpcOwnerType ? data.vpcOwnerType : "equal",
        value: data.vpcOwnerId,
      };
      filters.push(vpcOwnerObj);
    }
    if (data && data.vodafoneApproverId && data.vodafoneApproverId.length) {
      const vpcApproverObj = {
        name: "vodafoneApprover",
        type: data.vodafoneApproverType ? data.vodafoneApproverType : "equal",
        value: data.vodafoneApproverId,
      };
      filters.push(vpcApproverObj);
    }
    if (data && data.supplierOwnerId && data.supplierOwnerId.length) {
      const supplierOwnerObj = {
        name: "supplierOwner",
        type: data.supplierOwnerType ? data.supplierOwnerType : "equal",
        value: data.supplierOwnerId,
      };
      filters.push(supplierOwnerObj);
    }
    if (data && data.createdAtId && data.createdAtId.length) {
      const createdAtObj = {
        name: "createdAt",
        type: data.createdAtType ? data.createdAtType : "equal",
        value: data.createdAtId,
      };
      filters.push(createdAtObj);
    }
    if (data && data.createdById && data.createdById.length) {
      const createdByObj = {
        name: "createdBy",
        type: data.createdByType ? data.createdByType : "equal",
        value: data.createdById,
      };
      filters.push(createdByObj);
    }
    if (data && data.updatedAtId && data.updatedAtId.length) {
      const updatedAtObj = {
        name: "updatedAt",
        type: data.updatedAtType ? data.updatedAtType : "equal",
        value: data.updatedAtId,
      };
      filters.push(updatedAtObj);
    }
    if (data && data.updatedById && data.updatedById.length) {
      const updatedByObj = {
        name: "updatedBy",
        type: data.updatedByType ? data.updatedByType : "equal",
        value: data.updatedById,
      };
      filters.push(updatedByObj);
    }
    if (data && data.isVisibleId && data.isVisibleId.length) {
      const isVisibleObj = {
        name: "isVisible",
        type: data.isVisibleType ? data.isVisibleType : "equal",
        value: data.isVisibleId[0], // send value as string
      };
      filters.push(isVisibleObj);
    }
    if (
      data &&
      data.isSupplierOnboardedId &&
      data.isSupplierOnboardedId.length
    ) {
      const isSupplierOnboardedObj = {
        name: "isSupplierOnboarded",
        type: data.isSupplierOnboardedType
          ? data.isSupplierOnboardedType
          : "equal",
        value: data.isSupplierOnboardedId[0], // send value as string
      };
      filters.push(isSupplierOnboardedObj);
    }
    if (data && data.paStatusFilterId && data.paStatusFilterId.length) {
      const paStatusFilterObj = {
        name: "status",
        type: data.paStatusFilterType ? data.paStatusFilterType : "equal",
        value: data.paStatusFilterId,
      };
      filters.push(paStatusFilterObj);
    }
    if (
      data &&
      data.lineItemStatusFilterId &&
      data.lineItemStatusFilterId.length
    ) {
      const lineItemStatusFilterObj = {
        name: "catalogueStatus",
        type: data.lineItemStatusFilterType
          ? data.lineItemStatusFilterType
          : "equal",
        value: data.lineItemStatusFilterId,
      };
      filters.push(lineItemStatusFilterObj);
    }
    if (
      data &&
      data.integrationStatusFilterId &&
      data.integrationStatusFilterId.length
    ) {
      const integrationStatusFilterObj = {
        name: "integrationStatus",
        type: data.integrationStatusFilterType
          ? data.integrationStatusFilterType
          : "equal",
        value: data.integrationStatusFilterId,
      };
      filters.push(integrationStatusFilterObj);
    }
    if (
      data &&
      data.lastIntegratedVersionId &&
      data.lastIntegratedVersionId.length
    ) {
      const lastIntegratedVersionObj = {
        name: "lastIntegratedVersion",
        type: data.lastIntegratedVersionFilterType
          ? data.lastIntegratedVersionFilterType
          : "contains",
        value: data.lastIntegratedVersionId,
      };
      filters.push(lastIntegratedVersionObj);
    }
    if (data && data.outlineAgreementId && data.outlineAgreementId.length) {
      const outlineAgreementNumberObj = {
        name: "outlineAgreementNumber",
        type: data.outlineAgreementNumberType
          ? data.outlineAgreementNumberType
          : "contains",
        value: data.outlineAgreementId,
      };
      filters.push(outlineAgreementNumberObj);
    }
    if (data && data.projectIdColPa && data.projectIdColPa.length) {
      const projectIdColPaObj = {
        name: "projectId",
        type: data.projectIdColPaType
          ? data.projectIdColPaType
          : "contains",
        value: data.projectIdColPa,
      };
      filters.push(projectIdColPaObj);
    }
    if (data && data.companyCodeColPa && data.companyCodeColPa.length) {
      const companyCodeColPaObj = {
        name: "companyCode",
        type: data.companyCodeColPaType
          ? data.companyCodeColPaType
          : "contains",
        value: data.companyCodeColPa,
      };
      filters.push(companyCodeColPaObj);
    }
    if (data && data.supplierIdVal && data.supplierIdVal.length) {
      const supplierIdObj = {
        name: "supplierId",
        type: data.supplierIdType ? data.supplierIdType : "contains",
        value: data.supplierIdVal,
      };
      filters.push(supplierIdObj);
    }
    if (data && data.catalogueIdentifier && data.catalogueIdentifier.length) {
      const catalogueIdentifierObj = {
        name: "catalogueIdentifier",
        type: data.catalogueIdentifierFilterType
          ? data.catalogueIdentifierFilterType
          : "contains",
        value: data.catalogueIdentifier,
      };
      filters.push(catalogueIdentifierObj);
    }
    if (data && data.catalogueName && data.catalogueName.length) {
      const catalogueNameObj = {
        name: "catalogueName",
        type: data.catalogueNameFilterType
          ? data.catalogueNameFilterType
          : "contains",
        value: data.catalogueName,
      };
      filters.push(catalogueNameObj);
    }
    if (data && data.primaryDelegatorId && data.primaryDelegatorId.length) {
      const primaryDelegatorObj = {
        name: "delegatedByUser",
        type: data.primaryDelegatorType
          ? data.primaryDelegatorType
          : "contains",
        value: data.primaryDelegatorId,
      };
      filters.push(primaryDelegatorObj);
    }
    if (data && data.primaryDelegateId && data.primaryDelegateId.length) {
      const primaryDelegateObj = {
        name: "delegatedToUser",
        type: data.primaryDelegateType ? data.primaryDelegateType : "contains",
        value: data.primaryDelegateId,
      };
      filters.push(primaryDelegateObj);
    }
    if (data && data.secondaryDelegatorId && data.secondaryDelegatorId.length) {
      const secondaryDelegatorObj = {
        name: "secondaryDelegatedBy",
        type: data.secondaryDelegatorType
          ? data.secondaryDelegatorType
          : "contains",
        value: data.secondaryDelegatorId,
      };
      filters.push(secondaryDelegatorObj);
    }
    if (data && data.secondaryDelegateId && data.secondaryDelegateId.length) {
      const secondaryDelegateObj = {
        name: "secondaryDelegatedTo",
        type: data.secondaryDelegateType
          ? data.secondaryDelegateType
          : "contains",
        value: data.secondaryDelegateId,
      };
      filters.push(secondaryDelegateObj);
    }
    if (data && data.delegationTypeId && data.delegationTypeId.length) {
      const delegationTypeObj = {
        name: "delegationType",
        type: data.delegationTypeType ? data.delegationTypeType : "contains",
        value: data.delegationTypeId,
      };
      filters.push(delegationTypeObj);
    }
    if (
      data &&
      data.primaryDelegatedFromId &&
      data.primaryDelegatedFromId.length
    ) {
      const primaryDelegatedFromObj = {
        name: "primaryDelegatedFrom",
        type: data.primaryDelegatedFromType
          ? data.primaryDelegatedFromType
          : "contains",
        value: data.primaryDelegatedFromId,
      };
      filters.push(primaryDelegatedFromObj);
    }
    if (
      data &&
      data.secondaryDelegatedFromId &&
      data.secondaryDelegatedFromId.length
    ) {
      const secondaryDelegatedFromObj = {
        name: "secondaryDelegatedFrom",
        type: data.secondaryDelegatedFromType
          ? data.secondaryDelegatedFromType
          : "contains",
        value: data.secondaryDelegatedFromId,
      };
      filters.push(secondaryDelegatedFromObj);
    }
    if (data && data.materialCategoryId && data.materialCategoryId.length) {
      const materialCategoryObj = {
        name: "materialCategory",
        type: data.materialCategoryType
          ? data.materialCategoryType
          : "contains",
        value: data.materialCategoryId,
      };
      filters.push(materialCategoryObj);
    }
    if (data && data.paymentTermsId && data.paymentTermsId.length) {
      const paymentTermsObj = {
        name: "paymentTerms",
        type: data.paymentTermsType ? data.paymentTermsType : "contains",
        value: data.paymentTermsId,
      };
      filters.push(paymentTermsObj);
    }
    if (data && data.contractIdId && data.contractIdId.length) {
      const contractIdObj = {
        name: "contractId",
        type: data.contractIdType ? data.contractIdType : "contains",
        value: data.contractIdId,
      };
      filters.push(contractIdObj);
    }

    if (data && data.mmolaColId && data.mmolaColId.length) {
      const contractIdObj = {
        name: "mmola",
        type: data.mmolaColType ? data.mmolaColType : "contains",
        value: data.mmolaColId,
      };
      filters.push(contractIdObj);
    }

    if (data && data.companyCodeId && data.companyCodeId.length) {
      const companyCodeIdObj = {
        name: "companyCode",
        type: data.companyCodeType ? data.companyCodeType : "contains",
        value: data.companyCodeId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.itemStatusId && data.itemStatusId.length) {
      const companyCodeIdObj = {
        name: "itemStatus",
        type: "equal",
        value: data.itemStatusId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.mgolaId && data.mgolaId.length) {
      const companyCodeIdObj = {
        name: "mgo",
        type: data.mgolaIdType ? data.mgolaIdType : "contains",
        value: data.mgolaId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.proProjectDescriptionId && data.proProjectDescriptionId.length) {
      const companyCodeIdObj = {
        name: "projectDescription",
        type: "equal",
        value: data.proProjectDescriptionId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.projectDescriptionCol && data.projectDescriptionCol.length) {
      const companyCodeIdObj = {
        name: "projectDescription",
        type: data.projectDescriptionType,
        value: data.projectDescriptionCol,
      };
      filters.push(companyCodeIdObj);
    }

    if (data && data.proWarrantyColId && data.proWarrantyColId.length) {
      const companyCodeIdObj = {
        name: 'warranty',
        type: data.proWarrantyColType ? data.proWarrantyColType : 'contains',
        value: data.proWarrantyColId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.pricErosionId && data.pricErosionId.length) {
      const companyCodeIdObj = {
        name: 'pacr',
        type: data.pricErosionIdType ? data.pricErosionIdType : 'contains',
        value: data.pricErosionId,
      };
      filters.push(companyCodeIdObj);
    }
    if (data && data.incotermsId && data.incotermsId.length) {
      const incotermsObj = {
        name: "incoterms",
        type: data.incotermsType ? data.incotermsType : "contains",
        value: data.incotermsId,
      };
      filters.push(incotermsObj);
    }
    return filters;
  }

  /**
   *Function to create a filter objects to save the user added filters
   *
   * @param {*} array
   * @param {string} componentNames
   * @return {*}
   * @memberof FilterServices
   */
  public prepairFilterObjToSave(array, componentNames: string) {
    const filterObjToSave: {
      filters: Array<any>;
      filterName: string;
      componentName: string;
    } = {
      filters: [],
      filterName: "",
      componentName: componentNames,
    };
    const manufacturerFilters = {
      name: "manufacturer",
      value: [],
      filterType: "",
    };
    const projectIdStatusFilters = {
      name: "projectIdStatusFilter",
      value: [],
      filterType: "",
    };
    const manufacturerColFilters = {
      name: "manufacturer",
      value: [],
      filterType: "",
    };
    const materialDescriptionFilters = {
      name: "materialDescription",
      value: [],
      filterType: "",
    };
    const materialLongDescriptionFilters = {
      name: "materialLongDescription",
      value: [],
      filterType: "",
    };
    const oldMaterialFilters = {
      name: "oldMaterial",
      value: [],
      filterType: "",
    };
    const boumFilters = { name: "boum", value: [], filterType: "" };
    const materialGroupFilters = {
      name: "materialGroup",
      value: [],
      filterType: "",
    };
    const commodityCodeFilters = {
      name: "commodityCode",
      value: [],
      filterType: "",
    };
    const grossWeightFilters = {
      name: "grossWeight",
      value: [],
      filterType: "",
    };
    const netWeightFilters = { name: "netWeight", value: [], filterType: "" };
    const characteristicValueFilters = {
      name: "characteristicValue",
      value: [],
      filterType: "",
    };
    const subCharacteristicValueFilters = {
      name: "subCharacteristicValue",
      value: [],
      filterType: "",
    };
    const lengthFilters = { name: "length", value: [], filterType: "" };
    const widthFilters = { name: "width", value: [], filterType: "" };
    const heightFilters = { name: "height", value: [], filterType: "" };
    const hlwUnitFilters = { name: "hlwUnit", value: [], filterType: "" };
    const volumeFilters = { name: "volume", value: [], filterType: "" };
    const volumeUnitFilters = { name: "volumeUnit", value: [], filterType: "" };
    const natureFilters = { name: "nature", value: [], filterType: "" };
    const serializationFilters = {
      name: "serialization",
      value: [],
      filterType: "",
    };
    const usefulLifeFilters = { name: "usefulLife", value: [], filterType: "" };
    const eanFilters = { name: "ean", value: [], filterType: "" };
    const eccnFilters = { name: "eccn", value: [], filterType: "" };
    const gamFilters = { name: "gam", value: [], filterType: "" };
    const auom1Filters = { name: "auom1", value: [], filterType: "" };
    const unitConversion1Filters = {
      name: "unitConversion1",
      value: [],
      filterType: "",
    };
    const auomqty1Filters = { name: "auomqty1", value: [], filterType: "" };
    const auom2Filters = { name: "auom2", value: [], filterType: "" };
    const unitConversion2Filters = {
      name: "unitConversion2",
      value: [],
      filterType: "",
    };
    const auomqty2Filters = { name: "auomqty2", value: [], filterType: "" };
    const auom3Filters = { name: "auom3", value: [], filterType: "" };
    const unitConversion3Filters = {
      name: "unitConversion3",
      value: [],
      filterType: "",
    };
    const auomqty3Filters = { name: "auomqty3", value: [], filterType: "" };
    const stackingNormsFilters = {
      name: "stackingNorms",
      value: [],
      filterType: "",
    };
    const stackingNumberFilters = {
      name: "stackingNumber",
      value: [],
      filterType: "",
    };
    const productFamilyFilters = {
      name: "productFamily",
      value: [],
      filterType: "",
    };
    const physicalTypeColFilters = {
      name: "materialCategory",
      value: [],
      filterType: "",
    };
    const endOfLifeFilters = { name: "endOfLife", value: [], filterType: "" };
    const endOfSupportFilters = {
      name: "endOfSupport",
      value: [],
      filterType: "",
    };

    const supplierFilters = { name: "supplier", value: [], filterType: "" };
    const tradingFilters = { name: "trading", value: [], filterType: "" };
    const catalogueFilters = { name: "catalogue", value: [], filterType: "" };
    const opcoFilters = { name: "opco", value: [], filterType: "" };
    const materialFilters = { name: "material", value: [], filterType: "" };
    const validityFilters = { name: "validity", value: [], filterType: "" };
    const paStatusFilters = { name: "paStatus", value: [], filterType: "" };
    const catalogueStatusFilters = {
      name: "catalogueStatus",
      value: [],
      filterType: "",
    };
    const taskTypeFilters = { name: "taskType", value: [], filterType: "" };
    const taskStatusFilters = { name: "taskStatus", value: [], filterType: "" };
    const parentSupplierFilters = {
      name: "parentSupplier",
      value: [],
      filterType: "",
    };
    const integrationStatusFilters = {
      name: "integrationStatus",
      value: [],
      filterType: "",
    };
    const currencyFilters = { name: "currency", value: [], filterType: "" };
    const amountFilter = { name: "amountFilter", value: [], filterType: "" };
    /* VP3-3 start */
    const physicalNonphysical = {
      name: "physicalNonphysical",
      value: [],
      filterType: "",
    }; //vp3-3
    const masterAgreementName = {
      name: "referenceName",
      value: [],
      filterType: "",
    }; //vp3-3
    const primaryDelegator = {
      name: "delegatedByUser",
      value: [],
      filterType: "",
    }; //vp3-3
    const primaryDelegate = {
      name: "delegatedToUser",
      value: [],
      filterType: "",
    }; //vp3-3
    const secondaryDelegator = {
      name: "secondaryDelegatedBy",
      value: [],
      filterType: "",
    }; //vp3-3
    const secondaryDelegate = {
      name: "secondaryDelegatedTo",
      value: [],
      filterType: "",
    }; //vp3-3
    const delegationType = {
      name: "delegationType",
      value: [],
      filterType: "",
    }; //vp3-3
    const primaryDelegatedFrom = {
      name: "primaryDelegatedFrom",
      value: [],
      filterType: "",
    }; //vp3-3
    const secondaryDelegatedFrom = {
      name: "secondaryDelegatedFrom",
      value: [],
      filterType: "",
    }; //vp3-3
    const materialCategory = {
      name: "materialCategory",
      value: [],
      filterType: "",
    }; //vp3-3
    const inProgressMpnSpn = {
      name: "inProgressMpnSpn",
      value: [],
      filterType: "",
    }
    const completeMpnSpn = {
      name: "completeMpnSpn",
      value: [],
      filterType: "",
    }
    const completeSAPMatrilId = {
      name: "completeSAPMatrilId",
      value: [],
      filterType: "",
    }
    const inProgressSAPMatrilId = {
      name: "inProgressSAPMatrilId",
      value: [],
      filterType: "",
    }
    const manufacturerPartNumber = {
      name: "manufacturerPartNumber",
      value: [],
      filterType: "",
    }
    const sapMaterialId = {
      name: "sapMaterialId",
      value: [],
      filterType: "",
    }
    const productFamilyCode = {
      name: "productFamilyCode",
      value: [],
      filterType: "",
    }
    const buom = {
      name: "buom",
      value: [],
      filterType: "",
    }
    const serializationProfile = {
      name: "serializationProfile",
      value: [],
      filterType: "",
    }
    const companyCode = {
      name: "companyCode",
      value: [],
      filterType: "",
    }
    const supplierPartNumber = {
      name: "supplierPartNumber",
      value: [],
      filterType: "",
    }
    const paymentTerms = { name: "paymentTerms", value: [], filterType: "" }; //vp3-3
    const contractIdId = { name: "contractIdId", value: [], filterType: "" }; //vp3-3
    const supplierName = { name: "supplierName", value: [], filterType: "" }; //vp3-3
    const opcoName = { name: "opcoName", value: [], filterType: "" }; //vp3-3
    const tradingCurrencyKey = {
      name: "tradingCurrencyKey",
      value: [],
      filterType: "",
    }; //vp3-3
    const validFromDate = { name: "validFrom", value: [], filterType: "" }; //vp3-3
    const validToDate = { name: "validTo", value: [], filterType: "" }; //vp3-3
    const secondaryStartDate = {
      name: "secondaryStartDate",
      value: [],
      filterType: "",
    }; //vp3-3
    const secondaryEndDate = {
      name: "secondaryEndDate",
      value: [],
      filterType: "",
    }; //vp3-3
    const vpcOwner = { name: "vpcOwner", value: [], filterType: "" }; //vp3-3
    const supplierOwner = { name: "supplierOwner", value: [], filterType: "" }; //vp3-3
    const createdAt = { name: "createdAt", value: [], filterType: "" }; //vp3-3
    const createdBy = { name: "createdBy", value: [], filterType: "" }; //vp3-3
    const updatedAt = { name: "updatedAt", value: [], filterType: "" }; //vp3-3
    const updatedBy = { name: "updatedBy", value: [], filterType: "" }; //vp3-3
    const isVisible = { name: "isVisible", value: [], filterType: "" }; //vp3-3
    const isSupplierOnboarded = {
      name: "isSupplierOnboarded",
      value: [],
      filterType: "",
    }; //vp3-3
    const paStatusFilter = {
      name: "paStatusFilter",
      value: [],
      filterType: "",
    }; //vp3-3
    const lineItemStatusFilter = {
      name: "lineItemStatusFilter",
      value: [],
      filterType: "",
    }; //vp3-3
    const integrationStatusFilter = {
      name: "integrationStatusFilter",
      value: [],
      filterType: "",
    }; //vp3-3
    const lastIntegratedVersion = {
      name: "lastIntegratedVersion",
      value: [],
      filterType: "",
    }; //vp3-284
    const supplierId = { name: "supplirId", value: [], filterType: "" };
    const outlineAgreementNumber = {
      name: "outlineAgreementNumber",
      value: [],
      filterType: "",
    };
    const contractId = { name: "contractId", value: [], filterType: "" };
    const catalogueIdentifier = {
      name: "catalogueIdentifier",
      value: [],
      filterType: "",
    };
    const catalogueName = { name: "catalogueName", value: [], filterType: "" };
    /* VP3-3 */

    // VOD-1652
    const catalogueIdFilter = { name: "l1Category", value: [], filterType: "" };
    const l4GroupFilter = { name: "l4Group", value: [], filterType: "" };
    const projectIdFilter = { name: "projectId", value: [], filterType: "" };
    const proProjectIdFilter = { name: "projectId", value: [], filterType: "" };
    const proItemStatusFilter = {
      name: "itemStatus",
      value: [],
      filterType: "",
    };
    const proMGOLAIdFilter = { name: "mgo", value: [], filterType: "" };
    const proSupplierIDFilter = {
      name: "supplierId",
      value: [],
      filterType: "",
    };
    const projectIdColFilter = { name: "projectId", value: [], filterType: "" };
    const projectDescriptionFilter = {
      name: "projectDescription",
      value: [],
      filterType: "",
    };
    const projectOwnerFilter = {
      name: "projectOwner",
      value: [],
      filterType: "",
    };
    const proProjectDescriptionFilter = {
      name: "projectDescription",
      value: [],
      filterType: "",
    };
    const mgocolFilter = {
      name: "mgocol",
      value: [],
      filterType: "",
    };
    const validFromDateFilter = {
      name: "validFromDate",
      value: [],
      filterType: "",
    };
    const validToDateFilter = {
      name: "validToDate",
      value: [],
      filterType: "",
    };
    const warrantyFilter = {
      name: "warranty",
      value: [],
      filterType: "",
    };
    const pacrFilter = {
      name: "pacr",
      value: [],
      filterType: "",
    };
    const taskObjectFilter = {
      name: "taskObject",
      value: [],
      filterType: "",
    };
    const taskTypeFilter = {
      name: "type",
      value: [],
      filterType: "",
    };
    const taskGeneratedFilter = {
      name: "taskGenerated",
      value: [],
      filterType: "",
    };
    const taskSupplierFilter = {
      name: "taskSupplier",
      value: [],
      filterType: "",
    };
    const taskOwnerFilter = {
      name: "taskOwner",
      value: [],
      filterType: "",
    };
    const agreementStatusFilter = {
      name: "agreementStatus",
      value: [],
      filterType: "",
    };
    const incoterms = { name: "incoterms", value: [], filterType: "" }; //vp3-3

    if (array.length) {
      array.forEach((element) => {
        if (element.type === "supplier") {
          supplierFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          supplierFilters.filterType = element.filterType;
        } else if (element.type === "manufacturer") {
          manufacturerFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          manufacturerFilters.filterType = element.filterType;
        } else if (element.type === "projectId") {
          projectIdFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          projectIdFilter.filterType = element.filterType;
        } else if (element.type === "proProjectId") {
          proProjectIdFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          proProjectIdFilter.filterType = element.filterType;
        } else if (element.type === "itemStatus") {
          proItemStatusFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          proItemStatusFilter.filterType = element.filterType;
        } else if (element.type === "proMGOLA") {
          proMGOLAIdFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          proMGOLAIdFilter.filterType = element.filterType;
        } else if (element.type === "proSupplierID") {
          proSupplierIDFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          proSupplierIDFilter.filterType = element.filterType;
        } else if (element.type === "projectIdCol") {
          projectIdColFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          projectIdColFilter.filterType = element.filterType;
        } else if (element.type === "projectIdStatusFilter") {
          projectIdStatusFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          projectIdStatusFilters.filterType = element.filterType;
        } else if (element.type === "projectDescription") {
          projectDescriptionFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          projectDescriptionFilter.filterType = element.filterType;
        } else if (element.type === "projectOwner") {
          projectOwnerFilter.value.push({
            key: element.key,
            value: element["value"],
          });
          projectOwnerFilter.filterType = element.filterType;
        } else if (element.type === "manufacturerCol") {
          manufacturerColFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          manufacturerColFilters.filterType = element.filterType;
        } else if (element.type === "materialDescription") {
          materialDescriptionFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          materialDescriptionFilters.filterType = element.filterType;
        } else if (element.type === "materialLongDescription") {
          materialLongDescriptionFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          materialLongDescriptionFilters.filterType = element.filterType;
        } else if (element.type === "oldMaterial") {
          oldMaterialFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          oldMaterialFilters.filterType = element.filterType;
        } else if (element.type === "boum") {
          boumFilters.value.push({ key: element.key, value: element["value"] });
          boumFilters.filterType = element.filterType;
        } else if (element.type === "materialGroup") {
          materialGroupFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          materialGroupFilters.filterType = element.filterType;
        } else if (element.type === "commodityCode") {
          commodityCodeFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          commodityCodeFilters.filterType = element.filterType;
        } else if (element.type === "grossWeight") {
          grossWeightFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          grossWeightFilters.filterType = element.filterType;
        } else if (element.type === "netWeight") {
          netWeightFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          netWeightFilters.filterType = element.filterType;
        } else if (element.type === "characteristicValue") {
          characteristicValueFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          characteristicValueFilters.filterType = element.filterType;
        } else if (element.type === "subCharacteristicValue") {
          subCharacteristicValueFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          subCharacteristicValueFilters.filterType = element.filterType;
        } else if (element.type === "length") {
          lengthFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          lengthFilters.filterType = element.filterType;
        } else if (element.type === "width") {
          widthFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          widthFilters.filterType = element.filterType;
        } else if (element.type === "height") {
          heightFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          heightFilters.filterType = element.filterType;
        } else if (element.type === "hlwUnit") {
          hlwUnitFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          hlwUnitFilters.filterType = element.filterType;
        } else if (element.type === "volume") {
          volumeFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          volumeFilters.filterType = element.filterType;
        } else if (element.type === "volumeUnit") {
          volumeUnitFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          volumeUnitFilters.filterType = element.filterType;
        } else if (element.type === "nature") {
          natureFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          natureFilters.filterType = element.filterType;
        } else if (element.type === "serialization") {
          serializationFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          serializationFilters.filterType = element.filterType;
        } else if (element.type === "usefulLife") {
          usefulLifeFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          usefulLifeFilters.filterType = element.filterType;
        } else if (element.type === "ean") {
          eanFilters.value.push({ key: element.key, value: element["value"] });
          eanFilters.filterType = element.filterType;
        } else if (element.type === "eccn") {
          eccnFilters.value.push({ key: element.key, value: element["value"] });
          eccnFilters.filterType = element.filterType;
        } else if (element.type === "gam") {
          gamFilters.value.push({ key: element.key, value: element["value"] });
          gamFilters.filterType = element.filterType;
        } else if (element.type === "auom1") {
          auom1Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auom1Filters.filterType = element.filterType;
        } else if (element.type === "unitConversion1") {
          unitConversion1Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          unitConversion1Filters.filterType = element.filterType;
        } else if (element.type === "auomqty1") {
          auomqty1Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auomqty1Filters.filterType = element.filterType;
        } else if (element.type === "auom2") {
          auom2Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auom2Filters.filterType = element.filterType;
        } else if (element.type === "unitConversion2") {
          unitConversion2Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          unitConversion2Filters.filterType = element.filterType;
        } else if (element.type === "auomqty2") {
          auomqty2Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auomqty2Filters.filterType = element.filterType;
        } else if (element.type === "auom3") {
          auom3Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auom3Filters.filterType = element.filterType;
        } else if (element.type === "unitConversion3") {
          unitConversion3Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          unitConversion3Filters.filterType = element.filterType;
        } else if (element.type === "auomqty3") {
          auomqty3Filters.value.push({
            key: element.key,
            value: element["value"],
          });
          auomqty3Filters.filterType = element.filterType;
        } else if (element.type === "stackingNorms") {
          stackingNormsFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          stackingNormsFilters.filterType = element.filterType;
        } else if (element.type === "stackingNumber") {
          stackingNumberFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          stackingNumberFilters.filterType = element.filterType;
        } else if (element.type === "productFamily") {
          productFamilyFilters.value.push({
            key: element.key,
            value: element["value"],
          });
          productFamilyFilters.filterType = element.filterType;
        } else
          if (element.type === "materialCategory") {
            physicalTypeColFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            physicalTypeColFilters.filterType = element.filterType;
          } else if (element.type === "inProgressMpnSpn") {
            inProgressMpnSpn.value.push({
              key: element.key,
              value: element["value"],
            });
            inProgressMpnSpn.filterType = element.filterType;
          }
          else if (element.type === "completeMpnSpn") {
            completeMpnSpn.value.push({
              key: element.key,
              value: element["value"],
            });
            completeMpnSpn.filterType = element.filterType;
          }
          else if (element.type === "completeSAPMatrilId") {
            completeSAPMatrilId.value.push({
              key: element.key,
              value: element["value"],
            });
            completeSAPMatrilId.filterType = element.filterType;
          }
          else if (element.type === "inProgressSAPMatrilId") {
            inProgressSAPMatrilId.value.push({
              key: element.key,
              value: element["value"],
            });
            inProgressSAPMatrilId.filterType = element.filterType;
          }
          else if (element.type === "manufacturerPartNumber") {
            manufacturerPartNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            manufacturerPartNumber.filterType = element.filterType;
          }
          else if (element.type === "sapMaterialId") {
            sapMaterialId.value.push({
              key: element.key,
              value: element["value"],
            });
            sapMaterialId.filterType = element.filterType;
          }
          else if (element.type === "productFamilyCode") {
            productFamilyCode.value.push({
              key: element.key,
              value: element["value"],
            });
            productFamilyCode.filterType = element.filterType;
          }
          else if (element.type === "buom") {
            buom.value.push({
              key: element.key,
              value: element["value"],
            });
            buom.filterType = element.filterType;
          }
          else if (element.type === "serializationProfile") {
            serializationProfile.value.push({
              key: element.key,
              value: element["value"],
            });
            serializationProfile.filterType = element.filterType;
          }
          else if (element.type === "companyCode") {
            companyCode.value.push({
              key: element.key,
              value: element["value"],
            });
            companyCode.filterType = element.filterType;
          }
          else if (element.type === "supplierPartNumber") {
            supplierPartNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            supplierPartNumber.filterType = element.filterType;
          }
          else if (element.type === "endOfLife") {
            endOfLifeFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            endOfLifeFilters.filterType = element.filterType;
          } else if (element.type === "endOfSupport") {
            endOfSupportFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            endOfSupportFilters.filterType = element.filterType;
          } else if (element.type === "trading") {
            tradingFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            tradingFilters.filterType = element.filterType;
          } else if (element.type === "catalogue") {
            catalogueFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            catalogueFilters.filterType = element.filterType;
          } else if (element.type === "opco") {
            opcoFilters.value.push({ key: element.key, value: element["value"] });
            opcoFilters.filterType = element.filterType;
          } else if (element.type === "material") {
            materialFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            materialFilters.filterType = element.filterType;
          } else if (element.type === "validity") {
            validityFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            validityFilters.filterType = element.filterType;
          } else if (element.type === "paStatus") {
            paStatusFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            paStatusFilters.filterType = element.filterType;
          } else if (element.type === "catalogueStatus") {
            catalogueStatusFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            catalogueStatusFilters.filterType = element.filterType;
          } else if (element.type === "taskType") {
            taskTypeFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            taskTypeFilters.filterType = element.filterType;
          } else if (element.type === "taskStatus") {
            taskStatusFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            taskStatusFilters.filterType = element.filterType;
          } else if (element.type === "parentSupplier") {
            parentSupplierFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            parentSupplierFilters.filterType = element.filterType;
          } else if (element.type === "integrationStatus") {
            integrationStatusFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            integrationStatusFilters.filterType = element.filterType;
          } else if (element.type === "currency") {
            currencyFilters.value.push({
              key: element.key,
              value: element["value"],
            });
            currencyFilters.filterType = element.filterType;
          } else if (element.type === "amount") {
            amountFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            amountFilter.filterType = element.filterType;
          } else if (element.type === "physicalStatus") {
            physicalNonphysical.value.push({
              key: element.key,
              value: element["value"],
            });
            physicalNonphysical.filterType = element.filterType;
          }
          // VOD-1652
          else if (element.type === "l1Category") {
            catalogueIdFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            catalogueIdFilter.filterType = element.filterType;
          } else if (element.type === "l4Group") {
            l4GroupFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            l4GroupFilter.filterType = element.filterType;
          } else if (element.type === "referenceName") {
            masterAgreementName.value.push({
              key: element.key,
              value: element["value"],
            });
            masterAgreementName.filterType = element.filterType;
          } else if (element.type === "supplierName") {
            supplierName.value.push({
              key: element.key,
              value: element["value"],
            });
            supplierName.filterType = element.filterType;
          } else if (element.type === "opcoName") {
            opcoName.value.push({ key: element.key, value: element["value"] });
            opcoName.filterType = element.filterType;
          } else if (element.type === "tradingCurrencyKey") {
            tradingCurrencyKey.value.push({
              key: element.key,
              value: element["value"],
            });
            tradingCurrencyKey.filterType = element.filterType;
          } else if (element.type === "validFrom") {
            validFromDate.value.push({
              key: element.key,
              value: element["value"],
            });
            validFromDate.filterType = element.filterType;
          } else if (element.type === "validTo") {
            validToDate.value.push({ key: element.key, value: element["value"] });
            validToDate.filterType = element.filterType;
          } else if (element.type === "secondaryStartDate") {
            secondaryStartDate.value.push({
              key: element.key,
              value: element["value"],
            });
            secondaryStartDate.filterType = element.filterType;
          } else if (element.type === "secondaryEndDate") {
            secondaryEndDate.value.push({
              key: element.key,
              value: element["value"],
            });
            secondaryEndDate.filterType = element.filterType;
          } else if (element.type === "vpcOwner") {
            vpcOwner.value.push({ key: element.key, value: element["value"] });
            vpcOwner.filterType = element.filterType;
          } else if (element.type === "supplierOwner") {
            supplierOwner.value.push({
              key: element.key,
              value: element["value"],
            });
            supplierOwner.filterType = element.filterType;
          } else if (element.type === "createdAt") {
            createdAt.value.push({ key: element.key, value: element["value"] });
            createdAt.filterType = element.filterType;
          } else if (element.type === "createdBy") {
            createdBy.value.push({ key: element.key, value: element["value"] });
            createdBy.filterType = element.filterType;
          } else if (element.type === "updatedAt") {
            updatedAt.value.push({ key: element.key, value: element["value"] });
            updatedAt.filterType = element.filterType;
          } else if (element.type === "updatedBy") {
            updatedBy.value.push({ key: element.key, value: element["value"] });
            updatedBy.filterType = element.filterType;
          } else if (element.type === "isVisible") {
            isVisible.value.push({ key: element.key, value: element["value"] });
            isVisible.filterType = element.filterType;
          } else if (element.type === "isSupplierOnboarded") {
            isSupplierOnboarded.value.push({
              key: element.key,
              value: element["value"],
            });
            isSupplierOnboarded.filterType = element.filterType;
          } else if (element.type === "paStatusFilter") {
            paStatusFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            paStatusFilter.filterType = element.filterType;
          } else if (element.type === "lineItemStatusFilter") {
            lineItemStatusFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            lineItemStatusFilter.filterType = element.filterType;
          } else if (element.type === "integrationStatusFilter") {
            integrationStatusFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            integrationStatusFilter.filterType = element.filterType;
          } else if (element.type === "lastIntegratedVersion") {
            lastIntegratedVersion.value.push({
              key: element.key,
              value: element["value"],
            });
            lastIntegratedVersion.filterType = element.filterType;
          } else if (element.type === "supplierId") {
            supplierId.value.push({ key: element.key, value: element["value"] });
            supplierId.filterType = element.filterType;
          } else if (element.type === "outlineAgreementNumber") {
            outlineAgreementNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            outlineAgreementNumber.filterType = element.filterType;
          } else if (element.type === "contractId") {
            contractId.value.push({ key: element.key, value: element["value"] });
            contractId.filterType = element.filterType;
          } else if (element.type === "olaId") {
            outlineAgreementNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            outlineAgreementNumber.filterType = element.filterType;
          } else if (element.type === "catalogueIdentifier") {
            catalogueIdentifier.value.push({
              key: element.key,
              value: element["value"],
            });
            catalogueIdentifier.filterType = element.filterType;
          } else if (element.type === "catalogueName") {
            catalogueName.value.push({
              key: element.key,
              value: element["value"],
            });
            catalogueName.filterType = element.filterType;
          } else if (element.type === "delegatedByUser") {
            primaryDelegator.value.push({
              key: element.key,
              value: element["value"],
            });
            primaryDelegator.filterType = element.filterType;
          } else if (element.type === "delegatedToUser") {
            primaryDelegate.value.push({
              key: element.key,
              value: element["value"],
            });
            primaryDelegate.filterType = element.filterType;
          } else if (element.type === "secondaryDelegatedBy") {
            secondaryDelegator.value.push({
              key: element.key,
              value: element["value"],
            });
            secondaryDelegator.filterType = element.filterType;
          } else if (element.type === "secondaryDelegatedTo") {
            secondaryDelegate.value.push({
              key: element.key,
              value: element["value"],
            });
            secondaryDelegate.filterType = element.filterType;
          } else if (element.type === "delegationType") {
            delegationType.value.push({
              key: element.key,
              value: element["value"],
            });
            delegationType.filterType = element.filterType;
          } else if (element.type === "primaryDelegatedFrom") {
            primaryDelegatedFrom.value.push({
              key: element.key,
              value: element["value"],
            });
            primaryDelegatedFrom.filterType = element.filterType;
          } else if (element.type === "secondaryDelegatedFrom") {
            secondaryDelegatedFrom.value.push({
              key: element.key,
              value: element["value"],
            });
            secondaryDelegatedFrom.filterType = element.filterType;
          } else if (element.type === "materialCategory") {
            materialCategory.value.push({
              key: element.key,
              value: element["value"],
            });
            materialCategory.filterType = element.filterType;


          }
          else if (element.type === "inProgressMpnSpn") {
            inProgressMpnSpn.value.push({
              key: element.key,
              value: element["value"],
            });
            inProgressMpnSpn.filterType = element.filterType;
          }
          else if (element.type === "completeSAPMatrilId") {
            completeSAPMatrilId.value.push({
              key: element.key,
              value: element["value"],
            });
            completeSAPMatrilId.filterType = element.filterType;
          }
          else if (element.type === "completeMpnSpn") {
            completeMpnSpn.value.push({
              key: element.key,
              value: element["value"],
            });
            completeMpnSpn.filterType = element.filterType;
          }
          else if (element.type === "inProgressSAPMatrilId") {
            inProgressSAPMatrilId.value.push({
              key: element.key,
              value: element["value"],
            });
            inProgressSAPMatrilId.filterType = element.filterType;
          } else if (element.type === "manufacturerPartNumber") {
            manufacturerPartNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            manufacturerPartNumber.filterType = element.filterType;
          }
          else if (element.type === "sapMaterialId") {
            sapMaterialId.value.push({
              key: element.key,
              value: element["value"],
            });
            sapMaterialId.filterType = element.filterType;
          }
          else if (element.type === "productFamilyCode") {
            productFamilyCode.value.push({
              key: element.key,
              value: element["value"],
            });
            productFamilyCode.filterType = element.filterType;
          }
          else if (element.type === "buom") {
            buom.value.push({
              key: element.key,
              value: element["value"],
            });
            buom.filterType = element.filterType;
          }
          else if (element.type === "serializationProfile") {
            serializationProfile.value.push({
              key: element.key,
              value: element["value"],
            });
            serializationProfile.filterType = element.filterType;
          }
          else if (element.type === "companyCode") {
            companyCode.value.push({
              key: element.key,
              value: element["value"],
            });
            companyCode.filterType = element.filterType;
          }
          else if (element.type === "supplierPartNumber") {
            supplierPartNumber.value.push({
              key: element.key,
              value: element["value"],
            });
            supplierPartNumber.filterType = element.filterType;
          }
          else if (element.type === "paymentTerms") {
            paymentTerms.value.push({
              key: element.key,
              value: element["value"],
            });
            paymentTerms.filterType = element.filterType;
          } else if (element.type === "contractIdId") {
            contractIdId.value.push({
              key: element.key,
              value: element["value"],
            });
            contractIdId.filterType = element.filterType;
          } else if (element.type === "mgocol") {
            mgocolFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            mgocolFilter.filterType = element.filterType;
          } else if (element.type === "validFromDate") {
            validFromDateFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            validFromDateFilter.filterType = element.filterType;
          } else if (element.type === "validToDate") {
            validToDateFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            validToDateFilter.filterType = element.filterType;
          } else if (element.type === "warranty") {
            warrantyFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            warrantyFilter.filterType = element.filterType;
          } else if (element.type === "pacr") {
            pacrFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            pacrFilter.filterType = element.filterType;
          } else if (element.type === "proProjectDescription") {
            proProjectDescriptionFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            proProjectDescriptionFilter.filterType = element.filterType;
          }
          else if (element.type === "taskObject") {
            taskObjectFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            taskObjectFilter.filterType = element.filterType;
          }
          else if (element.type === "type") {
            taskTypeFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            taskTypeFilter.filterType = element.filterType;
          }
          else if (element.type === "taskGenerated") {
            taskGeneratedFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            taskGeneratedFilter.filterType = element.filterType;
          }
          else if (element.type === "taskSupplier") {
            taskSupplierFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            taskSupplierFilter.filterType = element.filterType;
          }
          else if (element.type === "taskOwner") {
            taskOwnerFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            taskOwnerFilter.filterType = element.filterType;
          }
          else if (element.type === "agreementStatus") {
            agreementStatusFilter.value.push({
              key: element.key,
              value: element["value"],
            });
            agreementStatusFilter.filterType = element.filterType;
          } else if (element.type === "incoterms") {
            incoterms.value.push({
              key: element.key,
              value: element["value"],
            });
            incoterms.filterType = element.filterType;
          }
      });

    }

    // material and bom   
    if (manufacturerFilters.value.length) {
      filterObjToSave.filters.push(manufacturerFilters);
    }
    if (projectIdFilter.value.length) {
      filterObjToSave.filters.push(projectIdFilter);
    }
    if (projectIdStatusFilters.value.length) {
      filterObjToSave.filters.push(projectIdStatusFilters);
    }

    if (projectIdColFilter.value.length) {
      filterObjToSave.filters.push(projectIdColFilter);
    }
    if (projectDescriptionFilter.value.length) {
      filterObjToSave.filters.push(projectDescriptionFilter);
    }
    if (projectOwnerFilter.value.length) {
      filterObjToSave.filters.push(projectOwnerFilter);
    }
    if (manufacturerColFilters.value.length) {
      filterObjToSave.filters.push(manufacturerColFilters);
    }
    if (materialDescriptionFilters.value.length) {
      filterObjToSave.filters.push(materialDescriptionFilters);
    }
    if (materialLongDescriptionFilters.value.length) {
      filterObjToSave.filters.push(materialLongDescriptionFilters);
    }
    if (oldMaterialFilters.value.length) {
      filterObjToSave.filters.push(oldMaterialFilters);
    }
    if (boumFilters.value.length) {
      filterObjToSave.filters.push(boumFilters);
    }
    if (materialGroupFilters.value.length) {
      filterObjToSave.filters.push(materialGroupFilters);
    }

    if (commodityCodeFilters.value.length) {
      filterObjToSave.filters.push(commodityCodeFilters);
    }
    if (grossWeightFilters.value.length) {
      filterObjToSave.filters.push(grossWeightFilters);
    }
    if (netWeightFilters.value.length) {
      filterObjToSave.filters.push(netWeightFilters);
    }
    if (characteristicValueFilters.value.length) {
      filterObjToSave.filters.push(characteristicValueFilters);
    }
    if (subCharacteristicValueFilters.value.length) {
      filterObjToSave.filters.push(subCharacteristicValueFilters);
    }
    if (lengthFilters.value.length) {
      filterObjToSave.filters.push(lengthFilters);
    }

    if (widthFilters.value.length) {
      filterObjToSave.filters.push(widthFilters);
    }
    if (heightFilters.value.length) {
      filterObjToSave.filters.push(heightFilters);
    }
    if (hlwUnitFilters.value.length) {
      filterObjToSave.filters.push(hlwUnitFilters);
    }
    if (volumeFilters.value.length) {
      filterObjToSave.filters.push(volumeFilters);
    }
    if (volumeUnitFilters.value.length) {
      filterObjToSave.filters.push(volumeUnitFilters);
    }
    if (natureFilters.value.length) {
      filterObjToSave.filters.push(natureFilters);
    }

    if (serializationFilters.value.length) {
      filterObjToSave.filters.push(serializationFilters);
    }
    if (usefulLifeFilters.value.length) {
      filterObjToSave.filters.push(usefulLifeFilters);
    }
    if (eanFilters.value.length) {
      filterObjToSave.filters.push(eanFilters);
    }
    if (eccnFilters.value.length) {
      filterObjToSave.filters.push(eccnFilters);
    }
    if (gamFilters.value.length) {
      filterObjToSave.filters.push(gamFilters);
    }
    if (auom1Filters.value.length) {
      filterObjToSave.filters.push(auom1Filters);
    }
    if (unitConversion1Filters.value.length) {
      filterObjToSave.filters.push(unitConversion1Filters);
    }

    if (auomqty1Filters.value.length) {
      filterObjToSave.filters.push(auomqty1Filters);
    }
    if (auom2Filters.value.length) {
      filterObjToSave.filters.push(auom2Filters);
    }
    if (unitConversion2Filters.value.length) {
      filterObjToSave.filters.push(unitConversion2Filters);
    }
    if (auomqty2Filters.value.length) {
      filterObjToSave.filters.push(auomqty2Filters);
    }
    if (auom3Filters.value.length) {
      filterObjToSave.filters.push(auom3Filters);
    }

    if (unitConversion3Filters.value.length) {
      filterObjToSave.filters.push(unitConversion3Filters);
    }
    if (auomqty3Filters.value.length) {
      filterObjToSave.filters.push(auomqty3Filters);
    }
    if (stackingNormsFilters.value.length) {
      filterObjToSave.filters.push(stackingNormsFilters);
    }
    if (stackingNumberFilters.value.length) {
      filterObjToSave.filters.push(stackingNumberFilters);
    }
    if (productFamilyFilters.value.length) {
      filterObjToSave.filters.push(productFamilyFilters);
    }
    if (physicalTypeColFilters.value.length) {
      filterObjToSave.filters.push(physicalTypeColFilters);
    }
    if (endOfLifeFilters.value.length) {
      filterObjToSave.filters.push(endOfLifeFilters);
    }
    if (endOfSupportFilters.value.length) {
      filterObjToSave.filters.push(endOfSupportFilters);
    }

    //material and bom end
    if (supplierFilters.value.length) {
      filterObjToSave.filters.push(supplierFilters);
    }
    if (tradingFilters.value.length) {
      filterObjToSave.filters.push(tradingFilters);
    }
    if (catalogueFilters.value.length) {
      filterObjToSave.filters.push(catalogueFilters);
    }
    if (opcoFilters.value.length) {
      filterObjToSave.filters.push(opcoFilters);
    }
    if (materialFilters.value.length) {
      filterObjToSave.filters.push(materialFilters);
    }
    if (validityFilters.value.length) {
      filterObjToSave.filters.push(validityFilters);
    }
    if (paStatusFilters.value.length) {
      filterObjToSave.filters.push(paStatusFilters);
    }
    if (catalogueStatusFilters.value.length) {
      filterObjToSave.filters.push(catalogueStatusFilters);
    }
    if (taskTypeFilters.value.length) {
      filterObjToSave.filters.push(taskTypeFilters);
    }
    if (taskStatusFilters.value.length) {
      filterObjToSave.filters.push(taskStatusFilters);
    }
    if (parentSupplierFilters.value.length) {
      filterObjToSave.filters.push(parentSupplierFilters);
    }
    if (integrationStatusFilters.value.length) {
      filterObjToSave.filters.push(integrationStatusFilters);
    }
    if (currencyFilters.value.length) {
      filterObjToSave.filters.push(currencyFilters);
    }
    if (amountFilter.value.length) {
      filterObjToSave.filters.push(amountFilter);
    }
    if (physicalNonphysical.value.length) {
      filterObjToSave.filters.push(physicalNonphysical);
    }
    // VOD-1652
    if (catalogueIdFilter.value.length) {
      filterObjToSave.filters.push(catalogueIdFilter);
    }
    // VOD-1652
    if (l4GroupFilter.value.length) {
      filterObjToSave.filters.push(l4GroupFilter);
    }
    /* VP3-3 start*/
    if (masterAgreementName.value.length) {
      filterObjToSave.filters.push(masterAgreementName);
    }
    if (supplierName.value.length) {
      filterObjToSave.filters.push(supplierName);
    }
    if (opcoName.value.length) {
      filterObjToSave.filters.push(opcoName);
    }
    if (tradingCurrencyKey.value.length) {
      filterObjToSave.filters.push(tradingCurrencyKey);
    }
    if (validFromDate.value.length) {
      filterObjToSave.filters.push(validFromDate);
    }
    if (validToDate.value.length) {
      filterObjToSave.filters.push(validToDate);
    }
    if (vpcOwner.value.length) {
      filterObjToSave.filters.push(vpcOwner);
    }
    if (supplierOwner.value.length) {
      filterObjToSave.filters.push(supplierOwner);
    }
    if (createdAt.value.length) {
      filterObjToSave.filters.push(createdAt);
    }
    if (createdBy.value.length) {
      filterObjToSave.filters.push(createdBy);
    }
    if (updatedAt.value.length) {
      filterObjToSave.filters.push(updatedAt);
    }
    if (updatedBy.value.length) {
      filterObjToSave.filters.push(updatedBy);
    }
    if (isVisible.value.length) {
      filterObjToSave.filters.push(isVisible);
    }
    if (isSupplierOnboarded.value.length) {
      filterObjToSave.filters.push(isSupplierOnboarded);
    }
    if (paStatusFilter.value.length) {
      filterObjToSave.filters.push(paStatusFilter);
    }
    if (lineItemStatusFilter.value.length) {
      filterObjToSave.filters.push(lineItemStatusFilter);
    }
    if (integrationStatusFilter.value.length) {
      filterObjToSave.filters.push(integrationStatusFilter);
    }
    if (lastIntegratedVersion.value.length) {
      filterObjToSave.filters.push(lastIntegratedVersion);
    }
    if (supplierId.value.length) {
      filterObjToSave.filters.push(supplierId);
    }
    if (outlineAgreementNumber.value.length) {
      filterObjToSave.filters.push(outlineAgreementNumber);
    }
    if (contractId.value.length) {
      filterObjToSave.filters.push(contractId);
    }
    if (catalogueIdentifier.value.length) {
      filterObjToSave.filters.push(catalogueIdentifier);
    }
    if (catalogueName.value.length) {
      filterObjToSave.filters.push(catalogueName);
    }
    if (primaryDelegator.value.length) {
      filterObjToSave.filters.push(primaryDelegator);
    }
    if (primaryDelegate.value.length) {
      filterObjToSave.filters.push(primaryDelegate);
    }
    if (secondaryDelegator.value.length) {
      filterObjToSave.filters.push(secondaryDelegator);
    }
    if (secondaryDelegate.value.length) {
      filterObjToSave.filters.push(secondaryDelegate);
    }
    if (secondaryStartDate.value.length) {
      filterObjToSave.filters.push(secondaryStartDate);
    }
    if (secondaryEndDate.value.length) {
      filterObjToSave.filters.push(secondaryEndDate);
    }
    if (delegationType.value.length) {
      filterObjToSave.filters.push(delegationType);
    }
    if (primaryDelegatedFrom.value.length) {
      filterObjToSave.filters.push(primaryDelegatedFrom);
    }
    if (secondaryDelegatedFrom.value.length) {
      filterObjToSave.filters.push(secondaryDelegatedFrom);
    }
    if (materialCategory.value.length) {
      filterObjToSave.filters.push(materialCategory);
    }
    if (inProgressMpnSpn.value.length) {
      filterObjToSave.filters.push(inProgressMpnSpn);
    }
    if (completeSAPMatrilId.value.length) {
      filterObjToSave.filters.push(completeSAPMatrilId);
    }
    if (completeMpnSpn.value.length) {
      filterObjToSave.filters.push(completeMpnSpn);
    }
    if (inProgressSAPMatrilId.value.length) {
      filterObjToSave.filters.push(inProgressSAPMatrilId);
    }
    if (manufacturerPartNumber.value.length) {
      filterObjToSave.filters.push(manufacturerPartNumber);
    }
    if (sapMaterialId.value.length) {
      filterObjToSave.filters.push(sapMaterialId);
    }
    if (productFamilyCode.value.length) {
      filterObjToSave.filters.push(productFamilyCode);
    }
    if (buom.value.length) {
      filterObjToSave.filters.push(buom);
    }
    if (serializationProfile.value.length) {
      filterObjToSave.filters.push(serializationProfile);
    }
    if (companyCode.value.length) {
      filterObjToSave.filters.push(companyCode);
    }
    if (supplierPartNumber.value.length) {
      filterObjToSave.filters.push(supplierPartNumber);
    }
    if (paymentTerms.value.length) {
      filterObjToSave.filters.push(paymentTerms);
    }
    if (contractIdId.value.length) {
      filterObjToSave.filters.push(contractIdId);
    }
    if (proProjectIdFilter.value.length) {
      filterObjToSave.filters.push(proProjectIdFilter);
    }
    if (proItemStatusFilter.value.length) {
      filterObjToSave.filters.push(proItemStatusFilter);
    }
    if (proMGOLAIdFilter.value.length) {
      filterObjToSave.filters.push(proMGOLAIdFilter);
    }
    if (proSupplierIDFilter.value.length) {
      filterObjToSave.filters.push(proSupplierIDFilter);
    }
    if (proProjectDescriptionFilter.value.length) {
      filterObjToSave.filters.push(proProjectDescriptionFilter);
    }
    if (mgocolFilter.value.length) {
      filterObjToSave.filters.push(mgocolFilter);
    }
    if (validToDateFilter.value.length) {
      filterObjToSave.filters.push(validToDateFilter);
    }
    if (validFromDateFilter.value.length) {
      filterObjToSave.filters.push(validFromDateFilter);
    }
    if (pacrFilter.value.length) {
      filterObjToSave.filters.push(pacrFilter);
    }
    if (warrantyFilter.value.length) {
      filterObjToSave.filters.push(warrantyFilter);
    }
    if (taskObjectFilter.value.length) {
      filterObjToSave.filters.push(taskObjectFilter);
    }
    if (taskTypeFilter.value.length) {
      filterObjToSave.filters.push(taskTypeFilter);
    }
    if (taskGeneratedFilter.value.length) {
      filterObjToSave.filters.push(taskGeneratedFilter);
    }
    if (taskSupplierFilter.value.length) {
      filterObjToSave.filters.push(taskSupplierFilter);
    }
    if (taskOwnerFilter.value.length) {
      filterObjToSave.filters.push(taskOwnerFilter);
    }
    if (agreementStatusFilter.value.length) {
      filterObjToSave.filters.push(agreementStatusFilter);
    }
    if (incoterms.value.length) {
      filterObjToSave.filters.push(incoterms);
    }
    /* VP3-3 end*/
    /* VP3-3 end*/
    return filterObjToSave;
  }

  /**
   *Function that create objects for search filters
   *
   * @param {*} data
   * @return {*}
   * @memberof FilterServices
   */
  public prepairSerchObjFromRes(data: any) {
    const filterObjArr = [];
    data.forEach((element) => {
      if (element["name"] === "trading") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "trading",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "catalogue") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "catalogue",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "supplier") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplier",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "opco") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "opco",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "material") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "material",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "validity") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validity",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "paStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "paStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "catalogueStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "catalogueStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "taskType") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskType",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "taskStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "parentSupplier") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "parentSupplier",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "integrationStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "integrationStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "currency") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "currency",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "amountFilter") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "amount",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "physicalNonphysical") {
        //vp3-3
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "physicalStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      // VOD-1652
      else if (element["name"] === "l4Group") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "l4Group",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "l1Category") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "l1Category",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "referenceName") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "referenceName",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "supplierName") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplierName",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "opcoName") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "opcoName",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "tradingCurrencyKey") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "tradingCurrencyKey",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "validFrom") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validFrom",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "validTo") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validTo",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "secondaryStartDate") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "secondaryStartDate",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "secondaryEndDate") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "secondaryEndDate",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "vpcOwner") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "vpcOwner",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "supplierOwner") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplierOwner",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "createdAt") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "createdAt",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "createdBy") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "createdBy",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "updatedAt") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "updatedAt",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "updatedBy") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "updatedBy",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "isVisible") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "isVisible",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "isSupplierOnboarded") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "isSupplierOnboarded",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "paStatusFilter") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "paStatusFilter",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "lineItemStatusFilter") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "lineItemStatusFilter",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "integrationStatusFilter") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "integrationStatusFilter",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "lastIntegrtedVersion") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "lastIntegrtedVersion",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "supplierId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplierId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "outlineAgreementNumber") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "outlineAgreementNumber",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "contractId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "contractId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "olaId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "outlineAgreementNumber",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "delegatedByUser") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "delegatedByUser",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "delegatedToUser") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "delegatedToUser",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "secondaryDelegatedBy") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "secondaryDelegatedBy",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "secondaryDelegatedTo") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "secondaryDelegatedTo",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "delegationType") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "delegationType",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "primaryDelegatedFrom") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "primaryDelegatedFrom",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "secondaryDelegatedFrom") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "secondaryDelegatedFrom",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "materialCategory") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "materialCategory",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "paymentTerms") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "paymentTerms",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "contractIdId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "contractIdId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "projectId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "projectId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "projectDescription") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "projectDescription",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "projectOwner") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "projectOwner",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      // material and bom
      else if (element["name"] === "manufacturer") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "manufacturer",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "materialDescription") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "materialDescription",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "materialLongDescription") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "materialLongDescription",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "oldMaterial") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "oldMaterial",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "boum") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "boum",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "materialGroup") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "materialGroup",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "commodityCode") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "commodityCode",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "grossWeight") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "grossWeight",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "netWeight") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "netWeight",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "characteristicValue") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "characteristicValue",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "subCharacteristicValue") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "subCharacteristicValue",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "length") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "length",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "width") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "width",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "height") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "height",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "hlwUnit") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "hlwUnit",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "volume") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "volume",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "volumeUnit") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "volumeUnit",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "nature") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "nature",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "serialization") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "serialization",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "usefulLife") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "usefulLife",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "ean") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "ean",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "eccn") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "eccn",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "gam") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "gam",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auom1") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auom1",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "unitConversion1") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "unitConversion1",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auomqty1") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auomqty1",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auom2") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auom2",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "unitConversion2") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "unitConversion2",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auomqty2") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auomqty2",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auom3") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auom3",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "unitConversion3") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "unitConversion3",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "auomqty3") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "auomqty3",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "stackingNorms") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "stackingNorms",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "stackingNumber") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "stackingNumber",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "manufacturer") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "manufacturer",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "productFamily") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "productFamily",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "materialCategory") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "materialCategory",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "endOfLife") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "endOfLife",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "endOfSupport") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "endOfSupport",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "supplirId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplierId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "mgo") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "mgocol",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "validFromDate") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validFromDate",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "validToDate") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validToDate",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "warranty") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "warranty",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "pacr") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "pacr",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "itemStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "itemStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "mgocol") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "mgocol",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "proProjectId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "proProjectId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "proSupplierID") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "proSupplierID",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "proMGOLA") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "proMGOLA",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "proProjectDescription") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "proProjectDescription",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "companyCode") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "companyCode",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "taskOwner") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskOwner",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      } else if (element["name"] === "agreementStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "agreementStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "taskSupplier") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskSupplier",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "taskGenerated") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskGenerated",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "type") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "type",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "taskObject") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "taskObject",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "validity") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "validity",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      // bom mat added missing filed 
      else if (element["name"] === "productFamilyCode") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "productFamilyCode",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "supplierPartNumber") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "supplierPartNumber",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "manufacturerPartNumber") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "manufacturerPartNumber",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "sapMaterialId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "sapMaterialId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      // added inProgressSAPMatrilId , was missing
      else if (element["name"] === "inProgressSAPMatrilId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "sapMaterialId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      // added completedSAPMatrilId , was missing
      else if (element["name"] === "completeSAPMatrilId") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "sapMaterialId",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "itemStatus") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "itemStatus",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }
      else if (element["name"] === "buom") {
        element["value"].forEach((valElement) => {
          filterObjArr.push({
            type: "buom",
            key: valElement["key"],
            value: valElement["value"],
            filterType: element["filterType"],
          });
        });
      }


      // material and bom end 
    });
    return filterObjArr;
  }

  /**
   *Function that create objects for search filters
   *
   * @param {*} data
   * @return {*}
   * @memberof FilterServices
   */
  public prepairSearchObj(data: any) {
    const removedFilterObj = {
      suplierId: [],
      opcoId: [],
      olaIds: [],
      catalogueId: [],
      materialId: [],
      tradingId: [],
      paStatusId: [],
      catalogueStatusId: [],
      validity: [],
      taskTypeId: [],
      taskStatusId: [],
      parentSupplierId: [],
      integrationStatusId: [],
      currencyId: [],
      amountId: [],
      agreementStatusId: [],
      taskType_Id: [],
      referenceNameId: [],
      taskObjectId: [],
      taskGeneratedId: [],
      taskSupplierId: [],
      taskOwnerId: [],
      status: [],
      /* VP3-3 start */
      physicalNonphysicalId: [],
      masterAgreementNameId: [],
      masterAgreementNameType: "",
      primaryDelegatorId: [],
      primaryDelegatorType: "",
      primaryDelegateId: [],
      primaryDelegateType: "",
      secondaryDelegatorId: [],
      secondaryDelegatorType: "",
      secondaryDelegateId: [],
      secondaryDelegateType: "",
      delegationTypeId: [],
      delegationTypeType: "",
      primaryDelegatedFromId: [],
      primaryDelegatedFromType: "",
      secondaryDelegatedFromId: [],
      secondaryDelegatedFromType: "",
      supplierNameId: [],
      supplierNameType: "",
      opcoNameId: [],
      opcoNameType: "",
      tradingCurrencyKeyId: [],
      tradingCurrencyKeyType: "",
      validFromDateId: [],
      validFromDateType: "",
      validToDateId: [],
      validToDateType: "",
      secondaryEndDateId: [],
      secondaryEndDateType: "",
      secondaryStartDateId: [],
      secondaryStartDateType: "",
      vpcOwnerId: [],
      vpcOwnerType: "",
      vodafoneApproverId: [],
      vodafoneApproverType: "",
      materialCategoryId: [],
      materialCategoryType: "",
      paymentTermsId: [],
      paymentTermsType: "",
      contractIdId: [],
      contractIdType: "",
      supplierOwnerId: [],
      supplierOwnerType: "",
      createdAtId: [],
      createdAtType: "",
      createdById: [],
      createdByType: "",
      updatedAtId: [],
      updatedAtType: "",
      updatedById: [],
      updatedByType: "",
      isVisibleId: [],
      isVisibleType: "",
      isSupplierOnboardedId: [],
      isSupplierOnboardedType: "",
      paStatusFilterId: [],
      paStatusFilterType: "",
      lineItemStatusFilterId: [],
      lineItemStatusFilterType: "",
      integrationStatusFilterId: [],
      integrationStatusFilterType: "",
      lastIntegratedVersionId: [],
      lastIntegratedVersionFilterType: "",
      supplierId: [],
      supplierIdType: "",
      outlineAgreementId: [],
      outlineAgreementNumberType: "",
      contractId: [],
      catalogueIdentifier: [],
      catalogueName: [],
      completeMpnSpnId: [],
      completeMpnSpnType: "",
      inProgressMpnSpnId: [],
      inProgressMpnSpnType: "",
      manufacturerId: [],
      manufacturerType: "",
      materialSupplierId: [],
      materialSupplierType: "",
      companyCodeId: [],
      companyCodeType: "",
      projectId: [],
      projectIdCol: [],
      projectIdType: "",
      projectDescription: [],
      projectDescriptionType: "",
      projectOwner: [],
      projectOwnerType: "",
      manufacturerPartNumberId: [], //added by ankita to fix the issue of multi filter
      manufacturerPartNumberType: "",
      materialGroupId: [],
      materialGroupType: "",
      volumeUnitId: [],
      volumeUnitType: "",
      itemStatusId: [],
      itemStatusType: "",
      hlwUnitId: [],
      hlwUnitType: "",
      eccnId: [],
      eccnType: "",
      sapMaterialId: [],
      supplierPartNumberId: [],
      materialDescriptionId: [],
      materialLongDescriptionId: [],
      boumId: [],
      commodityCodeId: [],
      grossWeightId: [],
      netWeightId: [],
      characteristicValueId: [],
      subCharacteristicValueId: [],
      lengthId: [],
      widthId: [],
      heightId: [],
      volumeId: [],
      natureId: [],
      serializationId: [],
      usefulLifeId: [],
      eanId: [],
      gamId: [],
      auom1Id: [],
      unitConversion1Id: [],
      auomqty1Id: [],
      auom2Id: [],
      unitConversion2Id: [],
      auomqty2Id: [],
      auom3Id: [],
      unitConversion3Id: [],
      auomqty3Id: [],
      stackingNormsId: [],
      stackingNumberId: [],
      productFamilyId: [],
      sapMaterialIdType: "",
      supplierPartNumberType: "",
      materialDescriptionType: "",
      materialLongDescriptionType: "",
      boumType: "",
      commodityCodeType: "",
      grossWeightType: "",
      netWeightType: "",
      characteristicValueType: "",
      subCharacteristicValueType: "",
      lengthType: "",
      widthType: "",
      heightType: "",
      volumeType: "",
      natureType: "",
      serializationType: "",
      usefulLifeType: "",
      eanType: "",
      gamType: "",
      auom1Type: "",
      unitConversion1Type: "",
      auomqty1Type: "",
      auom2Type: "",
      unitConversion2Type: "",
      auomqty2Type: "",
      auom3Type: "",
      unitConversion3Type: "",
      auomqty3Type: "",
      stackingNormsType: "",
      stackingNumberType: "",
      productFamilyType: "",
      endOfLifeType: "",
      endOfSupportType: "",
      endOfLifeId: [],
      endOfSupportId: [],
      proProjectIdId: [],
      proProjectIdType: "",
      proSupplierIDId: [],
      proSupplierIDType: "",
      proMGOLAId: [],
      proMGOLAType: "",
      proProjectDescriptionId: [],
      proProjectDescriptionType: "",
      mgolaId: [],
      mgolaIdType: '',
      supplierIdVal: [],
      pricErosionId: [],
      pricErosionIdType: '',
      proWarrantyColId: [],
      proWarrantyColType: '',
      currencyIdType: ''

      /* VP3-3 end */
    };
    if (data.length) {
      data.forEach((element) => {
        if (element.type === "supplier") {
          removedFilterObj.suplierId.push(element.key);
        } else if (element.type === "trading") {
          removedFilterObj.tradingId.push(element.key);
        }
        // VOD-1652
        else if (
          element.type === "catalogue" ||
          element.type === "l1Category"
        ) {
          removedFilterObj.catalogueId.push(element.key);
        } else if (element.type === "opco") {
          removedFilterObj.opcoId.push(element.key);
        } else if (element.type === "projectId") {
          removedFilterObj.projectId.push(element.key);
          removedFilterObj.projectIdType = element.filterType;
        } else if (element.type === "projectIdCol") {
          removedFilterObj.projectIdCol.push(element.key);
        } else if (element.type === "projectDescription") {
          removedFilterObj.projectDescription.push(element.key);
          removedFilterObj.projectDescriptionType = element.filterType;
        } else if (element.type === "projectOwner") {
          removedFilterObj.projectOwner.push(element.key);
          removedFilterObj.projectOwnerType = element.filterType;
        } else if (element.type === "material" || element.type === "l4Group") {
          removedFilterObj.materialId.push(element.key);
        } else if (element.type === "paStatus") {
          removedFilterObj.paStatusId.push(element.key);
        } else if (element.type === "catalogueStatus") {
          removedFilterObj.catalogueStatusId.push(element.key);
        } else if (element.type === "validity") {
          removedFilterObj.validity.push(element.key);
        } else if (element.type === "taskType") {
          removedFilterObj.taskTypeId.push(element.key);
        } else if (element.type === "taskStatus") {
          removedFilterObj.taskStatusId.push(element.key);
        } else if (element.type === "parentSupplier") {
          removedFilterObj.parentSupplierId.push(element.key);
        } else if (element.type === "integrationStatus") {
          removedFilterObj.integrationStatusId.push(element.key);
        } else if (element.type === "currency") {
          removedFilterObj.currencyId.push(element.key);
          removedFilterObj.currencyIdType = element.filterType;
        } else if (element.type === "amount") {
          removedFilterObj.amountId.push(element.key);
        } else if (element.type === "physicalStatus") {
          removedFilterObj.physicalNonphysicalId.push(element.key); //vp3-3
        } else if (element.type === "referenceName") {
          removedFilterObj.masterAgreementNameId.push(element.key); //vp3-3
          removedFilterObj.masterAgreementNameType = element.filterType;
        } else if (element.type === "supplierName") {
          removedFilterObj.supplierNameId.push(element.key); //vp3-3
          removedFilterObj.supplierNameType = element.filterType;
        } else if (element.type === "opcoName") {
          removedFilterObj.opcoNameId.push(element.key); //vp3-3
          removedFilterObj.opcoNameType = element.filterType;
        } else if (element.type === "tradingCurrencyKey") {
          removedFilterObj.tradingCurrencyKeyId.push(element.key); //vp3-3
          removedFilterObj.tradingCurrencyKeyType = element.filterType;
        } else if (element.type === "validFrom") {
          removedFilterObj.validFromDateId.push(element.key); //vp3-3
          removedFilterObj.validFromDateType = element.filterType;
        } else if (element.type === "validTo") {
          removedFilterObj.validToDateId.push(element.key); //vp3-3
          removedFilterObj.validToDateType = element.filterType;
        } else if (element.type === "vpcOwner") {
          removedFilterObj.vpcOwnerId.push(element.key); //vp3-3
          removedFilterObj.vpcOwnerType = element.filterType;
        } else if (element.type === "vodafoneApprover") {
          removedFilterObj.vodafoneApproverId.push(element.key); //vp3-3
          removedFilterObj.vodafoneApproverType = element.filterType;
        } else if (element.type === "supplierOwner") {
          removedFilterObj.supplierOwnerId.push(element.key); //vp3-3
          removedFilterObj.supplierOwnerType = element.filterType;
        } else if (element.type === "createdAt") {
          removedFilterObj.createdAtId.push(element.key); //vp3-3
          removedFilterObj.createdAtType = element.filterType;
        } else if (element.type === "createdBy") {
          removedFilterObj.createdById.push(element.key); //vp3-3
          removedFilterObj.createdByType = element.filterType;
        } else if (element.type === "updatedAt") {
          removedFilterObj.updatedAtId.push(element.key); //vp3-3
          removedFilterObj.updatedAtType = element.filterType;
        } else if (element.type === "updatedBy") {
          removedFilterObj.updatedById.push(element.key); //vp3-3
          removedFilterObj.updatedByType = element.filterType;
        } else if (element.type === "isVisible") {
          removedFilterObj.isVisibleId.push(element.key); //vp3-3
          removedFilterObj.isVisibleType = element.filterType;
        } else if (element.type === "isSupplierOnboarded") {
          removedFilterObj.isSupplierOnboardedId.push(element.key); //vp3-3
          removedFilterObj.isSupplierOnboardedType = element.filterType;
        } else if (element.type === "paStatusFilter") {
          removedFilterObj.paStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.paStatusFilterType = element.filterType;
        } else if (element.type === "lineItemStatusFilter") {
          removedFilterObj.lineItemStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.lineItemStatusFilterType = element.filterType;
        } else if (element.type === "integrationStatusFilter") {
          removedFilterObj.integrationStatusFilterId.push(element.key); //vp3-3
          removedFilterObj.integrationStatusFilterType = element.filterType;
        } else if (element.type === "lastIntegratedVersion") {
          removedFilterObj.lastIntegratedVersionId.push(element.key); //vp3-284
          removedFilterObj.lastIntegratedVersionFilterType = element.filterType;
        } else if (element.type === "supplierId") {
          removedFilterObj.supplierId.push(element.key); //vp3-284 
          removedFilterObj.supplierIdVal.push(element.key);
          removedFilterObj.supplierIdType = element.filterType;
        } else if (element.type === "outlineAgreementNumber") {
          removedFilterObj.outlineAgreementId.push(element.key); //vp3-284
          removedFilterObj.outlineAgreementNumberType = element.filterType;
        } else if (element.type === "contractId") {
          removedFilterObj.contractId.push(element.key);
        } else if (element.type === "olaId") {
          removedFilterObj.olaIds.push(element.key);
        } else if (element.type === "catalogueIdentifier") {
          removedFilterObj.catalogueIdentifier.push(element.key);
        } else if (element.type === "catalogueName") {
          removedFilterObj.catalogueName.push(element.key);
        } else if (element.type === "delegatedByUser") {
          removedFilterObj.primaryDelegatorId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegatorType = element.filterType;
        } else if (element.type === "delegatedToUser") {
          removedFilterObj.primaryDelegateId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegateType = element.filterType;
        } else if (element.type === "secondaryDelegatedBy") {
          removedFilterObj.secondaryDelegatorId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegatorType = element.filterType;
        } else if (element.type === "secondaryDelegatedTo") {
          removedFilterObj.secondaryDelegateId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegateType = element.filterType;
        } else if (element.type === "secondaryStartDate") {
          removedFilterObj.secondaryStartDateId.push(element.key); //vp3-3
          removedFilterObj.secondaryStartDateType = element.filterType;
        } else if (element.type === "secondaryEndDate") {
          removedFilterObj.secondaryEndDateId.push(element.key); //vp3-3
          removedFilterObj.secondaryEndDateType = element.filterType;
        } else if (element.type === "delegationType") {
          removedFilterObj.delegationTypeId.push(element.key); //vp3-3
          removedFilterObj.delegationTypeType = element.filterType;
        } else if (element.type === "primaryDelegatedFrom") {
          removedFilterObj.primaryDelegatedFromId.push(element.key); //vp3-3
          removedFilterObj.primaryDelegatedFromType = element.filterType;
        } else if (element.type === "secondaryDelegatedFrom") {
          removedFilterObj.secondaryDelegatedFromId.push(element.key); //vp3-3
          removedFilterObj.secondaryDelegatedFromType = element.filterType;
        } else if (element.type === "materialCategory") {
          removedFilterObj.materialCategoryId.push(element.key); //vp3-3
          removedFilterObj.materialCategoryType = element.filterType;
        } else if (element.type === "contractIdId") {
          removedFilterObj.contractIdId.push(element.key); //vp3-3
          removedFilterObj.contractIdType = element.filterType;
        } else if (element.type === "paymentTerms") {
          removedFilterObj.paymentTermsId.push(element.key); //vp3-3
          removedFilterObj.paymentTermsType = element.filterType;
        } else if (element.type === "completeMpnSpn") {
          removedFilterObj.completeMpnSpnId.push(element.key); //vp3-3
          removedFilterObj.completeMpnSpnType = element.filterType;
        } else if (element.type === "inProgressMpnSpn") {
          removedFilterObj.inProgressMpnSpnId.push(element.key); //vp3-3
          removedFilterObj.inProgressMpnSpnType = element.filterType;
        } else if (element.type === "manufacturer") {
          removedFilterObj.manufacturerId.push(element.key); //vp3-3
          removedFilterObj.manufacturerType = element.filterType;
        } else if (element.type === "materialSupplier") {
          removedFilterObj.materialSupplierId.push(element.key); //vp3-3
          removedFilterObj.materialSupplierType = element.filterType;
        } else if (element.type === "companyCode") {
          removedFilterObj.companyCodeId.push(element.key); //vp3-3
          removedFilterObj.companyCodeType = element.filterType;
        } else if (element.type === "manufacturerPartNumber") {
          removedFilterObj.manufacturerPartNumberId.push(element.key); //vp3-3
          removedFilterObj.manufacturerPartNumberType = element.filterType;
        } else if (element.type === "materialGroup") {
          removedFilterObj.materialGroupId.push(element.key); //vp3-3
          removedFilterObj.materialGroupType = element.filterType;
        } else if (element.type === "volumeUnit") {
          removedFilterObj.volumeUnitId.push(element.key); //vp3-3
          removedFilterObj.volumeUnitType = element.filterType;
        } else if (element.type === "itemStatus") {
          removedFilterObj.itemStatusId.push(element.key); //vp3-3
          removedFilterObj.itemStatusType = element.filterType;
        } else if (element.type === "hlwUnit") {
          removedFilterObj.hlwUnitId.push(element.key); //vp3-3
          removedFilterObj.hlwUnitType = element.filterType;
        } else if (element.type === "sapMaterialId") {
          removedFilterObj.sapMaterialId.push(element.key); //vp3-3
          removedFilterObj.sapMaterialIdType = element.filterType;
        } else if (element.type === "supplierPartNumber") {
          removedFilterObj.supplierPartNumberId.push(element.key); //vp3-3
          removedFilterObj.supplierPartNumberType = element.filterType;
        } else if (element.type === "materialDescription") {
          removedFilterObj.materialDescriptionId.push(element.key); //vp3-3
          removedFilterObj.materialDescriptionType = element.filterType;
        } else if (element.type === "materialLongDescription") {
          removedFilterObj.materialLongDescriptionId.push(element.key); //vp3-3
          removedFilterObj.materialLongDescriptionType = element.filterType;
        } else if (element.type === "buom") {
          removedFilterObj.boumId.push(element.key); //vp3-3
          removedFilterObj.boumType = element.filterType;
        } else if (element.type === "commodityCode") {
          removedFilterObj.commodityCodeId.push(element.key); //vp3-3
          removedFilterObj.commodityCodeType = element.filterType;
        } else if (element.type === "grossWeight") {
          removedFilterObj.grossWeightId.push(element.key); //vp3-3
          removedFilterObj.grossWeightType = element.filterType;
        } else if (element.type === "netWeight") {
          removedFilterObj.netWeightId.push(element.key); //vp3-3
          removedFilterObj.netWeightType = element.filterType;
        } else if (element.type === "characteristicValue") {
          removedFilterObj.characteristicValueId.push(element.key); //vp3-3
          removedFilterObj.characteristicValueType = element.filterType;
        } else if (element.type === "subCharacteristicValue") {
          removedFilterObj.subCharacteristicValueId.push(element.key); //vp3-3
          removedFilterObj.subCharacteristicValueType = element.filterType;
        } else if (element.type === "length") {
          removedFilterObj.lengthId.push(element.key); //vp3-3
          removedFilterObj.lengthType = element.filterType;
        } else if (element.type === "width") {
          removedFilterObj.widthId.push(element.key); //vp3-3
          removedFilterObj.widthType = element.filterType;
        } else if (element.type === "height") {
          removedFilterObj.heightId.push(element.key); //vp3-3
          removedFilterObj.heightType = element.filterType;
        } else if (element.type === "volume") {
          removedFilterObj.volumeId.push(element.key); //vp3-3
          removedFilterObj.volumeType = element.filterType;
        } else if (element.type === "nature") {
          removedFilterObj.natureId.push(element.key); //vp3-3
          removedFilterObj.natureType = element.filterType;
        } else if (element.type === "serializationProfile") {
          removedFilterObj.serializationId.push(element.key); //vp3-3
          removedFilterObj.serializationType = element.filterType;
        } else if (element.type === "usefulLife") {
          removedFilterObj.usefulLifeId.push(element.key); //vp3-3
          removedFilterObj.usefulLifeType = element.filterType;
        } else if (element.type === "ean") {
          removedFilterObj.eanId.push(element.key); //vp3-3
          removedFilterObj.eanType = element.filterType;
        } else if (element.type === "gam") {
          removedFilterObj.gamId.push(element.key); //vp3-3
          removedFilterObj.gamType = element.filterType;
        } else if (element.type === "auom1") {
          removedFilterObj.auom1Id.push(element.key); //vp3-3
          removedFilterObj.auom1Type = element.filterType;
        } else if (element.type === "unitConversion1") {
          removedFilterObj.unitConversion1Id.push(element.key); //vp3-3
          removedFilterObj.unitConversion1Type = element.filterType;
        } else if (element.type === "auomQty1") {
          removedFilterObj.auomqty1Id.push(element.key); //vp3-3
          removedFilterObj.auomqty1Type = element.filterType;
        } else if (element.type === "auom2") {
          removedFilterObj.auom2Id.push(element.key); //vp3-3
          removedFilterObj.auom2Type = element.filterType;
        } else if (element.type === "unitConversion2") {
          removedFilterObj.unitConversion2Id.push(element.key); //vp3-3
          removedFilterObj.unitConversion2Type = element.filterType;
        } else if (element.type === "auomQty2") {
          removedFilterObj.auomqty2Id.push(element.key); //vp3-3
          removedFilterObj.auomqty2Type = element.filterType;
        } else if (element.type === "auom3") {
          removedFilterObj.auom3Id.push(element.key); //vp3-3
          removedFilterObj.auom3Type = element.filterType;
        } else if (element.type === "unitConversion3") {
          removedFilterObj.unitConversion3Id.push(element.key); //vp3-3
          removedFilterObj.unitConversion3Type = element.filterType;
        } else if (element.type === "auomQty3") {
          removedFilterObj.auomqty3Id.push(element.key); //vp3-3
          removedFilterObj.auomqty3Type = element.filterType;
        } else if (element.type === "stackingNorms") {
          removedFilterObj.stackingNormsId.push(element.key); //vp3-3
          removedFilterObj.stackingNormsType = element.filterType;
        } else if (element.type === "stackingNumber") {
          removedFilterObj.stackingNumberId.push(element.key); //vp3-3
          removedFilterObj.stackingNumberType = element.filterType;
        } else if (element.type === "productFamilyCode") {
          removedFilterObj.productFamilyId.push(element.key); //vp3-3
          removedFilterObj.productFamilyType = element.filterType;
        } else if (element.type === "eccn") {
          removedFilterObj.eccnId.push(element.key); //vp3-3
          removedFilterObj.eccnType = element.filterType;
        } else if (element.type === "endOfLife") {
          removedFilterObj.endOfLifeId.push(element.key); //vp3-3
          removedFilterObj.endOfLifeType = element.filterType;
        } else if (element.type === "endOfSupport") {
          removedFilterObj.endOfSupportId.push(element.key); //vp3-3
          removedFilterObj.endOfSupportType = element.filterType;
        } else if (element.type === "proProjectId") {
          removedFilterObj.proProjectIdId.push(element.key); //vp3-3
          removedFilterObj.proProjectIdType = element.filterType;
        } else if (element.type === "proSupplierID") {
          removedFilterObj.proSupplierIDId.push(element.key); //vp3-3
          removedFilterObj.proSupplierIDType = element.filterType;
        } else if (element.type === "proMGOLA") {
          removedFilterObj.proMGOLAId.push(element.key); //vp3-3
          removedFilterObj.proMGOLAType = element.filterType;
        } else if (element.type === "proProjectDescription") {
          removedFilterObj.proProjectDescriptionId.push(element.key); //vp3-3
          removedFilterObj.proProjectDescriptionType = element.filterType;
        }
        // this case added for task filter
        else if (element.type === "type") {
          removedFilterObj.taskType_Id.push(element.key); //vp3-3          
        } else if (element.type === "taskGenerated") {
          removedFilterObj.taskGeneratedId.push(element.key); //vp3-3          
        } else if (element.type === "taskObject") {
          removedFilterObj.taskObjectId.push(element.key); //vp3-3          
        } else if (element.type === "priceAgreementId") {
          removedFilterObj.masterAgreementNameId.push(element.key); //vp3-3          
        } else if (element.type === "taskSupplier") {
          removedFilterObj.taskSupplierId.push(element.key); //vp3-3          
        } else if (element.type === "taskOwner") {
          removedFilterObj.taskOwnerId.push(element.key); //vp3-3          
        } else if (element.type === "agreementStatus") {
          removedFilterObj.agreementStatusId.push(element.key); //vp3-3          
        }
        // end task filter case 

        // project id  case 
        else if (element.type === "mgocol") {
          removedFilterObj.mgolaId.push(element.key); //vp3-3 
          removedFilterObj.mgolaIdType = element.filterType;
        } else if (element.type === "validFromDate") {
          removedFilterObj.validFromDateId.push(element.key); //vp3-3
          removedFilterObj.validFromDateType = element.filterType;
        } else if (element.type === "validToDate") {
          removedFilterObj.validToDateId.push(element.key); //vp3-3
          removedFilterObj.validToDateType = element.filterType;
        }
        else if (element.type === "validToDate") {
          removedFilterObj.validToDateId.push(element.key); //vp3-3
          removedFilterObj.validToDateType = element.filterType;
        } else if (element.type === "pacr") {
          removedFilterObj.pricErosionId.push(element.key); //vp3-3
          removedFilterObj.pricErosionIdType = element.filterType;
        }
        else if (element.type === "warranty") {
          removedFilterObj.proWarrantyColId.push(element.key); //vp3-3
          removedFilterObj.proWarrantyColType = element.filterType;
        }

      });
      return removedFilterObj;
    }
    return removedFilterObj;
  }

  /**
   *Prepairing excel sheet data for download
   *
   * @param {*} configObj
   * @return {*}
   * @memberof FilterServices
   */
  public prepairSheetData(configObj) {
    const data = [];
    let length = 0;
    Object.keys(configObj).map((key) => {
      if (configObj[key].length > length) {
        length = configObj[key].length;
      }
    });
    for (let i = 0; i < length; i++) {
      const array = ["", "", "", ""];
      Object.keys(configObj).map((key) => {
        switch (key) {
          case "tradingModel":
            configObj[key][i] !== undefined
              ? (array[0] = configObj[key][i]["tradingModel"])
              : (array[0] = "");
            break;
          case "suppliers":
            configObj[key][i] !== undefined
              ? (array[1] = configObj[key][i]["supplierName"])
              : (array[1] = "");
            break;
          case "localMarket":
            configObj[key][i] !== undefined
              ? (array[2] =
                configObj[key][i]["opcoId"] +
                "-" +
                configObj[key][i]["opcoName"])
              : (array[2] = "");
            break;
          case "materialGroups":
            // tslint:disable-next-line:max-line-length
            configObj[key][i] !== undefined
              ? (array[3] =
                configObj[key][i]["materialGroupId"] +
                "-" +
                configObj[key][i]["materialGroupName"])
              : (array[3] = "");
            break;
        }
      });
      data.push(array);
    }
    return data;
  }
}
