import { Component, OnInit, Input } from '@angular/core';

/**
 *Custom component for show the  dynamic page header name and its icons 
 *
 * @export
 * @class TitleInfoComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-title-info',
  templateUrl: './title-info.component.html',
  styleUrls: ['./title-info.component.scss']
})
export class TitleInfoComponent implements OnInit {
  public value = 'Clear me';
  @Input() public items: {
    header: string,
    content: string,
    SubContent: string,
    image: string
    edit: string;
    url:string
  }[] = [];

  // tslint:disable-next-line:no-empty
  constructor() { }

  public ngOnInit() {
  }

}
