import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../_config';
import { Observable, Subject } from 'rxjs';
import {
  IAccountExistance,
  IVerifyOTP,
  ILoginRes,
  IUserInformation,
  IForgetPass
} from '../_models/auth';
import { IServerResponse } from '../_models/response';
// tslint:disable-next-line:import-blacklist
const { accessUrl } = AppConfig;

@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  constructor(private http: HttpClient) { }
  private goback = new Subject<any>();
  private leftMenuList: any;
  private email: string;
  private role: string;
  private organization: string;
  private fullName: string;
  private token: string;
  private userData: object;
  private userId: string;
  private isRefreshTokenRequestSent = false;
  private setPasswordUrl: string = accessUrl + 'auth/set-password';
  private loginUrl: string = accessUrl + 'auth';
  private twoFaUrl: string = accessUrl + 'auth/verify-2fa';
  private ssoUrl: string = accessUrl + 'auth/sso';
  private changePasswordUrl: string = accessUrl + 'auth/change-password';
  private forgetPasswordUrl: string = accessUrl + 'auth/forget-password';
  private refreshTokenUrl: string = accessUrl + 'auth/refresh-access-token';
  private termAndConditionUrl: string = accessUrl + 'auth/accept-terms-and-condition';
  private logoutUrl: string = accessUrl + 'auth/logout';
  private menuUrl: string = accessUrl + 'auth/left-menu';

  /**
   * Function for save back URL(navigate to back link)
   *
   * @param {*} data
   * @memberof AuthServices
   */
  public goUrlBack(data: any) {
    this.goback.next({ result: data });
  }

  /**
   *Returns the back URL(navigate to back link) as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof AuthServices
   */
  public getGoUrlBack(): Observable<any> {
    return this.goback.asObservable();
  }

  /**
   *Api for generating refresh token once current token is expired
   *
   * @memberof AuthServices
   */
  public refreshTokenGeneration() {
    if (!this.isRefreshTokenRequestSent) {
      const data: IUserInformation = this.getUserData();
      this.isRefreshTokenRequestSent = true;
      const rToken = { refreshToken: data.refreshToken };
      this.http.post(
        this.refreshTokenUrl,
        rToken,
        this.getAccessHeaders()
      ).subscribe((response: any) => {
        this.setUserData(response.result);
        this.isRefreshTokenRequestSent = false;
        // tslint:disable-next-line: align
      }, () => {
        this.isRefreshTokenRequestSent = false;
      });
    }
  }

  /**
   *Api for creating new password
   *
   * @param {{
   *     email: string;
   *     password: string;
   *     verificationCode: string;
   *   }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public setPassword(data: {
    email: string;
    password: string;
    verificationCode: string;
  }) {
    return this.http.post<IServerResponse<any>>(this.setPasswordUrl, data, {});
  }

  /**
   * Api function to get menu list according to the user role
   * This dynamic menu feature not implemented in this project version
   * @return {*} 
   * @memberof AuthServices
   */
  public getLeftMenu() {
    return this.http.get(this.menuUrl, this.getAccessHeaders());
  }

  /**
   * function to set menu list for the logged user
   * This dynamic menu feature not implemented in this project version
   * @param {*} data
   * @memberof AuthServices
   */
  public setMenuItems(data: any) {
    this.leftMenuList = data;
  }

  /**
   * function to get menu list for the logged user
   * This dynamic menu feature not implemented in this project version
   * @return {*}  {*}
   * @memberof AuthServices
   */
  public getMenuItems(): any {
    return this.leftMenuList;
  }

  /**
   * Api fucntion for login
   *
   * @param {{ email: string; password: string }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public login(data: { email: string; password: string }) {
    return this.http.post<IServerResponse<ILoginRes>>(this.loginUrl, data, {});
  }

  /**
   *Api function for Accept/Reject terms and conditions
   *
   * @param {{ email: string, verificationCode: string,status:boolean }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public acceptTermsAndCondition(data: { email: string, verificationCode: string, status: boolean }) {
    return this.http.post(this.termAndConditionUrl, data, {});
  }

  /**
   *Api function for verify the OTP
   *
   * @param {{ requestId: string; otp: string }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public verifyTwoFa(data: { requestId: string; otp: string }) {
    return this.http.post<IServerResponse<IUserInformation>>(
      this.twoFaUrl,
      data,
      {}
    );
  }

  /**
   *Api function for sso call
   *
   * @param {{ code: string }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public ssoCall(data: { code: string }) {
    return this.http.post<IServerResponse<IUserInformation>>(
      this.ssoUrl,
      data,
      {}
    );
  }

  /**
   *Api function for change the current password
   *
   * @param {{ password: string, oldPassword: string }} data
   * @return {*} 
   * @memberof AuthServices
   */
  public changePassword(data: { password: string, oldPassword: string }) {
    return this.http.post<IServerResponse<any>>(this.changePasswordUrl, data, this.getAccessHeaders());
  }

  /**
   *Function returns logged user email, stored in the local storage
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getEmail(): string {
    return this.email || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).email : '');
  }

  /**
   *Function for set the logged user email
   *
   * @param {string} email
   * @memberof AuthServices
   */
  public setEmail(email: string): void {
    this.email = email;
  }

  /**
   *Function returns logged user Full  name, stored in the local storage
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getFullName(): string {
    return this.fullName || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).fullName : '');
  }

  /**
   *Function returns logged user Token, stored in the local storage
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getToken(): string {
    return this.token || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).token : '');
  }

  /**
   *Function returns logged user Details, stored in the local storage
   *
   * @return {*}  {IUserInformation}
   * @memberof AuthServices
   */
  public getUserData(): IUserInformation {
    return this.userData || JSON.parse(localStorage.getItem('_u'));
  }

  /**
   *Function for save user details in local storage after successfully logged in
   *
   * @param {IUserInformation} userData
   * @memberof AuthServices
   */
  public setUserData(userData: IUserInformation): void {
    localStorage.setItem('_u', JSON.stringify(userData));
    this.userData = userData;
  }


  /**
   *Function returns logged user role, stored in the local storage
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getRole(): string {
    return this.role || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).role : '');
  }

  /**
   *Function returns logged user Organization, stored in the local storage
   *
   * @return {*}  {(string | number)}
   * @memberof AuthServices
   */
  public getOrganization(): string | number {
    return this.organization || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).organizationId : '');
  }

  /**
   *Function returns logged user ID, stored in the local storage
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getUserId(): string {
    return this.userId || (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).uuid : '');
  }


  /**
   *TODO : Absolute function need to check & clear
   *
   * @param {string} fullName
   * @memberof AuthServices
   */
  public setTempFullName(fullName: string) {
    localStorage.setItem('fullName', fullName);
  }

  /**
   *TODO : Absolute function need to check & clear
   *
   * @return {*}  {string}
   * @memberof AuthServices
   */
  public getTempFullName(): string {
    return localStorage.getItem('fullName');
  }

  /**
   *Function that clear the logged user data from local storage after logout or once session expired
   *
   * @memberof AuthServices
   */
  public clearUserData(): void {
    this.token = null;
    this.organization = null;
    this.role = null;
    this.fullName = null;
    this.userData = null;
    this.email = null;
    localStorage.removeItem('_u');
    localStorage.removeItem('fullName');
    localStorage.removeItem('Pa_filters');
    localStorage.removeItem('Pa_PageConfig');
    localStorage.removeItem('catalogue_filters');
    localStorage.removeItem('delegatedPA_filters');
  }

  /**
   * common function for set headers fo api
   *
   * @param {string} [contentType='application/json']
   * @return {*}  {{ headers: HttpHeaders }}
   * @memberof AuthServices
   */
  public getAccessHeaders(contentType: string = 'application/json'): { headers: HttpHeaders } {
    const authorization = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': authorization,
        'Content-Type': contentType,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '-1',
        'If-Modified-Since': '0',
      })
    };

    return httpOptions;
  }

  public getAccessHeaders2(contentType: string = 'application/json'): { headers: HttpHeaders } {
    const authorization = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': authorization,
        'Content-Type': contentType,
        'Cache-Control': 'no-cache',
        'trust-token': 'KOZy5aSUFsNB2qN5345354yymUBSs6es3qHoFpGkec75RCeBb8cpKauGefw5qy4',
        Pragma: 'no-cache',
        Expires: '-1',
        'If-Modified-Since': '0',
      })
    };

    return httpOptions;
  }

  /**
   *
   * common function for set headers fo api to access file content
   * @return {*}  {object}
   * @memberof AuthServices
   */
  public getAccessHeadersForFile(): object {
    const authorization = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': authorization,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '-1',
        'If-Modified-Since': '0',
      })
    };

    return httpOptions;
  }

  public getAccessHeadersForFileNetwork(): object {
    const authorization = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': authorization,
        'Cache-Control': 'no-cache',
        'trust-token': 'KOZy5aSUFsNB2qN5345354yymUBSs6es3qHoFpGkec75RCeBb8cpKauGefw5qy4',
        Pragma: 'no-cache',
        Expires: '-1',
        'If-Modified-Since': '0',
      })
    };

    return httpOptions;
  }

  /**
   *Api function for forget password
   *
   * @param {*} email
   * @return {*} 
   * @memberof AuthServices
   */
  public forgetPassword(email) {
    return this.http.post<IServerResponse<IForgetPass>>(
      this.forgetPasswordUrl,
      { email },
      {}
    );
  }

  /**
   *Api function for logout
   *
   * @return {*} 
   * @memberof AuthServices
   */
  public logout() {
    return this.http.post(
      this.logoutUrl,
      null,
      this.getAccessHeaders()
    );
  }

  /**
   *Function returns logged user Buyer status, stored in the local storage
   *
   * @return {*} 
   * @memberof AuthServices
   */
  public getIsBuyer() {
    return (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).isBuyer : true);
  }
  public getUserCategory() {
    return (JSON.parse(localStorage.getItem('_u')) ? JSON.parse(localStorage.getItem('_u')).category : 'Network Workspace,IT & CS');
  }

  public isSupplierUser() {
    if (this.getRole() == 'SUPPLIER_ADMIN' || this.getRole() == 'SUPPLIER_MANAGER') {
      return true
    } else {
      return false
    }
  }
}
