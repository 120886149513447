import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

/**
 *Component for show the  progress bar 
 *
 * @export
 * @class ProgressBarDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-progress-bar-dialog',
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.scss']
})
export class ProgressBarDialogComponent implements OnInit {

  public header: string;
  public progress: number;
  public icon: string;
  public redirection: string;
  public buttonText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProgressBarDialogComponent>,
  ) {
    this.header = data && data.header;
    this.progress = data && data.progress;
    this.icon = data && data.icon;
    this.redirection = data && data.redirection;
    this.buttonText = data && data.buttonText;
  }


  ngOnInit() {
  }
  public close(value) {
    this.dialogRef.close(value);
  }
}
