import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServices } from '../../_services/auth.service';
import { LoaderService } from '../../_services/loader.service';
import { AppConfig } from '../../_config';
import { SSOService } from '../../_services/sso.service';
import { PriceManagerServices } from 'src/app/_services/price-manager.service';
const { contactSupportLink } = AppConfig;
import { MatDialog } from '@angular/material';
/**
 *Component for login page
 *
 * @export
 * @class LoginComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public passwordForm: FormGroup;
  public hidePassword: boolean;
  public email: string;
  public password: string;
  public isRequestIdSet = false;
  public requestId: string;
  public fullName: string;
  public errorOtpMsg: string;
  public errorSsoMsg: string;
  public otpText = '';
  public otpTitle: string;
  public otpMsg: string;
  public errorSubmitMsg = '';
  public isOtpResend = false;
  public finalTime = '';
  public contactLink: any;
  public validEmail = false;
  public ssoClicked = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authServices: AuthServices,
    private loaderService: LoaderService,
    private ssoService: SSOService,
    public priceManagerService: PriceManagerServices,
    public dialog: MatDialog

  ) {
    this.email = this.route.snapshot.params.email || this.authServices.getEmail();
  }

  public ngOnInit() {
    this.dialog.closeAll()
    this.contactLink = contactSupportLink;
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      otp: ['']
    });
    this.hidePassword = true;
    this.fullName = this.authServices.getTempFullName();
    this.getFragmentedCode();
  }

  public submit(email: string, password: string) {
    this.password = password;
    this.email = email;
    this.errorSubmitMsg = '';
    this.loaderService.startLoading();
    this.authServices.login({ email, password }).subscribe(
      response => {
        if (response.result.requestId) {
          this.isRequestIdSet = true;
          this.requestId = response.result.requestId;
        }
        this.loaderService.stopLoading();
      },
      error => {
        this.errorSubmitMsg = error.error.message;
        this.loaderService.stopLoading();
      }
    );
  }
  public onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || window['clipboardData'];
    let pastedText = clipboardData.getData('text');
    let numTxt = pastedText.replace(/\D/g, '');
    this.otpText = numTxt.toString().length > 6 ? numTxt : numTxt.substring(0, 6);
  }
  public onlyNumberKey(event: { charCode: number; }) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43;
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public twoFaLogin(data: { otp: string; }) {
    this.loaderService.startLoading();
    this.authServices.verifyTwoFa({ requestId: this.requestId, otp: data.otp }).subscribe(
      response => {
        if (response.result) {
          let workspaceValue = response.result.category.split(',').length > 0 ? response.result.category.split(',')[0] : response.result.category

          this.priceManagerService.setWorkspaceValue(workspaceValue);
          if (response.result.role.includes("COMMERCIAL_CONTRACT_MANAGER")) {
            this.authServices.setUserData(response.result);
            this.router.navigate(['/dashboard/value-leakage']);
          } else {
            this.authServices.setUserData(response.result);
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.loaderService.stopLoading();
          this.errorOtpMsg = 'Invalid OTP';
        }
      },
      error => {
        this.loaderService.stopLoading();
        this.errorOtpMsg = error.error.message;
      }
    );
  }

  public reSendOtp() {
    this.errorOtpMsg = '';
    this.submit(this.email, this.password);
  }

  public timer() {
    this.isOtpResend = true;
    // Set the date we're counting down to
    const countDownDate = new Date().getTime() + 2 * 60 * 1000;

    // Update the count down every 1 second
    const x = setInterval(() => {

      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      this.finalTime = `${minutes} m ${seconds} s`;
      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        this.finalTime = '0m 0s';
        this.isOtpResend = false;
      }
      // tslint:disable-next-line: align
    }, 1000);
  }

  public forgetPassword() {
    this.router.navigate(['/forgot-password']);
  }

  public eventListener(event) {
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      document.getElementById('login').click();
    }
  }

  public async ssoLogin() {
    this.ssoClicked = true;
    this.loaderService.startLoading();
    try {
      await this.ssoService.login();
    } catch (err) {
      this.loaderService.stopLoading();
      this.ssoClicked = false;
    }
  }

  private getFragmentedCode() {
    let code = null;
    const urlFragment = this.route.snapshot.fragment;
    if (urlFragment) {
      urlFragment.split('&').forEach((data) => {
        if (data && data.includes('code=')) {
          code = data.split('code=')[1];
          return;
        }
      });
    }

    if (!urlFragment) {
      code = this.route.snapshot.queryParams && this.route.snapshot.queryParams.code;
    }

    if (code) {
      this.apiSSOCall(code);
    } else {
      this.ssoClicked = false;
      // TODO : do more actions
    }
  }

  private apiSSOCall(code: string) {
    setTimeout(() => {
      this.loaderService.startLoading();
      // tslint:disable-next-line: align
    }, 10);
    this.authServices.ssoCall({ code }).subscribe(
      response => {
        if (response.result) {
          this.authServices.setUserData(response.result);
          this.router.navigate(['/dashboard']);
        } else {
          this.loaderService.stopLoading();
          this.errorSsoMsg = 'Invalid Login';
        }
      },
      error => {
        this.loaderService.stopLoading();
        this.errorSsoMsg = error.error.message;
      }
    );
  }

}
