import { Component, OnInit } from "@angular/core";
import { PanelServices } from "../../_services/panel.service";
import { AuthServices } from "../../_services/auth.service";
import { Router } from "@angular/router";
import { AppConfig } from "../../_config";
import { applicationRoles } from "../../_config/constants";
/* [VOD-1695] [ALM id-242] start */
import { WarningComponent } from "../warning/warning.component";
import { MatDialogConfig, MatDialog } from "@angular/material";
/* [VOD-1695] [ALM id-242] end */
import { LoaderService } from "../../_services/loader.service"; // [VOD-1743]
import { PriceManagerServices } from "../../_services/price-manager.service";

/**
 *Navigation menu component for all pages
 *
 * @export
 * @class NavLeftComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-nav-left",
  templateUrl: "./nav-left.component.html",
  styleUrls: ["./nav-left.component.scss"],
})
export class NavLeftComponent implements OnInit {
  public router1: string;
  public name: string;
  public role: string;
  public roleDisply: string;
  public learnMoreLink = AppConfig.learnMoreLink; /* [VOD-1697] [ALM ID-226]  */
  public isBuyer: boolean;
  public menuList: any;
  public isBulkUpload: any;

  constructor(
    public panelService: PanelServices,
    private authServices: AuthServices,
    public dialog: MatDialog /* [VOD-1695] [ALM id-242]  */,
    public router: Router,
    private loaderService: LoaderService, // [VOD-1743]
    public pricemanagerServices: PriceManagerServices
  ) { }

  public ngOnInit() {
    this.isBuyer = this.authServices.getIsBuyer();
    this.setDetails();
    this.router1 = this.router.url;
    this.role = this.authServices.getRole();
    if (this.role === "BUSINESS_USER") {
      this.roleDisply = "Business User";
    } else if (this.role === "CATEGORY_MANAGER") {
      this.roleDisply = "Category Manager";
    } else if (this.role === "COMMERCIAL_CONTRACT_MANAGER") {
      this.roleDisply = "Commercial Contract Manager";
    } else if (this.role === "LOCAL_MARKET_MANAGER") {
      this.roleDisply = "Local Market Manager";
    } else if (this.role === "LOCAL_MARKET_OVERSEER") {
      this.roleDisply = "Local Market Overseer";
    } else if (this.role === "SOURCING_SPECIALIST") {
      this.roleDisply = "Sourcing Specialist";
    } else if (this.role === "CATALOGUE_SPECIALIST") {
      this.roleDisply = "Catalogue Specialst"; // VPC-65
    } else if (this.role === "SUB_ADMIN") {
      this.roleDisply = "Sub Admin";
    } else if (this.role === "SUPER_ADMIN") {
      this.roleDisply = "Super Admin";
    } else if (this.role === "LOCAL_SUPPLIER_MANAGER") {
      this.roleDisply = "Local Supplier Manager";
    } else if (this.role === "SUPPLIER_ADMIN") {
      this.roleDisply = "Supplier Admin";
    } else if (this.role === "SUPPLIER_MANAGER") {
      this.roleDisply = "Supplier Manager";
    } else if (this.role === "CHANNEL_ADMIN") {
      this.roleDisply = "Channel Admin";
    } else if (this.role === "SUPPLIER_REVIEWER") {
      this.roleDisply = "Supplier Reviewer";
    } else if (this.role === "UAM_TEAM") {
      this.roleDisply = "UAM Team";
    } else if (this.role === "MDM_TEAM") {
      this.roleDisply = "MDM Team";
    } else if (this.role === "PRIVILEGED_USER") {
      this.roleDisply = "Privileged User";
    } else if (this.role === "APA") {
      this.roleDisply = "APA";
    } else if (this.role === "DATA_MIGRATION_SPECIALIST") {
      this.roleDisply = "Data Migration Specialist";
    } else if (this.role === "TECHNOLOGY_USER") {
      this.roleDisply = "Technology User";
    } else {
      this.roleDisply = this.role;
    }
  }
  public routePa() {
    this.panelService.PriceAgreementContent("AllPriceAgree");
  }
  public getMenuItems() {
    this.menuList = this.authServices.getMenuItems();
    if (!this.menuList) {
      this.authServices.getLeftMenu().subscribe((response) => {
        if (response && response["result"].length > 0) {
          this.authServices.setMenuItems(response["result"]);
          this.menuList = response["result"];
        }
      });
    }
  }
  public isVisible() {
    if (this.role === applicationRoles.CHANNEL_ADMIN) {
      return false;
    } else if (this.role.includes("ADMIN")) {
      return true;
    }
    return false;
  }

  public isVisibleForUAM() {
    if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return true;
    } else if (
      this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return true;
    }
    return false;
  }

  private setDetails() {
    this.name = this.authServices.getFullName();
    this.role = this.authServices.getRole();
    this.isBulkUpload = this.pricemanagerServices.getBulkUploadUI() ? 1 : 0;
  }
  /* [VOD-1695] [ALM id-242] - start */
  public logoutPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "300px";
    dialogConfig.panelClass = "waring-dialog-container";
    dialogConfig.data = {
      /* [VOD-1699] [ALM ID-242]  - start */
      header: "Confirm Action",
      message:
        "You are about to Logout the Price Manager tool. Please ensure you have saved your work before proceeding. Unsaved changes will be discarded." /* [VOD-1699] [ALM ID-242] */,
      /* [VOD-1699] [ALM ID-242]  - end */
      type: "dismiss",
      confirmText: "Logout",
    };
    const dialogRef = this.dialog.open(WarningComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.logout();
      }
    });
  }
  /* [VOD-1695] [ALM id-242] - end */

  public logout() {
    this.loaderService.startLoading(); // [VOD-1743]
    this.authServices.logout().subscribe(
      (res) => {
        localStorage.clear();
        // clear all storages
        this.authServices.setMenuItems(null);
        this.authServices.clearUserData();
        // redirect
        this.router.navigate(["/login"]);
        this.loaderService.stopLoading(); // [VOD-1743]
      },
      () => {
        localStorage.clear();
        this.authServices.setMenuItems(null);
        // clear all storages
        this.authServices.clearUserData();
        // redirect
        this.router.navigate(["/login"]);
        this.loaderService.stopLoading(); // [VOD-1743]
      }
    );
  }

  public isAnalyticReportsVisible() {
    if (this.isBuyer) {
      if (
        this.role.includes("CATALOGUE") ||
        this.role.includes("BUSINESS") ||
        this.role === applicationRoles.UAM_TEAM ||
        this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.CHANNEL_ADMIN
      ) {
        return false;
      }
      return true;
    } else if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    }
    return false;
  }

  public isValueLeakageVisible() {
    return true;
  }

  public isVoucherVisible() {
    if (
      this.role.includes("CATALOGUE") ||
      this.role.includes("COMMERCIAL") ||
      this.role === applicationRoles.CHANNEL_ADMIN
    ) {
      return false;
    } else if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    } else if (
      this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    }
    return true;
  }

  public isReportVisible() {
    if (
      this.role.includes("BUSINESS_USER") ||
      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER") ||
      this.role.includes("ASSET_ACCOUNTING_SPECIALIST") ||
      this.role.includes("COMMERCIAL_CONTRACT_MANAGER")
    ) {
      return false;
    }
    return true;
  }

  public notBusinessOrCommercial() {
    if (
      this.role.includes("COMMERCIAL") ||
      this.role.includes("BUSINESS") ||
      this.role === applicationRoles.CHANNEL_ADMIN
    ) {
      return false;
    } else if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    } else if (
      this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    }
    return true;
  }

  public notCommercial() {
    if (
      this.role.includes("COMMERCIAL") ||
      this.role.includes("APA") ||
      this.role.includes("TECHNOLOGY_USER") ||
      this.role.includes("CATALOGUE_SPECIALIST") ||
      this.role.includes("COMMERCIAL_CONTRACT_MANAGER") ||
      this.role.includes("PRIVILEGED_USER") ||
      this.role.includes("ASSET_ACCOUNTING_SPECIALIST") ||
      this.role === applicationRoles.CHANNEL_ADMIN
    ) {
      return false;
    } else if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    } else if (
      this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    }
    return true;
  }

  public isManagementVisible() {
    if (this.role === applicationRoles.CHANNEL_ADMIN) {
      return true;
    }
    return false;
  }

  public isMatAndBomVisible() {
    if (localStorage.getItem("workspace") == "Network Workspace") {
      if (
        this.role.includes("CATALOGUE_SPECIALIST") ||
        this.role.includes("SUPPLIER_REVIEWER") ||
        this.role.includes("UAM_TEAM") ||
        this.role.includes("COMMERCIAL_CONTRACT_MANAGER") ||
        this.role.includes("PRIVILEGED_USER")
      ) {
        return false;
      } else {
        this.isBulkUploadShow();
        return true;
      }
    } else {
      return false
    }

  }
  public isNetworkTab() {
    if (localStorage.getItem("workspace") == "Network Workspace") {
      return true
    } else {
      return false
    }
  }
  public isProjectIdVisible() {
    if (localStorage.getItem("workspace") == "Network Workspace") {
      if (
        this.role.includes("LOCAL_MARKET_MANAGER") ||
        this.role.includes("LOCAL_MARKET_OVERSEER") ||
        this.role.includes("CATEGORY_MANAGER") ||
        this.role.includes("SOURCING_SPECIALIST") ||
        this.role.includes("BUSINESS_USER") ||
        this.role.includes("SUPER_ADMIN") ||
        this.role.includes("SUB_ADMIN") ||
        this.role.includes("MDM_TEAM") ||
        this.role.includes("DATA_MIGRATION_SPECIALIST") ||
        this.role.includes("SUPPLIER_ADMIN") ||
        this.role.includes("SUPPLIER_MANAGER") ||
        this.role.includes("MASTER_DATA_SPECIALIST")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }

  public isDashboardVisible() {
    if ((this.role === applicationRoles.CHANNEL_ADMIN) || (this.role == "COMMERCIAL_CONTRACT_MANAGER")) {
      return false;
    } else if (
      !this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    } else if (
      this.isBuyer &&
      (this.role === applicationRoles.SUPPLIER_REVIEWER ||
        this.role === applicationRoles.UAM_TEAM)
    ) {
      return false;
    }

    return true;
  }

  public isBulkUploadShow() {
    this.isBulkUpload = this.pricemanagerServices.getBulkUploadUI() ? 1 : 0;
    this.router.onSameUrlNavigation = "reload";
    if (this.isBulkUpload == 1) {
      this.isBulkUpload = 0;
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }
  }
}
