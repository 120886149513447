import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../_config';
import { IFilterConfigurations, IConfiguration } from '../_models/configuration';
import { IServerResponse } from '../_models/response';
// tslint:disable-next-line:import-blacklist
import { Observable, Subject } from 'rxjs';
import { AuthServices } from '../_services/auth.service';

const { configUrl, priceUrl, priceUrl1 } = AppConfig;
@Injectable({
  providedIn: 'root'
})
export class ConfigurationNntwkServices {

  private configurationData = new Subject<any>();
  private resetData = new Subject<any>();
  private buttonPlay = new Subject<any>();
  private cancelData = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) { }

  private globalConfiguration: IFilterConfigurations;
  private globalCommodityCodes: any;
  private globalApproverDetails: any;
  private getFiltersUrl: string = configUrl + 'pm/integration/getFilterValues';
  private getCommodityCodes: string = configUrl + 'pm/integration/searchCommodityCode';
  private getMeasurementsUrl: string = configUrl + 'pm/integration/getMeasurementUnits/ALL';
  private configurationApiUrl: string = priceUrl1 + 'configuration';
  private getIncotermsUrl: string = configUrl + 'pm/integration/getIncotermDetails?';
  private getLineItemApproverApiUrl: string = priceUrl1 + 'price-manager/get-line-item-approver';


  /**
   *Api function that returns all list values for filters like opco list, supplier, parent supplier list etc
   * 
   * @private
   * @return {*} 
   * @memberof ConfigurationServices
   */
  private getFilterValues() {
    return this.http.get<IServerResponse<IFilterConfigurations>>(this.getFiltersUrl, this.authService.getAccessHeaders());
  }


  /**
   *Function that returns commodity code values
   *
   * @param {{ pageNumber: number; perPage: number; filters: any }} data
   * @return {*} 
   * @memberof ConfigurationServices
   */
  public getCommodityCodeValues(data: { pageNumber: number; perPage: number; filters: any }) {
    return this.http.post(this.getCommodityCodes, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function that returns measurement units list for dropdown
   *
   * @private
   * @return {*} 
   * @memberof ConfigurationServices
   */
  private getDropdownValues() {
    return this.http.get<any>(this.getMeasurementsUrl, this.authService.getAccessHeaders());
  }

  /**
   *Function that return Global filter configuration list
   *Checking whether it's first load or not, and call the api for first load
   * @param {boolean} [freshLoad=false]
   * @return {*}  {Promise<IFilterConfigurations>}
   * @memberof ConfigurationServices
   */
  public async getGlobalFilterConfiguration(freshLoad = false): Promise<IFilterConfigurations> {
    if (!freshLoad && this.globalConfiguration) {
      return this.globalConfiguration;
    }

    return new Promise((resolve, reject) => {
      this.getFilterValues()
        .subscribe(response => {
          if (response && response.result && response.result[0]) {
            let details = [];
            if (response.result[0].supplierDetails) {
              response.result[0].supplierDetails.map(data => {
                Object.keys(data.childSupplierDetails).map(k => {
                  details[k] = data.childSupplierDetails[k];
                });
              });
              delete response.result[0].suppliersDetails;
              response.result[0].suppliersDetails = details;
            }

            let l4Details = {};
            if (response.result[0].materialGroupDetails) {
              for (const code of response.result[0].materialGroupDetails) {
                if (code && code.l4Code) {
                  l4Details[code.l4Code] = {
                    l4Code: code.l4Code,
                    l4Description: code.l4Description,
                    l1Code: code.l1Code,
                    status: code.status ? true : false,
                    physicalStatus: code.physicalStatus.includes('|') ? code.physicalStatus.split('|') : [code.physicalStatus]
                  }
                }
              }
              response.result[0].keyDetailsWithL4 = l4Details;
            }
            this.setGlobalConfiguration(response.result[0]);
            resolve(response.result[0]);
          }
        },
          // tslint:disable-next-line: align
          (err: Error) => {
            reject(err);
          });
    });
  }


  /**
   *Function that return Global Commodity Code list
   *Checking whether it's first load or not, and call the api for first load
   * @param {boolean} [freshLoad=false]
   * @return {*}  {Promise<any>}
   * @memberof ConfigurationServices
   */
  public async getCommodityCodeListConfiguration(freshLoad = false): Promise<any> {
    if (!freshLoad && this.globalCommodityCodes) {
      return this.globalCommodityCodes;
    }
    const commodityCodeList = []
    return new Promise((resolve, reject) => {
      const params = {
        filters: [],
        pageNumber: 0,
        perPage: 5000
      };
      this.getCommodityCodeValues(params)
        .subscribe((response: any) => {
          if (response && response.result && response.result[0]) {
            const data = response['result'][0]['searchResult'];
            data.forEach((element: any) => {
              commodityCodeList.push(element['commodityCode']);
            });
            this.setGlobalCommodityCodes(commodityCodeList);
            resolve(commodityCodeList);
          } else {
            resolve(['-']);
          }
        },
          // tslint:disable-next-line: align
          (err: Error) => {
            resolve(['-']);
          });
    });
  }


  /**
   *Function to get the configurations list for dropdown
   *
   * @return {*}  {Promise<any>}
   * @memberof ConfigurationServices
   */
  public async getPaDropdownConfigurations(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDropdownValues()
        .subscribe(response => {
          if (response && response.result && response.result[0]) {
            resolve(response.result[0]);
          }
        },
          // tslint:disable-next-line: align
          (err: Error) => {
            reject(err);
          });
    });
  }


  /**
   *Function that set the configuration list globally
   *
   * @param {IFilterConfigurations} globalConfiguration
   * @memberof ConfigurationServices
   */
  public setGlobalConfiguration(
    globalConfiguration: IFilterConfigurations
  ): void {
    this.globalConfiguration = globalConfiguration;
  }


  /**
   *
   *Function that set the Commodity Codes globally
   * @param {*} commodityCodes
   * @memberof ConfigurationServices
   */
  public setGlobalCommodityCodes(commodityCodes: any): void {
    this.globalCommodityCodes = commodityCodes;
  }

  /**
   *Api function that returns prefered Filters configuration list
   *
   * @return {*} 
   * @memberof ConfigurationServices
   */
  public getConfiguration() {
    return this.http.get<IServerResponse<IConfiguration>>(this.configurationApiUrl, this.authService.getAccessHeaders());
  }

  /**
   *Api function that save the prefered Filters configuration list
   *
   * @param {*} data
   * @return {*} 
   * @memberof ConfigurationServices
   */
  public saveConfiguration(data) {
    return this.http.post(this.configurationApiUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Observable function that send the filter configuration data
   *
   * @param {*} data
   * @memberof ConfigurationServices
   */
  public sendConfiguration(data: any) {
    this.configurationData.next({ result: data });
  }

  /**
   *bservable function to get the filter configuration data
   *
   * @return {*}  {Observable<any>}
   * @memberof ConfigurationServices
   */
  public getPostedConfiguration(): Observable<any> {
    return this.configurationData.asObservable();
  }

  /**
   *Observable function that reset the filter configuration data
   *
   * @param {boolean} data
   * @memberof ConfigurationServices
   */
  public resetConfiguration(data: boolean) {
    this.resetData.next(data);
  }

  /**
   *Observable function that reset the filter configuration data
   *
   * @return {*}  {Observable<boolean>}
   * @memberof ConfigurationServices
   */
  public getResetVal(): Observable<boolean> {
    return this.resetData.asObservable();
  }

  /**
   *Observable Funtion to eable/disable button
   *
   * @param {boolean} data
   * @memberof ConfigurationServices
   */
  public buttonEnabled(data: boolean) {
    this.buttonPlay.next(data);
  }
  /**
   *Observable funtion to eable/disable button
   *
   * @return {*}  {Observable<boolean>}
   * @memberof ConfigurationServices
   */
  public getButtonEnabled(): Observable<boolean> {
    return this.buttonPlay.asObservable();
  }

  /**
   *Observable Function that save the cancel changes 
   *
   * @param {boolean} data
   * @memberof ConfigurationServices
   */
  public cancelChanges(data: boolean) {
    this.cancelData.next(data);
  }

  /**
   *Observable fuction that cancel the changes
   *
   * @return {*}  {Observable<boolean>}
   * @memberof ConfigurationServices
   */
  public getCancelChanges(): Observable<boolean> {
    return this.cancelData.asObservable();
  }

  /**
   *Api function that returns incoterms data
   *
   * @param {*} status
   * @return {*} 
   * @memberof ConfigurationServices
   */
  public getIncoterms(status) {
    let url = this.getIncotermsUrl;
    url = url + `status=${status}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *
   *Api function that returns line items approver list
   * @param {*} data
   * @return {*} 
   * @memberof ConfigurationServices
   */
  public getLineItemApprovers(data) {
    return this.http.post(this.getLineItemApproverApiUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Function to get line items approver list
   *
   * @param {{ priceAgreementId: string }} data
   * @return {*}  {Promise<any>}
   * @memberof ConfigurationServices
   */
  public async getLineItemApproverList(data: { priceAgreementId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getLineItemApprovers(data)
        .subscribe((response: any) => {
          resolve(response);
        },
          // tslint:disable-next-line: align
          (err: Error) => {
            resolve(err);
          });
    });
  }
}
