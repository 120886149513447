import { Component, OnInit,Inject } from '@angular/core';
import { SuccessDialogComponent } from '../../../../../../global-module/success-dialog/success-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PriceManagerServices } from '../../../../../../_services/price-manager.service';
import { LoaderService } from '../../../../../../_services/loader.service';
import { PriceMessagesComponent } from '../../../../../../global-module/price-messages/price-messages.component';

@Component({
  selector: 'app-reject-popup-projectid',
  templateUrl: './reject-popup-projectid.component.html',
  styleUrls: ['./reject-popup-projectid.component.scss']
})
export class RejectPopupProjectidComponent implements OnInit {

    public submitted: boolean;
    public comment: string;
    public loggedInUserRole: string;
    public userActions: FormGroup;
    public errorMessage: string;
    public isBuyer: boolean;
    projectIdData: any;
   
    constructor(
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<RejectPopupProjectidComponent>,
      private loaderService: LoaderService,
      public dialog: MatDialog,
      public pricemanagerServices: PriceManagerServices,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    
    }
  
    public ngOnInit() {
      this.projectIdData = this.data.selectedUsersList
      const controlsConfig = {}
     
        // controlsConfig['completionDate'] = ['', [Validators.required]];
        controlsConfig['comment'] = ['', [Validators.required, Validators.maxLength(1200)]];
     
      this.userActions = this.formBuilder.group(controlsConfig);
    }
  
  
    /**
     *Submit function for create user action
     *
     * @param {*} 
     * @return {*} 
     * @memberof PopupCommentsComponent
     */
    public submit(data) {
      this.submitted = true;
   
  
      const options = {
        ...data
      };
  
      if (options.comment) {
        options.comment = options.comment.trim();
     
      }
      
      const payload = {
        "type":"reject",
        "comments": options.comment,
        projectId: this.projectIdData.projectId,
        itemType: "PROJECT_ID_TYPE"
    }
   
    //  this.loaderService.startLoading();
    this.loaderService.startLoading();
    this.pricemanagerServices.actionProjectId(payload).subscribe(
      (response: any) => {
        let popupMessage = '';
        if (response['result']) {
       
          this.loaderService.stopLoading();
          this.dialogRef.close(true);
          this.userActions.reset();
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'succes-dialog-div';
          dialogConfig.data = {
            id: 1,
            header: 'Success',
            message: 'Rejected Successfully',
            buttonTxt: 'Ok',
          };
          dialogConfig.width = "200px";
          this.dialog.open(SuccessDialogComponent, dialogConfig);
        }
      },
      err => {        
        this.loaderService.stopLoading();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "waring-dialog-container";
        dialogConfig.data = {
          id: 1,
          header: "Validation Error",
          message: err.error.message,
        };
        dialogConfig.width = '200px';
        this.dialog.open(PriceMessagesComponent, dialogConfig);        
      });
   
    }
  
   
}