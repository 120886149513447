import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSelect, MatDialogRef } from '@angular/material';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-breakdown-block-chart',
  templateUrl: './breakdown-block-chart.component.html',
  styleUrls: ['./breakdown-block-chart.component.scss']
})
export class BreakdownBlockChartComponent implements OnInit {
  public highcharts = Highcharts;
  public chartOptions = {
    chart: {
      type: 'line',
      plotBorderWidth: 1,
      marginTop: 80
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      style: {
        color: '#5B5B5B',
        fontSize: '11px',
        fontFamily: 'Gilroy-Bold'
      },
      useHTML: true,
      padding: 0,
      margin: 0,
      formatter: function () {
        let val;
        if (this.y > 999 && this.y < 1000000) {
          val = (this.y / 1000).toFixed(0) + 'K';  
        } else if (this.y > 1000000) {
          val = (this.y / 1000000).toFixed(0) + 'M';  
        } else if (this.y < 900) {
          val = this.y; // if value < 1000, nothing to do
        }
        let str = `<div class="barchart_tooltip"> <h2>${this.series.name} </h2> <div class="tool_content">  <span class="bar_sub"><span class="pointer " style="background:${this.color}"></span> €${val} </span> <br /> <span class="bar_sub_val">  ${this.x}</span></div></div>`;
        return str;
      }
    },
    title: {
      text: '',
      align: 'left',
      margin: 10,
      style: {
        color: '#434343',
        fontFamily: 'Vodafone-Bold',
        fontSize: '14px'
      }
    },
    subtitle: {
      text: 'Showing trends based on applied filters.',
      align: 'left',
      style: {
        color: '#888888',
        fontFamily: 'Vodafone-Bold',
        fontSize: '10px',
        // marginBottom: '10px',
      }
    },
    xAxis: {
      categories: []
    },
    yAxis: [{ // left y axis
      title: {
        text: 'Order Value & Invoiced Value',
        margin: 50
      },
      labels: {
        align: 'left',
        x: -40,
        y: -1,
        formatter() {
          let val;
          if (this.value > 999 && this.value < 1000000) {
            val = (this.value / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
          } else if (this.value >= 1000000) {
            val = (this.value / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
          } else if (this.value < 900) {
            val = this.value; // if value < 1000, nothing to do
          }
          return `€${val}`;
        }
      },
      showFirstLabel: false
    }, { // right y axis
      linkedTo: 0,
      gridLineWidth: 2,
      opposite: true,
      title: {
        text: 'Item Price',
        margin: 50
      },
      labels: {
        align: 'right',
        x: 40,
        y: -1,
        formatter() {
          let val;
          if (this.value > 999 && this.value < 1000000) {
            val = (this.value / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
          } else if (this.value >= 1000000) {
            val = (this.value / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
          } else if (this.value < 900) {
            val = this.value; // if value < 1000, nothing to do
          }
          return `€${val}`;
        }
      },
      showFirstLabel: false
    }],

    credits: {
      enabled: false
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      itemMarginTop: 5,
      itemMarginBottom: 5,
      borderColor: '#999999',
      x: -50,
      y: -10,
      floating: true,
      shadow: {
        color: '#f0f0f0',
        opacity: '0.5',
        offsetX: '1px',
        offsetY: '1px',
      },
      itemHiddenStyle: {
        color: 'red',
      },
      itemStyle: {
        color: '#434343',
        fontFamily: 'Vodafone-Bold',
        fontSize: '12px',
        boxShadow: '0px 1px 1px 2px rgba(0,0,0,0.3)',
        borderRadius: 5,
        borderWidth: 5,
      }
    },

    series: []
  };
  public chart;
  public chartCallback;
  public updateFromInput = false;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {
    const self = this;
    this.chartCallback = chart => {
      self.chart = chart;
    };
  }

  public onNoClick(): void {
    // this.dialogRef.close();
  }

  public ngOnInit() {
    this.chartOptions.series = this.dialogData.chart;
    this.chartOptions.title.text = this.dialogData.title;
    this.chartOptions.xAxis.categories = this.dialogData.months;
    this.updateFromInput = true;
  }

}
