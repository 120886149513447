import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from '../../../../_services/analytics.service';
import { LoaderService } from '../../../../_services/loader.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PriceMessagesComponent } from '../../../../global-module/price-messages/price-messages.component';
@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public dimensionName: string;
  @Input() public fileName: string;
  @Input() public priceAgreementId: any = null;  //  [VOD-1724]
  @Output() public searchValueEvent = new EventEmitter<string>();
  public searchKey: string = '';
  public search = false;

  constructor(private analyticsService: AnalyticsService, 
    public dialog: MatDialog,
    private loaderService: LoaderService) {}

  public ngOnInit() {}

  /* VOD-1505 start */

  public getSearchValue() {
    if (this.searchKey && this.searchKey.trim().length > 0) {
      // this.searchKey = e.target.value;
      this.search = true;
      const value = this.searchKey && this.searchKey.trim();
      this.searchValueEvent.emit(value);
    } else if(this.search) {
      this.searchAgain();
      this.search = false;
    }
  }
  public globalSearch(event) {
    if(event.target.value.length == 0 && event.which === 32){    //not allow initial space 
      return false;
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 13) {
      if (this.searchKey && this.searchKey.trim().length > 0) {
        this.search = true;
        const value = this.searchKey && this.searchKey.trim();
        this.searchValueEvent.emit(value);
      } else if(this.search) {
        this.searchAgain();
        this.search = false;
      }
    }
  }
  public searchAgain() {
    this.searchKey = '';
    this.searchValueEvent.emit(this.searchKey);
  }
 /* VOD-1505 end */

  downloadData() {
    this.loaderService.startLoading()
    let body = {
      downloadType: this.dimensionName,
      // downloadType: 'kpiCards',
      datesFilter: this.analyticsService.getFilteredDates(),
      dimensionName: this.dimensionName,
      dimensionValueSearchKey: this.searchKey && this.searchKey.trim(),
      filters: this.analyticsService.getAnalyticsFilter(),
      kpiSortKey: this.analyticsService.getKpiSortKey()
    }

    this.analyticsService.getDownloadFile(body, this.priceAgreementId).subscribe(data => { //  [VOD-1724]
      if (data) {
        this.loaderService.stopLoading()
        this.analyticsService.downloadFile(data, this.fileName);
      } else {
        this.onSaveErrorPopup({
          header: 'Invalid Action',
          message: 'Unable to download, Please try again later'
        });
      }
    }, err => {
      this.loaderService.stopLoading();
      this.onSaveErrorPopup({
        header: 'Invalid Action',
        message: 'Unable to download, Please try again later'
      });
      console.log(err);
    });
  }
  private onSaveErrorPopup(obj: { header: string; message: string, icon?: string}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        icon: obj.icon ? obj.icon : 'info-pophover-blue.svg',
        buttonText: 'OK'
      };
    }
    const dialogRef = this.dialog.open(PriceMessagesComponent, dialogConfig);
  }

}
