import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  public transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      if (typeof it === 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it === 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else if (typeof it === 'object') {
        let text = '';        
        if (it.value !==undefined && it.value !== null) {          
          text = it.value.toString().toLowerCase();
        }
        if (it.loginId !==undefined && it.loginId !== null) {          
          text = it.loginId.toString().toLowerCase();
        }
        if (it.key && it.value) {
          text = (it.key.toString() + '-' + it.value.toString()).toLowerCase();
        } else if (it.key) {
          text = it.key.toString().toLowerCase();
        }
        return text.includes(searchText);
      }
      return false;
    });
  }
}
