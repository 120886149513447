import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-chart-block',
  templateUrl: './chart-block.component.html',
  styleUrls: ['./chart-block.component.scss']
})
export class ChartBlockComponent implements OnInit {
  @Input() public chartData: any;
  @Input() public chartColor: any;
  @Input() public chartName: any;
  public dummyData = [0, 0, 0, 0, 0, 0, 0];
  public highcharts = Highcharts;
  public chartOptions = {
    chart: {
      type: 'line'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      style: {
        color: '#5B5B5B',
        fontSize: '11px',
        fontFamily: 'Vodafone-Bold'
      },
      formatter: function () {
        return `${this.point.categoryName}: <b>${this.point.y}</b>`;
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    labels: {
      visible: false
    },
    ticks: {
      visible: false
    },
    legend: { enabled: false },
    title: {
      text: ''
    },
    series: []
  };
  public chart;
  public chartCallback;
  public updateFromInput = false;
  constructor() {
    const self = this;
    this.chartCallback = chart => {
      self.chart = chart;
    };
  }

  public ngOnInit() {
    let newArry;
    let defaultData = this.dummyData.map(obj => {
      let temp = { y: obj, categoryName: this.chartName }
      return temp;
    });
    if(this.chartData) {
       newArry = this.chartData.map(obj => {
        let temp = { y: obj, categoryName: this.chartName }
        return temp;
      })
    }
    const self = this;
    const chart = this.chart;
    self.chartOptions.series = [{
      color: this.chartColor,
      data: newArry && newArry.length > 0 ? newArry : defaultData
    }];
    self.updateFromInput = true;
  }
  }
