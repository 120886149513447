import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../_config';
const { contactSupportLink, privacyPolicyLink } = AppConfig;
import { AuthServices } from '../../_services/auth.service';

/**
 *Common footer component (copy rights section) for all pages
 *
 * @export
 * @class FooterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public contactLink: any;
  public privacyPolicyLink: any;
  public isBuyer: boolean;

  constructor(private authService: AuthServices) {
  }

  ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
    this.contactLink = contactSupportLink;
    this.privacyPolicyLink = privacyPolicyLink;
  }

}
