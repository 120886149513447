import { environment } from '../../environments/environment';
import { envType } from './constants';

function makeAppConfig() {
  const date = new Date();
  const year = date.getFullYear();
  const apiURLJava = environment.apiURLJava;
  const apiURLPriceManager = environment.apiURLPriceManagerNode;
  const apiURLAccessManagerNode = environment.apiURLAccessManagerNode;
  const environmentType = environment.environmentType; // values can be dev, preporod, prod
  const apiV1 = '/api/v1/';
  const itemCustomGroup = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  const pageSizeOptions = [10, 20, 50, 100];
  const adminServiceUrl = environment.adminServiceUrl;
  const analyticsUrl = environment.analyticsUrl;
  const maxNoOfItemsInPA = 10000;
  // tslint:disable-next-line: max-line-length
  const contactSupportLink = 'mailto:pm.support@vodafone.com';
  /* [VOD-1697] [ALM ID-226] start */
  const learnMoreLink = 'https://vodafone.sharepoint.com/sites/VodafonePriceManager/SitePages/Training-Material.aspx';
  /* [VOD-1697] [ALM ID-226] end */
  const privacyPolicyLink = 'https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/Employee-Privacy-Statement.aspx';
  const serviceCatalogueProduction = 'https://servicecatalogue.vodafone.com/catalogue/';
  const serviceCataloguePreProduction = 'https://servicecatalogue-test.vodafone.com/catalogue/';
  const serviceCatalogueDev = 'https://servicecatalogue-test.vodafone.com/catalogue';

  const conf = {
    brand: 'Vodafone',
    appOwner: 'vodafone',
    year,
    priceUrl: apiURLPriceManager + apiV1,
    priceUrl1: environment.apiURLPriceManagerNode + apiV1,
    accessUrl: apiURLAccessManagerNode + apiV1,
    configUrl: apiURLJava + apiV1,
    socketLink: environment.ioURL,
    perPage: 10,
    idleLogout: 3600,
    itemCustomGroup,
    pageSizeOptions,
    adminServiceUrl: adminServiceUrl + apiV1,
    analyticsUrl: analyticsUrl + apiV1,
    contactSupportLink,
    learnMoreLink,
    privacyPolicyLink,
    tenantId: environment.tenantId,
    clientId: environment.ssoClientId,
    ssoAuthority: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/authorize`,
    maxNoOfItemsInPA,
    environmentType,
    serviceCatalogueUrl: environmentType == envType.prod ? serviceCatalogueProduction : environmentType == envType.preprod ? serviceCataloguePreProduction : serviceCatalogueDev
  };
  return conf;
}

export const AppConfig = makeAppConfig();
