import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../_config';
import { AuthServices } from './auth.service';

const { adminServiceUrl } = AppConfig;

@Injectable({
  providedIn: 'root',
})
export class MasterDataServices {
  private globalConfiguration: any;
  constructor(private http: HttpClient, private authService: AuthServices) { }

  private materialGroupDataUrl: string =
    adminServiceUrl + 'pm/integration/searchMasterData';
  private GlobalFilterUrl: string =
    adminServiceUrl + 'pm/integration/getAdminModulesDropdownData/ALL';
  private addMaterialGroupUrl: string =
    adminServiceUrl + 'pm/integration/saveMaterialGroup';
  private addGnpHeirarchyUrl: string =
    adminServiceUrl + 'pm/integration/saveGnpHeirarchy';
  private saveSupplierUrl: string =
    adminServiceUrl + 'pm/integration/saveSupplierDetails';
  private saveSubAssetClassificationUrl: string =
    adminServiceUrl + 'pm/integration/saveSubAssetClassification';
  private saveAssetClassificationUrl: string =
    adminServiceUrl + 'pm/integration/saveAssetClassification';
  private saveBuomUrl: string =
    adminServiceUrl + 'pm/integration/saveBaseUnitOfMeasure';
  private saveLocalMarketUrl: string =
    adminServiceUrl + 'pm/integration/saveLocalMarket';
  private saveCurrencyXRUrl: string =
    adminServiceUrl + 'pm/integration/saveForeignExchangeRate';
  private importMasterDataUrl: string =
    adminServiceUrl + 'pm/integration/uploadFile';
  private saveCatalougeUrl: string =
    adminServiceUrl + 'pm/integration/saveCatalogueGroup';
  private saveVolumeUnitsUrl: string =
    adminServiceUrl + 'pm/integration/saveVolumeUnits'; // [VP2-127]
  private saveHeightLengthWidthUnitsUrl: string =
    adminServiceUrl + 'pm/integration/saveHeightLengthWidthUnits'; // [VP2-127]
  private saveCommodityCodeUrl: string =
    adminServiceUrl + 'pm/integration/saveCommodityCode';
  private saveProductFamilyUrl: string =
    adminServiceUrl + 'pm/integration/saveProductFamily';
  private saveWarrantyEffectiveFromUrl: string =
    adminServiceUrl + 'pm/integration/saveWarrantyEffectiveFrom';
  private saveIncoTermUrl: string =
    adminServiceUrl + 'pm/integration/saveIncoTerm';
  private getChildSupplierDataUrl: string =
    adminServiceUrl + 'pm/integration/getChildSuppliers';

  /**
   *Api function that returns all master data based on the params
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public materialGroupMasterData(data: any) {
    return this.http.post(
      this.materialGroupDataUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }


  /**
   *Api function to get the Global filter values
   *
   * @return {*} 
   * @memberof MasterDataServices
   */
  public getGlobalFilterValues() {
    return this.http.get(
      this.GlobalFilterUrl,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to save the material group data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveMaterialGroup(data: any) {
    return this.http.post(
      this.addMaterialGroupUrl, data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to save the GNP heirarchy data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveGnpHeirarchy(data: any) {
    return this.http.post(
      this.addGnpHeirarchyUrl, data,
      this.authService.getAccessHeaders());
  }

  /**
   *Api function to save the local market data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveLocalMarket(data: any) {
    return this.http.post(this.saveLocalMarketUrl,
      data, this.authService.getAccessHeaders());
  }


  /**
   *Function that return Global filter configuration list
   *Checking whether it's first load or not, and call the api for first load
   *
   * @return {*} 
   * @memberof MasterDataServices
   */
  public async getGlobalFilterConfiguration() {
    if (this.globalConfiguration) {
      return this.globalConfiguration;
    }
    return new Promise((resolve, reject) => {
      this.getGlobalFilterValues().subscribe(
        (response) => {
          if (response['result']) {
            resolve(response['result'][0]);
          }
        },
        (err: Error) => {
          reject(err);
        }
      );
    });
  }

  /**
   *Function that create global filter object for master data page
   *It's returns array of object which contains filter name, type and value
   * @param {*} filters
   * @param {*} [page]
   * @return {*} 
   * @memberof MasterDataServices
   */
  public generateGlobalSearchFilter(filters: any, page?: any) {
    const data: any = [];
    Object.keys(filters).forEach(name => {
      const {
        filterType, type, values, filter, dateFrom, dateTo
      } = filters[name];
      if (name.endsWith('_1')) {
        name = name.replace('_1', '');
      }
      if (page === 'materialFilter') {
        data.push({
          name,
          value: filter.split(' '),
          type
        });
      } else if (filterType === 'text') {
        if (name === 'userRole' || name === 'tableName') {
          data.push({
            name,
            value: filter.trim().split(' ').join('_').toUpperCase()
          });
        } else {
          data.push({
            name,
            value: filter
          });
        }
      } else if (filterType === 'set') {
        /* VP3-11 start */
        if (page && page === 'trainingMaterials') {
          data.push({
            name,
            value: values,
            type: 'EXACT_MATCH'
          });
        } else {
          data.push({
            name,
            value: values[0],
            type: 'EXACT_MATCH'
          });
        }
        /* VP3-11 end */
      } else if (filterType === 'date') {
        if (type === 'inRange') {
          const startDate = new Date(dateFrom);
          startDate.setUTCHours(0, 0, 0, 0);
          const endDate = new Date(dateTo);
          endDate.setUTCHours(23, 59, 59, 0);
          data.push({
            name,
            value: startDate.toISOString(),
            type: 'START_DATE'
          });
          data.push({
            name,
            value: endDate.toISOString(),
            type: 'END_DATE'
          });
        } else if (type === 'greaterThanOrEqual') {
          const startDate = new Date(dateFrom);
          startDate.setUTCHours(0, 0, 0, 0);
          data.push({
            name,
            value: startDate.toISOString(),
            type: 'START_DATE'
          });
          data.push({
            name,
            value: '9999-12-31T23:59:59.999Z',
            type: 'END_DATE'
          });
        } else if (type === 'lessThanOrEqual') {
          const endDate = new Date(dateFrom);
          endDate.setUTCHours(23, 59, 59, 0);
          data.push({
            name,
            value: '1947-01-01T00:00:01.000Z',
            type: 'START_DATE'
          });
          data.push({
            name,
            value: endDate.toISOString(),
            type: 'END_DATE'
          });
        }
      }
    });
    return data;
  }


  /**
   *Function for generate sorting objects for Master data page,
   *Based on the input params, Its returns attribute name and sort type (ASC | DESC)
   * @param {*} data
   * @param {boolean} [status=false]
   * @return {*} 
   * @memberof MasterDataServices
   */
  public globalSorting(data: any, status = false) {
    if (data && data.length) {
      if (data[0].colId.endsWith('_1')) {
        data[0].colId = data[0].colId.replace('_1', '');
      }
      return {
        attibName: data[0].colId,
        sortType: data[0].sort.toUpperCase()
      };
    }
    return {
      attibName: status ? 'actionDate' : 'createdAt',
      sortType: 'DESC'
    };
  }


  /**
   *This function check the input events and returns true only if input is number
   *
   * @param {*} event
   * @return {*}  {boolean}
   * @memberof MasterDataServices
   */
  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   *Api function to save the supplier data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveSupplier(data) {
    return this.http.post(
      this.saveSupplierUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to save the asset classification data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveAssetClassification(data: any) {
    return this.http.post(
      this.saveAssetClassificationUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to save the sub asset classification data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveSubAssetClassification(data: any) {
    return this.http.post(
      this.saveSubAssetClassificationUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to save the BUOM data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveBUOM(data: any) {
    return this.http.post(this.saveBuomUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to save the volume units data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveVolumeUnits(data: any) {
    return this.http.post(this.saveVolumeUnitsUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to save the height length width units data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveHeightLengthWidthUnits(data: any) {
    return this.http.post(this.saveHeightLengthWidthUnitsUrl, data, this.authService.getAccessHeaders());
  }

  public saveCommodityCode(data: any) {
    return this.http.post(this.saveCommodityCodeUrl, data, this.authService.getAccessHeaders2());
  }
  public saveProductFamily(data: any) {
    return this.http.post(this.saveProductFamilyUrl, data, this.authService.getAccessHeaders2());
  }
  public saveIncoTerms(data: any) {
    return this.http.post(this.saveIncoTermUrl, data, this.authService.getAccessHeaders2());
  }
  public saveWarrantyEffectiveFrom(data: any) {
    return this.http.post(this.saveWarrantyEffectiveFromUrl, data, this.authService.getAccessHeaders2());
  }
  /**
   *Api function to save the currency xr data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveCurrencyXR(data) {
    return this.http.post(
      this.saveCurrencyXRUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *function to verify the import file names
   *
   * @param {string} name
   * @param {string} type
   * @return {*} 
   * @memberof MasterDataServices
   */
  public importFileNameCheck(name: string, type: string) {
    const nameFormat = {
      BASE_UNIT_OF_MEASURE: 'BASE_UNIT_OF_MEASURE_',
      SUPPLIER_DETAILS: 'SUPPLIER_DETAILS_',
      FOREIGN_EXCHANGE_RATE: 'FOREIGN_EXCHANGE_RATE_',
      GNP_HEIRARCHY: 'GNP_HIERARCHY_',
      LOCAL_MARKET: 'LOCAL_MARKET_',
      MATERIAL_GROUP: 'MATERIAL_GROUP_',
      SUB_ASSET_CLASSIFICATION: 'SUB_ASSET_CLASSIFICATION_',
      ASSET_CLASSIFICATION: 'ASSET_CLASSIFICATION_',
      CATALOGUE_GROUP: 'CATALOGUE_GROUP_',
      VOLUME_UNITS: 'VOLUME_UNITS_',
      HEIGHT_LENGTH_WIDTH_UNITS: 'HEIGHT_LENGTH_WIDTH_UNITS_',
      MATERIAL_DETAILS: 'MATERIAL_DETAILS_',
      COMMODITY_CODE: 'COMMODITY_CODE_',
      INCO_TERM: 'INCO_TERM_',
      PRODUCT_FAMILY: 'PRODUCT_FAMILY_',
      WARRANTY_EFFECTIVE_FROM_LOOKUP: 'WARRANTY_EFFECTIVE_FROM_LOOKUP_'
    };
    return name.startsWith(nameFormat[type]);
  }

  /**
   *Api function to download the master data details based on the input params
   *
   * @param {FormData} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public importMasterData(data: FormData) {
    return this.http.post(
      this.importMasterDataUrl,
      data,
      this.authService.getAccessHeadersForFile()
    );
  }

  /**
   *Api function to save the catalogue data
   *
   * @param {*} data
   * @return {*} 
   * @memberof MasterDataServices
   */
  public saveCatalouge(data) {
    return this.http.post(
      this.saveCatalougeUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public getChildSupplierDetails(parentSupplierId) {
    return this.http.get(
      this.getChildSupplierDataUrl + "/" + parentSupplierId,
      this.authService.getAccessHeaders2()
    );
  }

}
