import { Injectable } from '@angular/core';
import { AppConfig } from '../_config';
const { ssoAuthority, clientId } = AppConfig;

export const BrowserConstants = {
    // Interaction status key (only used for browsers)
    INTERACTION_STATUS_KEY: 'interaction.status',
    // Interaction in progress cache value
    INTERACTION_IN_PROGRESS_VALUE: 'interaction_in_progress',
    // Invalid grant error code
    INVALID_GRANT_ERROR: 'invalid_grant',
    // Default popup window width
    POPUP_WIDTH: 483,
    // Default popup window height
    POPUP_HEIGHT: 600,
    // Default popup monitor poll interval in milliseconds
    POLL_INTERVAL_MS: 50,
    // msal-browser SKU
    LIBRARY_NAME: 'vodasso.js.browser',
};

@Injectable({
    providedIn: 'root'
})
export class SSOService {

    private object: any;
    private currentWindow: Window;

    // tslint:disable-next-line: no-empty
    constructor() {
        this.object = {
            client_id: clientId,
            response_type: 'code',
            grant_type: 'authorization_code',
            scope: 'user.read'
        };

        // Properly sets this reference for the unload event.
        this.unloadWindow = this.unloadWindow.bind(this);
    }
/**
 *Function for sso login
 *
 * @return {*} 
 * @memberof SSOService
 */
public async login() {
        try {
            return this.navigateWindow(this.getAuthCodeUrl(), 3000);
        } catch (err) {
            throw err;
        }

    }

    private navigateWindow(urlNavigate: string, navigationTimeout: number, noHistory?: boolean): Promise<void> {
        if (noHistory) {
            window.location.replace(urlNavigate);
        } else {
            window.location.assign(urlNavigate);
        }

        // To block code from running after navigation, this should not throw if navigation succeeds
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
                // tslint:disable-next-line: align
            }, navigationTimeout);
        });
    }

    private getAuthCodeUrl(): string {
        const queryString = this.createQueryString();
        return `${ssoAuthority}?${queryString}`;
    }

    private createQueryString(): string {
        const queryParameterArray: Array<string> = new Array<string>();
        for (const key in this.object) {
            if (this.object.hasOwnProperty(key)) {
                queryParameterArray.push(`${key}=${this.object[key]}`);
            }
        }
        return queryParameterArray.join('&');
    }

    private detectIEOrEdge(): boolean {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        const msie11 = ua.indexOf('Trident/');
        const msedge = ua.indexOf('Edge/');
        const isIE = msie > 0 || msie11 > 0;
        const isEdge = msedge > 0;
        return isIE || isEdge;
    }

    private async loginWithPopup() {

        try {
            const popupWindow: Window = this.openPopup(this.getAuthCodeUrl());
            // interactionHandler.initiateAuthRequest(navigateUrl, authCodeRequest, popup);

            // tslint:disable-next-line: max-line-length
            // Monitor the window for the hash. Return the string value and close the popup when the hash is received. Default timeout is 60 seconds.
            const hash = await this.monitorPopupForHash(popupWindow, 3000);
            return hash;

            // Handle response from hash string.
            // const result = await this.handleCodeResponse(hash);
        } catch (err) {
            throw new Error('issue');
        }
    }

    private openPopup(urlNavigate: string, popup?: Window | null): Window {
        try {
            let popupWindow;
            // Popup window passed in, setting url to navigate to
            if (popup) {
                popupWindow = popup;
                popupWindow.location.assign(urlNavigate);
            } else if (typeof popup === 'undefined') {
                // Popup will be undefined if it was not passed in
                popupWindow = this.openSizedPopup(urlNavigate);
            }

            // Popup will be null if popups are blocked
            if (!popupWindow) {
                throw new Error('empty window');
            }
            if (popupWindow.focus) {
                popupWindow.focus();
            }
            this.currentWindow = popupWindow;
            // tslint:disable-next-line: no-unbound-method
            window.addEventListener('beforeunload', this.unloadWindow);

            return popupWindow;
        } catch (e) {
            throw new Error('error window');
        }
    }

    private unloadWindow(e: Event): void {
        this.currentWindow.close();
        // Guarantees browser unload will happen, so no other errors will be thrown.
        delete e['returnValue'];
    }

    private openSizedPopup(urlNavigate: string = 'about:blank'): Window | null {
        /**
         * adding winLeft and winTop to account for dual monitor
         * using screenLeft and screenTop for IE8 and earlier
         */
        const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
        const winTop = window.screenTop ? window.screenTop : window.screenY;
        /**
         * window.innerWidth displays browser window"s height and width excluding toolbars
         * using document.documentElement.clientWidth for IE8 and earlier
         */
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const left = Math.max(0, ((width / 2) - (BrowserConstants.POPUP_WIDTH / 2)) + winLeft);
        const top = Math.max(0, ((height / 2) - (BrowserConstants.POPUP_HEIGHT / 2)) + winTop);

        // tslint:disable-next-line: max-line-length restrict-plus-operands
        return window.open(urlNavigate, BrowserConstants.LIBRARY_NAME, 'width=' + BrowserConstants.POPUP_WIDTH + ', height=' + BrowserConstants.POPUP_HEIGHT + ', top=' + top + ', left=' + left);
    }

    private monitorPopupForHash(popupWindow: Window, timeout: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const maxTicks = timeout / BrowserConstants.POLL_INTERVAL_MS;
            let ticks = 0;
            const intervalId = setInterval(() => {
                if (popupWindow.closed) {
                    // Window is closed
                    this.cleanPopup();
                    clearInterval(intervalId);
                    reject('user context cancel error');
                    return;
                }

                let href: string;
                try {
                    /*
                     * Will throw if cross origin,
                     * which should be caught and ignored
                     * since we need the interval to keep running while on STS UI.
                     */
                    href = popupWindow.location.href;
                } catch (e) { }

                // Don't process blank pages or cross domain
                if (this.isEmpty(href) || href === 'about:blank') {
                    return;
                }

                // Only run clock when we are on same domain
                ticks++;
                const contentHash = popupWindow.location.hash;
                if (this.hashContainsKnownProperties(contentHash)) {
                    // Success case
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    resolve(contentHash);
                    return;
                } else if (ticks > maxTicks) {
                    // Timeout error
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    reject('create monitor window error');
                    return;
                }
                // tslint:disable-next-line: align
            }, BrowserConstants.POLL_INTERVAL_MS);
        });
    }

    private cleanPopup(popupWindow?: Window): void {
        if (popupWindow) {
            // Close window.
            popupWindow.close();
        }
        // Remove window unload function
        // tslint:disable-next-line: no-unbound-method
        window.removeEventListener('beforeunload', this.unloadWindow);
    }

    private isEmpty(str: string): boolean {
        return (typeof str === 'undefined' || !str || 0 === str.length);
    }

    private hashContainsKnownProperties(hash: string): boolean {
        if (this.isEmpty(hash)) {
            return false;
        }

        const parameters: any = this.getDeserializedHash(hash);
        return !!(
            parameters.code ||
            parameters.error_description ||
            parameters.error ||
            parameters.state
        );
    }

    private getDeserializedHash(hash: string): any {
        // Check if given hash is empty
        if (this.isEmpty(hash)) {
            return {};
        }
        // Strip the # symbol if present
        const parsedHash = this.parseHash(hash);
        // If # symbol was not present, above will return empty string, so give original hash value
        // tslint:disable-next-line: max-line-length
        const deserializedHash: any = this.queryStringToObject<any>(this.isEmpty(parsedHash) ? hash : parsedHash);
        // Check if deserialization didn't work
        if (!deserializedHash) {
            throw new Error('deserialize hash error');
        }
        return deserializedHash;
    }

    private parseHash(hashString: string): string {
        const hashIndex1 = hashString.indexOf('#');
        const hashIndex2 = hashString.indexOf('#/');
        if (hashIndex2 > -1) {
            return hashString.substring(hashIndex2 + 2);
        } else if (hashIndex1 > -1) {
            return hashString.substring(hashIndex1 + 1);
        }
        return '';
    }

    private queryStringToObject<T>(query: string): T {
        let match: Array<string>; // Regex for replacing addition symbol with a space
        const pl = /\+/g;
        const search = /([^&=]+)=([^&]*)/g;
        const decode = (s: string): string => decodeURIComponent(decodeURIComponent(s.replace(pl, " ")));
        const obj: {} = {};
        match = search.exec(query);
        while (match) {
            obj[decode(match[1])] = decode(match[2]);
            match = search.exec(query);
        }
        return obj as T;
    }

    private async handleCodeResponse(locationHash: string): Promise<any> {
        // // Check that location hash isn't empty.
        // if (this.isEmpty(locationHash)) {
        //     throw new Error('empty hash error');
        // }

        // // Deserialize hash fragment response parameters.
        // const serverParams = BrowserProtocolUtils.parseServerResponseFromHash(locationHash);

        // // Handle code response.
        // const stateKey = this.browserStorage.generateStateKey(serverParams.state);
        // const requestState = this.browserStorage.getTemporaryCache(stateKey);
        // const authCode = this.authModule.handleFragmentResponse(locationHash, requestState);

        // // Assign code to request
        // this.authCodeRequest.code = authCode;

        // // Acquire token with retrieved code.
        // const tokenResponse = await this.authModule.acquireToken(this.authCodeRequest, cachedNonce, requestState);
        // return tokenResponse;

        // TODO - WIP
    }

}
