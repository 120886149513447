import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthModule } from "./auth/auth.module";
import { GlobalModulesModule } from "./global-module/global-module.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthServices } from "./_services/auth.service";
import { FilterServices } from "./_services/filter.service";
import { LineItemsDifferenceService } from "./_services/line-items-diff.service";
import { GoBackDirective } from "./_directive/go-back.directive";
import { PanelServices } from "./_services/panel.service";
import { NgProgressModule } from "ngx-progressbar";
import { TokenInterceptor } from "./_helpers/token.interceptor";
import { StylePaginatorDirective } from "./_directive/pagination.directive";
import { PublisherService } from "./_services/publisher.service";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { BnNgIdleService } from "bn-ng-idle";
import { LoaderService } from "./_services/loader.service";
import { FormsModule } from "@angular/forms";
import { AppDateAdapter, APP_DATE_FORMATS } from "./_helpers/date.adapter";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GoBackDirective,
    StylePaginatorDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GlobalModulesModule,
    AuthModule,
    HttpClientModule,
    NgProgressModule,
    ToastrModule.forRoot({ positionClass: "toast-bottom-right" }),
    FormsModule,
  ],
  providers: [
    AuthServices,
    PanelServices,
    PublisherService,
    FilterServices,
    LineItemsDifferenceService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
