import { Component, OnInit,ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Filters } from '../../../../../_models/analytics';
import { AnalyticsService } from '../../../../../_services/analytics.service';
@Component({
  selector: 'app-parent-suppliers',
  templateUrl: './parent-suppliers.component.html',
  styleUrls: ['./parent-suppliers.component.scss']
})
export class ParentSuppliersComponent implements OnInit {
  public page = 1;
  public pageSize = 5;
  public parentSupplier: any = [];
  public totalRecords = 0;
  public kpiCards: any = {};
  public showLoader = true;
  public sortName: string = '';
  public sortType: number = 1;
  public searchKey: string = '';
  public loaded: boolean = false;
  public showNoRes: boolean = false;
  public kpiCardSubscription: Subscription;
  public sortSubscription: Subscription;
  public indexStart = 0;
  public indexEnd = 4;
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  public ngOnInit() {
    this.sort(); // subscribing emitted changes by global sort

    // call api only when id="parentSup" becomes visible or is in the viewport
    var observer = new IntersectionObserver((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting === true && !this.loaded) {
        this.initComp();
      }
    }, { threshold: [0.1] });
    observer.observe(document.querySelector("#parentSup"));
  }

  initComp() {
    this.loaded = true;
    this.kpiCardSubscription = this.analyticsService.getAnalytics().subscribe(res => {
      if (res.result) {
        this.kpiCards = res;
        this.getKpi();
      }
    });
  }

  sort() {
    this.sortSubscription = this.analyticsService.getSort().subscribe(res => {
      this.sortName = res.sortKey;
      this.sortType = res.sortType;
      if (this.loaded) {
        this.getKpi();
      }
    })
  }

  public search(searchValue: string): void {
    this.searchKey = searchValue;
    const filter = this.analyticsService.initFilter('parentSupplierId', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter);
  }

  public loadPage(page: number): void {
    const filter = this.analyticsService.initFilter('parentSupplierId', page - 1, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter);
  }

  public getKpi(): void {
    const filter = this.analyticsService.initFilter('parentSupplierId', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter);
  }

  public formatKpiData(filter: Filters): void {
    this.showLoader = true;
    this.analyticsService.getKpiDimensionData(filter).subscribe(kpiDimensions => {
      if (kpiDimensions && kpiDimensions.result && this.kpiCards && this.kpiCards.result) {
        this.showLoader = false;
        if (kpiDimensions.result[0].dimensionValues != null) {
          const { records, totalRecords } = this.analyticsService.createObject
          (
            kpiDimensions.result[0],
            this.kpiCards
          );
          this.showNoRes = false;
          this.parentSupplier = records;
          this.totalRecords = totalRecords;
          this.indexEnd = records.length - 1;
        } else {
          this.showNoRes = true;
        }
      }
    }, err => {
      this.showLoader = false;
      this.showNoRes = true;
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.kpiCardSubscription) {
      this.kpiCardSubscription.unsubscribe();
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
  }

}
