import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppConfig } from '../../_config';
import { AuthServices } from '../../_services/auth.service';


/**
 *Popup component for price agreement creation success
 *
 * @export
 * @class AgreementDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent implements OnInit {
  public supplierName: string;
  public isBuyer: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AgreementDialogComponent>,
    private authService: AuthServices
  ) {
    this.supplierName = data && data.supplierName;
  }

  public ngOnInit() {
    this.isBuyer = this.authService.getIsBuyer();
  }
}
