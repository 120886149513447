export const catalogueStatusConst = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  cataloguePending: "CATALOGUE_PENDING",
  commercialPending: "COMMERCIAL_PENDING",
  pendingReview: "PENDING_REVIEW",
};

export const lineItemStatus = {
  mandatoryPending: "MANDATORY_FIELD_PENDING",
  approvalPending: "APPROVAL_PENDING",
  buyerApprovalPending: "BUYER_APPROVAL_PENDING",
  supplierApprovalPending: "SUPPLIER_APPROVAL_PENDING",
  approved: "APPROVED",
  active: "ACTIVE",
  itemExpired: "ITEM_EXPIRED",
  itemDeleted: "ITEM_DELETED",
  deleted: "DELETED",
  itemRejected: "ITEM_REJECTED",
  deletionPending: "DELETION_PENDING",
  creationApprovalPending: "CREATION_APPROVAL_PENDING",
  changeApprovalPending: "CHANGE_APPROVAL_PENDING",
  updateApprovalPending: "UPDATE_APPROVAL_PENDING",
  blockApprovalPending: "BLOCK_APPROVAL_PENDING",
  unblockApprovalPending: "UNBLOCK_APPROVAL_PENDING",
  changeRejected: "CHANGE_REJECTED",
  creationRejected: "CREATION_REJECTED",
  blockRejected: "BLOCK_REJECTED",
  unblockRejected: "UNBLOCK_REJECTED",
  blocked: "BLOCKED",
  synchronizationInProgress: "SYNCHRONIZATION_IN_PROGRESS",
  synchronizationError: 'SYNCHRONIZATION_ERROR',
  correctionRequestPendingOnSupplier: "CORRECTION_REQUEST_PENDING_ON_SUPPLIER",
  readyForSubmission: "READY_FOR_SUBMISSION",
  blockedWithChangeRequest: "BLOCKED_WITH_CHANGE_REQUEST",
  activeWithChangeRequest: "ACTIVE_WITH_CHANGE_REQUEST",
  pending: "PENDING",
  inProgress: "IN_PROGRESS",
  materialCreationRequired: "MATERIAL_CREATION_REQUIRED",
  readyToSubmit: "READY_TO_SUBMIT",
  exceptionalMandatoryFieldPending: "EXCEPTIONAL_MANDATORY_FIELD_PENDING",
  exceptionMandatoryAndMandatoryFieldPending: "EXCEPTIONAL_MANDATORY_AND_MANDATORY_FIELD_PENDING",
  validationError: 'VALIDATION_ERROR'
};

export const integrationStatus = {
  requiredSynchronization: "REQUIRED_SYNCHRONIZATION",
  pendingSynchronization: "PENDING_SYNCHRONIZATION",
  pendingSynchronizationApproval: "PENDING_SYNCHRONIZATION_APPROVAL",
  successSynchonization: "SUCCESS_SYNCHRONIZATION",
  inactive: "CATALOGUE_INACTIVE",
  syncError: "SYNCHRONIZATION_ERROR",
  synchronizationFinalized: "SYNCHRONIZATION_FINALIZED",
};
export const lineItemStatusNaming = {
  MANDATORY_FIELD_PENDING: {
    name: "MANDATORY FIELD PENDING",
    icon: "",
  },
  APPROVAL_PENDING: {
    name: "APPROVAL PENDING",
    icon: "",
  },
  BUYER_APPROVAL_PENDING: {
    name: "VODAFONE APPROVAL PENDING",
    icon: "",
  },
  SUPPLIER_APPROVAL_PENDING: {
    name: "SUPPLIER APPROVAL PENDING",
    icon: "",
  },
  APPROVED: {
    name: "APPROVED",
    icon: "",
  },
  ITEM_EXPIRED: {
    name: "ITEM EXPIRED",
    icon: "",
  },
  ITEM_DELETED: {
    name: "ITEM DELETED",
    icon: "",
  },
  ITEM_REJECTED: {
    name: "ITEM REJECTED",
    icon: "",
  },
  DELETION_PENDING: {
    name: "DELETION PENDING",
    icon: "",
  },
  BLOCKED: {
    name: "BLOCKED",
    icon: "",
  },
  PENDING: {
    name: "PENDING",
    icon: "",
  },
  MATERIAL_CREATION_REQUIRED: {
    name: "MATERIAL CREATION REQUIRED",
    icon: "",
  },
  READY_TO_SUBMIT: {
    name: "READY TO SUBMIT",
    icon: "",
  },
  EXCEPTIONAL_MANDATORY_FIELD_PENDING: {
    name: "EXCEPTIONAL MANDATORY FIELD PENDING",
    icon: "",
  },
  EXCEPTIONAL_MANDATORY_AND_MANDATORY_FIELD_PENDING: {
    name: "EXCEPTIONAL MANDATORY AND MANDATORY FIELD PENDING",
    icon: "",
  },
};
export const integrationStatusNaming = {
  REQUIRED_SYNCHRONIZATION: {
    name: "SYNC REQUIRED",
    icon: "",
  },
  PENDING_SYNCHRONIZATION: {
    name: "SYNC IN-PROGRESS",
    icon: "",
  },
  SUCCESS_SYNCHRONIZATION: {
    name: "SYNC SUCCESS",
    icon: "",
  },
  CATALOGUE_INACTIVE: {
    name: "SYNC INACTIVE",
    icon: "",
  },
  SYNCHRONIZATION_ERROR: {
    name: "SYNC FAILED",
    icon: "",
  },
  SYNCHRONIZATION_FINALIZED: {
    name: "SYNC FINALIZED",
    icon: "",
  },
};

export const activityType = {
  EXCEL: "EXCEL",
  WORKFLOW: "WORKFLOW",
  AGREEMENT: "AGREEMENT",
};

export const agreementStatus = {
  draft: "DRAFT",
  underReview: "UNDER_REVIEW",
  approved: "APPROVED",
  expired: "EXPIRED",
  archive: "ARCHIVE",
};

export const applicationRoles = {
  CHANNEL_ADMIN: "CHANNEL_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  SUPPLIER_ADMIN: "SUPPLIER_ADMIN",
  UAM_TEAM: "UAM_TEAM",
  SUPPLIER_REVIEWER: "SUPPLIER_REVIEWER",
  LOCAL_MARKET_MANAGER: "LOCAL_MARKET_MANAGER",
  LOCAL_MARKET_OVERSEER: "LOCAL_MARKET_OVERSEER",
  CATEGORY_MANAGER: "CATEGORY_MANAGER",
  SOURCING_SPECIALIST: "SOURCING_SPECIALIST",
};

export const applicationUserStatuses = {
  APPROVED: {
    action: "APPROVE",
    status: "APPROVED",
  },
  REJECTED: {
    action: "REJECT",
    status: "REJECTED",
  },
  BLOCKED: {
    action: "BLOCK",
    status: "BLOCKED",
  },
  UNBLOCKED: {
    action: "UNBLOCK",
    status: "UNBLOCKED",
  },
  PENDING: {
    action: "PENDING",
    status: "PENDING",
  },
};

// tslint:disable-next-line: max-line-length
// export const passwordRegex = `^(?!.*([A-Za-z0-9])\1{2,})(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+=''.:;/[\`\\](){}~""/<>,| ±§_-]).{8,}$`;
export const passwordRegex = `^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$`;

export const envType = {
  dev: "dev",
  preprod: "preprod",
  prod: "prod",
};
export const physcialCategoryConst = {
  ZN01: "Hardware",
  ZN04: "Services",
  ZN05: "Software/Licenses",
};

export const materialItemStatus = {
  mandatoryFieldPending: "MANDATORY_FIELD_PENDING",
  approvalPending: "APPROVAL_PENDING",
  buyerApprovalPending: "BUYER_APPROVAL_PENDING",
  approved: 'APPROVED'
}

export const MAT_DATE_LOCALE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}
