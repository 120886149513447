import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { PriceManagerServices } from '../../../../../_services/price-manager.service';
import { LoaderService } from '../../../../../_services/loader.service';
import { ExcelService } from '../../../../../_services/excel.service';

@Component({
  selector: 'app-material-error-popup',
  templateUrl: './material-error-popup.component.html',
  styleUrls: ['./material-error-popup.component.scss']
})
export class MaterialErrorPopupComponent implements OnInit {
  public errorArray: any;
  public actionFor: string | null = null;
  materilType: string = '';
  materialLongDescription: string = '';
  materialDescription: string = '';
  physicalCategory: string = '';
  materilGroup: string = '';
  dataError: any = '';
  buom: string = '';
  commodityCode: string = '';
  endOfLife: string = '';
  endOfSupport: string = '';
  netWeight: string = '';
  height: string = '';
  length: string = '';
  width: string = '';
  hlwUnit: string = '';
  gam: string = '';
  eccn: string = '';
  stackingNumber: string = '';
  stackingNorms: string = '';
  supplierPartNumber: string = '';
  usefulLife: string = '';
  grossWeight: string = '';
  nature: string = '';
  serializationProfile: string = '';
  manufacturerPartNumber: string = '';
  volumeUnit: string = '';
  volume: string = '';
  constructor(
    public dialogRef: MatDialogRef<MaterialErrorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public priceManagerService: PriceManagerServices,
    private loaderService: LoaderService,
    public dialog: MatDialog,
  ) {
    const displayArray: any = data.errorArray
    const finalDisplayArray: any = []
  
      const uniqueIndexes = {}
      let index = 0
      displayArray.map((item) => {
        let key = `${item.supplierPartNumber}_${item.manufacturerPartNumber || null}_${item.projectId || null}`
        if (item && (item.lineNumber === 0 || item.lineNumber)) {
          key = item.lineNumber
        }
        if (uniqueIndexes && (uniqueIndexes[key] === 0 || uniqueIndexes[key])) {
          item.error = `${finalDisplayArray[uniqueIndexes[key]].error} <br /> ${item.error}`
          finalDisplayArray[uniqueIndexes[key]] = item;
        } else {
          finalDisplayArray.push(item);
          uniqueIndexes[key] = index
          index++
        }
        return true
      })
    
    this.errorArray = finalDisplayArray;
    // console.log(typeof(this.errorArray[0].errorDetails),'errorArray')
    if(this.errorArray[0].errorDetails.includes('materialType')){
    this.materilType = 'Missing value for Material Type'
    }  
    if(this.errorArray[0].errorDetails.includes('materialGroup')){
      this.materilGroup = 'Missing value for Material Group'
    } 
     if(this.errorArray[0].errorDetails.includes('physicalCategory')){
      this.physicalCategory = 'Missing value for Physical Category'
    } 
     if(this.errorArray[0].errorDetails.includes('materialDescription')){
      this.materialDescription = 'Missing value for Material Description'
    } 
     if(this.errorArray[0].errorDetails.includes('materialLongDescription')){
      this.materialLongDescription = 'Missing value for Material Long Description'
    } 
 
      if(this.errorArray[0].errorDetails.includes('buom')){
        this.buom  = 'Missing value for buom'
      } 
       if(this.errorArray[0].errorDetails.includes('commodityCode')){
        this.commodityCode = 'Missing value for Physical Category'
      } 
       if(this.errorArray[0].errorDetails.includes('endOfLife')){
        this.endOfLife = 'Missing value for Material Description'
      } 
       if(this.errorArray[0].errorDetails.includes('endOfSupport')){
        this.endOfSupport = 'Missing value for Material Long Description'
      } 
      if(this.errorArray[0].errorDetails.includes('netWeight')){
        this.netWeight  = 'Missing value for Material Type'
        }  
        if(this.errorArray[0].errorDetails.includes('height')){
          this.height = 'Missing value for Material Group'
        } 
         if(this.errorArray[0].errorDetails.includes('length')){
          this.length = 'Missing value for Physical Category'
        } 
         if(this.errorArray[0].errorDetails.includes('width')){
          this.width = 'Missing value for Material Description'
        } 
         if(this.errorArray[0].errorDetails.includes('hlwUnit')){
          this.hlwUnit = 'Missing value for Material Long Description'
        } 
        if(this.errorArray[0].errorDetails.includes('gam')){
          this.gam = 'Missing value for Material Type'
          }  
          if(this.errorArray[0].errorDetails.includes('eccn')){
            this.eccn = 'Missing value for Material Group'
          } 
           if(this.errorArray[0].errorDetails.includes('stackingNumber')){
            this.stackingNumber = 'Missing value for Physical Category'
          } 
           if(this.errorArray[0].errorDetails.includes('stackingNorms')){
            this.stackingNorms = 'Missing value for Material Description'
          } 
           if(this.errorArray[0].errorDetails.includes('supplierPartNumber')){
            this.supplierPartNumber = 'Missing value for Material Long Description'
          } 
          if(this.errorArray[0].errorDetails.includes('usefulLife')){
            this.usefulLife = 'Missing value for Material Type'
            }  
            if(this.errorArray[0].errorDetails.includes('grossWeight')){
              this.grossWeight = 'Missing value for Material Group'
            } 
             if(this.errorArray[0].errorDetails.includes('hlwUnit')){
              this.hlwUnit = 'Missing value for Physical Category'
            } 
             if(this.errorArray[0].errorDetails.includes('nature')){
              this.nature = 'Missing value for Material Description'
            } 
             if(this.errorArray[0].errorDetails.includes('serializationProfile')){
              this.serializationProfile = 'Missing value for Material Long Description'
            } 
            if(this.errorArray[0].errorDetails.includes('manufacturerPartNumber')){
              this.manufacturerPartNumber  = 'Missing value for Material Type'
              }  
              if(this.errorArray[0].errorDetails.includes('volumeUnit')){
                this.volumeUnit = 'Missing value for Material Group'
              } 
              if(this.errorArray[0].errorDetails.includes('volume')){
                this.volume = 'Missing value for Physical Category'
              } 
              // 
            
              //  if(this.errorArray[0].errorDetails.includes('nature')){
              //   this.nature = 'Missing value for Material Description'
              // } 
              //  if(this.errorArray[0].errorDetails.includes('serializationProfile')){
              //   this.serializationProfile = 'Missing value for Material Long Description'
              // } 
              // if(this.errorArray[0].errorDetails.includes('manufacturerPartNumber')){
              //   this.manufacturerPartNumber  = 'Missing value for Material Type'
              //   }  
              //   if(this.errorArray[0].errorDetails.includes('volumeUnit')){
              //     this.volumeUnit = 'Missing value for Material Group'
              //   } 
              
  
    this.dataError=  this.materilType+this.materilGroup+this.physicalCategory+this.materialDescription
   +this.materialLongDescription;
  }

  public ngOnInit() {
  }


}
