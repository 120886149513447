import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'readable' })

export class Readable implements PipeTransform {
  public transform(value: string): string {
    if (value) {
      return value = value.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(value) { return value.toUpperCase()});
    }
  }
}
