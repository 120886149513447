import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../_config';
import { AuthServices } from './auth.service';
const { accessUrl, configUrl } = AppConfig;
import { Observable, Subject } from 'rxjs';
import { PanelServices } from './panel.service';

@Injectable({
  providedIn: 'root'
})
export class UsersServices {
  private fetchData = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices, private panelService: PanelServices) { }

  private createUserUrl: string = accessUrl + 'user';
  private getUserUrl: string = accessUrl + 'user/list';
  public getUserForAGGridUrl: string = accessUrl + 'user/list-for-ag-grid';
  private updateUserUrl: string = accessUrl + 'user/update-user';
  private getRolesUrl: string = accessUrl + 'role';
  private downloadUserUrl: string = accessUrl + 'user/download-list';
  private rejectionReasonUrl: string = accessUrl + 'user/get-rejection-reason';
  private managerSupplierUserUrl: string = accessUrl + 'user/manage-supplier-user';
  private getUserLocalMarketsUrl: string = accessUrl + 'user/user-local-markets';
  private createUserActionsUrl: string = accessUrl + 'user/user-actions';
  private saveSupplierDetailsUrl: string = configUrl + 'pm/integration/saveSupplierDetails';

  /**
   *Api function to create a new user
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public createUser(data) {
    return this.http.post(this.createUserUrl, data, this.authService.getAccessHeaders());
  }
  /**
   *Api function to get the user list
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public getUsers(data) {
    data = Object.entries(data)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    return this.http.post(this.getUserUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to get the user list
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public getUserList(data: any) {
    return this.http.post(this.getUserUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to get the user details for Ag grid table
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public getUsersForAGGrid(data) {
    const role = this.authService.getRole();

    data.activeTab = this.panelService.getUserTab() == 'supplierUserAccount' ? 'supplier' : 'vodafone'
    return this.http.post(this.getUserForAGGridUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to get the reject reason
   *
   * @param {string} userId
   * @return {*} 
   * @memberof UsersServices
   */
  public getRejectionReason(userId: string) {
    const url = `${this.rejectionReasonUrl}?userId=${userId}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Api function to save te supplier user details
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public managerSupplierUser(data: any) {
    return this.http.post(this.managerSupplierUserUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function that updates the user details
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public updateUser(data) {
    return this.http.post(this.updateUserUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Function that set the user details
   *
   * @param {*} data
   * @memberof UsersServices
   */
  public setUserData(data: any) {
    this.fetchData.next({ result: data });
  }

  /**
   *Function that returns the user data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof UsersServices
   */
  public getUserData(): Observable<any> {
    return this.fetchData.asObservable();
  }
  /**
   *Api function that returns all role list
   *
   * @return {*} 
   * @memberof UsersServices
   */
  public getRoles() {
    return this.http.get(this.getRolesUrl, this.authService.getAccessHeaders());
  }

  /**
   *Api function to download the user data
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public downloadExcel(data) {
    data = Object.entries(data)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    return this.http.post(this.downloadUserUrl, data, { ...this.authService.getAccessHeaders(), responseType: 'blob' });
  }

  /**
   *Api function that returns local markets based on the user
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public getUserLocalMarkets(data) {
    return this.http.post(this.getUserLocalMarketsUrl, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function to create the user actions
   *
   * @param {*} data
   * @return {*} 
   * @memberof UsersServices
   */
  public createUserActions(data) {
    return this.http.post(this.createUserActionsUrl, data, this.authService.getAccessHeaders());
  }

  public saveSupplierDetails(data) {
    return this.http.post(this.saveSupplierDetailsUrl, data, this.authService.getAccessHeaders());
  }

}
