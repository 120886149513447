import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';

/**
 *Delegation selection component whether it's Price agreement delegation or Approval rights delegation
 *
 * @export
 * @class DelegateSelectionPopupComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-delegate-selection-popup',
  templateUrl: './delegate-selection-popup.component.html',
  styleUrls: ['./delegate-selection-popup.component.scss']
})
export class DelegateSelectionPopupComponent implements OnInit {

  public delegateOption;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DelegateSelectionPopupComponent>
  ) { }

  ngOnInit() {
  }
  public onDelegateOption() {
    let delegateFrom = this.delegateOption;
    this.dialogRef.close(true);
    if (this.delegateOption && this.delegateOption === 'priceAgreement') {
      this.router.navigate(['/dashboard/price-agreements'], { queryParams: { 'delegate': delegateFrom } });
    } else if (this.delegateOption && this.delegateOption === 'approverRights') {
      this.router.navigate(['/dashboard/profile/approver-delegation']);
    }
  }
}
