import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category-blocks',
  templateUrl: './category-blocks.component.html',
  styleUrls: ['./category-blocks.component.scss']
})
export class CategoryBlocksComponent implements OnInit {
@Input() public cateList: any;
@Input() public index: number;
@Input() public page: number;
public CategoryTitle: any;
// tslint:disable-next-line: no-empty
constructor() { }

public ngOnInit() {
  this.CategoryTitle = this.cateList.subTitleName ? this.cateList.subTitleName == 'parentSupplierId' ? 'Parent Supplier ID' :
  this.cateList.subTitleName == 'opcoCode' ? 'Local Market Code' : '' : '';
  }
}
