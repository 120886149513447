import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "exactMatchFilter",
})
export class ExactMatchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    return items.filter(
      (item) => item.value.toLowerCase() === searchText.toLowerCase()
    );
  }
}
