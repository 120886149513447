import { Injectable } from '@angular/core';
import { AppConfig } from '../_config';
import { HttpClient } from '@angular/common/http';
import { AuthServices } from '../_services/auth.service';
import { CardGraphDetails, ChartResult, ChartsData, Filters, KpiDimension, lastYearGraph } from '../_models/analytics';
import { IServerResponse, IServerViewAnalyticsResponse } from '../_models/response';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


const { analyticsUrl, adminServiceUrl } = AppConfig;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsNntwkService {

  private anlyticsResult = new BehaviorSubject({ result: null });
  private kpiDimensionResult = new Subject<any>();
  private sortObj = new Subject<any>();
  private kpiSortKey = {
    attibName: 'activePriceAgreements',
    sortType: 0
  };
  private analyticsFilter: any;
  private dateFilter: Array<string>;

  constructor(
    private http: HttpClient,
    private authService: AuthServices
  ) { }

  private chartDataUrl: string = analyticsUrl + 'pm/analytics/getAnalyticsDashboardView';
  private dimensionUrl: string = analyticsUrl + 'pm/analytics/getKpiByDimension';
  private lastOneYearDimentionUrl: string = analyticsUrl + 'pm/analytics/aggregateDimensionMonthWiseForLastOneYear';
  private oneYearGraph: string = analyticsUrl + 'pm/analytics/getKpiGraphDataForLastOneYearMonthWise';
  // private expiringSpnUrl: string = adminServiceUrl + 'pm/analytics/getExpiringSpnWithFilteres';
  private expiringSpnUrl: string = analyticsUrl + 'pm/analytics/getExpiringSpnWithFilteres';
  private downloadUrl: string = analyticsUrl + 'pm/analytics/downloadExcel';
  private viewAnalyticsByDimension: string = analyticsUrl + 'pm/analytics/getViewAnalyticsByDimension'; // [VOD-1598]

  public analyticsArr: any = [];


  public getChartsData(filters: object): Observable<IServerResponse<ChartsData[]>> {
    return this.http.post<IServerResponse<ChartsData[]>>(this.chartDataUrl, filters, this.authService.getAccessHeaders());
  }

  public setAnalytics(data: any): void {
    this.anlyticsResult.next({ result: data });
  }

  public getAnalytics(): Observable<any> {
    return this.anlyticsResult.asObservable();
  }

  public getKpiDimensionData(filters: Filters): Observable<IServerResponse<KpiDimension[]>> {
    return this.http.post<IServerResponse<KpiDimension[]>>(this.dimensionUrl, filters, this.authService.getAccessHeaders());
  }

  /*[VOD-1598] [VOD-1724] start*/
  public getViewAnalyticsByDimension(filters: Filters, PaId): Observable<IServerViewAnalyticsResponse<KpiDimension[]>> {
    const url = PaId ? `${this.viewAnalyticsByDimension}/${PaId}` : this.viewAnalyticsByDimension;
    return this.http.post<IServerViewAnalyticsResponse<KpiDimension[]>>(url, filters, this.authService.getAccessHeaders());
  }
  /* [VOD-1598] [VOD-1724] end */

  public getLastOneYearDimention(filters): Observable<IServerResponse<lastYearGraph[]>> {
    return this.http.post<IServerResponse<lastYearGraph[]>>(this.lastOneYearDimentionUrl, filters, this.authService.getAccessHeaders());
  }

  public setKpiDimension(data: any): void {
    this.kpiDimensionResult.next({ result: data });
  }

  public getKpiDimension(): Observable<any> {
    return this.kpiDimensionResult.asObservable();
  }

  public getKpiCardOneYearGraph(cardName): Observable<IServerResponse<CardGraphDetails[]>> {
    const body = {
      kpiCardType: cardName,
      filters: this.analyticsFilter || []
    }
    return this.http.post<IServerResponse<CardGraphDetails[]>>(this.oneYearGraph, body, this.authService.getAccessHeaders());
  }

  public getSort(): Observable<any> {
    return this.sortObj.asObservable();
  }

  public setSort(sort: object) {
    this.sortObj.next(sort);
    this.setKpiSortKey(sort);
  }

  public getKpiSortKey() {
    return this.kpiSortKey;
  }

  public setKpiSortKey(sort) {
    this.kpiSortKey = sort;
  }

  public setAnalyticsFilter(obj: any) {
    this.analyticsFilter = obj;
  }

  public getAnalyticsFilter() {
    return this.analyticsFilter;
  }

  public setDatesFilter(dates: Array<string>) {
    this.dateFilter = dates;
  }

  public getFilteredDates(): Array<string> {
    return this.dateFilter;
  }

  //  [VOD-1724] start
  public getDownloadFile(body, priceAgreementId?) {
    const url = priceAgreementId && priceAgreementId != null ? `${this.downloadUrl}/${priceAgreementId}` : this.downloadUrl;
    return this.http.post(url, body, { ...this.authService.getAccessHeadersForFile(), responseType: 'blob' });
  }
  //  [VOD-1724] end

  public downloadFile(data, fileName) {
    // IE11 & Edge
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(data, `${fileName}-${new Date().toISOString()}.xlsx`);
      return;
    }

    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    a.download = `${fileName}-${new Date().toISOString()}.xlsx`;
    a.click();
  }


  public initFilter(dimensionName: string, pageNumber: number, perPage: number, sortKeyName: string, sortType: number, searchKey: string): Filters {
    let filters = {
      dimensionValueSearchKey: searchKey,
      dimensionName: dimensionName,
      pageNumber: pageNumber,
      perPage: perPage,
      kpiSortKey: {
        attibName: sortKeyName || 'activePriceAgreements',
        sortType: sortType || 0
      },
      datesFilter: this.dateFilter || [],
      filters: this.analyticsFilter || []
    }
    return filters;
  }

  public createObject(kpiByDimension: KpiDimension, kpiCards) {
    let kpi = this.arrToObj(kpiCards.result);
    let analyticsObj = {};
    let totalRecords = 0;
    this.analyticsArr = [];

    totalRecords = kpiByDimension.totalRecords;
    kpiByDimension.dimensionValues.forEach((element, index) => {
      let paPercent = 0;
      let itPercent = 0;
      let poPercent = 0;
      let ovPercent = 0;
      let ivPercent = 0;
      let voPercent = 0;
      let vaPercent = 0;
      let vlPercent = 0;
      let vrPercent = 0;


      if (element.kpis.activePriceAgreement && kpi.activePriceAgreements) {
        // tslint:disable-next-line:radix
        paPercent = (parseInt(element.kpis.activePriceAgreement) / parseInt(kpi.activePriceAgreements)) * 100;
      }
      if (element.kpis.activeItems && kpi.activeItems) {
        // tslint:disable-next-line:radix
        itPercent = (parseInt(element.kpis.activeItems) / parseInt(kpi.activeItems)) * 100;
      }
      if (element.kpis.orderIssueds && kpi.orderIssueds) {
        // tslint:disable-next-line:radix
        poPercent = (parseInt(element.kpis.orderIssueds) / parseInt(kpi.orderIssueds)) * 100;
      }
      if (element.kpis.orderValues && kpi.orderValues) {
        // tslint:disable-next-line:radix
        ovPercent = (parseInt(element.kpis.orderValues) / parseInt(kpi.orderValues)) * 100;
      }
      if (element.kpis.invoicedValues && kpi.invoicedValues) {
        // tslint:disable-next-line:radix
        ivPercent = (parseInt(element.kpis.invoicedValues) / parseInt(kpi.invoicedValues)) * 100;
      }
      if (element.kpis.voucherValues && kpi.voucherValues) {
        // tslint:disable-next-line:radix
        voPercent = (parseInt(element.kpis.voucherValues) / parseInt(kpi.voucherValues)) * 100;
      }
      if (element.kpis.remainigVoucherValues && kpi.remainigVoucherValues) {
        // tslint:disable-next-line:radix
        vaPercent = (parseInt(element.kpis.remainigVoucherValues) / parseInt(kpi.remainigVoucherValues)) * 100;
      }
      if (element.kpis.valueLeakageIdntifiers && kpi.valueLeakageIdntifiers) {
        // tslint:disable-next-line:radix
        vlPercent = (parseInt(element.kpis.valueLeakageIdntifiers) / parseInt(kpi.valueLeakageIdntifiers)) * 100;
      }
      if (element.kpis.valueLeakageRecovered && kpi.valueLeakageRecovered) {
        // tslint:disable-next-line:radix
        vrPercent = (parseInt(element.kpis.valueLeakageRecovered) / parseInt(kpi.valueLeakageRecovered)) * 100;
      }
      analyticsObj = {
        title: element.dimensionValuesName,
        subTitleName: kpiByDimension.dimensionName,
        subTitleValue: element.dimensionId,
        pa: {
          value: element.kpis.activePriceAgreement,
          percentage: paPercent
        },
        it: {
          value: element.kpis.activeItems,
          percentage: itPercent
        },
        po: {
          value: element.kpis.orderIssueds,
          percentage: poPercent
        },
        ov: {
          value: element.kpis.orderValues,
          percentage: ovPercent
        },
        iv: {
          value: element.kpis.invoicedValues,
          percentage: ivPercent
        },
        vo: {
          value: element.kpis.voucherValues,
          percentage: voPercent
        },
        va: {
          value: element.kpis.remainigVoucherValues,
          percentage: vaPercent
        },
        vl: {
          value: element.kpis.valueLeakageIdntifiers,
          percentage: Math.abs(vlPercent)   /* [VOD-1692]   */
        },
        vr: {
          value: element.kpis.valueLeakageRecovered,
          percentage: vrPercent
        },
      };
      this.analyticsArr.push(analyticsObj);
    });
    return {
      records: this.analyticsArr,
      totalRecords
    }
  }

  /*  [VOD-1598] */
  public createObjectViewAnalytics(kpiByDimension: KpiDimension, totalCards) {
    let kpi = totalCards;
    let analyticsObj = {};
    let totalRecords = 0;
    this.analyticsArr = [];

    totalRecords = kpiByDimension.totalRecords;
    kpiByDimension.dimensionValues.forEach((element, index) => {
      let paPercent = 0;
      let itPercent = 0;
      let poPercent = 0;
      let ovPercent = 0;
      let ivPercent = 0;
      let voPercent = 0;
      let vaPercent = 0;
      let vlPercent = 0;
      let vrPercent = 0;


      if (element.kpis.activePriceAgreement && kpi.activePriceAgreements) {
        // tslint:disable-next-line:radix
        paPercent = (parseInt(element.kpis.activePriceAgreement) / parseInt(kpi.activePriceAgreements)) * 100;
      }
      if (element.kpis.activeItems && kpi.activeItems) {
        // tslint:disable-next-line:radix
        itPercent = (parseInt(element.kpis.activeItems) / parseInt(kpi.activeItems)) * 100;
      }
      if (element.kpis.orderIssueds && kpi.orderIssueds) {
        // tslint:disable-next-line:radix
        poPercent = (parseInt(element.kpis.orderIssueds) / parseInt(kpi.orderIssueds)) * 100;
      }
      if (element.kpis.orderValues && kpi.orderValues) {
        // tslint:disable-next-line:radix
        ovPercent = (parseInt(element.kpis.orderValues) / parseInt(kpi.orderValues)) * 100;
      }
      if (element.kpis.invoicedValues && kpi.invoicedValues) {
        // tslint:disable-next-line:radix
        ivPercent = (parseInt(element.kpis.invoicedValues) / parseInt(kpi.invoicedValues)) * 100;
      }
      if (element.kpis.voucherValues && kpi.voucherValues) {
        // tslint:disable-next-line:radix
        voPercent = (parseInt(element.kpis.voucherValues) / parseInt(kpi.voucherValues)) * 100;
      }
      if (element.kpis.remainigVoucherValues && kpi.remainigVoucherValues) {
        // tslint:disable-next-line:radix
        vaPercent = (parseInt(element.kpis.remainigVoucherValues) / parseInt(kpi.remainigVoucherValues)) * 100;
      }
      if (element.kpis.valueLeakageIdntifiers && kpi.valueLeakageIdntifiers) {
        // tslint:disable-next-line:radix
        vlPercent = (parseInt(element.kpis.valueLeakageIdntifiers) / parseInt(kpi.valueLeakageIdntifiers)) * 100;
      }
      if (element.kpis.valueLeakageRecovered && kpi.valueLeakageRecovered) {
        // tslint:disable-next-line:radix
        vrPercent = (parseInt(element.kpis.valueLeakageRecovered) / parseInt(kpi.valueLeakageRecovered)) * 100;
      }
      analyticsObj = {
        title: element.dimensionValuesName,
        subTitleName: kpiByDimension.dimensionName,
        subTitleValue: element.dimensionId,
        pa: {
          value: element.kpis.activePriceAgreement,
          percentage: paPercent
        },
        it: {
          value: element.kpis.activeItems,
          percentage: itPercent
        },
        po: {
          value: element.kpis.orderIssueds,
          percentage: poPercent
        },
        ov: {
          value: element.kpis.orderValues,
          percentage: ovPercent
        },
        iv: {
          value: element.kpis.invoicedValues,
          percentage: ivPercent
        },
        vo: {
          value: element.kpis.voucherValues,
          percentage: voPercent
        },
        va: {
          value: element.kpis.remainigVoucherValues,
          percentage: vaPercent
        },
        vl: {
          value: element.kpis.valueLeakageIdntifiers,
          percentage: vlPercent
        },
        vr: {
          value: element.kpis.valueLeakageRecovered,
          percentage: vrPercent
        },
      };
      this.analyticsArr.push(analyticsObj);
    });
    return {
      records: this.analyticsArr,
      totalRecords
    }
  }

  /* [VOD-1598] */


  arrToObj(arr: Array<any>) {
    let obj: any = {};
    arr.forEach(element => {
      obj[element.kpiCardName] = element.totalValue;
    });
    return obj;
  }

  public expiringItems(data: any) {
    return this.http.post(this.expiringSpnUrl, data, this.authService.getAccessHeaders());
  }

  public globalSorting(data: any) {
    if (data && data.length) {
      return {
        attibName: data[0].colId,
        sortType: data[0].sort.toUpperCase() === 'ASC' ? 0 : 1
      };
    }
    return {
      // attibName: 'createdDate',
      attibName: 'updatedAt',
      sortType: 1
    };
  }

}
