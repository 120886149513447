import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  // tslint:disable-next-line: only-arrow-functions
  window.console.log = function() {};
  enableProdMode();
}

import {LicenseManager} from '@ag-grid-enterprise/core';
// tslint:disable-next-line: max-line-length
LicenseManager.setLicenseKey('CompanyName=BLOCKGEMINI TECHNOLOGIES FZ LLC,LicensedGroup=BlockGemini,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007472,ExpiryDate=23_March_2021_[v2]_MTYxNjQ1NzYwMDAwMA==5f2eabf06d23901ee9fd2c19992d7d8f');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
