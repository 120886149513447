import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PriceManagerServices } from "./../../_services/price-manager.service";
import { TaskServices } from "./../../_services/task.service";

/**
 *Navigation menu component for task page
 *
 * @export
 * @class NavMyTasksComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-nav-my-tasks",
  templateUrl: "./nav-my-tasks.component.html",
  styleUrls: ["./nav-my-tasks.component.scss"],
})
export class NavMyTasksComponent implements OnInit {
  public router: string;
  public allTask: number;
  public delegatedTask: number;
  public pending: number;
  public completed: number;
  public updateRequired: number;
  public dismissed: number;
  public pendingApproval: number;
  public syncPending: number;
  public pendingCompletion: number;
  public pendingSubmission: number;
  public taskType: object;
  public taskStatus: object;
  public filterData: any = {};
  public taskActive = "PENDING";
  // tslint:disable-next-line:no-empty
  constructor(
    // tslint:disable-next-line: variable-name
    public _router: Router,
    public priceManagerService: PriceManagerServices,
    private taskTypeService: TaskServices
  ) {}

  public ngOnInit() {
    this.taskTypeService.setTaskActiveStatus(["PENDING"]);
    this.getPendingTaskCount();
    this.router = this._router.url;
  }
  public reDirect() {
    this._router.navigate(["/dashboard/my-tasks"]);
  }

  public getPendingTaskCount() {
    this.priceManagerService.getPendingTaskCount().subscribe((data) => {
      this.allTask = data["result"].allTask;
      this.pending = data["result"].taskStatus.PENDING
        ? data["result"].taskStatus.PENDING
        : 0;
      this.completed = data["result"].taskStatus.COMPLETED
        ? data["result"].taskStatus.COMPLETED
        : 0;
      this.dismissed = data["result"].taskStatus.DISMISSED
        ? data["result"].taskStatus.DISMISSED
        : 0;
      this.pendingApproval = data["result"].taskType.PENDING_APPROVAL
        ? data["result"].taskType.PENDING_APPROVAL
        : 0;
      this.syncPending = data["result"].taskType.SYNCHRONIZATION_PENDING
        ? data["result"].taskType.SYNCHRONIZATION_PENDING
        : 0;
      this.pendingCompletion = data["result"].taskType.PENDING_COMPLETION
        ? data["result"].taskType.PENDING_COMPLETION
        : 0;
      this.pendingSubmission = data["result"].taskType.PENDING_SUBMISSION
        ? data["result"].taskType.PENDING_SUBMISSION
        : 0;
      this.updateRequired = data["result"].taskType.UPDATE_REQUIRED
        ? data["result"].taskType.UPDATE_REQUIRED
        : 0;
      this.delegatedTask = data["result"].delegatedTask;
    });
  }

  public taskFilter(type: string, value: string) {
    this.taskActive = value;
    if (type === "status" && value === "PENDING") {
      this.filterData.name = type;
      this.filterData.type = "array";
      this.filterData.value = [value];
      this.taskTypeService.setTaskActiveStatus([value]);
    }
    if (type === "status" && value === "COMPLETED") {
      this.filterData.name = type;
      this.filterData.type = "array";
      this.filterData.value = ["DISMISSED", "COMPLETED"];
      this.taskTypeService.setTaskActiveStatus(["DISMISSED", "COMPLETED"]);
    }
    if (type === "type" && value === "SYNCHRONIZATION_PENDING") {
      this.filterData.name = type;
      this.filterData.type = "string";
      this.filterData.value = value;
    }
    this.taskTypeService.setTopMenuTaskFilter(this.filterData);
  }
}
