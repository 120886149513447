import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { UsersServices } from '../../_services/users.service';
import { ToggleItemService } from '../../_services/toggle-item.service';
import { LoaderService } from './../../_services/loader.service';
import { PriceManagerServices } from '../../_services/price-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { PriceMessagesComponent } from '../price-messages/price-messages.component';
import { AuthServices } from '../../_services/auth.service';
import * as moment from 'moment';
import { applicationRoles } from "../../_config/constants";

/**
 *Popup component for Price agreement/Approval rights delegation
 *
 * @export
 * @class DelegateActionPopupComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-delegate-action-popup',
  templateUrl: './delegate-action-popup.component.html',
  styleUrls: ['./delegate-action-popup.component.scss']
})
export class DelegateActionPopupComponent implements OnInit {
  public priceAgreement: any;
  public priceAgreementIdList: any;
  public actionType: string;
  public myControl: FormControl = new FormControl();
  public pageNumber = 1;
  public perPage = 50;
  public search: string;
  public delegate: string;
  public result: any = [];
  public resultUser: any = [];
  public selectedValue: any;
  public selectedDelegatedBy: any;
  public selectedData: any;
  public selectedDelegatedByData: any;
  public option: any;
  public loggedInEmail: any;
  public errorMessage = '';
  public startDate;
  public endDate;
  public minDate: Date = moment().toDate();
  public minEndDate: Date = moment().toDate();
  public maxDate = moment().add(90, 'days').toDate();
  public maxStartDate = moment().add(60, 'days').toDate();
  public userRole: any;

  public filteredOptions: Observable<string[]>;
  userId: string;
  constructor(
    private userInfoService: UsersServices,
    public toggleItemService: ToggleItemService,
    private loaderService: LoaderService,
    private priceService: PriceManagerServices,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DelegateActionPopupComponent>,
    private authServices: AuthServices
  ) {
    this.priceAgreement = data && data.priceAgreementId;
    this.delegate = data && data.delegate;
    this.actionType = data && data.actionType;
  }
  ngOnInit() {
    this.userId = this.authServices.getUserId();
    this.userRole = this.authServices.getRole();
    this.loggedInEmail = this.authServices.getEmail();
    if (this.delegate === 'approverRights') {
      this.priceAgreementIdList = this.priceAgreement.map((d: any) => d.l4Code.split(' - ')[0]);
    } else {
      this.priceAgreementIdList = this.priceAgreement.map((d: any) => d.masterAgreementName.pId);
    }
  }

  public onDateChange(date) {
    this.endDate = '';
    this.minEndDate = moment(date.value).toDate();
    this.maxDate = moment(date.value).add(90, 'days').toDate();
  }

  public searchUser(data) {
    if (data.target.value.length >= 3) {
      let userRole = [];
      let filtersMod = {};
      if (this.delegate === 'approverRights') {
        if (this.userRole && this.userRole.includes("LOCAL_MARKET")) {
          userRole = [applicationRoles.LOCAL_MARKET_MANAGER, applicationRoles.LOCAL_MARKET_OVERSEER];
        } else if (this.userRole && this.userRole === applicationRoles.CATEGORY_MANAGER) {
          userRole = [applicationRoles.CATEGORY_MANAGER]
        }
        filtersMod = {
          "loginId": { "filterType": "text", "type": "contains", "filter": data.target.value },
          "role": { "values": userRole, "filterType": "set" }
        }
      } else {
        filtersMod = {
          "loginId": { "filterType": "text", "type": "contains", "filter": data.target.value },
        }
      }
      this.userInfoService.getUsersForAGGrid({
        "startRow": 0,
        "endRow": 1000,
        "rowGroupCols": [],
        "valueCols": [],
        "pivotCols": [],
        "pivotMode": false,
        "groupKeys": [],
        "filterModel": filtersMod,
        "sortModel": []
      }).subscribe(res => {
        this.result = res['result'].rows.filter((o1) => {
          return o1.loginId !== this.loggedInEmail;
        });
      });
    }
  }
  public searchUserDelegate(data) {
    if (data.target.value.length >= 3) {
      this.userInfoService.getUserList({
        pageNumber: this.pageNumber,
        perPage: this.perPage,
        searchByName: data.target.value
      }).subscribe(res => {
        this.resultUser = res['result'].rows.filter((o1) => {
          return o1.loginId !== this.loggedInEmail;
        });
      });
    }
  }

  public selectAndSetValue(option) {
    this.errorMessage = '';
    this.selectedData = this.result.find(obj => {
      return obj.loginId === this.selectedValue;
    });
  }
  public selectAndSetValueDelegatedBy(option) {
    this.errorMessage = '';
    this.selectedDelegatedByData = this.resultUser.find(obj => {
      return obj.loginId === this.selectedDelegatedBy;
    });
  }

  /**
   *Function to delegate the price agreement / approval rights delegation
   *
   * @return {*} 
   * @memberof DelegateActionPopupComponent
   */
  public onDelegate() {
    if (!this.selectedData) {
      this.errorMessage = 'Access has been denied';
      return;
    }
    if (!this.priceAgreementIdList || !this.startDate || !this.endDate) {
      this.errorMessage = 'Invalid';
      return;
    }
    this.loaderService.startLoading();
    const startFrom = new Date(this.startDate);
    const endTo = new Date(this.endDate);
    startFrom.setHours(12, 0, 0, 0);
    endTo.setHours(12, 0, 0, 0);
    if (this.delegate === 'approverRights') {
      const payload = {
        delegatedBy: this.userId,
        delegatedTo: this.selectedData.uuid,
        l4CodeList: this.priceAgreementIdList || [],
        startDate: startFrom.toISOString(),
        endDate: endTo.toISOString()
      }
      this.priceService.delegateApproverRights(payload).subscribe((response: any) => {
        this.loaderService.stopLoading();
        this.dialogRef.close(true);
        this.onSaveErrorPopup({
          header: 'Success',
          message: this.delegate === 'approverRights' ? `You have successfully delegated the material group (L4) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> from <span class="blue-span">${moment(this.startDate).format('DD-MM-YYYY')}</span> to <span class="blue-span">${moment(this.endDate).format('DD-MM-YYYY')}</span>. At the end of the delegation period, the material group (L4) will return to you. `
            : `You have successfully delegated the selected Price Agreement(s) and the related pending task(s) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> from <span class="blue-span">${moment(this.startDate).format('DD-MM-YYYY')}</span> to <span class="blue-span">${moment(this.endDate).format('DD-MM-YYYY')}</span>. At the end of the delegation period, the Price Agreement(s) and the related pending task(s) will return to you. `
        });
      }, err => {
        this.loaderService.stopLoading();
        this.onSaveErrorPopup({
          header: 'Delegation Material Group (L4) Error',
          message: err.error.message || 'Unable to delegate this material Group (L4)',
          icon: 'info-pophover-blue.svg',
          buttonText: 'OK'
        });
      })

    } else {
      const payload = {
        email: this.selectedData.loginId,
        priceAgreementIds: this.priceAgreementIdList || [],
        startDate: startFrom.toISOString(),
        endDate: endTo.toISOString()
      }
      this.priceService.delegateAgreement(payload).subscribe((response: any) => {
        this.loaderService.stopLoading();
        this.dialogRef.close(true);
        this.onSaveErrorPopup({
          header: 'Success',
          message: `You have successfully delegated the selected Price Agreement(s) and the related pending task(s) to <span class="blue-span">${this.selectedData.firstName} ${this.selectedData.lastName}</span> from <span class="blue-span">${moment(this.startDate).format('DD-MM-YYYY')}</span> to <span class="blue-span">${moment(this.endDate).format('DD-MM-YYYY')}</span>. At the end of the delegation period, the Price Agreement(s) and the related pending task(s) will return to you. `
        });
      }, err => {
        this.loaderService.stopLoading();
        this.onSaveErrorPopup({
          header: 'Invalid Action',
          message: err.error.message || 'Unable to delegate this price agreements',
          icon: 'info-pophover-blue.svg',
          buttonText: 'OK'
        });
      })
    }
  }
  private onSaveErrorPopup(obj?: { header: string, message: string, icon?: string, buttonText?: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj && obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        buttonText: obj.buttonText ? obj.buttonText : 'Done'
      };
      if (obj.icon) {
        dialogConfig.data.icon = obj.icon
      }
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }

}
