import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ToggleItemService } from "../../_services/toggle-item.service"; // [VOD-1684]
import { PriceManagerServices } from "../../_services/price-manager.service";
import { LoaderService } from "../../_services/loader.service";
import { Router } from "@angular/router";
import * as moment from "moment";


/**
 *Custom comfirmation popup component
 *
 * @export
 * @class WarningComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-warning",
  templateUrl: "./warning.component.html",
  styleUrls: ["./warning.component.scss"],
})
export class WarningComponent implements OnInit {
  public header: string;
  public message: string;
  public confirmText: string;
  public cancelText: string;
  public type: string;
  public quickTip: string;
  public priceAgreementId: string = null;
  public bodyText: string;
  public bodyTable: any;
  public maretialBlockReason: string;
  public level2Code: string = "";
  public fromDate: any;
  public toDate: any;
  public selectFromDate: Date;
  public selectToDate: Date;
  private dateFormats = [
    'DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY',
    'YYYY/MM/DD', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'DD MMM YYYY'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WarningComponent>,
    public toggleItemService: ToggleItemService,
    public priceManagerServices: PriceManagerServices,
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.header = data && data.header;
    this.message = data && data.message;
    this.type = data && data.type;
    this.quickTip = data && data.quickTip;
    this.confirmText = data && data.confirmText ? data.confirmText : "Proceed";
    this.cancelText = data && data.cancelText ? data.cancelText : "Cancel";
    this.priceAgreementId = (data && data.priceAgreementId) || null;
    this.bodyText = data && data.bodyText;
    this.bodyTable = data && data.bodyTable;
  }

  public ngOnInit() {}

  convertToYYYYMMDD(date: string): string {
    for (const format of this.dateFormats) {
      const parsedDate = moment(date, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
    throw new Error('Invalid date format');
  }

  public close(value) {
    /* [VOD-1684] */
    if(this.data && this.data.removeLockItem && this.data.removeLockItem.length > 0){       
      const lockPayload = {         
        moduleItemId :this.data.removeLockItem
      }
      this.priceManagerServices.unlockProjectId(lockPayload).subscribe( (res)=> {
        //console.log("in lock api", res);
      });
    }
    if (
      this.message == "Please provide a reason to block the Material." ||
      this.message == "Please provide a reason to unblock the Material."
    ) {
      this.dialogRef.close(this.maretialBlockReason);
      return;
    }
    if (
      this.message ==
        "We have found multiple Level 2 Categories. Please select one Level 2 Category to proceed with Project ID creation." &&
      this.level2Code == ""
    ) {
      alert("Please select Level 2 Category.");
      return;
    }
    if (this.type === "date_range_filter") {
      if (this.fromDate && this.toDate) {
        let fromDate = this.convertToYYYYMMDD(this.fromDate)
        let toDate = this.convertToYYYYMMDD(this.toDate)
        this.dialogRef.close(fromDate + " " + toDate);
      } else {
        this.dialogRef.close("false");
      }
      return;
    }
    if (this.type === "backConfirmation" && value) {
      this.toggleItemService.isConfigEditable = false;
      this.dialogRef.close(true);
    }
    if (!this.priceAgreementId) {
      this.dialogRef.close(value);
      return;
    }
    if (value == "close") {
      this.dialogRef.close(value);
      return;
    }

    if (this.type == "dismissMatBom") {
      this.router.navigate([`/dashboard/material-and-bom`]);
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(true);
    }
  }

  public onInputChange(data) {
    this.maretialBlockReason = data;
  }

  public showProjectId(l2Code: any) {
    this.level2Code = l2Code;
    this.dialogRef.close(l2Code);
    return;
  }
  public onFromDate(fromDate) {
    this.fromDate = fromDate;
    this.selectFromDate= fromDate;
  }
  public onToDate(toDate) {
    this.toDate = toDate;
    this.selectToDate= toDate;
  }
 public onfocusout(e){}
}
