import { Component, OnInit, Inject } from '@angular/core';
import { SavefilterServices } from './../../_services/save-filter.service';
import { FilterServices } from './../../_services/filter.service';
import { PriceMessagesComponent } from '../price-messages/price-messages.component';
import { WarningComponent } from '../warning/warning.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

/**
 *Popup component for show the save filter form
 *
 * @export
 * @class SaveFiltersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-save-filters',
  templateUrl: './save-filters.component.html',
  styleUrls: ['./save-filters.component.scss']
})
export class SaveFiltersComponent implements OnInit {

  public savedFilterName: string;
  public success = 'null';
  public filterList: any;
  constructor(public savefilterService: SavefilterServices,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SaveFiltersComponent>,
    private filterService: FilterServices) {
    this.filterList = data && data.filterList;
  }

  public ngOnInit() {
    this.savedFilterName = this.savefilterService.savedFilterName;
  }

  /* [VOD-1656] */
  public checkFilterName() {
    const nameExist = this.filterList.filter(item => item.filterName == this.savedFilterName);
    if (nameExist && nameExist.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'waring-dialog-container';
      dialogConfig.data = {
        header: "Filter name already exists",
        message: "A filter with the same name already exists. Would you like to overwrite it?",
        type: 'dismiss'
      };
      const dialogRef = this.dialog.open(WarningComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.saveFilters()
        }
      });
    } else {
      this.saveFilters()
    }
  }
  /* [VOD-1656] */

  public saveFilters() {
    this.savefilterService.saveData.filterName = this.savedFilterName;
    this.filterService.addUserFilters(this.savefilterService.saveData).subscribe(response => {
      if (response.result['filter_name'] || response.result['filterName']) {
        const obj = {
          header: 'Success',
          message: 'You have successfully saved the applied filters.',
          icon: 'success-icon.svg',
          buttonText: 'Done'
        };
        this.onSaveErrorPopup(obj);
      } else if (!response.result['filter_name']) {
        // this.success = 'false';
        const obj = {
          header: 'Save Filters',
          message: 'Filter not saved please try again..',
          icon: 'dismiss-success.svg',
          buttonText: 'OK'
        };
        this.onSaveErrorPopup(obj);
      }
      this.filterService.setFilter(response);
    },
      error => {
        console.log(error);
      }
    );
  }
  private onSaveErrorPopup(obj?: { header: string; message: string, icon?: string, buttonText?: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'price-success-dialog-container';
    if (obj.message) {
      dialogConfig.data = {
        header: obj.header,
        message: obj.message,
        icon: obj.icon,
        buttonText: obj.buttonText
      };
    }
    this.dialog.open(PriceMessagesComponent, dialogConfig);
  }
}
