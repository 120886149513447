import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TaskServices } from './../../_services/task.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DelegateDialogComponent } from '../../global-module/delegate-dialog/delegate-dialog.component';
import { DelegateActionPopupComponent } from '../../global-module/delegate-action-popup/delegate-action-popup.component';
import { DelegateSelectionPopupComponent } from '../../global-module/delegate-selection-popup/delegate-selection-popup.component';
import { AppConfig } from '../../_config';
import { applicationRoles } from '../../_config/constants';
import * as io from 'socket.io-client';
import { AuthServices } from '../../_services/auth.service';
import { PanelServices } from '../../_services/panel.service';
import { PriceManagerServices } from 'src/app/_services/price-manager.service';
import { AddNewProjectIdComponent } from '../../dashboard/project-id/project-id-list/add-new-project-id/add-new-project-id.component';
import { PriceService } from '../../_services/price.service';
import { ConfigurationServices } from 'src/app/_services/configuration.service';
import { ConfigurationNntwkServices } from 'src/app/_services_nntwk/configuration-nntwk.service';


/**
 *Common Top section menu component for all pages
 *
 * @export
 * @class QuickLinksComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  @Input() delegateFrom: boolean;
  @Input() delegatePaList: any;
  workSpaceValue
  @Output() public clearSelection = new EventEmitter<any>();  // [VP3-336]
  @Output() public selectWorkspaceEvent = new EventEmitter<any>();
  public limit = 8;
  public page = 0;
  public isBuyer: boolean;
  public getUserCategory
  isNetworkWorkspace = false;
  showDropDown = false;
  // tslint:disable-next-line: variable-name
  constructor(
    public dialog: MatDialog,
    public _router: Router,
    public route: ActivatedRoute,
    public routerService: Router,
    private taskServices: TaskServices,
    public panelService: PanelServices,
    private priceService: PriceService,
    public authService: AuthServices,
    public priceManagerService: PriceManagerServices,
    public configurationService: ConfigurationServices,
    private configurationNntwkService: ConfigurationNntwkServices,
  ) { }

  public router: string;
  public socket: any;
  public isNewNotification = false;
  public notificationList: any = [];
  public totalNotification = 0;
  public userRole: string;

  private createSocketConnection() {

    this.socket = this.taskServices.getNotificationSocket();
    this.getNotificationList();
    if (!this.socket) {
      this.socket = io.connect(AppConfig.socketLink, {
        path: '/notifications',
        query: { token: this.authService.getToken() }
      });
      this.taskServices.setNotificationSocket(this.socket)
    }

    this.socket.on('connect', () => {
      // console.log('new docket connected')
    });

    this.socket.on('newNotification', () => {
      this.isNewNotification = true;
      if (this.page > 0) {
        this.getNotificationList();
      }
    });

    this.socket.on('socketError', error => {
      this.socket.disconnect();
      this.taskServices.setNotificationSocket(null)
    });

    this.socket.on('disconnect', () => {
      this.taskServices.setNotificationSocket(null)
    });

    this.socket.on('error', error => {
      this.socket.disconnect();
      this.taskServices.setNotificationSocket(null)
    });
  }

  public getNotificationList() {
    this.taskServices.getNotificationList({ page: this.page, limit: this.limit }).subscribe((data: any) => {
      if (data.result) {
        let notifications = data.result.rows;
        this.isNewNotification = notifications.some(notification => !notification.isRead);
        if (this.page === 0) {
          this.notificationList = data.result.rows || [];
        } else {
          this.notificationList.push(...(data.result.rows || []));
        }
        this.totalNotification = data.result.count || 0;
      }
    });
  }

  public notificationIconRead() {
    this.isNewNotification = false;
    this.socket.emit('readNotification', {});
    this.page = 0;
    this.getNotificationList();
  }

  public readAndRedirect(agreementId, applicationId, otherId, uuid, notificationType) {
    this.taskServices.readNotification({ uuids: [uuid] }).subscribe(() => { });
    if (agreementId && applicationId !== "project_id") {
      this._router.navigate([
        `/dashboard/price-agreements/edit-price-agreement/${agreementId}`
      ]);
    } else if (applicationId == "project_id") {
      this.getDataForProjectID(otherId);
    }
    else if (notificationType == 'NEW_USER_REQUEST'
      || notificationType == 'USER_REQUEST_STATUS'
    ) {
      if (this.authService.getRole() === applicationRoles.CHANNEL_ADMIN) {
        this._router.navigate([
          `/dashboard/management`
        ]);
      } else {
        this._router.navigate([
          `/dashboard/configuration/user-management`
        ]);
      }
    } else if (
      notificationType === 'ASSETS_CLASSIFICATION_ADDED'
      || notificationType === 'VODAFONE_ACCOUNTING_EXCHANGE_RATE_EXPIRED'
      || notificationType === 'NEW_L4_ADDED'
      || notificationType === 'NEW_OPCO_ADDED'
      // VOD-1352
      || notificationType === 'SUB_ASSETS_ADDED'
      // VOD-1682
      || notificationType === 'NEW_SUPPLIER_ADDED'
    ) {
      this._router.navigate([
        `/dashboard/configuration/master-data`
      ]);
    } else if (notificationType === 'APPROVAL_MAPPING') {
      this._router.navigate([
        `/dashboard/profile/approver-delegation`
      ]);
    }
    else {
      this._router.navigate([
        `/dashboard/price-agreements/my-tasks`
      ]);
    }
  }

  public ngOnInit() {
    this.workSpaceValue = localStorage.getItem('workspace') || "Network Workspace"
    // console.log(" this.workSpaceValue = ", this.workSpaceValue)
    this.priceManagerService.setWorkspaceValue(this.workSpaceValue);
    this.priceManagerService.workspaceValue.subscribe((res) => {
      if (res == "Network Workspace") {
        this.isNetworkWorkspace = true
      } else {
        this.isNetworkWorkspace = false
      }
    })
    this.isBuyer = this.authService.getIsBuyer();
    this.getUserCategory = this.authService.getUserCategory();
    this.showDropDown = this.getUserCategory.split(",").length > 1 ? true : false
    this.router = this._router.url;
    this.userRole = this.authService.getRole();
    this.createSocketConnection();
    if (!this.taskServices.getGlobalNotificationCallFlag()) {
      this.taskServices.setGlobalNotificationCallFlag(true)
      this.getNotificationList();
    }

  }

  public getDataForProjectID(pid, flag = false) {
    const reqBody = {
      pageNumber: 0,
      perPage: 20,
      filters: [
        {
          "name": "projectId",
          "type": "equal",
          "value": [
            pid
          ]
        }
      ],
      lookup: "",
      sortKey: {},
      isRecommendedOnly: false,
    };
    this.priceManagerService.getProjectId(reqBody).subscribe(
      (res) => {
        const result = res["result"];
        let draft = [];
        draft = result.staging[0];
        let approved = [];
        approved = result.master[0];
        if (draft || approved) {
          this._router.navigate([
            `/dashboard/project-id`
          ]);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = draft ? draft : approved;
          if (flag) {
            dialogConfig.data.parentSupplierOpen = true;
          } else {
            if (dialogConfig.data) {
              dialogConfig.data.parentSupplierOpen = false;
            }
          }
          const dialogRef = this.dialog.open(AddNewProjectIdComponent, dialogConfig);
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              //console.log(result);
            }

          })
          this.priceService.setActionValue(
            'edit'
          );
        }
      })

  }
  public checkCAM() {
    if (this.authService.getRole() == 'COMMERCIAL_CONTRACT_MANAGER') {
      return true;
    } else {
      return false;
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  public ngOnDestroy() { }

  public delegateAllTasks() {
    this.delegateDialog('DelegateAll');
  }
  public delegateOptionSelection() {
    this.DelegateSelectionPopup('DelegateAll');
  }
  private delegateDialog(actionType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      actionType
    };
    this.dialog.open(DelegateDialogComponent, dialogConfig);

  }
  private DelegateSelectionPopup(actionType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      actionType
    };
    this.dialog.open(DelegateSelectionPopupComponent, dialogConfig);

  }
  public delegateSelectedPopupVisible() {
    if (!this.delegateFrom || this.delegatePaList.length === 0) {
      this.panelService.PriceAgreementContent('AllPriceAgree');
      this._router.navigate(['/dashboard/price-agreements']);
      return;
    }
    this.delegateSelectedPopup()
  }
  public delegateSelectedPopup() {
    if (this.delegatePaList.length > 0) {
      this.delegateFrom = true;
    }
    if (!this.delegateFrom || this.delegatePaList.length === 0) {
      this.panelService.PriceAgreementContent('AllPriceAgree');
      this._router.navigate(['/dashboard/price-agreements'], { queryParams: { 'delegate': 'priceAgreement' } });
      return;
    }
    let data = {
      delegate: 'priceAgreement',
      priceAgreementId: this.delegatePaList
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.data = data;
    const dialogRef = this.dialog.open(DelegateActionPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delegatePaList = [];
        this.delegateFrom = false;
        this._router.navigate(['/dashboard/price-agreements']);
        this.clearSelection.emit();
      }
    })
  }
  public loadMoreNotification(event: Event, loadFirstPage = false) {
    event.stopPropagation();
    loadFirstPage ? this.page = 0 : this.page += 1;
    this.getNotificationList();
  }

  public markAllAsRead(event) {
    event.stopPropagation();
    this.taskServices.readNotification({}).subscribe(() => {
      this.page = 0;
      this.getNotificationList();
    });
  }

  selectWorkspace(event) {
    //console.log('in which workspace', event.target.value);
    this.workSpaceValue = event.target.value
    this.priceManagerService.setWorkspaceValue(event.target.value);
    this.selectWorkspaceEvent.emit(this.workSpaceValue);
    if (this.workSpaceValue == 'Network Workspace') {
      this.panelService.userContent("isMaterialandBom");
    } else {
      this.panelService.userContent("isPriceAgreement");
      this.panelService.activeUserVal == 'isPriceAgreement'
    }
    if (localStorage.getItem("workspace") == "Network Workspace") {
      this.configurationService.getGlobalFilterConfiguration();
    } else {
      this.configurationNntwkService.getGlobalFilterConfiguration();
    }
    this.routerService.navigate(['dashboard'])
  }
}
