import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class PublisherService {
  public static contractValidFrom: Date;
  public static contractValidTo: Date;
  public static projectIdValidFrom;
  public static projectIdValidTo;
  public static editProjectId = false;
  public contractValidity = new Subject<any>();
  public priceErosionValidity = new Subject<any>();
  public itemSelection = new Subject<boolean>();
  public catalogueGroupList = new Subject<any>();
  public catalogueGroup = new Subject<string>();
  public catalogueGroupObj: any;
  public catalogueGroupObjList: any;
  public shoppingCart = new Subject<string>();
  public shoppingCartObj: any;
  public catalogueTypeObj: string;
  public catalogueType = new Subject<string>();
  public voucherApplyStatus = new Subject<boolean>();

  /**
   *Function that set the contract validity dates
   *
   * @param {*} data
   * @memberof PublisherService
   */
  public setContractValidity(data) {
    PublisherService.contractValidFrom = moment(data.validFrom, 'YYYY-MM-DD').toDate();
    PublisherService.contractValidTo = moment(data.validTo, 'YYYY-MM-DD').toDate();
    this.contractValidity.next(data);
  }

  public setProjectIdValidityFrom(data) {
    PublisherService.projectIdValidFrom = moment(data, 'YYYY-MM-DD').toDate();
  }

  public setProjectIdValidityTo(data) {
    PublisherService.projectIdValidTo = moment(data, 'YYYY-MM-DD').toDate();
  }

  /**
   *Function that set the price erosion validity dates
   *
   * @param {*} data
   * @memberof PublisherService
   */
  public setPriceErosionValidity(data) {
    this.priceErosionValidity.next(data);
  }

  /**
   *Function that set the current selected Item 
   *
   * @memberof PublisherService
   */
  public setItemSelection() {
    this.itemSelection.next(true);
  }

  /**
   *Function that returns the contract validity from date
   *
   * @return {*} 
   * @memberof PublisherService
   */
  public getContractValidFrom() {
    return PublisherService.contractValidFrom;
  }

  /**
   *Function that returns the contract validity to date
   *
   * @return {*} 
   * @memberof PublisherService
   */
  public getContractValidTo() {
    return PublisherService.contractValidTo;
  }

  /**
   *Function that set the catalogue group list
   *
   * @param {any[]} data
   * @memberof PublisherService
   */
  public setCatalogueGroupList(data: any[]) {
    this.catalogueGroupList.next(data);
    this.catalogueGroupObjList = data;
  }

  /**
   *Function that return the catalogue group list as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PublisherService
   */
  public getCatalogueGroupList(): Observable<any> {
    return this.catalogueGroupList.asObservable();
  }

  /**
   *Function that returns the catalogue group object list
   *
   * @return {*}  {*}
   * @memberof PublisherService
   */
  public getCatalogueGroupObjList(): any {
    return this.catalogueGroupObjList;
  }

  /**
   *Function that set the catalogue group data
   *
   * @param {*} data
   * @memberof PublisherService
   */
  public setCatalogueGroup(data: any) {
    this.catalogueGroup.next(data);
    this.catalogueGroupObj = data;
  }

  /**
   *Function that returns catalogue group data as Observable
   *
   * @return {*}  {Observable<string>}
   * @memberof PublisherService
   */
  public getCatalogueGroup(): Observable<string> {
    return this.catalogueGroup.asObservable();
  }

  /**
   *Function that returns the catalogue group object
   *
   * @return {*}  {*}
   * @memberof PublisherService
   */
  public getCatalogueGroupObj(): any {
    return this.catalogueGroupObj;
  }

  /**
   *Function that set the shopping cart data
   *
   * @param {*} data
   * @memberof PublisherService
   */
  public setShoppingCart(data: any) {
    this.shoppingCart.next(data);
    this.shoppingCartObj = data;
  }

  /**
   *Function that returns the shopping cart data as Observable
   *
   * @return {*}  {Observable<string>}
   * @memberof PublisherService
   */
  public getShoppingCart(): Observable<string> {
    return this.shoppingCart.asObservable();
  }

  /**
   *Function that returns the shopping cart objects
   *
   * @return {*}  {*}
   * @memberof PublisherService
   */
  public getShoppingCartObj(): any {
    return this.shoppingCartObj;
  }

  /**
   *Function that set the applied voucher
   *
   * @param {*} status
   * @return {*} 
   * @memberof PublisherService
   */
  public setVoucherApply(status) {
    return this.voucherApplyStatus.next(status);
  }

  /**
   *Function that returns voucher status as Observable
   *
   * @return {*}  {Observable<boolean>}
   * @memberof PublisherService
   */
  public getVoucherStatus(): Observable<boolean> {
    return this.voucherApplyStatus.asObservable();
  }

  /**
   *Function that returns the catalogue type object
   *
   * @return {*}  {string}
   * @memberof PublisherService
   */
  public getCatalogueTypeObj(): string {
    return this.catalogueTypeObj;
  }

  /**
   *Function that returns the catalogue type as Observable
   *
   * @return {*}  {Observable<string>}
   * @memberof PublisherService
   */
  public getCatalogueType(): Observable<string> {
    return this.catalogueType.asObservable();
  }

  /**
   *Function that set the catalogue type 
   *
   * @param {string} data
   * @memberof PublisherService
   */
  public setCatalogueType(data: string) {
    this.catalogueType.next(data);
    this.catalogueTypeObj = data;
  }

}
