export const environment = {
  production: false,
  environmentType: 'dev',
  apiURLLocal: 'https://test.vpm.vodafone.com',
  apiURLJava: 'https://dev2.api.vpm.vodafone.com/pmi',
  apiURLAccessManagerNode: 'https://dev2.api.vpm.vodafone.com/pma',
  apiURLPriceManagerNode: 'https://dev2.api.vpm.vodafone.com/pmb',
  ioURL: 'https://dev2.api.vpm.vodafone.com',
  adminServiceUrl: 'https://dev2.api.vpm.vodafone.com/pmi',
  analyticsUrl: 'https://dev2.api.vpm.vodafone.com/pmt',
  ssoClientId: '################################',
  tenantId: '###################################'
  };
