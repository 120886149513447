import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {MatDialogModule, MatAutocompleteModule} from '@angular/material';
import { NavLeftComponent } from './nav-left/nav-left.component';
import { NavPriceAgreementsComponent } from './nav-price-agreements/nav-price-agreements.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { NavMyTasksComponent } from './nav-my-tasks/nav-my-tasks.component';
import { TitleInfoComponent } from './title-info/title-info.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { AgreementDialogComponent } from './agreement-dialog/agreement-dialog.component';
import { DelegateDialogComponent } from './delegate-dialog/delegate-dialog.component';
import { SaveFiltersComponent } from './save-filters/save-filters.component';
import { LoaderComponent } from './loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatTableModule,
  MatToolbarModule,
  MatIconModule,
  MatTabsModule,
  MatExpansionModule,
  MatGridListModule,
  MatCardModule,
  MatRadioModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatDialogModule,
  MatMenuModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatListModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatChipsModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSidenavModule,
  MatSliderModule,
  MatSortModule,
  MatStepperModule,
  MatTooltipModule,
  MatTreeModule
} from '@angular/material';
import { PriceMessagesComponent } from './price-messages/price-messages.component';
import { WarningComponent } from './warning/warning.component';
import { FooterComponent } from './footer/footer.component';
import { MinutePipe } from '../_pipes/minute.pipe';
import { ReversePipe } from '../_pipes/reverse.pipe';
import { LegendComponent } from './legend/legend.component';
import { ProgressBarDialogComponent } from './progress-bar-dialog/progress-bar-dialog.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { DelegateSelectionPopupComponent } from './delegate-selection-popup/delegate-selection-popup.component';
import { DelegateActionPopupComponent } from './delegate-action-popup/delegate-action-popup.component';
//import { MaterialPopupComponent } from './material-popup/material-popup.component';
import { MaterialConfigurationComponent } from './material-popup/material-configuration/material-configuration.component';
import { BomConfigurationComponent } from './material-popup/bom-configuration/bom-configuration.component';
import { CompanySpecificFieldsComponent } from './material-popup/company-specific-fields/company-specific-fields.component';
import { SaveDataComponent } from './save-data-dialog/save-filters.component';
import { WatcherOwnerActionPopupComponent } from './watcher-owner-action-popup/watcher-owner-action-popup.component';
import { DropdownFilterComponent } from '../dashboard/dropdown-filter/dropdown-filter.component';
import { FilterPipe } from '../dashboard/dropdown-filter/filter.pipe';
import { RemoveUnderscorePipe } from '../_pipes/remove-underscore.pipe';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ExactMatchFilterPipe } from '../dashboard/dropdown-filter/exactMatchFilter.pipe';
import { SortPipe } from '../_pipes/sort.pipe';
import { ConfrimSelectEditExistComponent } from '../dashboard/price-agreements/create-price-agreement/confirm-selection/confrim-select-edit-exist/confrim-select-edit-exist.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ItemsComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-breakdown/items/items.component';
import { AnalyticsBlocksComponent } from '../dashboard/analytics-page/analytics-blocks/analytics-blocks.component';
import { AnalyticsByBreakdownComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-breakdown/analytics-by-breakdown.component';
import { L4MaterialCategoryComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-breakdown/l4-material-category/l4-material-category.component';
import { AnalyticsByCategoryComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-category/analytics-by-category.component';
import { L1CategoriesComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-category/l1-categories/l1-categories.component';
import { LocalMarketsComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-category/local-markets/local-markets.component';
import { ParentSuppliersComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-category/parent-suppliers/parent-suppliers.component';
import { TradingModelsComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-category/trading-models/trading-models.component';
import { AnalyticsDetailsComponent } from '../dashboard/analytics-page/analytics-details/analytics-details.component';
import { HeaderComponent } from '../dashboard/analytics-page/header/header.component';
import { HeaderBarComponent } from '../dashboard/analytics-page/shared-components/header-bar/header-bar.component';
import { LegendBarComponent } from '../dashboard/analytics-page/shared-components/legend-bar/legend-bar.component';
import { CatalogueGroupComponent } from '../dashboard/price-agreements/edit-price-agreement/right-nav/catalogue-group/catalogue-group.component';
import { CounterPartyComponent } from '../dashboard/price-agreements/edit-price-agreement/shared-components/counter-party/counter-party.component';
import { AnalyticPriceAgreementsComponent } from '../dashboard/analytics-page/analytics-details/analytics-by-breakdown/analytic-price-agreements/analytic-price-agreements.component';
import { AnalyticsDialogComponent } from '../dashboard/analytics-page/analytics-dialog/analytics-dialog.component';
import { BreakdownBlocksComponent } from '../dashboard/analytics-page/shared-components/breakdown-blocks/breakdown-blocks.component';
import { CategoryBlocksComponent } from '../dashboard/analytics-page/shared-components/category-blocks/category-blocks.component';
import { ChartBlockComponent } from '../dashboard/analytics-page/shared-components/chart-block/chart-block.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HighchartsChartModule } from 'highcharts-angular';
import { BreakdownBlockChartComponent } from '../dashboard/analytics-page/shared-components/breakdown-block-chart/breakdown-block-chart.component';
import { Readable } from '../_pipes/readable.pipe';
@NgModule({
  declarations: [
    NavLeftComponent,
    QuickLinksComponent,
    NavPriceAgreementsComponent,
    NavMyTasksComponent,
    TitleInfoComponent,
    SuccessDialogComponent,
    AgreementDialogComponent,
    DelegateDialogComponent,
    SaveFiltersComponent,
    PriceMessagesComponent,
    LoaderComponent,
    WarningComponent,
    FooterComponent,
    MinutePipe,
    ReversePipe,
    LegendComponent,
    ProgressBarDialogComponent,
    TermsAndConditionsComponent,
    MonthPickerComponent,
    DelegateSelectionPopupComponent,
    DelegateActionPopupComponent,
    WatcherOwnerActionPopupComponent,
    SaveDataComponent,
    DropdownFilterComponent,
    RemoveUnderscorePipe,
    FilterPipe,
    ExactMatchFilterPipe,
    SortPipe,
    ConfrimSelectEditExistComponent,
    ItemsComponent,
    CatalogueGroupComponent,
    CounterPartyComponent,
    HeaderComponent,
    AnalyticsBlocksComponent,
    AnalyticsDetailsComponent,
    AnalyticsByCategoryComponent,
    AnalyticsByBreakdownComponent,
    ParentSuppliersComponent,
    LocalMarketsComponent,
    L1CategoriesComponent,
    TradingModelsComponent,
    L4MaterialCategoryComponent,
    HeaderBarComponent,
    LegendBarComponent,
    CategoryBlocksComponent,
    BreakdownBlocksComponent,
    AnalyticPriceAgreementsComponent,
    ChartBlockComponent,
    AnalyticsDialogComponent,
    BreakdownBlockChartComponent,
    Readable,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    MatGridListModule,
    MatCardModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatListModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatChipsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatStepperModule,
    MatTooltipModule,
    MatTreeModule,
    NgbModule,
    SatDatepickerModule,
    SatNativeDateModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
    HighchartsChartModule,
    ScrollingModule
  ],
  exports: [
    NavLeftComponent,
    NavPriceAgreementsComponent,
    QuickLinksComponent,
    NavMyTasksComponent,
    TitleInfoComponent,
    LoaderComponent,
    WarningComponent,
    FooterComponent,
    MinutePipe,
    ReversePipe,
    LegendComponent,
    ProgressBarDialogComponent,
    TermsAndConditionsComponent,
    MonthPickerComponent,
    DropdownFilterComponent,
    RemoveUnderscorePipe,
    FilterPipe,
    ExactMatchFilterPipe,
    SortPipe,
    ConfrimSelectEditExistComponent,
    ItemsComponent,
    CatalogueGroupComponent,
    CounterPartyComponent,
    HeaderComponent,
    AnalyticsBlocksComponent,
    AnalyticsDetailsComponent,
    AnalyticsByCategoryComponent,
    AnalyticsByBreakdownComponent,
    ParentSuppliersComponent,
    LocalMarketsComponent,
    L1CategoriesComponent,
    TradingModelsComponent,
    L4MaterialCategoryComponent,
    HeaderBarComponent,
    LegendBarComponent,
    CategoryBlocksComponent,
    BreakdownBlocksComponent,
    AnalyticPriceAgreementsComponent,
    ChartBlockComponent,
    AnalyticsDialogComponent,
    NgxSkeletonLoaderModule,
    BreakdownBlockChartComponent,
    Readable,
  ],
  providers: [],
  entryComponents: [
    SuccessDialogComponent,
    AgreementDialogComponent,
    DelegateDialogComponent,
    SaveFiltersComponent,
    SaveDataComponent,
    PriceMessagesComponent,
    LoaderComponent,
    WarningComponent,
    ProgressBarDialogComponent,
    DelegateDialogComponent,
    DelegateSelectionPopupComponent,
    DelegateActionPopupComponent,
    WatcherOwnerActionPopupComponent
    //  MaterialPopupComponent
  ]
})
export class GlobalModulesModule { }
