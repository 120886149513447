import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthServices } from '../_services/auth.service';
import { MatDialog } from '@angular/material';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private token: any;

    constructor(private router: Router, private auth: AuthServices, public dialog: MatDialog) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // All HTTP requests are going to go through this method
        this.token = window.localStorage.getItem('token');
        if (this.token) {
            req = req.clone({
                setHeaders: {
                    Authorization: this.token
                },
            });
        }
        return next.handle(req).pipe((tap((event: any) => {
            this.handleError(event);
        }, (err) => {
            this.handleError(err);
            this.dialog.closeAll();
        })));
    }

    public handleError(err) {
        if (err instanceof HttpErrorResponse || err instanceof HttpResponse) {
            if (err['error'] && err['error']['responseCode'] === 'TOKEN_EXPIRED') {
                this.dialog.closeAll();
                this.auth.refreshTokenGeneration();
            } else {
                if (err.status === 401) {
                    window.localStorage.clear();
                    this.router.navigate(['/']);
                    this.dialog.closeAll();
                }
            }
        }
    }
}
