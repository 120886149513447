import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Task, Price, Master, Edit, PriceConfirm, IContractWatchOLA, IContractWatchOLADetails, IContractWatchProjectIdDetails } from '../_models/price-manager';
import { IServerResponse } from '../_models/response';
import { AppConfig } from '../_config';
import { AuthServices } from './auth.service';
import { Observable, Subject } from 'rxjs';
const { priceUrl, configUrl, priceUrl1 } = AppConfig;
@Injectable({
  providedIn: 'root'
})
export class PriceService {
  constructor(private http: HttpClient, private authService: AuthServices) { }
  public excelData: Subject<any> = new Subject();
  private outlineAgreementListUrl: string = priceUrl1 + 'network/price-manager/list-outline-agreements';
  private projectIdPAListUrl: string = priceUrl1 + 'network/price-manager/list-project-ids';
  private outlineAgreementDetailsUrl: string = priceUrl1 + 'network/price-manager/outline-agreement';
  private projectIdDetailsUrl: string = priceUrl1 + 'network/price-manager/project-id';
  private downloadoutlineAgreementListUrl: string = priceUrl1 + 'network/price-manager/download-outline-agreements';
  private downloadProjectIdListUrl: string = priceUrl1 + 'network/price-manager/download-project-ids';
  private downloadSelectProjectIdListUrl: string = priceUrl1 + 'network/price-manager/download-select-project-ids';
  private workspaceFiltersURL: string = priceUrl1 + 'network/user-filter/workspace-filters';
  private activityLogURL: string = priceUrl1 + 'network/price-manager/activity-logs';
  private getExistingPriceManagerForOlaUrl: string = priceUrl1 + 'network/price-manager/existing-price-agreements-for-ola/';
  private getExistingPriceManagerForProjectIdUrl: string = priceUrl1 + 'network/price-manager/existing-price-agreements-for-project-id/';
  private downloadExistingPriceManagerForOlaUrl: string = priceUrl1 + 'network/price-manager/download-existing-price-agreements-for-ola/';
  private downloadExistingPriceManagerForProjectIdUrl: string = priceUrl1 + 'network/price-manager/download-existing-price-agreements-for-project-id/';
  private getCatalogueSyncLogURL: string = configUrl + 'pm/integration/network/getPriceAgreementSyncLogs';
  private getCatalogueSyncLogErrorDetailsURL: string = configUrl + 'pm/integration/network/getCatalogueSyncDetails';

  /**
   *Observable function that set the given excel data
   *
   * @param {*} data
   * @memberof PriceService
   */
  public setExceldata(data) {
    this.excelData.next(data);
  }

  /**
   *function that returns the save excel data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceService
   */
  public getPostedexecel(): Observable<any> {
    return this.excelData.asObservable();
  }

  /**
   *function that returns the save excel data
   *
   * @memberof PriceService
   */
  public getExceldata() {
    this.excelData.subscribe(res => {
      return res;
    });
  }

  // set screen name value
  setActionValue(action) {
    localStorage.setItem("value", action);
  }

  getActionValue() {
    return localStorage.getItem("value");
  }

  /**
   *Api function that returns contracts based on OLA
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; startDate: Date, endDate: Date, isRecommendedOnly: boolean }} data
   * @return {*} 
   * @memberof PriceService
   */
  public searchContractWatchOLA(data: { pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; startDate: Date, endDate: Date, isRecommendedOnly: boolean }) {
    return this.http.post<IServerResponse<IContractWatchOLA>>(this.outlineAgreementListUrl, data, this.authService.getAccessHeaders());
  }

  public searchProjectIdList(data: { pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; startDate: Date, endDate: Date, isRecommendedOnly: boolean }) {
    return this.http.post<IServerResponse<IContractWatchOLA>>(this.projectIdPAListUrl, data, this.authService.getAccessHeaders());
  }
  /**
   *Api function that returns contract watch OLA details
   *
   * @param {string} olaNumber
   * @return {*} 
   * @memberof PriceService
   */
  public contractWatchOLADetails(olaNumber: string) {
    return this.http
      .get<IServerResponse<IContractWatchOLADetails>>(
        this.outlineAgreementDetailsUrl + '/' + olaNumber, this.authService.getAccessHeaders());
  }

  public contractWatchProjectDetails(projectId: string) {
    return this.http
      .get<IServerResponse<IContractWatchProjectIdDetails>>(
        this.projectIdDetailsUrl + '/' + projectId, this.authService.getAccessHeaders());
  }
  /**
   *Api function that download the contract watch OLA details
   *
   * @param {{ filters: any; sortKey: string; lookup: string, isRecommendedOnly: boolean }} data
   * @return {*} 
   * @memberof PriceService
   */
  public downloadContractWatchOLA(data: { filters: any; sortKey: string; lookup: string, isRecommendedOnly: boolean }) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.downloadoutlineAgreementListUrl, data, { ...this.authService.getAccessHeaders(), responseType: 'blob' });
  }

  public downloadContractWatchProjectId(data: { filters: any; sortKey: string; lookup: string, isRecommendedOnly: boolean }) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.downloadProjectIdListUrl, data, { ...this.authService.getAccessHeaders(), responseType: 'blob' });
  }
  public downloadSelectProjectId(data: { filters: any; sortKey: string; lookup: string, isRecommendedOnly: boolean }) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.downloadSelectProjectIdListUrl, data, { ...this.authService.getAccessHeaders(), responseType: 'blob' });
  }

  /**
   *Api function that return the filter data for PA page workspace tab
   *
   * @return {*} 
   * @memberof PriceService
   */
  public getWorkspaceFilters() {
    return this.http.get(this.workspaceFiltersURL, this.authService.getAccessHeaders());
  }

  /**
   *Api function that update the workspace filter data for PA page workspace tab
   *
   * @param {*} data
   * @return {*} 
   * @memberof PriceService
   */
  public updateWorkspaceFilters(data) {
    return this.http.post(this.workspaceFiltersURL, data, this.authService.getAccessHeaders());
  }

  /**
   *Api function that returns the existing price agreements for the specified OLA
   *
   * @param {*} olaNumber
   * @param {*} params
   * @param {*} [searchObj={}]
   * @return {*} 
   * @memberof PriceService
   */
  public getExistingPriceAgreementsForOla(olaNumber, params, searchObj = {}) {
    params = Object.entries(params)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    const url = `${this.getExistingPriceManagerForOlaUrl}${olaNumber}`;
    const httpParams: any = this.authService.getAccessHeaders();
    httpParams.params = params;
    return this.http.post(url, searchObj, httpParams);
  }

  public getExistingPriceAgreementsForProjectId(projectId, params, searchObj = {}) {
    params = Object.entries(params)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    const url = `${this.getExistingPriceManagerForProjectIdUrl}${projectId}`;
    const httpParams: any = this.authService.getAccessHeaders();
    httpParams.params = params;
    return this.http.post(url, searchObj, httpParams);
  }

  /**
   *Api function that download the existing price agreements for the specified OLA
   *
   * @param {*} olaNumber
   * @param {*} params
   * @param {*} [searchObj={}]
   * @return {*} 
   * @memberof PriceService
   */
  public downloadExistingPriceAgreementsForOla(olaNumber, params, searchObj = {}) {
    params = Object.entries(params)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    const url = `${this.downloadExistingPriceManagerForOlaUrl}${olaNumber}`;
    return this.http.post(url, searchObj, { ...this.authService.getAccessHeaders(), params, responseType: 'blob' });
  }

  public downloadExistingPriceAgreementsForProjectId(projectId, params, searchObj = {}) {
    params = Object.entries(params)
      .reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
    const url = `${this.downloadExistingPriceManagerForProjectIdUrl}${projectId}`;
    return this.http.post(url, searchObj, { ...this.authService.getAccessHeaders(), params, responseType: 'blob' });
  }

  /**
   *Api function to gets the activity logs
   *
   * @param {{
   *     priceAgreementId: string,
   *   }} options
   * @return {*} 
   * @memberof PriceService
   */
  public getActivityLogs(options: {
    priceAgreementId: string,
  }) {
    const url = `${this.activityLogURL}?priceAgreementId=${encodeURIComponent(options.priceAgreementId)}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Api function that returns the catalogue sync logs
   *
   * @param {*} priceAgreementId
   * @return {*} 
   * @memberof PriceService
   */
  public getCatalogueSyncLog(priceAgreementId) {
    const url = `${this.getCatalogueSyncLogURL}/${priceAgreementId}`;
    return this.http.get(url, this.authService.getAccessHeaders2());
  }

  /**
   *Api function that returns the catalogue sync log error details
   *
   * @param {*} body
   * @return {*} 
   * @memberof PriceService
   */
  public getCatalogueSyncLogErrorDetails(body) {
    return this.http.post(this.getCatalogueSyncLogErrorDetailsURL, body, this.authService.getAccessHeaders2());
  }

}
