import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { UsersServices } from '../../../_services/users.service';
import { SavefilterServices } from '../../../_services/save-filter.service';
import { FilterServices } from '../../../_services/filter.service';
import { ConfigurationServices } from '../../../_services/configuration.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SaveFiltersComponent } from '../../../global-module/save-filters/save-filters.component';
import { MasterDataServices } from '../../../_services/masterdata.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public items = [
    {
      header: 'Analytics',
      image: 'analytics-big.svg',
      content: 'Apply custom filters to generate analytics and download reports.'
    } // ... more items
  ];

  public preSetting;
  public preferedFilters: any;
  public filterArray:any = [];
  public userFilters: any[];
  public savedFilterValue: any;
  public filterRecord: any[];
  public filterObjToSave: {
    filters: Array<any>,
    filterName: string,
    componentName: string
  } = {
    filters: [],
    filterName: '',
    componentName: ''
  };

  public dropdownType: string;
  public dropdownNumber: any = 0;
  public dropdownTempData: any;
  public searchText: any = '';
  public selectedDate = [];

  public dropDownObjArray: any[] = [];
  public tradingModels: any = [];
  public catalogueNames: any = [];
  public parentSupplierDetails: any = [];
  public parentSupplierArr: any = [];
  public organizationIdNameMapping: any = {};
  public filterSupplier: any = [];
  public suppliersDetails: any = [];
  public opcoDetails: any = [];
  public catalogueType: any = [];
  public materialGroups: any = [];
  public currencyDetails: any = [];
  public supDet: any = {};

  public supplierId: any = [];
  public opcoId: any = [];
  public catalogueId: any = [];
  public materialId: any = [];
  public tradingId: any = [];
  public paStatusId: any = [];
  public catalogueStatusId: any = [];
  public catalogueName: any = [];
  public catalogueNameFilterType = 'equal'
  public taskTypeId: any = [];
  public taskStatusId: any = [];
  public parentSupplierId: any = [];
  public integrationStatusId: any = [];
  public currencyId: any = [];

  public counts: any = 0;
  public supplierCount: any = 0;
  public opcoCount: any = 0;
  public catalogueCount: any = 0;
  public materialCount: any = 0;
  public tradingCount: any = 0;
  public catalogueStatusCount: any = 0;
  public catalogueNameCount: any = 0;
  public paStatusCount: any = 0;
  public taskTypeCount: any = 0;
  public taskStatusCount: any = 0;
  public parentSupplierCount: any = 0;
  public l1CategoryCount: any = 0;
  public currencyCount: any = 0;

  public responseObj: object;
  public catStatusArr = [
    {
      key: 'ACTIVE',
      value: 'ACTIVE',
      selected: false,
      type: 'caStatus'
    },
    {
      key: 'PENDING',
      value: 'PENDING',
      selected: false,
      type: 'caStatus'
    }
  ];
  public catalougeArr = ['IT&E', 'SV', 'NW', 'IT&E,SV', 'IT&E,NW', 'SV,NW', 'IT&E,SV,NW'];
  @Output() public OutputToParent = new EventEmitter<any>();
  public filterSub: Subscription;

  public constructor(
    public dialog: MatDialog,
    public usersServices: UsersServices,
    public savefilterService: SavefilterServices,
    private filterService: FilterServices,
    private configurationServices: ConfigurationServices,
    public masterDataServices: MasterDataServices
    ) { }

  public ngOnInit() {
    this.getFilterValues();
    // this.getCurrency();
    this.getSavedFilters();
    this.filterSub = this.filterService.getFilter().subscribe(res => {
      // VOD-1652
      const alreadyExist = this.userFilters.find(o => o.filterName === res['result']['result']['filterName']);
      if (!alreadyExist) {
        this.userFilters.push(res['result']['result']);
      }
      // VOD-1686
      else {
        alreadyExist.updatedAt = res['result']['result'].updatedAt || alreadyExist.updatedAt ;
      }
      // VOD-1686
    });
  }

  public ngOnDestroy() {
    this.filterSub.unsubscribe();
  }
  public displayApplierFilters(data) {
    if(data.type === 'catalogueStatus') {
       return `Catalogue Status ${data.filterType === 'notEqual' ? 'Not Equals' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'catalogueNames') {
      return `Catalogue Name ${data.filterType === 'notEqual' ? 'Not Equals' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
   }  else if(data.type === 'supplier') {
      return `Supplier ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'opco') {
      return `Local Market ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'validity') {
      return `validity :  ${data.value}`
    }  else if(data.type === 'trading') {
      return `Trading Model ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'catalogue') {
      return `L1 Category ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    }else if(data.type === 'supplierId') {
      return `Supplier Id ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'parentSupplier') {
      return `Parent Supplier  ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } else if(data.type === 'material') {
      return `L4 Group  ${data.filterType === 'notEqual' ? 'Not Equals: ' : data.filterType === 'contains' ? 'Contains: '  : 'Equals: '} ${data.value}`
    } 
    else if (data.type === 'paStatus') {
      return `Status : ${data.value}` 
    }
    else {
      return`${data.filterType === 'notEqual' ? 'Not Equal :' : data.filterType === 'contains' ? 'Contains :'  : 'Equals :'} ${data.value}`
    }
  }

  // public getCurrency() {
  //   const reqBody = {
  //     pageNumber: 0,
  //     perPage: 500,
  //     lookup: 'FOREIGN_EXCHANGE_RATE',
  //     filters: [],
  //     sortKey: {
  //       attibName: 'createdDate',
  //       sortType: 'DESC'
  //     }
  //   };
  //   this.masterDataServices.materialGroupMasterData(reqBody).subscribe((res) => {
  //     const result = res['result'];
  //     if (result && result[0].searchResult) {
  //       const arr = result[0].searchResult.map(data => {
  //         return data.tradingCurrency;
  //       });
  //       const currencyArr = this.getUnique(arr);
  //       this.currencyDetails = currencyArr.map(currencys => {
  //         return {
  //           key: currencys,
  //           value: currencys,
  //           selected: false
  //         };
  //       });
  //     }
  //   });
  // }

  public getUnique(array) {
    const uniqueArray = [];
    let i = 0;
    for (i; i < array.length; i++) {
        if (uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
  }

  public async getFilterValues() {
    const filterRes = await this.configurationServices.getGlobalFilterConfiguration();
    this.responseObj = filterRes;

    // For Trading Model Details
    this.tradingModels = filterRes.tradingModels.map(tradingM => {
      return {
        key: tradingM,
        value: tradingM,
        selected: false
      };
    });
    this.catalogueNames = Object.keys(filterRes.catalogueNameList).map(name => {
      return {
        key: name,
        value: name,
        selected: false
      };
    });

    this.filterSupplier = filterRes.suppliersDetails;
    this.suppliersDetails = Object.keys(filterRes.suppliersDetails).map(k => {
      return {
        key: k,
        value: filterRes.suppliersDetails[k],
        selected: false
      };
    });
    this.parentSupplierArr = filterRes.supplierDetails;
    if (this.parentSupplierArr !== null) {
      this.organizationIdNameMapping = filterRes.supplierDetails.reduce((acc, ps) => {
        const { organizationId, organizationName } = ps;
        acc[organizationId] = organizationName;
        return acc;
      }, {})
      this.parentSupplierDetails = Object.keys(filterRes.supplierDetails).map(k => {
        return {
          key: filterRes.supplierDetails[k].organizationId,
          value: filterRes.supplierDetails[k].organizationName,
          selected: false
        };
      });
    }

    // For Local market Details
    this.opcoDetails = Object.keys(filterRes.opcoDetails).map(k => {
      return {
        key: k,
        value: filterRes.opcoDetails[k],
        selected: false
      };
    });

    // For Catalogue Type Details
    // this.catalogueType = filterRes.catalogueType.map(cType => {
    //   return {
    //     key: cType,
    //     value: cType,
    //     selected: false
    //   };
    // });
    this.catalogueType =  this.catalougeArr.map(cType => {
      return {
        key: cType,
        value: cType,
        selected: false
      };
    });

    // For Material Groups Details
    this.materialGroups = Object.keys(filterRes.materialGroups).map(k => {
      return {
        key: k,
        value: filterRes.materialGroups[k],
        selected: false
      };
    });

    // For Currency Details
    this.currencyDetails = Object.keys(filterRes.currencyList).map(k => {
      return {
        key: k,
        value: filterRes.currencyList[k],
        selected: false
      };
    });

  }

  public dropDownToggle(type, num) {

    this.searchText = '';
    this.dropdownType = type;
    if (this.dropdownNumber === 0) {
      this.dropdownNumber += num;
      if (this.dropdownNumber > 1) {
        this.dropdownNumber--;
      }
    } else {
      this.dropdownNumber = 0;
    }
  }

  public onButtonGroupClick(event, types) {
    this.savedFilterValue = undefined;
    const clickedElement = event.target || event.srcElement;
    const text = clickedElement.innerText;
    if (types === 'trading') {
      const tradingM = this.tradingModels.find(obj => obj.value === text);
      tradingM.selected = !tradingM.selected;
    }
    if (types === 'catalogueStatus') {
      const catalogueSts = this.catStatusArr.find(obj => obj.value === text);
      if(catalogueSts) {
        catalogueSts.selected = !catalogueSts.selected;
      }
    }
    if (types === 'catalogueNames') {
      const catalogueName = this.catalogueNames.find(obj => obj.value === text);
      if(catalogueName) {
        catalogueName.selected = !catalogueName.selected;
      }
    }
    if (types === 'parentSupplier') {
      const pSupplierD = this.parentSupplierDetails.find(parentsupplier => parentsupplier.value === text);
      pSupplierD.selected = !pSupplierD.selected;
    }
    if (types === 'supplier') {
      const supplierD = this.suppliersDetails.find(supplier => supplier.value === text);
      supplierD.selected = !supplierD.selected;
    }
    if (types === 'opco') {
      const data = text.split('-');
      const textSearch = data.slice(1).join('-').trim();
      const opcoD = this.opcoDetails.find(opco => opco.key + '-' + opco.value === text);
      opcoD.selected = !opcoD.selected;
    }
    if (types === 'catalogue') {
      const cType = this.catalogueType.find(obj => obj.value === text);
      cType.selected = !cType.selected;
    }
    if (types === 'material') {
      const data = text.split('-');
      const textSearch = data.slice(1).join('-').trim();
      const materialD = this.materialGroups.find(material => material.value === textSearch);

      materialD.selected = !materialD.selected;
    }
    if (types === 'currency') {
      const currencyD = this.currencyDetails.find(obj => obj.value === text);
      currencyD.selected = !currencyD.selected;
    }

    const searchIds = clickedElement.id;
    if ((clickedElement && clickedElement.className === 'active' || clickedElement.className === 'active ng-star-inserted')) {
      clickedElement.classList.remove('active');
      clickedElement.classList.remove('ng-star-inserted');
      clickedElement.value = '';
      this.GetOutputVal(types, false, searchIds);
      if (types === 'parentSupplier') {
        this.supplierDetails(searchIds, 'remove');
      }
    } else if (clickedElement) {
      if (types !== 'date') {
        clickedElement.className = 'active';
      }
      clickedElement.value = searchIds;
      this.GetOutputVal(types, true, searchIds);
      if (types === 'parentSupplier') {
        this.supplierDetails(searchIds, 'add');
      }
    }

    this.emitToParent(types, text);
    this.filterArray = this.dropDownObjArray;
  }
  public emitToParent(types?, text?) {
    let tradingId = this.preferedFilters && this.preferedFilters.tradingId ? [...this.preferedFilters.tradingId, ...this.tradingId]  : this.tradingId ;
    let materialId = this.preferedFilters && this.preferedFilters.materialId ? [...this.preferedFilters.materialId, ...this.materialId]  : this.materialId ;
    let opcoId = this.preferedFilters && this.preferedFilters.opcoId ? [...this.preferedFilters.opcoId, ...this.opcoId]  : this.opcoId ;
    let supplierId = this.preferedFilters && this.preferedFilters.suplierId ? [...this.preferedFilters.suplierId, ...this.supplierId]  : this.supplierId ;
    this.OutputToParent.emit({
      type: types,
      filterName: text,
      suplierId: [...new Set(supplierId)],
      opcoId: [...new Set(opcoId)],
      catalogueId: this.catalogueId,
      materialId: [...new Set(materialId)],
      tradingId: [...new Set(tradingId)] ,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      catalogueName: this.catalogueName,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      selectedDate: this.selectedDate,
      catalogueNameFilterType: this.catalogueNameFilterType
    });
  }

  public supplierDetails(searchIds, addRemove ) {
    this.parentSupplierArr.find((suppliers) => {
      if (suppliers.organizationName === searchIds) {
        if (addRemove === 'add') {
          this.supDet = {...this.supDet, ...suppliers.childSupplierDetails};
          this.suppliersDetails = Object.keys(this.supDet).map(k => {
            return {
              key: k,
              value: this.supDet[k],
              selected: false
            };
          });
        } else {
          Object.keys(suppliers.childSupplierDetails).map(k => {
            delete this.supDet[k];
            this.suppliersDetails = Object.keys(this.supDet).map(t => {
              return {
                key: t,
                value: this.supDet[t],
                selected: false
              };
            });
          });

          if (this.suppliersDetails.length === 0) {
            this.suppliersDetails = Object.keys(this.filterSupplier).map(k => {
              return {
                key: k,
                value: this.filterSupplier[k],
                selected: false
              };
            });
          }
        }
      }
    });
  }

  public autoSelect(searchIds, listArr) {
    listArr.map((element) => {
      if (element.key === searchIds) {
        element['selected'] = true;
      }
    });
  }

  public deleteFromDropDownObj(type, key) {
    const index = this.dropDownObjArray.findIndex(obj => {
      return obj.type === type && obj.key === key;
    });
    if (index >= 0) {
      this.dropDownObjArray.splice(index, 1);
    }
  }

  public GetOutputVal(type, status, searchIds) {
    if (type === 'parentSupplier') {
      this.supplierDetails(searchIds, 'add');
      if (status === true) {
        if (this.parentSupplierId.includes(searchIds)) {
          this.parentSupplierCount = this.parentSupplierCount;
          this.parentSupplierId = this.parentSupplierId;
        } else {
          this.parentSupplierCount += 1;
          this.parentSupplierId.push(searchIds);
          const obj = {
            type,
            value: this.organizationIdNameMapping[searchIds],
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.parentSupplierDetails);
        }
      } else {
        this.parentSupplierCount -= 1;
        this.deleteValueFromArr(this.parentSupplierId, searchIds, this.parentSupplierDetails);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'supplier') {
      if (status === true) {
        if (this.supplierId.includes(searchIds)) {
          this.supplierCount = this.supplierCount;
          this.supplierId = this.supplierId;
        } else {
          this.supplierCount += 1;
          this.supplierId.push(searchIds);
          const obj = {
            type,
            value: this.responseObj['suppliersDetails'][searchIds],
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.suppliersDetails);
        }
      } else {
        this.supplierCount -= 1;
        this.deleteValueFromArr(this.supplierId, searchIds, this.suppliersDetails);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'opco') {
      if (status === true) {
        if (this.opcoId.includes(searchIds)) {
          this.opcoCount = this.opcoCount;
          this.opcoId = this.opcoId;
        } else {
          this.opcoCount += 1;
          this.opcoId.push(searchIds);
          const obj = {
            type,
            value: `${searchIds}-${this.responseObj['opcoDetails'][searchIds]}`,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.opcoDetails);
        }
      } else {
        this.opcoCount -= 1;
        this.deleteValueFromArr(this.opcoId, searchIds, this.opcoDetails);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'catalogue') {
      if (status === true) {
        if (this.catalogueId.includes(searchIds)) {
          this.catalogueCount = this.catalogueCount;
          this.catalogueId = this.catalogueId;
        } else {
          this.catalogueCount += 1;
          this.catalogueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catalogueType);
        }
      } else {
        this.catalogueCount -= 1;
        this.deleteValueFromArr(this.catalogueId, searchIds, this.catalogueType);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'l1Category') {
      if (status === true) {
        if (this.catalogueId.includes(searchIds)) {
          this.l1CategoryCount = this.l1CategoryCount;
          this.catalogueId = this.catalogueId;
        } else {
          this.l1CategoryCount += 1;
          this.catalogueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catalogueType);
        }
      } else {
        this.l1CategoryCount -= 1;
        this.deleteValueFromArr(this.catalogueId, searchIds, this.catalogueType);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'material') {
      if (status === true) {
        if (this.materialId.includes(searchIds)) {
          this.materialCount = this.materialCount;
          this.materialId = this.materialId;
        } else {
          this.materialCount += 1;
          this.materialId.push(searchIds);
          const obj = {
            type,
            value: `${searchIds}-${this.responseObj['materialGroups'][searchIds]}`,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.materialGroups);
        }
      } else {
        this.materialCount -= 1;
        this.deleteValueFromArr(this.materialId, searchIds, this.materialGroups);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'trading') {
      if (status === true) {
        if (this.tradingId.includes(searchIds)) {
          this.tradingCount = this.tradingCount;
          this.tradingId = this.tradingId;
        } else {
          this.tradingCount += 1;
          this.tradingId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.tradingModels);
        }
      } else {
        this.tradingCount -= 1;
        this.deleteValueFromArr(this.tradingId, searchIds, this.tradingModels);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'catalogueStatus') {
      if (status === true) {
        if (this.catalogueStatusId.includes(searchIds)) {
          this.catalogueStatusCount = this.catalogueStatusCount;
          this.catalogueStatusId = this.catalogueStatusId;
        } else {
          this.catalogueStatusCount += 1;
          this.catalogueStatusId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catStatusArr);
        }
      } else {
        this.catalogueStatusCount -= 1;
        this.deleteValueFromArr(this.catalogueStatusId, searchIds, this.catStatusArr);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'catalogueNames') {
      if (status === true) {
        if (this.catalogueName.includes(searchIds)) {
          this.catalogueNameCount = this.catalogueNameCount;
          this.catalogueName = this.catalogueName;
        } else {
          this.catalogueNameCount += 1;
          this.catalogueName.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catalogueNames);
        }
      } else {
        this.catalogueNameCount -= 1;
        this.deleteValueFromArr(this.catalogueName, searchIds, this.catalogueNames);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === 'currency') {
      if (status === true) {
        if (this.currencyId.includes(searchIds)) {
          this.currencyCount = this.currencyCount;
          this.currencyId = this.currencyId;
        } else {
          this.currencyCount += 1;
          this.currencyId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.currencyDetails);
        }
      } else {
        this.currencyCount -= 1;
        this.deleteValueFromArr(this.currencyId, searchIds, this.currencyDetails);
        this.deleteFromDropDownObj(type, searchIds);
      }
    }
  }

  public deleteValueFromArr(arrayData, searchIds, listArr) {
    listArr.map((element) => {
      if (element.key === searchIds) {
        element['selected'] = false;
      }
    });

    const index = arrayData.indexOf(searchIds);
    if (index !== -1) { arrayData.splice(index, 1); }
    let filterIndex = -1;
    this.filterArray.forEach((element, i) => {
      if (element['value'] === searchIds) {
        filterIndex = i;
      }
    });
    if (filterIndex !== -1) { this.filterArray.splice(filterIndex, 1); }
  }

  public onRemoveFilter(index) {
    this.savedFilterValue = undefined;
    const removeObj = this.filterArray[index];
    this.filterArray.splice(index, 1);
    if (removeObj['type'] === 'validity') {
      this.selectedDate = [];
    } else {
      this.GetOutputVal(removeObj['type'], false, removeObj['key']);
    }
    this.emitToParent();
  }

  public getSavedFilters() {
    this.filterService.getUserFilters('analytics').subscribe(
      response => {
        this.userFilters = response['result'];
      },
      error => console.log(error)
    );
  }

  public onSelect(filterName: string) {
    this.savedFilterValue = filterName;
    this.clearOnSelect();
    this.filterService.getUserFilter({ componentName: 'analytics', filterName }).subscribe(
      response => {
        this.filterRecord = response['result']['filters'];
        this.filterArray = this.prepairSerchObjFromRes(response['result']['filters']);
        this.filterArray.forEach(element => {
          this.GetOutputVal(element['type'], true, element['key']);
        });
        this.emitToParent();
      },
      error => console.log(error)
    );
  }

  public prepairSerchObjFromRes(data) {
    const filterObjArr = [];
    data.forEach(element => {
      if (element['name'] === 'validity') {
        element['value'].forEach(valElement => {
          this.selectedDate = valElement['value'];
          filterObjArr.push({ type: element['name'], key: valElement['key'], value: valElement['value'] })
        });
      } else {
        element['value'].forEach(valElement => {
          filterObjArr.push({ type: element['name'], key: valElement['key'], value: valElement['value'] });
        });
      }
    });
    return filterObjArr;
  }

  public clearFilter(emitToParent) {
    this.filterArray = [];
    this.savedFilterValue = undefined;
    this.filterObjToSave = {
      filters: [],
      filterName: '',
      componentName: ''
    };

    this.counts = 0;
    this.supplierCount = 0;
    this.opcoCount = 0;
    this.catalogueCount = 0;
    this.materialCount = 0;
    this.tradingCount = 0;
    this.catalogueStatusCount = 0;
    this.catalogueNameCount = 0;
    this.paStatusCount = 0;
    this.taskTypeCount = 0;
    this.taskStatusCount = 0;
    this.l1CategoryCount = 0;
    this.parentSupplierCount = 0;
    this.currencyCount = 0;

    this.supplierId = [];
    this.opcoId = [];
    this.catalogueId = [];
    this.materialId = [];
    this.tradingId = [];
    this.paStatusId = [];
    this.catalogueStatusId = [];
    this.catalogueName = [];
    this.taskTypeId = [];
    this.dropDownObjArray = [];
    this.taskStatusId = [];
    this.parentSupplierId = [];
    this.integrationStatusId = [];
    this.currencyId = [];
    
    this.selectedDate = [];

    this.suppliersDetails = Object.keys(this.filterSupplier).map(k => {
      return {
        key: k,
        value: this.filterSupplier[k],
        selected: false
      };
    });

    // tslint:disable-next-line: max-line-length
    const array = [this.tradingModels, this.suppliersDetails, this.opcoDetails, this.catalogueType, this.materialGroups, this.parentSupplierDetails];
    array.forEach(nestedArray => {
      nestedArray.forEach(element => {
        if (element['selected'] === true) {
          element['selected'] = false;
        }
      });
    });

    if (emitToParent) {
      this.emitToParent();
    }
  }

  public clearOnSelect() {
    this.filterArray = [];
    this.filterObjToSave = {
      filters: [],
      filterName: '',
      componentName: ''
    };
  }

  public onChangeLastUpdate(event) {
    let dates = event.split('&');
    this.selectedDate = dates.map(element => {
      let date = new Date(element);
      let year = date.getFullYear();
      let month = <any>date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${year}-${month}`;
    });
    this.filterArray = this.filterArray.filter(obj => obj.type !== 'validity');
    this.filterArray.push({ type: 'validity', value: this.selectedDate, key: this.selectedDate });
    this.emitToParent();
  }

  public saveFilterDialog() {
    this.filterObjToSave = this.prepairFilterObjToSave(this.filterArray, 'analytics');
    if (!this.filterObjToSave['filters'].length) {
      return;
    }
    this.savefilterService.saveData = this.filterObjToSave;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: '',
      filterList: this.userFilters // [VOD-1656]
    };
    const dialogRef = this.dialog.open(SaveFiltersComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      // this.getSavedFilters()
    });

  }

  public prepairFilterObjToSave(array, componentNames: string) {
    const filterObjToSave: { filters: Array<any>, filterName: string, componentName: string } = {
      filters: [],
      filterName: '',
      componentName: componentNames
    };

    if (array.length) {
      array.forEach(element => {
        const foundIndex = filterObjToSave.filters.findIndex((ele) => ele.name === element.type);
        if (foundIndex >= 0) {
          filterObjToSave.filters[foundIndex].value.push({key: element.key, value: element['value']})
        } else {
          filterObjToSave.filters.push({
            name: element.type,
            value: []
          });
          filterObjToSave.filters[filterObjToSave.filters.length - 1].value.push({key: element.key, value: element['value']});
        }
      });
    }
    return filterObjToSave;
  }

  changePreferenceSetting(event) {
    // this.clearFilter(false);
    // this.preSetting
    if (event.checked) {
      if (!localStorage.getItem('preferedFilters')) {
        this.configurationServices.getConfiguration().subscribe(res => {
          localStorage.setItem('preferedFilters', JSON.stringify(res.result));
          let filters = this.preparePreferredSettings(res.result);
          this.preferedFilters = filters;
          this.OutputToParent.emit(filters);
        })
      } else {
        let data = JSON.parse(localStorage.getItem('preferedFilters'));
        let filters = this.preparePreferredSettings(data);
        this.preferedFilters = filters;
        this.OutputToParent.emit(filters);
      }
    } else {
      this.clearFilter(false);
      this.preferedFilters = null;
      this.OutputToParent.emit({});
    }
  }


  preparePreferredSettings(filters) {
    let tradingModel = [];
    let localMarket = [];
    let materialGroups = [];
    let suppliers = [];

    if (filters.tradingModel) {
      filters.tradingModel.forEach(element => {
        tradingModel.push(element.tradingModel);
      });
    }
    if (filters.localMarket) {
      filters.localMarket.forEach(element => {
        localMarket.push(element.opcoId);
      });
    }
    if (filters.materialGroups) {
      filters.materialGroups.forEach(element => {
        materialGroups.push(element.materialGroupId);
      });
    }
    if (filters.suppliers) {
      filters.suppliers.forEach(element => {
        suppliers.push(element.supplierId);
      });
    }
    let filter = {
      suplierId: suppliers,
      tradingId: tradingModel,
      opcoId: localMarket,
      materialId: materialGroups
    }
    return filter
  }

  public deleteSavedUserFilter(filterName: string) {
    // this.deleteCheck = true;
    this.clearFilter(false);
    this.filterService.deleteSavedUserFilter({ componentName: 'analytics', filterName }).subscribe(response => {
      this.userFilters = this.userFilters.filter(f => {
        return f.filterName !== filterName
      })
    }, error => {
      console.log(error);
    });
  }
  
}
