import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServices } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthServices) {
  }

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authService.getToken();
    if (token) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}