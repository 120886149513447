import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Filters } from '../../../../../_models/analytics';
import { AnalyticsService } from '../../../../../_services/analytics.service';
import { MatPaginator } from '@angular/material';
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @ViewChild('paginator', { static: false}) public paginator: MatPaginator;
  @Input() paId: any;
  public page = 1;
  public pageSize = 5;
  public kpiCards: any = [];
  public totalRecords = 0;
  public categoryList = [];
  public showLoader = true;
  public sortName: string = '';
  public sortType: number = 1;
  public searchKey: string = '';
  public loaded: boolean = false;
  public showNoRes: boolean = false;
  public kpiCardSubscription: Subscription;
  public sortSubscription: Subscription;
  public indexStart = 0;
  public indexEnd = 4;

  // tslint:disable-next-line: no-empty
  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  public ngOnInit() {
    this.sort(); // subscribing emitted changes by global sort
    if(this.paId) {
      this.searchKey = this.paId;
      this.initComp();
    }
    // call api only when id="items" becomes visible or is in the viewport
    var observer = new IntersectionObserver((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting === true && !this.loaded) {
        this.initComp();
      }
    }, { threshold: [0.1] });
    observer.observe(document.querySelector("#items"));
  }

  initComp() {
    this.loaded = true;
    this.kpiCardSubscription = this.analyticsService.getAnalytics().subscribe(res => {
      if (res.result) {
        this.kpiCards = res;
        this.getKpiData();
      }
    });
  }

  sort() {
    this.sortSubscription = this.analyticsService.getSort().subscribe(res => {
      this.sortName = res.sortKey;
      this.sortType = res.sortType;
      if (this.loaded) {
        this.getKpiData();
      }
    })
  }

  public search(searchValue: string): void {
    this.searchKey = searchValue;
    const filter = this.analyticsService.initFilter('supplierPartNumber', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatDataForView(filter);
  }

  public getKpiData(): void {
    const filter = this.analyticsService.initFilter('supplierPartNumber', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatDataForView(filter);
  }

  public loadPage(page: number): void {
    const filter = this.analyticsService.initFilter('supplierPartNumber', page - 1, 5, this.sortName, this.sortType, this.searchKey);
    this.formatDataForView(filter);
  }
 
  public formatDataForView(filter: Filters): void {
    this.showLoader = true;
    this.analyticsService.getKpiDimensionData(filter).subscribe(kpiDimensions => {
      if (kpiDimensions && kpiDimensions.result && this.kpiCards && this.kpiCards.result) {
        if (kpiDimensions.result[0].dimensionValues != null) {
          const { records, totalRecords } = this.analyticsService.createObject
            (
              kpiDimensions.result[0],
              this.kpiCards
            );
          this.getGraph(records, totalRecords);
        } else {
          this.showLoader = false;
          this.showNoRes = true;
        }
      }
    }, err => {
      this.showLoader = false;
      this.showNoRes = true;
      console.log(err)
    });
  }

  public getGraph(records,totalRecords: number): void {
    let count = 0;
    let invoice = [];
    let order = [];
    let item = [];
    let months = [];
    records.forEach(supplier => {
      const filters = {
        dimensionName: 'supplierPartNumber',
        dimensionValue: supplier.subTitleValue
      };
      this.analyticsService.getLastOneYearDimention(filters).subscribe(res => {
        count++;
        invoice = [];
        order = [];
        item = [];
        months = [];
        if (res.result) {
          res.result.forEach(graph => {
            months.push(graph.monthYear);
            graph.aggregateDimensionValue.forEach(kpi => {
              if (kpi.kpiName == "INVOICE_VALUE") {
                invoice.push(kpi.kpiValue)
              } else if (kpi.kpiName == "ORDER_VALUE") {
                order.push(kpi.kpiValue);
              } else if (kpi.kpiName == "SPN_PRICE") {
                item.push(kpi.kpiValue);
              }
            })
          })
          supplier.chartData = [{
            name: 'Order Value',
            color: '#9ABDFF',
            data: order,
            dashStyle: 'solid',
            connectNulls: true
          }, {
            name: 'Invoiced Value',
            color: '#7DF0D1',
            data: invoice,
            dashStyle: 'solid',
            connectNulls: true
          },
            {
              name: 'Item Price',
              color: '#333333',
              data: item,
              dashStyle: 'dot',
              connectNulls: true
            }
          ]
          supplier.months = months;
        }
        if (count == records.length) {
          this.showLoader = false;
          this.showNoRes = false;
          this.categoryList = records;
          this.totalRecords = totalRecords;
          this.indexEnd = records.length - 1;
        }
      }, err => {
        console.log(err)
      });
    });
  }

  ngOnDestroy() {
    if (this.kpiCardSubscription) {
      this.kpiCardSubscription.unsubscribe();
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
  }

}