import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  /**
   *Function that accept json data & header name and send as excel beffer data to next function
   *
   * @param {any[]} json
   * @param {*} headerColumns
   * @param {string} excelFileName
   * @memberof ExcelService
   */
  public async exportAsExcelFile(json: any[], headerColumns, excelFileName: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(excelFileName ? excelFileName : 'sheet-1');
    worksheet.columns = headerColumns;
    const headerCol = 65;
    var j: number = 26;
    var value;
    // tslint:disable-next-line: variable-name
    headerColumns.forEach((_header: any, i: number) => {
      if (i > 25) {
        const p = i - j;
        value = `A${String.fromCharCode(65 + p)}1`;
      }
      else {
        value = `${String.fromCharCode(headerCol + i)}1`;
      }
      const cell = worksheet.getCell(value);
      //const cell = worksheet.getCell(`${String.fromCharCode(headerCol + i)}1`); //commented by ankita to pass the column from a-z and aa to az

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' }
      };
      cell.font = {
        bold: true,
      };
    });
    json.forEach(obj => {
      worksheet.addRow(obj);
    });
    if (excelFileName.includes('MATERIAL_DETAILS_')) {
      json.forEach((element, index) => {
        worksheet.getCell("B" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Physical,Non-Physical"']
        };
        worksheet.getCell("L" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Parent,Child"']
        };
        worksheet.getCell("P" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Y,N"']
        };
        worksheet.getCell("Y" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"CCM,CDM,HL,L,M3,ML,QT,4G,CTL,FT3,GLL,IN3,MMQ,OZA,PT,YD3"']
        };
        worksheet.getCell("AA" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Active,Passive"']
        };
        worksheet.getCell("AB" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Yes,No"']
        };
        worksheet.getCell("W" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"CM,DM,FT,HWL1,HWL2,HWL3,IN,KG,KM,M,MI,MIM,MM,NAM,YD"']
        };

        worksheet.getCell("E" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"AB-01,AB-02,AB-03"']
        };
        /*worksheet.getCell("H" + (+index + 2)).dataValidation = {
           type: "list",
           allowBlank: true,
           formulae: ['"M-2,K,MIN,TC3,TM3,ACR,AU,A,BAG,BAR,BQK,BT,CD,CAN,CAR,CV,CL,CM,CMH,CMS,CPM,CRT,CCM,C3S,CD3,FT3,M3,KMK,M3H,M3S,MM3,YD3,D,DAY,DM,DEG,°C,DZ,DR,EA,EU,EML,EI,WKY,°F,F,FOZ,FT,GPH,GPM,GJ,GOH,G,GAI,G/L,GAU,RHO,GM3,GHG,TON,VAL,V,VAM,VPB,VPM,VPT,W,WK,WP,YD,YR,D10,GKG,GLI,GM,GM2,GRO,PRC,WMK,HA,HL,HPA,HZ,H,HR,ST,J,JKG,JMO,KMN,KMS,KIK,KA,KBK"']
           //,KG,KAI,KD3,KGV,KGK,KGM,KGS,KGF,KHZ,KJ,KJK,KJM,KM,KMH,QML,NI,KOH,KPA,KT,KV,KVA,KW,KWH,L,LHK,LPH,LMI,LMS,MPB,MPM,MPT,MHZ,MEJ,MN,MPA,MHV,MVA,MGW,MWH,MGO,M,M/H,000,M/S,MS2,MBZ,MPZ,µA,µF,µGQ,µGL,µL,µM,MIS,MSC,MI,MPG,MA,MBA,RF,MG,MGQ,MGG,MGK,MGL,MGE,MIJ,ML,MLI,MLK,MM,MMH,MMS,MMA,MMO,MPL,MMG,MMK,MNM,MPS,MSE,MTE,MV,MW,MOL,M/M,M/L,MOK,MON,NA,R-U,NAM,NS,N,NM,NMM,PRS,OHM,ONE,OZ,PAC,PAA,PAL,PPB,PPM,PPT,PA,PAS,%O,M%,V%,%,M%O,V%O,PS,IB,PT,P,QT,ROL,S,A/V,OCM,OM,JKK,CM2,FT2,KM2,M2,M2S,MI2,MM2,22S,YD2,TES,TS,TOM,TO,GAL,LB,C25,C26,VPh,M1,2M,C10,C24,C2,#MG,TC3,C111,C112,C76,C111"']
           
         };*/
      });
    }

    const excelBuffer: Excel.Buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public async exportAsExcelFile_Bulk(json: any[], headerColumns, excelFileName: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('sheet-1');
    const worksheet1 = workbook.addWorksheet('sheet2');
    ///
    var state_list = ["Alabama-AL", "Alaska-AK", "Arizona-AZ", "Arkansas-AR",
      "California-CA", "Colorado-CO", "Connecticut-CT",
      "Delaware-DE", "District of Columbia-DC", "Florida-FL",
      "Georgia-GA", "Hawaii-HI", "Idaho-ID", "Illinois-IL", "Indiana-IN",
      "Iowa-IA", "Kansas-KS", "Louisiana-LA", "Maine-ME", "Maryland-MD",
      "Massachusetts-MA", "Michigan-MI", "Minnesota-MN", "Mississippi-MS",
      "Missouri-MO", "Montana-MT", "Nebraska-NE", "Nevada-NV",
      "New Hampshire-NH", "New Jersey-NZ", "New Mexico-NM", "New York-NY",
      "North Carolina-NC", "North Dakota", "Ohio", "Oklahoma-OK",
      "Oregon-OR", "Pennsylvania-PA", "Rhode Island-RI", "South Dakota-SD",
      "Tennessee-TN", "Texas-TX", "Utah-UT", "Vermont-VT",
      "Virginia-VA", "Washington-WA", "West Virginia-WV",
      "Wisconsin-WI", "Wyoming-WY"];

    // add a table to a sheet
    worksheet1.addTable({
      name: 'MyTable',
      ref: 'A1',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleDark3',
        showRowStripes: true,
      },
      columns: [
        { name: 'Date', filterButton: true },
      ],
      rows: [
        ["Alabama-AL"],
        ["Hello"],
        ["Hi"],
      ],
    });
    //worksheet1.write_column("")
    //.write_column('K10', state_list);

    /*worksheet1.data_validation(1, 3, 5000, 3,
                              {'validate': 'list',
                               'source': '=sheet-2!$A$1:$A$61'});*/
    ///




    worksheet.columns = headerColumns;
    const headerCol = 65;
    var j: number = 26;
    var value;
    // tslint:disable-next-line: variable-name
    headerColumns.forEach((_header: any, i: number) => {
      if (i > 25) {
        const p = i - j;
        value = `A${String.fromCharCode(65 + p)}1`;
      }
      else {
        value = `${String.fromCharCode(headerCol + i)}1`;
      }
      const cell = worksheet.getCell(value);
      //const cell = worksheet.getCell(`${String.fromCharCode(headerCol + i)}1`); //commented by ankita to pass the column from a-z and aa to az

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' }
      };
      cell.font = {
        bold: true,
      };
    });
    json.forEach(obj => {
      worksheet.addRow(obj);
    });
    if (excelFileName.includes('MATERIAL_DETAILS_')) {
      json.forEach((element, index) => {
        worksheet.getCell("B" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Physical,Non-Physical"']
        };
        worksheet.getCell("L" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Parent,Child"']
        };
        worksheet.getCell("P" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Y,N"']
        };
        worksheet.getCell("Y" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"CCM,CDM,HL,L,M3,ML,QT,4G,CTL,FT3,GLL,IN3,MMQ,OZA,PT,YD3"']
        };
        worksheet.getCell("AA" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Active,Passive"']
        };
        worksheet.getCell("AB" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"Yes,No"']
        };
        worksheet.getCell("W" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"CM,DM,FT,IN,KM,M,MI,MIM,MM,NAM,YD"']
        };
        worksheet.getCell("W" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"CM,DM,FT,IN,KM,M,MI,MIM,MM,NAM,YD"']
        };
        worksheet.getCell("E" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: ['"AB-01,AB-02,AB-03"']
        };
        worksheet.getCell("H" + (+index + 2)).dataValidation = {
          type: "list",
          allowBlank: true,
          source: '=sheet2!$A$1:$A$4'
        };
        /*worksheet.getCell("H" + (+index + 2)).dataValidation = {
           type: "list",
           allowBlank: true,
           formulae: ['"M-2,K,MIN,TC3,TM3,ACR,AU,A,BAG,BAR,BQK,BT,CD,CAN,CAR,CV,CL,CM,CMH,CMS,CPM,CRT,CCM,C3S,CD3,FT3,M3,KMK,M3H,M3S,MM3,YD3,D,DAY,DM,DEG,°C,DZ,DR,EA,EU,EML,EI,WKY,°F,F,FOZ,FT,GPH,GPM,GJ,GOH,G,GAI,G/L,GAU,RHO,GM3,GHG,TON,VAL,V,VAM,VPB,VPM,VPT,W,WK,WP,YD,YR,D10,GKG,GLI,GM,GM2,GRO,PRC,WMK,HA,HL,HPA,HZ,H,HR,ST,J,JKG,JMO,KMN,KMS,KIK,KA,KBK"']
           //,KG,KAI,KD3,KGV,KGK,KGM,KGS,KGF,KHZ,KJ,KJK,KJM,KM,KMH,QML,NI,KOH,KPA,KT,KV,KVA,KW,KWH,L,LHK,LPH,LMI,LMS,MPB,MPM,MPT,MHZ,MEJ,MN,MPA,MHV,MVA,MGW,MWH,MGO,M,M/H,000,M/S,MS2,MBZ,MPZ,µA,µF,µGQ,µGL,µL,µM,MIS,MSC,MI,MPG,MA,MBA,RF,MG,MGQ,MGG,MGK,MGL,MGE,MIJ,ML,MLI,MLK,MM,MMH,MMS,MMA,MMO,MPL,MMG,MMK,MNM,MPS,MSE,MTE,MV,MW,MOL,M/M,M/L,MOK,MON,NA,R-U,NAM,NS,N,NM,NMM,PRS,OHM,ONE,OZ,PAC,PAA,PAL,PPB,PPM,PPT,PA,PAS,%O,M%,V%,%,M%O,V%O,PS,IB,PT,P,QT,ROL,S,A/V,OCM,OM,JKK,CM2,FT2,KM2,M2,M2S,MI2,MM2,22S,YD2,TES,TS,TOM,TO,GAL,LB,C25,C26,VPh,M1,2M,C10,C24,C2,#MG,TC3,C111,C112,C76,C111"']
           
         };*/
      });
    }

    const excelBuffer: Excel.Buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  /**
   *Function that download the excel excel file
   *
   * @param {Excel.Buffer} buffer
   * @param {string} fileName
   * @return {*}  {void}
   * @memberof ExcelService
   */
  public saveAsExcelFile(buffer: Excel.Buffer, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    if (window.navigator.msSaveBlob) {
      // IE11 & Edge
      window.navigator.msSaveBlob(data, `${fileName}-${new Date().toISOString()}${EXCEL_EXTENSION}`);
      return;
    }
    const url = window.URL.createObjectURL(data);
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = `${fileName}${new Date().toISOString()}${EXCEL_EXTENSION}`;
    a.click();
  }
}
