import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Filters } from '../../../../../_models/analytics';
import { AnalyticsService } from '../../../../../_services/analytics.service';

@Component({
  selector: 'app-l1-categories',
  templateUrl: './l1-categories.component.html',
  styleUrls: ['./l1-categories.component.scss']
})
export class L1CategoriesComponent implements OnInit {
  public page = 1;
  public pageSize = 5;
  public l1CategoryList: any = [];
  public totalRecords = 0;
  public kpiCards: any = {};
  public showLoader = true;
  public sortName: string = '';
  public sortType: number = 1;
  public searchKey: string = '';
  public loaded: boolean = false;
  public showNoRes: boolean = false;
  public kpiCardSubscription: Subscription;
  public sortSubscription: Subscription;
  public indexStart = 0;
  public indexEnd = 4;
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  public ngOnInit() {
    this.sort(); // subscribing emitted changes by global sort

    // call api only when id="l1Category" becomes visible or is in the viewport
    var observer = new IntersectionObserver((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if(entries[0].isIntersecting === true && !this.loaded) {
        this.initComp();
      }
    }, { threshold: [0.1] });
    observer.observe(document.querySelector("#l1Category"));
  }

  initComp() {
    this.loaded = true;
    this.kpiCardSubscription = this.analyticsService.getAnalytics().subscribe(res => {
      if (res.result) {
        this.kpiCards = res;
        this.getAnalytics();
      }
    });
  }

  sort() {
    this.sortSubscription = this.analyticsService.getSort().subscribe(res => {
      this.sortName = res.sortKey;
      this.sortType = res.sortType;
      if (this.loaded) {
        this.getAnalytics();
      }
    })
  }

  public search(searchValue: string): void {
    this.searchKey = searchValue;
    const filter = this.analyticsService.initFilter('catalogueType', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter)
  }

  public getAnalytics(): void {
    const filter = this.analyticsService.initFilter('catalogueType', 0, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter);
  }

  public loadPage(page: number): void {
    const filter = this.analyticsService.initFilter('catalogueType', page - 1, 5, this.sortName, this.sortType, this.searchKey);
    this.formatKpiData(filter);
  }

  public formatKpiData(filter: Filters): void {
    this.showLoader = true;
    this.analyticsService.getKpiDimensionData(filter).subscribe(kpiDimensions => {
      if (kpiDimensions && kpiDimensions.result && this.kpiCards && this.kpiCards.result) {
        this.showLoader = false;
        if (kpiDimensions.result[0].dimensionValues != null) {
          const { records, totalRecords } = this.analyticsService.createObject
          (
            kpiDimensions.result[0],
            this.kpiCards
          );
          this.showNoRes = false;
          this.l1CategoryList = records;
          this.totalRecords = totalRecords;
          this.indexEnd = records.length - 1;
        } else {
          this.showNoRes = true;
        }
      }
    }, err => {
      this.showLoader = false;
      this.showNoRes = true;
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.kpiCardSubscription) {
      this.kpiCardSubscription.unsubscribe();
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
  }
}

