import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../_config";
import { IServerResponse } from "../_models/response";
import { IPriceAgreementInput } from "../_models/price-manager";

import "rxjs/add/observable/of";
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { AuthServices } from "./auth.service";

const { priceUrl1, priceUrl, configUrl } = AppConfig;

@Injectable({
  providedIn: "root",
})
export class PriceManagerServices {
  private priceAgreementStatusAndConstants: any;

  private priceFilter = new Subject<any>();
  private priceAgreement = new Subject<any>();
  private priceAgreement1: any;
  private materialDropDownData: any;
  private roleBasedDataAdd: any;
  private roleBasedDataEdit: any;
  private roleBasedDataChange: any;
  private activeColumns: any;
  private priceAgreementVersion1: any;
  private savedTransactionDiffResponse = new Subject<any>();
  public workspaceValue = new BehaviorSubject<any>("Network Workspace");
  private savedTransactionDiffResponse1: any;
  public agreementDetails = new Subject<any>();
  public storeFilterConfiguration = new Subject<any>();
  public priceAgreementVersion = new Subject<any>();
  public priceAgreementVersionDifference = new Subject<any>();
  public confirmSelection = new Subject<any>();
  public topMenuFilter = new Subject<any>();
  private catalogueData1: any;
  private catalogueData = new Subject<any>();
  private currencyData1: any;
  private currencyData = new Subject<any>();
  private watcherDetails = new Subject<any>();
  private editPaBeforeProceed = new Subject<any>();
  private logRefresh = new Subject<any>();
  private archiveVal = new Subject<any>();
  private isRecomended = new Subject<any>();
  public dataRefreshFlag = new BehaviorSubject<boolean>(false);
  private LastSavedDate1: string;
  private LastSavedDate = new Subject<any>();

  private supplierApprovalFlag1: string;
  private supplierApprovalFlag = new Subject<any>();
  public supplierApprovalFlagdata = new BehaviorSubject<boolean>(false);
  private formDataList: any;
  constructor(private http: HttpClient, private authService: AuthServices) { }
  private getPriceAgreementStatusUrl: string =
    priceUrl1 + "network/price-manager/agreement-status";
  private priceAgreementUrl: string = priceUrl1 + "network/price-manager/agreements";
  private priceAgreementCatalogueUrl: string =
    priceUrl1 + "network/price-manager/get-catalogues";
  private priceAgreementCatalogueListUrl: string =
    priceUrl1 + "network/price-manager/price-manager-catalogue";
  private catalogueSettingUrl: string =
    priceUrl1 + "network/price-manager/catalogue-settings-value";
  private updateCatalogueSettingUrl: string =
    priceUrl1 + "network/price-manager/update-catalogue-settings";
  private restoreArchiveCatalogueUrl: string =
    priceUrl1 + "network/price-manager/restore-archive-catalogue";
  private downloadCataloguesUrl: string =
    priceUrl1 + "network/price-manager/download-catalogues";
  private unlockPid: string =
    priceUrl1 + "network/price-manager/remove-object-lock";
  private priceAgreementAutomatedDataURL: string =
    configUrl + "pm/integration/getAssetClassDetailsByOlaId?";
  private getUserApprovalRequiredFlagUrl: string =
    configUrl + "pm/integration/getSupplierApprovalRequiredFlag/";
  private priceAgreementInputByOlaURL: string =
    configUrl + "pm/integration/network/getPaInputDetailsByOlaId?";
  private savePriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/save-price-agreement";
  private getPriceAgreementCountsUrl: string =
    priceUrl1 + "network/price-manager/agreements/count";
  private getPendingTaskCountURL: string = priceUrl1 + "network/user-task/statistics";
  private downloadPriceListUrl: string =
    priceUrl1 + "network/price-manager/agreements/download";
  private getRecentAgreementURL: string =
    priceUrl1 + "network/price-manager/recently-viewed-agreements";
  private getEditPriceHeadersUrl: string =
    configUrl + "pm/integration/getPriceAgreementFields";
  private getEditPriceHeadersNodeUrl: string =
    priceUrl1 + "network/configuration/item-fields?component=all";
  private getEditPriceHeadersNonNetoworkNodeUrl: string =
    priceUrl1 + "network/configuration/item-fields-it?component=all";
  private getRequiredPriceHeadersUrl: string =
    configUrl + "pm/integration/mdmFileGenReadyCheck";
  private getPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/get-price-agreement";
  private getFixRatesURL = configUrl + "pm/integration/network/getFxRates";
  private checkSupplierPartNumberForSupplierUrl =
    priceUrl + "network/price-manager/check-supplier-part-number-for-supplier";
  private searchCommodityCode: string =
    configUrl + "pm/integration/searchCommodityCode";
  private approvePriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/approve-price-agreement";
  private submitPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/submit-price-agreement";
  private uploadExcelUrl: string =
    configUrl + "pm/integration/network/uploadPriceAgreement";

  private uploadMatBOMExcelUrl: string =
    priceUrl1 + "network/price-manager/import-material";
  private uploadDataMigrtnSpclstFileUrl: string =
    priceUrl1 + "network/price-manager/data-migration-specialist-import";
  private getPriceAgreementWithTxId: string =
    priceUrl + "network/price-manager/get-agreement-versions-with-txId";
  private downloadCatalogueOutputFileUrl =
    configUrl + "pm/integration/downloadMDMOutputExcel/";
  private syncCatalogueStatusUrl: string =
    priceUrl1 + "network/price-manager/sync-catalogue-status";
  private priceAgreementCatalogueDownload: string =
    priceUrl1 + "network/price-manager/download-pa-catalogue";
  private archivePriceAgreementUrl = priceUrl1 + "network/price-manager/archive";
  private lockUnlockPriceAgreementUrl = priceUrl1 + "network/price-manager/lock";
  private agreementUsersURL = priceUrl1 + "network/price-manager/users";
  private addRemoveWatcherUrl = priceUrl1 + "network/price-manager/watcher";
  private delegateAgreementUrl = priceUrl1 + "network/price-manager/delegate";
  private delegateOwnerWatcherAgreementUrl =
    priceUrl1 + "network/price-manager/change-ownership";
  private reassignProjectOwnershipUrl =
    priceUrl1 + "network/price-manager/reassign-project-ownership";
  private delegateApproverRightsUrl =
    priceUrl1 + "network/price-manager/update-user-delegation-rights";
  private cancelDelegateAgreementUrl =
    priceUrl1 + "network/price-manager/cancel-delegation";
  private changeOwnershipUrl = priceUrl1 + "network/price-manager/ownership";
  private getCommentUrl = priceUrl1 + "network/price-manager/get-comment";
  private addCommentsUrl = priceUrl1 + "network/price-manager/add-comment";
  private getApaUrl = priceUrl1 + "network/price-manager/apa";
  private editReferenceNameUrl =
    priceUrl1 + "network/price-manager/update-reference-name";
  private editSupplierCatalogueVisibilityUrl =
    priceUrl1 + "network/price-manager/update-pa-visibility";
  private presignedUrl =
    priceUrl + "network/price-manager/local-comment-media-pre-signed-url/";
  private triggerPayloadStatusUrl =
    configUrl + "pm/integration/reSynScpIntegration"; // [VP2-164]
  private createPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/create-price-agreement";
  private createPriceAgreementUrlNonNetwork: string =
    priceUrl1 + "network/price-manager/create-price-agreement-it";
  private deletePriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/delete-price-agreement";
  private restoreDeletedPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/restore-deleted-price-agreement";
  private archiveCatalogueUrl: string =
    priceUrl1 + "network/price-manager/catalogue-archive";
  private delegatedPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/get-delegated-price-agreement";
  private lineItemApprovalActionUrl: string =
    priceUrl1 + "network/price-manager/line-item-approval-action";
  private itemApprovalHistory: string =
    priceUrl1 + "network/price-manager/getPAItemApproverHistory";
  private itemSynchronizeHistory: string =
    priceUrl1 + "network/price-manager/getPAItemFieldSynchronizationHistory";
  private itemSynchronizeHistoryButton: string =
    priceUrl1 +
    "network/price-manager/getPAItemFieldSynchronizationHistoryButtonDisplay";
  private fieldChangeHistory: string =
    priceUrl1 + "network/price-manager/getPAItemChangeHistory";
  private allFieldChangeHistory: string =
    priceUrl1 + "network/price-manager/getPAAllItemsChangeHistory";
  // private triggerScpSyncUrl: string =
  //   configUrl + "pm/integration/triggerScpSync";
  private triggerScpSyncUrl: string = priceUrl1 + "network/price-manager/triggerPaSync";
  private lastSyncedVersionPAUrl: string =
    configUrl + "pm/integration/network/getLastSyncedVersionPA";
  private restoreArchivedPriceAgreementUrl: string =
    priceUrl1 + "network/price-manager/restore-archive-price-agreement";
  private cancelMaterialL4DelegateAgreementUrl =
    priceUrl1 + "network/price-manager/cancel-user-delegation-rights";
  private downloadDelegatedAgreementUrl: string =
    priceUrl1 + "network/price-manager/download-delegated-agreement";
  private downloadMaterialBom: string =
    priceUrl1 + "network/price-manager/material/download";
  private legends: string = priceUrl1 + "network/price-manager/legend-mst";
  private downloadMaterialReportsUrl: string =
    priceUrl1 + "network/price-manager/download-material-report";
  private exportMaterialBom: string =
    priceUrl1 + "network/price-manager/export-material";
  private getMaterialUrl: string = priceUrl1 + "network/price-manager/get-materials";
  private getAllMaterialUrl: string =
    priceUrl1 + "network/price-manager/get-all-materials";
  private getSingleMaterialNewUrl: string =
    priceUrl1 + "network/price-manager/get-single-material-data";
  private deleteMaterialUrl: string =
    priceUrl + "network/price-manager/delete-material";
  private getAllBomUrl: string = priceUrl1 + "network/price-manager/get-bom-details";
  private createMaterialUrl: string = priceUrl + "network/price-manager/save-material";
  private validateMaterialUrl: string =
    priceUrl + "network/price-manager/validate-material-bom-network";
  private getSingleMaterialUrl: string =
    priceUrl1 + "network/price-manager/get-single-material";
  private getValidateMaterialUrl: string =
    priceUrl1 + "network/price-manager/validate-material";
  private getSingleBOMUrl: string = priceUrl1 + "network/price-manager/get-single-bom";
  private getSingleBOMDetailsUrl: string =
    priceUrl1 + "network/price-manager/get-single-bom-data";
  private getCorrectionCompleteUrl: string =
    priceUrl1 + "network/price-manager/correction-complete";
  private withdrawMaterialUrl: string =
    priceUrl1 + "network/price-manager/withdrawal-material";
  private getBomUrl: string = priceUrl1 + "network/price-manager/get-single-bom";
  private saveBomUrl: string = priceUrl + "network/price-manager/save-bom-details";
  private getMaterialDropdownsUrl: string =
    priceUrl + "network/price-manager/get-material-dropdowns";
  private deleteBomUrl: string = priceUrl + "network/price-manager/delete-bom";
  private deleteBomChildUrl: string =
    priceUrl + "network/price-manager/delete-bom-child";
  // private actionMaterialUrl: string =priceUrl + 'network/price-manager/approve-material';
  private roleBasedFieldUrl: string =
    priceUrl + "network/configuration/role-based-item-fields";
  private getProjectIdlUrl: string = priceUrl1 + "network/price-manager/get-project-id";
  private actionBomUrl: string = priceUrl + "network/price-manager/approve-bom";
  private actionProjectIdCreateUrl: string =
    priceUrl + "network/price-manager/create-project-id";
  private projectListUrl = priceUrl + "network/price-manager/get-single-mgola/";
  private allProjectIdDrpdwnListUrl =
    priceUrl + "network/price-manager/get-all-projectId-dropdown";
  private deleteProjectIdUrl: string =
    priceUrl + "network/price-manager/delete-project-id";
  private withdrawProjectIdUrl: string =
    priceUrl + "network/price-manager/withdrawal-project";
  private companyCodeDataUrl: string =
    priceUrl + "network/price-manager/get-all-company-code";
  private actionProjectIdUrl: string =
    priceUrl + "network/price-manager/approve-project-id";
  private getProjectIdDropdownsUrl: string =
    priceUrl + "network/price-manager/project-id-dropdown";
  private getSapMaterialIdListUrl: string =
    priceUrl + "network/price-manager/sap-material-ids";
  private syncMaterialUrl: string = priceUrl + "network/price-manager/sync-material-network/";
  private syncMaterialUpdateUrl: string =
    priceUrl + "network/price-manager/sync-material-update/";
  private actionMaterialUrl: string =
    priceUrl + "network/price-manager/approval-material-action";    
  private actionMaterialUrlNetwork: string =
  priceUrl + "network/price-manager/approval-material-action-network";
  private correctionRequestUrl: string =
    priceUrl + "network/price-manager/correction-request";
  private getRecentMaterialAndBOMURL: string =
    priceUrl1 + "network/price-manager/get-recent-item";
  private createMaterialDataUrl: string =
    priceUrl + "network/price-manager/create-material-data-network";
  private blockMaterialUrl: string =
    priceUrl1 + "network/price-manager/block-materials";
  private getVfUserDropdownUrl: string =
    priceUrl1 + "network/price-manager/vodafone-user-dropdown";
  private getReassignUserUrl: string =
    priceUrl1 + "network/price-manager/reassign-vodafone-user-dropdown";
  private unblockMaterialUrl: string =
    priceUrl1 + "network/price-manager/unblock-materials";
  private getL2Url: string = priceUrl1 + "network/price-manager/get-l2";
  private getLogsListUrl: string = priceUrl1 + "network/price-manager/pm-change-logs";
  private getOldValueUrl: string = priceUrl + "network/price-manager/recent-log";
  private materialProjIdLockUrl: string =
    priceUrl1 + "network/price-manager/check-lock";
  private getSingleProjectIdUrl: string =
    priceUrl + "network/price-manager/get-single-projectId/";
  private bussJustDialogData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public bussJustCommentsData: any = [];
  public popUpSubmit = false;
  private getUserIdsForProjectOwnerChange: string =
    priceUrl + "network/price-manager/get-user-ids-for-role/";
  private downloadDmsApprovalDocUrl: string =
    priceUrl + "network/price-manager/dms-download/";
  private mdsRejectionUploadUrl: string =
    priceUrl + "network/price-manager/rejection-import-file";
  private mdsRejectionContentMsg: string =
    priceUrl + "network/price-manager/project-rejection-comment";
  private paApaWorkFlowCheck: string =
    priceUrl + "network/price-manager/apa-workflow-pa";
  public statusListSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public companyCodeListSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public companyCodeList$: Observable<string[]> = this.companyCodeListSubject.asObservable();
  /**
   * This is added for getting Comapny code list
   *
   * @param {{ outlineAgreementNumber: string }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  setConpanyCodeList(newList: string[]): void {
    this.companyCodeListSubject.next(newList);
  }
  /**
   *Api function to create a new price agreement
   *
   * @param {{ outlineAgreementNumber: string }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public createPriceAgreement(data: { projectId: string }) {
    return this.http.post(
      this.createPriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public createPriceAgreement2(data: { outlineAgreementNumber: string }) {
    return this.http.post(
      this.createPriceAgreementUrlNonNetwork,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   * This is added for getting Material Status list
   * @return {*}
   * @memberof PriceManagerServices
   */
  setMaterialStatusList(newList: string[]): void {
    this.statusListSubject.next(newList);
  }
  getMaterialStatusData() {
    return this.statusListSubject.asObservable();
  }

  /**
   *Api function to get the price agreements status and filters
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementStatusAndFilters() {
    return this.http.get(
      this.getPriceAgreementStatusUrl,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch all material */
  public getMaterial(data) {
    return this.http.post(
      this.getMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch all material */
  public getAllMaterial(data) {
    return this.http.post(
      this.getAllMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch all logs */
  public getLogsList(data) {
    return this.http.post(
      this.getLogsListUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**API for getting old value */
  public getOldValue(data) {
    return this.http.post(
      this.getOldValueUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  public getAllLegends(data) {
    return this.http.post(
      this.legends,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public getSingleMaterialNew(data) {
    return this.http.post(
      this.getSingleMaterialNewUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch all Project Id */
  public getProjectId(data: any) {
    return this.http.post(
      this.getProjectIdlUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public getSingleProjectId(projectId: any) {
    return this.http.get(
      this.getSingleProjectIdUrl + projectId,
      this.authService.getAccessHeaders()
    );
  }
  // project id list
  public getProjectOla(olaNumber, l2Code) {
    return this.http.get(
      this.projectListUrl + olaNumber + "/" + l2Code,
      this.authService.getAccessHeaders()
    );
  }

  // Get project id header dropdown list
  public getAllProjectIdDrpdwnlList() {
    return this.http.get(
      this.allProjectIdDrpdwnListUrl,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch single material by MPN,SPN or SAP material id */
  public getSingleMaterial(para) {
    return this.http.get(
      this.getSingleMaterialUrl + "/" + para,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch Vf users */
  public getVfUsersDropdown() {
    return this.http.get(
      this.getVfUserDropdownUrl,
      this.authService.getAccessHeaders()
    );
  }

  /**API for Validate Material*/
  public getReassignUser(data) {
    return this.http.post(
      this.getReassignUserUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**API for Validate Material*/
  public getValidateMaterial(data) {
    return this.http.post(
      this.getValidateMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**API for Fetch Company code data*/
  public companyCodeData(data) {
    return this.http.post(
      this.companyCodeDataUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**API for Fetch single BOM by pmBOMid */
  public getSingleBOM(para) {
    return this.http.post(
      this.getSingleBOMUrl + "/" + para,
      this.authService.getAccessHeaders()
    );
  }

  public getSingleBOMDetails(data) {
    return this.http.post(
      this.getSingleBOMDetailsUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for approve material */
  public actionMaterial(data) {
    //actionMaterialUrl
    return this.http.post(
      this.actionMaterialUrlNetwork,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for correction request */
  public correctionRequest(data) {
    return this.http.post(
      this.correctionRequestUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api for withdraw request */
  public withdrawMaterialRequest(data) {
    return this.http.post(
      this.withdrawMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for create material data*/
  public createMaterialData(data) {
    return this.http.post(
      this.createMaterialDataUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  // API for correction req
  public getCorrectionComplete(data) {
    return this.http.post(
      this.getCorrectionCompleteUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api for block material*/
  public blockMaterials(data) {
    return this.http.post(
      this.blockMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api for unblock material*/
  public unblockMaterials(data) {
    return this.http.post(
      this.unblockMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api to get L2s from L1 and OLA for project Id*/
  public getL2(data) {
    return this.http.post(
      this.getL2Url,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api for sync material */
  public syncMaterial(itemId) {
    const url = `${this.syncMaterialUrl}${itemId}`;
    const httpParams: any = this.authService.getAccessHeaders();
    return this.http.post(url, null, httpParams);
  }
  /**Api for sync material */
  public syncEditedMaterial(sapId, itemId, system) {
    const url = `${this.syncMaterialUpdateUrl}${sapId}/${itemId}/${system}`;
    const httpParams: any = this.authService.getAccessHeaders();
    return this.http.post(url, null, httpParams);
  }

  /**
   *Api function to download the Material and Bom
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadMatAndBomList(data) {
    return this.http.post(this.downloadMaterialBom, data, {
      ...this.authService.getAccessHeadersForFile(),
      responseType: "blob",
    });
  }

  /**
   *Api function to download the Reports
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadMaterialReports(data) {
    return this.http.post(this.downloadMaterialReportsUrl, data, {
      ...this.authService.getAccessHeadersForFile(),
      responseType: "blob",
    });
  }

  /**
   *Api function to export the Material and Bom
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public exportMatAndBomList(data) {
    return this.http.post(this.exportMaterialBom, data, {
      ...this.authService.getAccessHeadersForFile(),
      responseType: "blob",
    });
  }

  /**Api for approve reject for projectId */
  public actionProjectId(data) {
    return this.http.post(
      this.actionProjectIdUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for approve and reject BOM */
  public actionBom(data) {
    return this.http.post(
      this.actionBomUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for delete material */
  public deleteMaterial(data) {
    return this.http.post(
      this.deleteMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
 *Function to set the autoload
 *
 * @param {*} data
 * @memberof PriceManagerServices
 */
  public dataRefreshMaterial(data: any) {
    this.dataRefreshFlag.asObservable();
    this.dataRefreshFlag.next(data);
  }

  /** API for Fetch all bom */
  public getBomDetails(data) {
    return this.http.post(
      this.getAllBomUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch all bom */
  public getApaDetails(data) {
    return this.http.post(
      this.getApaUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**Api for delete projectId */
  public deleteProjectId(data) {
    return this.http.post(
      this.deleteProjectIdUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**Api for withdraw projectId */
  public withdrawProjectId(data) {
    return this.http.post(
      this.withdrawProjectIdUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /** API for Fetch single bom */
  public getBomSingle(bomId: any) {
    return this.http.get(
      this.getBomUrl + "/" + bomId,
      this.authService.getAccessHeaders()
    );
  }
  /** API for save single material */
  public saveBomDetails(bomData: any) {
    return this.http.post(
      this.saveBomUrl,
      bomData,
      this.authService.getAccessHeaders()
    );
  }

  public deleteBomDetails(bomId: any) {
    return this.http.post(
      this.deleteBomUrl,
      bomId,
      this.authService.getAccessHeaders()
    );
  }

  public deleteBomChild(bomChildId: any) {
    return this.http.post(
      this.deleteBomChildUrl,
      bomChildId,
      this.authService.getAccessHeaders()
    );
  }
  //
  public roleBasedField(data) {
    return this.http.post(
      this.roleBasedFieldUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public setRoleBasedFieldDataEdit(data) {
    this.roleBasedDataEdit = data;
  }

  public setRoleBasedFieldDataAdd(data) {
    this.roleBasedDataAdd = data;
  }

  public setRoleBasedFieldDataChange(data) {
    this.roleBasedDataChange = data;
  }

  public getRoleBasedFieldDataAdd() {
    return this.roleBasedDataAdd;
  }

  public getRoleBasedFieldDataEdit() {
    return this.roleBasedDataEdit;
  }

  public getRoleBasedFieldDataChange() {
    return this.roleBasedDataChange;
  }
  /**
   *Api function to create a new material
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public createMaterial(data) {
    return this.http.post(
      this.createMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public validateMaterial(data) {
    return this.http.post(
      this.validateMaterialUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public createProjectId(data) {
    return this.http.post(
      this.actionProjectIdCreateUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  isBobParent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  isParamsId: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  isProjectId: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // 
  private syncMaterialStatus = new BehaviorSubject<any>({});
  syncMat(syncMaterialStatus: any) {
    this.syncMaterialStatus.next(syncMaterialStatus);
  }
  getsyncMattData() {
    return this.syncMaterialStatus.asObservable();
  }
  // calling material data on sucsess
  private materialData = new BehaviorSubject<any>({});
  bobParentData(materialData: any) {
    this.materialData.next(materialData);
  }
  getBobParentData() {
    return this.materialData.asObservable();
  }

  //calling material field data on sucsess
  private fieldMaterialData = new BehaviorSubject<any>({});
  fieldData(fieldMaterialData: any) {
    this.fieldMaterialData.next(fieldMaterialData);
  }
  getFieldData() {
    return this.fieldMaterialData.asObservable();
  }

  isFieldEditable(fieldName) {

    let fieldPermission
    this.getFieldData().subscribe((res: any) => {
      const DataItem = res["result"];
      //this.dataRoleAccessDetails = DataItem;
      if(DataItem){
        const fieldDataJson = DataItem.reduce((acc, item: any) => {
          let key = `${item.field}`
          acc[key] = item.userEntryModeAction;
          return acc;
        }, {});
        fieldPermission = fieldDataJson[fieldName]
      }
    })
    return fieldPermission;
  }
  // set workspace value
  setWorkspaceValue(data) {
    localStorage.setItem("workspace", data);
    this.workspaceValue.next(data);
  }

  getWorkspaceValue() {
    return localStorage.getItem("workspace");
  }
  //Material In progress data
  private materialInProgressData = new BehaviorSubject<any>({});
  matInProgressData(materialInProgressData: any) {
    this.materialInProgressData.next(materialInProgressData);
  }
  getMatInProgressData() {
    return this.materialInProgressData.asObservable();
  }

  //Material complete data
  private materialCompleteData = new BehaviorSubject<any>({});
  matCompleteData(materialCompleteData: any) {
    this.materialCompleteData.next(materialCompleteData);
  }
  getMatCompleteData() {
    return this.materialCompleteData.asObservable();
  }
  //
  //project id
  send_data = new Subject<any>();
  //
  private selectedMaterialData = new BehaviorSubject<any>({});
  selectedData(selectedMaterialData: any) {
    this.selectedMaterialData.next(selectedMaterialData);
  }
  getSelectedData() {
    return this.selectedMaterialData.asObservable();
  }

  private selectedMaterialDataForDownload = new BehaviorSubject<any>({});
  downloadData(selectedMaterialDataForDownload: any) {
    this.selectedMaterialDataForDownload.next(selectedMaterialDataForDownload);
  }

  getDownloadData() {
    return this.selectedMaterialDataForDownload.asObservable();
  }
  // calling material and bom data ui on left tab click
  private isBulk_Upload: boolean;
  /**
   *Function returns is bulk uplod ui visible or not
   *
   * @return {*}  {boolean}
   * @memberof price-managerservice
   */
  public getBulkUploadUI(): boolean {
    return this.isBulk_Upload;
  }

  /**
   *Function for set the bulk uplod ui visible or not
   *
   * @param {boolean} isBulk_Upload
   * @memberof price-managerservice
   */
  public setBulkUploadUI(isBulk_Upload: boolean): void {
    this.isBulk_Upload = isBulk_Upload;
  }

  /**
   *Api function to call dropdown list material
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getMaterialDropdowns() {
    return this.http.get(
      this.getMaterialDropdownsUrl,
      this.authService.getAccessHeaders()
    );
  }

  public setMaterialDropDown(dropDownData) {
    this.materialDropDownData = dropDownData;
  }

  public getMaterialDropDown() {
    return this.materialDropDownData;
  }
  // get dropdown projectid
  public getProjectIdDropdowns(data) {
    return this.http.post(
      this.getProjectIdDropdownsUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  //get Sapmaterial id dropdown values
  public getSAPMaterialIdList(data) {
    return this.http.post(
      this.getSapMaterialIdListUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }
  /**
   *Promise Function that returns price agreements status and filters
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public async getPriceAgreementStatus() {
    if (this.priceAgreementStatusAndConstants) {
      return this.priceAgreementStatusAndConstants;
    }

    return new Promise((resolve, reject) => {
      this.getPriceAgreementStatusAndFilters().subscribe(
        (response) => {
          this.priceAgreementStatusAndConstants = response;
          resolve(response);
        },
        // tslint:disable-next-line: align
        (err: Error) => {
          reject(err);
        }
      );
    });
  }

  /**
   *Api function that returns price agreement list,
   *It returns list according to the given pageno,perpage and filters & sort type
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; isRecommendedOnly: boolean }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementList(data: {
    pageNumber: number;
    perPage: number;
    filters: any;
    sortKey: string;
    lookup: string;
    isRecommendedOnly: boolean;
  }) {
    return this.http.post(
      this.priceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to download the price agreement list
   *
   * @param {{ filters: any; sortKey: string; lookup: string }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadPriceAgreementList(data: {
    filters: any;
    sortKey: string;
    lookup: string;
  }) {
    return this.http.post(this.downloadPriceListUrl, data, {
      ...this.authService.getAccessHeaders(),
      responseType: "blob",
    });
  }

  /**
   *Api function to download the delegated price agreement list
   *
   * @param {{ filters: any; sortKey: string; lookup: string }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadDelegatedAgreeement(data: {
    filters: any;
    sortKey: string;
    lookup: string;
  }) {
    return this.http.post(this.downloadDelegatedAgreementUrl, data, {
      ...this.authService.getAccessHeaders(),
      responseType: "blob",
    });
  }

  /**
   *Api function to download the price agreement catalogue list
   *
   * @param {{ filters: any; sortKey: string; lookup: string }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadPriceAgreementCatalogueList(data: {
    filters: any;
    sortKey: string;
    lookup: string;
  }) {
    return this.http.post(this.downloadCataloguesUrl, data, {
      ...this.authService.getAccessHeaders(),
      responseType: "blob",
    });
  }

  /**
   *Function to set the price agreement filters
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceFilter(data: any) {
    this.priceFilter.next({ result: data });
  }

  /**
   *Function that returns price agreement filters as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceFilter(): Observable<any> {
    return this.priceFilter.asObservable();
  }

  /**
   *Function to set the top menu filters
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setTopMenuFilter(data: any) {
    this.topMenuFilter.next({ result: data });
  }

  /**
   *Function that returns top menu filters as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getTopMenuFilter(): Observable<any> {
    return this.topMenuFilter.asObservable();
  }

  /**
   *Function to set the confirm selection filters
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setConfirmSelectionFilter(data: any) {
    this.confirmSelection.next({ result: data });
  }

  /**
   *Function that returns confirm selection filters as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getConfirmSelectionFilter(): Observable<any> {
    return this.confirmSelection.asObservable();
  }

  /**
   *Function to set the price agreement data
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceAgreement(data: any) {
    this.priceAgreement.next({ result: data });
  }

  /**
   *Function to set the Active column details
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setActiveColumns(data: any) {
    this.activeColumns = data;
  }

  /**
   *Function that returns Active column details
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getActiveColumns() {
    return this.activeColumns;
  }

  /**
   *Function to set the latest selected price agreement
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceAgreementLatest(data: any) {
    this.priceAgreement1 = { result: data };
  }

  /**
   *Function that returns price agreement details as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceAgreement(): Observable<any> {
    return this.priceAgreement.asObservable();
  }

  /**
   *Function that returns latest selected price agreement details
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreement1(): any {
    return this.priceAgreement1;
  }

  /**
   *Function to set the price agreement version details
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceAgreementVersion(data: any) {
    this.priceAgreementVersion.next(data);
    this.priceAgreementVersion1 = data;
  }

  /**
   *Function that returns price agreement version details as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementVersion(): Observable<any> {
    return this.priceAgreementVersion.asObservable();
  }

  /**
   *Function that returns price agreement version details
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementVersion1(): any {
    return this.priceAgreementVersion1;
  }

  /**
   *Function that set the price agreement version difference data
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceAgreementVersionDifference(data: any) {
    this.priceAgreementVersionDifference.next(data);
  }

  /**
   *Function that returns price agreement version difference data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementVersionDifference(): Observable<any> {
    return this.priceAgreementVersionDifference.asObservable();
  }

  /**
   *Function that set the transaction difference response data
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setTransactionDiffResponse(data) {
    this.savedTransactionDiffResponse1 = data;
    this.savedTransactionDiffResponse.next(data);
  }

  /**
   *Function that returns price agreement version difference response data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementVersionDifferenceResponse(): Observable<any> {
    return this.savedTransactionDiffResponse.asObservable();
  }

  /**
   *Function that returns price agreement version difference response data
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementVersionDifferenceResponse1(): any {
    return this.savedTransactionDiffResponse1;
  }

  /**
   *Function that set the catalogue data
   *
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setCatalogueData(data) {
    this.catalogueData1 = data;
    this.catalogueData.next(data);
  }

  /**
   *Function that returns catalogue data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getCatalogueData(): Observable<any> {
    return this.catalogueData.asObservable();
  }

  /**
   *Function that returns catalogue data
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getCatalogueData1(): any {
    return this.catalogueData1;
  }

  /**
   *Function that set the currency data
   *
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setCurrencyData(data) {
    this.currencyData1 = data;
    this.currencyData.next(data);
  }

  /**
   *Function that returns currency data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getCurrencyData(): Observable<any> {
    return this.currencyData.asObservable();
  }

  /**
   *Function that returns currency data
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getCurrencyData1(): any {
    return this.currencyData1;
  }

  /**
   *Api function that returns the catalogue and opco details
   *
   * @param {{
   *     olaNumber: string,
   *     vfpOpco?: string
   *   }} options
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getCatalogueAndOpcoData(options: {
    olaNumber: string;
    vfpOpco?: string;
  }) {
    let url = `${this.priceAgreementInputByOlaURL}olaNumber=${options.olaNumber}`;
    if (options.vfpOpco) {
      url = url + `&vfpOpco=${options.vfpOpco}`;
    }
    return this.http.get<IServerResponse<IPriceAgreementInput>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the user approval required flag data
   *
   * @param {{
   *     supplierId: string
   *   }} options
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getUserApprovalRequiredFlag(options: { supplierId: string }) {
    let url = `${this.getUserApprovalRequiredFlagUrl}${options.supplierId}`;
    return this.http.get<IServerResponse<any>>(
      url,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the price agreement automated data
   *
   * @param {{
   *     olaNumber: string,
   *     vfpOpco: string
   *   }} options
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementAutomatedData(options: {
    olaNumber: string;
    vfpOpco: string;
  }) {
    let url = this.priceAgreementAutomatedDataURL;
    if (options.olaNumber) {
      url = url + `olaNumber=${options.olaNumber}`;
    }

    if (options.vfpOpco) {
      url = url + `&vfpOpco=${options.vfpOpco}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Api function to get the price agreements total count
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementsCount() {
    return this.http.get(
      this.getPriceAgreementCountsUrl,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the Pending task count
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPendingTaskCount() {
    return this.http.get(
      this.getPendingTaskCountURL,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the recently viewed price agreements data
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getRecentlyViewedAgreement() {
    return this.http.get(
      this.getRecentAgreementURL,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the recently viewed material and bom data
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getRecentlyViewedMaterialAndBOM(data: any) {
    return this.http.post(
      this.getRecentMaterialAndBOMURL,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the price agreements edit header details
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getEditPriceHeaders(data: any) {
    return this.http.post(
      this.getEditPriceHeadersUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the price agreements edit header node details
   *
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getEditPriceHeadersNode() {
    return this.http.get(
      this.getEditPriceHeadersNodeUrl,
      this.authService.getAccessHeaders()
    );
  }
  public getEditPriceHeadersNntwkNode() {
    return this.http.get(
      this.getEditPriceHeadersNonNetoworkNodeUrl,
      this.authService.getAccessHeaders()
    );
  }

  public setBussJustDialogData(data) {
    this.bussJustDialogData.next(data);
  }

  public getBussJustDialogData(): Observable<string> {
    return this.bussJustDialogData;
  }

  public getMaterialAndBomHeaderNode() {
    return Observable.of([
      {
        id: "spId",
        field: "sapMaterialId",
        displayName: "SAP Material ID",
        fieldCategory: "material",
        isRequired: false,
        status: true,
        fieldType: "text",
        displayOrder: 1,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(18)",
        inputCheker: "upperCaseManager",
        fieldInformation:
          "This is a non-editable and auto-populated field. The SAP Material Id will be generated once the Material is created in SAP. The Material ceration action can be done by Vodafone Users only.",

        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "ptId",
        field: "materialCategory",
        displayName: "Physical Type",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 2,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "physicalTypeOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field. Select from the drop-down options if the material is Hardware or Services or Software / Licenses. Once a material is successfully created, it won’t be possible to make changes on this Physical Category field. The material synchronisation can be done by Vodafone Users only. If a change is required for the Physical Category after the material is successfully created, then please contact the Super Admin team through the pm.support@vodafone.com.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "mpnId",
        field: "manufacturerPartNumber",
        displayName: "Manufacturer Part Number",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40,false)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field for Hardware material type. Every material must have a unique Manufacturer Part Number (MPN). The maximum allowed characters for this field is 40. Please try to fill the Manufacturer Part Number for every material correctly. If an incorrect MPN was provided, it is possible to edit and change it before the material is created. Once a material is successfully created, it won’t be possible to make changes on its MPN field. If a change is required after the creation, then you need to delete that material and create a new one with the correct MPN.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "spnId",
        field: "supplierPartNumber",
        displayName: "Supplier Part Number",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 6,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(35,false)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field Service and Software & License material type. Every material must have a unique Supplier Part Number (SPN). The maximum allowed characters for this field is 35. Please try to fill the Supplier Part Numbers for every material correctly. If an incorrect SPN was provided, it is possible to edit and change it before the material is created. Once a material is successfully created, it won’t be possible to make changes on its SPN field. If a change is required after the creation, then you need to delete that material and create a new one with the correct SPN. ",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        id: "productFamilyId",
        field: "productFamilyCode",
        displayName: "Product Family",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 3,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageProductFamilyOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field. Select from the drop-down options. This can not be changed post sync with S4. If change required, user will have to raise a change request from Material and BOM tab which goes to MDS approval. Post approval the updated Product family would be synced to S4.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "matDescId",
        field: "materialDescription",
        displayName: "Material Description",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 8,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field. This is the short description of the material. The maximum allowed characters for this field is 40. Please try to fill the Material Description for every material correctly. If an incorrect Material Description was provided, it is possible to edit and change it before the material is created. If a material is successfully created and a change is required for the Material Description, user will have to raise a change request from Material and BOM tab which goes to MDS approval. post approval the updated material description would be synced to S4.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        id: "matLDescId",
        field: "materialLongDescription",
        displayName: "Material Long Description",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 9,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(1024)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field. This is the long detailed description of the material. The maximum allowed characters for this field is 1024. If change required, user will have to raise a change request from Material and BOM tab which goes to MDS approval. Post approval the updated Material long description  would be synced to S4.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        id: "oldMaterialId",
        field: "oldMaterial",
        displayName: "Old Material/Substitute",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 8,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. It should be an existing material which is a substitute.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        id: "boumId",
        field: "buom",
        displayName: "Base Unit Of Measure",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 10,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "boumOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field. Select from the drop-down options the correct base unit of measure (BUOM) for the material. Once a material is successfully reated, it won’t be possible to make changes on this BUOM field. The material synchronisation can be done by Vodafone Users only. If a change is required for the BUOM after the material is successfully created, then please contact the Super Admin team through the pm.support@vodafone.com.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "materialGroupl4Id",
        field: "materialGroup",
        displayName: "Material Group (L4)",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 7,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          afterSync: false,
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "materialGroupOptions",
        inputCheker: "emptyString",
        fieldInformation:
          "This is a mandatory field. Select from the drop-down options the correct Material Group L4 code for the material. If a material is successfully created and a change is required for the Material Group (L4), then please contact the Super Admin team through the pm.support@vodafone.com. The material synchronisation can be done by Vodafone Users only.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      // {
      //   id: "manufacturerId",
      //   field: "manufacturer",
      //   displayName: "Manufacturer",
      //   fieldCategory: "material",
      //   isRequired: true,
      //   status: true,
      //   fieldType: "dropdown",
      //   displayOrder: 7,
      //   isEditable: true,
      //   mandatoryRules: null,
      //   editableRules: {
      //     afterSync: false,
      //     agreementNwMaterial: false,
      //   },
      //   defaultValue: null,
      //   defaultRules: null,
      //   validationRegex: null,
      //   editorComponent: "searchableDropdownComponent",
      //   editorOptions: "manufacturerOptions",
      //   inputCheker: "emptyString",
      //   fieldInformation:
      //     'This is a mandatory field. Select from the drop-down options the correct base unit of measure (BUOM) for the catalogue line-item. Once a catalogue line-item is successfully synchronised, it won’t be possible to make changes on this BUOM field. The catalogue line-item synchronisation can be done by Vodafone Users only. The catalogue line-item synchronisation status is visible for Vodafone Users only. If a change is required for the BUOM after the catalogue line-item is successfully synchronised, then please contact the Super Admin team through the <span class="blue-span">pm.support@vodafone.com</span>.',
      //   dropdownListName: null,
      //   showOnMandatoryFilter: true,
      //   isSupplierVisible: true,
      //   approvalLevel: 1,
      // },
      {
        id: "comCodeId",
        field: "commodityCode",
        displayName: "Commodity Code Taric",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 11,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageCommodityCodeTaricOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field for ZN01. Select from the drop-down options the correct Commodity code TARIC number for the material if required. Minimum character is 8 digits or more. This can differ from company code to company code.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "grossWeightId",
        field: "grossWeight",
        displayName: "Gross Weight (KG)",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 16,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: "",
        defaultRules: null,
        validationRegex: "^\\d{1,10}(\\.\\d{1,2})?$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "decimalEditor(16)",
        inputCheker: "decimalOnlyWithDefault",
        fieldInformation:
          "This is a mandatory field. By default the 0.01 value is auto-populated. This can be changed if required but at least the 0.01 should be given even if the material has no weight (service, software, license, etc.).",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "netWeightId",
        field: "netWeight",
        displayName: "Net Weight (KG)",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 17,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: "",
        defaultRules: null,
        validationRegex: "^\\d{1,16}(\\.\\d{1,2})?$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "decimalEditor(16)",
        inputCheker: "decimalOnlyWithDefault",
        fieldInformation:
          "This is a mandatory field. By default the 0.01 value is auto-populated. This can be changed if required but at least the 0.01 should be given even if the material has no weight (service, software, license, etc.).",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        field: "characteristicValue",
        displayName: "Asset Value",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 25,
        isEditable: false,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageCharacteristicOptions",
        inputCheker: null,
        fieldInformation: "This is an Auto-populated and non-editable field.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        field: "subCharacteristicValue",
        displayName: "Sub Asset Value",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 26,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageSubCharacteristicOptions",
        inputCheker: null,
        fieldInformation:
          "This is an optional field for ZN01 and NA for ZN04/ZN05. It is an autopopulated field. This field is editable for ZN01 and non-editable for ZN04/ZN05. Sub assetclass Value field can be edited. If edited post sync, it should trigger approval workflow accordingly (APA team) whenever there is any change in the value displayed in this field.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "lengthId",
        field: "length",
        displayName: "Length",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 19,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,12}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(15)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is a mandatory field for Hardware material. Note: not applicable where Unit of measure is 'Meter' or 'KG'",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "widthId",
        field: "width",
        displayName: "Width",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 20,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,12}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(15)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is a mandatory field for Hardware material. Note: not applicable where Unit of measure is 'Meter' or 'KG'",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "heightId",
        field: "height",
        displayName: "Height",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 18,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,12}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(15)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is a mandatory field for Hardware material. Note: not applicable where Unit of measure is 'Meter' or 'KG'",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "heightLengthWidthId",
        field: "hlwUnit",
        displayName: "LWH Unit",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 21,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageHeightLenWidOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field for Hardware material. Note: not applicable where Unit of measure is 'Meter' or 'KG'",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        field: "volume",
        displayName: "Volume",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 22,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,12}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(15)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is an optional field.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "volumeUnitId",
        field: "volumeUnit",
        displayName: "Volume Unit",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 23,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "manageVolumeUnitOptions",
        inputCheker: null,
        fieldInformation:
          "This is an optional field.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "natureId",
        field: "nature",
        displayName: "Nature",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 3,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "natureOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory dropdown field with two options. (Active / Passive)",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },

      {
        id: "serialPId",
        field: "serializationProfile",
        displayName: "Serialization",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 4,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "serializationProfileOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field for ZN01 and NA for ZN04, ZN05. It is an User Input drop-down field. It takes user input if type is ZN01 else autopopulated with empty value(Non-editable). This field can be edited with drop-down value 'Yes' or 'No'. It can be edited before synchronization only. If edited pre sync, it should trigger approval workflow accordingly, whenever there is any change in the value displayed in this field.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "endOfLifeId",
        field: "endOfLife",
        displayName: "End Of Life",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "date",
        displayOrder: 13,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: "",
        defaultRules: null,
        validationRegex: null,
        editorComponent: "agDatePickerEditor",
        editorOptions: "manageDateOptions(endOfLife)",
        inputCheker: "manageDatesValidation",
        fieldInformation:
          "This is an optional field with date format (DDMMYYYY). If not provided, system would consider and auto populate with 31.03.2099.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "endOfSupportId",
        field: "endOfSupport",
        displayName: "End Of Support",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "date",
        displayOrder: 14,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: "",
        defaultRules: null,
        validationRegex: null,
        editorComponent: "agDatePickerEditor",
        editorOptions: "manageDateOptions(endOfSupport)",
        inputCheker: "manageDatesValidation",
        fieldInformation:
          "This is an optional field with date format (DDMMYYYY). If not provided, system would consider and auto populate with 31.03.2099.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "usefulLifeId",
        field: "usefulLife",
        displayName: "Useful Life (In years)",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(2)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field. If not provided, system would consider and auto populate with 8 years.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "eanUpcId",
        field: "ean",
        displayName: "EAN/UPC",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 12,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(18)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. The International Article Number (also known as European Article Number or EAN) is a standard describing a barcode symbology and numbering system used in global trade to identify a specific retail product type, in a specific packaging configuration, from a specific manufacturer.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        field: "eccn",
        displayName: "ECCN",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 27,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[a-zA-Z0-9]*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(5)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field.  An Export Control Classification Number (ECCN) is an alphanumeric designation (e.g., 1A984 or 4A001) to identify items for export control purposes.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        field: "gam",
        displayName: "Global Account Manager",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 24,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex:
          "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
        editorComponent: "agTextEditorComponent",
        editorOptions: "",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory email field. Email address of the Global Account Manager to be provided.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        id: "auom1Id",
        field: "auom1",
        displayName: "AUOM - 1",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 31,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "boumOptions",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. It is a dropdown selection in case if there is a need for Alternative Measurement purpose. A unit of measure that is defined in the SAP system in addition to the base unit of measure (BUn, base UoM).",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "unitConversion1Id",
        field: "unitConversion1",
        displayName: "Unit Conversion - 1",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(5)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM1 is filled. To convert a quantity, whose unit of measure is not the same as the Base Unit of Measure, into the Base Unit of Measure, the system requires a quotient: i.e - Quantity (in alternative unit of measure) = quotient * quantity (in base unit of measure)",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "auomQty1Id",
        field: "auomQty1",
        displayName: "AUOM Quantity - 1",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(2)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM1 is filled. This is the quantity number for the alternate unit of measure.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        field: "stackingNumber",
        displayName: "Stacking Number",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 28,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,5}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(5)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is an optional field. Number of boxes stack in a pallet.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },
      {
        field: "stackingNorms",
        displayName: "Stacking Norms",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 29,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          agreementNwMaterial: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,5}(\\.\\d{1,2})?$",
        editorComponent: "agNumericEditor",
        editorOptions: "decimalEditor(5)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is an optional field. Number of pallets that can be stacked.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 3,
      },

      {
        id: "auom2Id",
        field: "auom2",
        displayName: "AUOM - 2",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 31,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "boumOptions",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. It is a dropdown selection in case if there is a need for Alternative Measurement purpose. A unit of measure that is defined in the SAP system in addition to the base unit of measure (BUn, base UoM).",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "unitConversion2Id",
        field: "unitConversion2",
        displayName: "Unit Conversion - 2",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(5)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM2 is filled. To convert a quantity, whose unit of measure is not the same as the Base Unit of Measure, into the Base Unit of Measure, the system requires a quotient: i.e - Quantity (in alternative unit of measure) = quotient * quantity (in base unit of measure)",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "auomQty2Id",
        field: "auomQty2",
        displayName: "AUOM Quantity - 2",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(2)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM 2 is filled. This is the quantity number for the alternate unit of measure.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "auom3Id",
        field: "auom3",
        displayName: "AUOM - 3",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 31,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "boumOptions",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. It is a dropdown selection in case if there is a need for Alternative Measurement purpose. A unit of measure that is defined in the SAP system in addition to the base unit of measure (BUn, base UoM).",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "unitConversion3Id",
        field: "unitConversion3",
        displayName: "Unit Conversion - 3",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(5)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM 3 is filled. To convert a quantity, whose unit of measure is not the same as the Base Unit of Measure, into the Base Unit of Measure, the system requires a quotient: i.e - Quantity (in alternative unit of measure) = quotient * quantity (in base unit of measure)",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "auomQty3Id",
        field: "auomQty3",
        displayName: "AUOM Quantity - 3",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(2)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is an optional field but become mandatory if AUOM 3 is filled. This is the quantity number for the alternate unit of measure.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "bomMaterialId",
        field: "bomMaterial",
        displayName: "BOM Material",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 2,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "bomMaterialOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory dropdown field while creating BOM for Parent or Child component.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "bomDescriptionId",
        field: "bomText",
        displayName: "BOM Description",
        fieldCategory: "agreement",
        isRequired: false,
        status: true,
        fieldType: "text",
        displayOrder: 6,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory while creating BOM.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 4,
      },
      {
        id: "bomParentMpnId",
        field: "bomParentMpn",
        displayName: "BOM Parent MPN",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(35)",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory field for child component. Parent MPN should be entered for the respective child components.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "childComponentId",
        field: "childComponent",
        displayName: "Child Component",
        fieldCategory: "material",
        isRequired: true,
        status: true,
        fieldType: "dropdown",
        displayOrder: 23,
        isEditable: true,
        mandatoryRules: null,
        editableRules: {
          physicalTypeRule: false,
        },
        defaultValue: null,
        defaultRules: null,
        validationRegex: null,
        editorComponent: "searchableDropdownComponent",
        editorOptions: "childComponentOptions",
        inputCheker: null,
        fieldInformation:
          "This is a mandatory dropdown field for child component.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "percentageOfParentId",
        field: "percentageOfParent",
        displayName: "% Child On Father",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "decimal",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agDecimalEditor",
        editorOptions: "decimalEditor(2)",
        inputCheker: "decimalOnly",
        fieldInformation:
          "This is a mandatory field for child components. The % of all mandatory child components should equal to 100%.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "childComponentQuantityId",
        field: "childComponentQuantity",
        displayName: "Child Component Quantity",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 15,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^\\d{1,2}?$",
        editorComponent: "agNumericEditor",
        editorOptions: "manageTextEditorOptions(3)",
        inputCheker: "numberOnly",
        fieldInformation:
          "This is a mandatory numeric value field for child components. The field should be filled with quantity of child component associated in the BOM parent.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 1,
      },
      {
        id: "alternateMaterialForChild1Id",
        field: "alternateMaterialForChild1",
        displayName: "Alternate Material for Child - 1",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild2Id",
        field: "alternateMaterialForChild2",
        displayName: "Alternate Material for Child - 2",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild3Id",
        field: "alternateMaterialForChild3",
        displayName: "Alternate Material for Child - 3",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild4Id",
        field: "alternateMaterialForChild4",
        displayName: "Alternate Material for Child - 4",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild5Id",
        field: "alternateMaterialForChild5",
        displayName: "Alternate Material for Child - 5",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild6Id",
        field: "alternateMaterialForChild6",
        displayName: "Alternate Material for Child - 6",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild7Id",
        field: "alternateMaterialForChild7",
        displayName: "Alternate Material for Child - 7",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild8Id",
        field: "alternateMaterialForChild8",
        displayName: "Alternate Material for Child - 8",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild9Id",
        field: "alternateMaterialForChild9",
        displayName: "Alternate Material for Child - 9",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
      {
        id: "alternateMaterialForChild10Id",
        field: "alternateMaterialForChild10",
        displayName: "Alternate Material for Child - 10",
        fieldCategory: "agreement",
        isRequired: true,
        status: true,
        fieldType: "text",
        displayOrder: 5,
        isEditable: true,
        mandatoryRules: null,
        editableRules: null,
        defaultValue: null,
        defaultRules: null,
        validationRegex: "^[-a-zA-Z0-9-()_$]+(s+[-a-zA-Z0-9-()_$]+)*$",
        editorComponent: "agTextEditorComponent",
        editorOptions: "manageTextEditorOptions(40)",
        inputCheker: null,
        fieldInformation:
          "This is an optional field. MPN of a material can be entered for a BOM component which can be replaced with the alternate material.",
        dropdownListName: null,
        showOnMandatoryFilter: true,
        isSupplierVisible: true,
        approvalLevel: 5,
      },
    ]);
  }
  /**
   *Api function to get the required price agreement headers
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getRequiredPriceHeaders(data: any) {
    if (data) {
      // tslint:disable-next-line: max-line-length
      const url = `${this.getRequiredPriceHeadersUrl
        }?categoryType=${encodeURIComponent(data.categoryType)}&physicalStatus=${data.physicalStatus
        }`;
      return this.http.get(url, this.authService.getAccessHeaders());
    }
  }

  /**
   *Api function to get the price agreement details by price agreement id
   *
   * @param {{
   *     priceAgreementId: string,
   *     version?: string
   *   }} options
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreements(options: {
    priceAgreementId: string;
    version?: string;
  }) {
    let url = `${this.getPriceAgreementUrl
      }?priceAgreementId=${encodeURIComponent(options.priceAgreementId)}`;
    if (options.version) {
      url = `${url}&version=${options.version}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Function that converts date to iso string type
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public convertChainDateToString(data) {
    if (data && data.seconds && data.seconds.low) {
      return new Date(data.seconds.low).toISOString();
    }
    return null;
  }

  /**
   *Function that returns difference between two dates
   *
   * @param {*} startDate
   * @param {*} endDate
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getDateDifference(startDate, endDate) {
    if (startDate > endDate) {
      return null;
    }
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();
    const startHour = startDate.getHours();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();
    const endHour = endDate.getHours();
    // We calculate February based on end year as it might be a leep year which might influence the number of days.
    const february =
      (endYear % 4 === 0 && endYear % 100 !== 0) || endYear % 400 === 0
        ? 29
        : 28;
    const daysOfMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const startDateNotPassedInEndYear =
      endMonth < startMonth || (endMonth === startMonth && endDay < startDay);
    const years = endYear - startYear - (startDateNotPassedInEndYear ? 1 : 0);
    const months =
      (12 + +endMonth - startMonth - (endDay < startDay ? 1 : 0)) % 12;
    // (12 + ...) % 12 makes sure index is always between 0 and 11
    const days =
      startDay <= endDay
        ? endDay - startDay
        : daysOfMonth[(12 + +endMonth - 1) % 12] - startDay + +endDay;
    const hours = startHour <= endHour ? endHour - startHour : "00";
    return {
      years,
      months,
      days,
      hours,
    };
  }

  /**
   *Function that checks the given date is a valid data or not
   *
   * @param {*} d
   * @return {*}
   * @memberof PriceManagerServices
   */
  public isValidDate(d) {
    return d instanceof Date || !isNaN(d);
  }

  /**
   *Api function to get the fix rates
   *
   * @param {*} currency
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getFixRate(currency) {
    return this.http.get(
      `${this.getFixRatesURL}/${currency}`,
      this.authService.getAccessHeaders2()
    );
  }

  /**
   *Function that set the price agreement details data
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setAgreementDetail(data: any) {
    this.agreementDetails.next({ result: data });
  }

  /**
   *Function that returns price agreement details data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getAgreementDetail(): Observable<any> {
    return this.agreementDetails.asObservable();
  }

  /**
   *Function that set the configuration details
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public sendConfigurationDetail(data: any) {
    this.storeFilterConfiguration.next({ result: data });
  }

  /**
   *Function that returns configuration details as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getConfigurationDetail(): Observable<any> {
    return this.storeFilterConfiguration.asObservable();
  }

  /**
   *Api function that save the price agreement details
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public savePriceAgreement(data: any) {
    return this.http.post(
      this.savePriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to check the spn for supplier
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public checkSupplierPartNumberForSupplier(data) {
    return this.http.post(
      this.checkSupplierPartNumberForSupplierUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the commodity code by search value
   *
   * @param {{ pageNumber: number; perPage: number; filters: any }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getSearchedCommodityCode(data: {
    pageNumber: number;
    perPage: number;
    filters: any;
  }) {
    return this.http.post(
      this.searchCommodityCode,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to approve the price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public approvePriceAgreement(data: any) {
    return this.http.post(
      this.approvePriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to line items approval action
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public lineItemApprovalAction(data: any) {
    return this.http.post(
      this.lineItemApprovalActionUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to submit the price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public submitPriceAgreement(data: any) {
    return this.http.post(
      this.submitPriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to upload the excel file
   *
   * @param {*} data
   * @param {*} file
   * @return {*}
   * @memberof PriceManagerServices
   */
  public uploadExcelFile(data, file) {
    const fd = new FormData();
    fd.append("file", file);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          // tslint:disable-next-line: triple-equals
          if (typeof data[key] == "object") {
            fd.append(key, JSON.stringify(data[key]));
          } else {
            // tslint:disable-next-line: restrict-plus-operands
            fd.append(key, ("" + data[key]).trim());
          }
        }
      });
    }
    return this.http.post(
      this.uploadExcelUrl,
      fd,
      this.authService.getAccessHeadersForFileNetwork()
    );
  }

  public uploadMdsRejectionFile(data, file) {
    const fd = new FormData();
    fd.append("file", file);
    if (data) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            // Append each item in the array individually
            value.forEach((item) => fd.append(`${key}[]`, item));
          } else if (typeof value === "object") {
            // Stringify objects
            fd.append(key, JSON.stringify(value));
          } else {
            // Append other data types as strings
            fd.append(key, value.toString().trim());
          }
        }
      });
    }
    return this.http.post(
      this.mdsRejectionUploadUrl,
      fd,
      this.authService.getAccessHeadersForFile()
    );
  }
  /**
   *Api function to upload the Material and BOM excel file
   *
   * @param {*} data
   * @param {*} file
   * @return {*}
   * @memberof PriceManagerServices
   */
  public uploadMatBOMExcelFile(data, file) {
    const fd = new FormData();
    fd.append("file", file);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          // tslint:disable-next-line: triple-equals
          if (typeof data[key] == "object") {
            fd.append(key, JSON.stringify(data[key]));
          } else {
            // tslint:disable-next-line: restrict-plus-operands
            fd.append(key, ("" + data[key]).trim());
          }
        }
      });
    }
    return this.http.post(
      this.uploadMatBOMExcelUrl,
      fd,
      this.authService.getAccessHeadersForFile()
    );
  }

  public uploadDataMigrtnSpclstFile(file) {
    const fd = new FormData();
    fd.append("file", file);

    return this.http.post(
      this.uploadDataMigrtnSpclstFileUrl,
      fd,
      this.authService.getAccessHeadersForFile()
    );
  }
  /**
   *Api function to get the price agreement version with transaction id
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getAgreementWithTxId(data: any) {
    return this.http.post(
      this.getPriceAgreementWithTxId,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to download the price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadPriceAgreement(data) {
    return this.http.post(this.priceAgreementCatalogueDownload, data, {
      ...this.authService.getAccessHeadersForFile(),
      responseType: "blob",
    });
  }

  /**
   *Api function to download the catalogue output file
   *
   * @param {string} priceAgreementId
   * @param {string} organizationId
   * @return {*}
   * @memberof PriceManagerServices
   */
  public downloadCatalogueOutputFile(
    priceAgreementId: string,
    organizationId: string
  ) {
    return this.http.post(
      this.downloadCatalogueOutputFileUrl +
      encodeURIComponent(priceAgreementId) +
      "/" +
      encodeURIComponent(organizationId),
      null,
      { ...this.authService.getAccessHeadersForFile(), responseType: "blob" }
    );
  }

  /**
   *Api function to synchronise the catalogue status
   *
   * @param {*} object
   * @return {*}
   * @memberof PriceManagerServices
   */
  public synchronizeCatalogueStatus(object) {
    return this.http.post(this.syncCatalogueStatusUrl, object, {
      ...this.authService.getAccessHeaders(),
    });
  }

  /**
   *Api function to get the archived price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public archivePriceAgreement(data) {
    return this.http.post(
      this.archivePriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to  deleted the price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public deletePriceAgreement(data) {
    return this.http.post(
      this.deletePriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to restore the deleted price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public restoreDeletedPriceAgreement(data) {
    return this.http.post(
      this.restoreDeletedPriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to restore the archived price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public restoreArchivedPriceAgreement(data) {
    return this.http.post(
      this.restoreArchivedPriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to archive the catalogue
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public archiveCatalogue(data) {
    return this.http.post(
      this.archiveCatalogueUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api functio to lock/unlock the price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public lockUnlockPriceAgreement(data) {
    if (this.authService.getToken()) {
      return this.http.post(
        this.lockUnlockPriceAgreementUrl,
        data,
        this.authService.getAccessHeaders()
      );
    } else {
      return new Observable((observer) => {
        observer.next(false);
      });
    }
  }

  /**
   *Api function to get the lock status for price agreement
   *
   * @param {string} priceAgreementId
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getLockStatusForAgreement(priceAgreementId: string) {
    if (this.authService.getToken()) {
      return this.http.get(this.lockUnlockPriceAgreementUrl, {
        ...this.authService.getAccessHeaders(),
        params: { priceAgreementId },
      });
    } else {
      return new Observable((observer) => {
        observer.next(false);
      });
    }
  }

  /**
   *Api function to get the watcher list
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getWatcherList(data: any) {
    const url = `${this.agreementUsersURL
      }?priceAgreementId=${encodeURIComponent(data.priceAgreementId)}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   *Api function to add/remove the watcher details
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public addRemoveWatcher(data: any) {
    return this.http.post(
      this.addRemoveWatcherUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to delegate the price agreements
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public delegateAgreement(data) {
    return this.http.post(
      this.delegateAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public delegateAgreementOwnerWatcher(data) {
    return this.http.post(
      this.delegateOwnerWatcherAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to delegate the approver rights
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public delegateApproverRights(data) {
    return this.http.post(
      this.delegateApproverRightsUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to delegate the approver rights
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public reassignProjectOwnership(data) {
    return this.http.post(
      this.reassignProjectOwnershipUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to end the price agreement delegation
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public endDelegation(data) {
    return this.http.post(
      this.cancelDelegateAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to cancel the user approval rights delegation
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public cancelUserApproverRightsDelegation(data) {
    return this.http.post(
      this.cancelMaterialL4DelegateAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Function to set the watcher details
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setWatcher(data: any) {
    this.watcherDetails.next({ result: data });
  }

  /**
   *Function that returns the watcher data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getWatcher(): Observable<any> {
    return this.watcherDetails.asObservable();
  }

  /**
   *Function to set the edit price agreement details before proceed
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setEditPaBeforeProceed(data: any) {
    this.editPaBeforeProceed.next({ result: data });
  }

  /**
   *Function that returns edit pa details as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getEditPaBeforeProceed(): Observable<any> {
    return this.editPaBeforeProceed.asObservable();
  }

  /**
   *Api function to change the price agreement ownership
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public changeOwnership(data: any) {
    return this.http.post(
      this.changeOwnershipUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the price agreement comments
   *
   * @param {*} priceAgreementId
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getComments(priceAgreementId) {
    return this.http.get(this.getCommentUrl, {
      ...this.authService.getAccessHeaders(),
      params: { priceAgreementId },
    });
  }
  /**
   *Api function to add a comment for price agreement
   *
   * @param {*} data
   * @param {*} file
   * @return {*}
   * @memberof PriceManagerServices
   */
  public addComments(data, file) {
    const fd = new FormData();
    fd.append("file", file);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          if (typeof data[key] === "object") {
            fd.append(key, JSON.stringify(data[key]));
          } else {
            fd.append(key, data[key].trim());
          }
        }
      });
    }
    return this.http.post(
      this.addCommentsUrl,
      fd,
      this.authService.getAccessHeadersForFile()
    );
  }

  /**
   *Function to set the Log refresh status
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setLogRefresh(data: any) {
    this.logRefresh.next({ result: data });
  }

  /**
   *Function that returns the log refresh status as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getLogRefresh(): Observable<any> {
    return this.logRefresh.asObservable();
  }

  /**
   *Function to set the archive details
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setArchive(data: any) {
    this.archiveVal.next({ result: data });
  }
  /**
   *Function that returns the archive data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getArchive(): Observable<any> {
    return this.archiveVal.asObservable();
  }

  /**
   *Function to set the price agreement recomended status
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setPriceRecomended(data: any) {
    this.isRecomended.next({ result: data });
  }

  /**
   *Function that returns the price agreement recomended status as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getPriceRecomended(): Observable<any> {
    return this.isRecomended.asObservable();
  }

  /**
   *Api function to edit the price agreement name
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public editReferenceName(data) {
    return this.http.post(
      this.editReferenceNameUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to edit the catalogue visibility status for supplier
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public editCatalogueVisibilityForSupplier(data) {
    return this.http.post(
      this.editSupplierCatalogueVisibilityUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the payload trigger status
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public triggerPayloadStatus(data) {
    return this.http.post(
      this.triggerPayloadStatusUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Function to set the last saved data
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setLastSavedDate(data: any) {
    this.LastSavedDate1 = data;
    this.LastSavedDate.next(data);
  }

  /**
   *Function that returns the last saved data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getLastSavedDate(): Observable<any> {
    return this.LastSavedDate.asObservable();
  }
  /**
   *Function that returns the last saved data
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getLastSavedDate1(): any {
    return this.LastSavedDate1;
  }

  /**
   *Function that converts dates as iso timestamps
   *
   * @param {Date} date
   * @return {*}
   * @memberof PriceManagerServices
   */
  public convertToISOTimestamp(date: Date) {
    function pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }

    if (
      isNaN(date.getFullYear()) ||
      isNaN(date.getMonth()) ||
      isNaN(date.getDate())
    ) {
      return null;
    }

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate())
    );
  }

  /**
   *Api function to get the pre signed url
   *
   * @param {string} fileName
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPresignedUrl(fileName: string) {
    return this.http.get(this.presignedUrl, {
      ...this.authService.getAccessHeaders(),
      params: { fileName },
    });
  }

  /**
   *Function to set the supplier approval required status
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public setSupplierApprovalRequired(data: any) {
    this.supplierApprovalFlag1 = data;
    this.supplierApprovalFlag.next(data);
  }
  /**
   *Function to set the approval list autoload
   *
   * @param {*} data
   * @memberof PriceManagerServices
   */
  public supplierApprovalUpdate(data: any) {
    this.supplierApprovalFlagdata.asObservable();
    this.supplierApprovalFlagdata.next(data);
  }

  /**
   *Function that returns the supplier approval required status as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof PriceManagerServices
   */
  public getSupplierApprovalRequired(): Observable<any> {
    return this.supplierApprovalFlag.asObservable();
  }

  /**
   *Function that returns the supplier approval required status
   *
   * @return {*}  {*}
   * @memberof PriceManagerServices
   */
  public getSupplierApprovalRequired1(): any {
    return this.supplierApprovalFlag1;
  }

  /**
   *Api function to get the price agreement catalogue list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; isRecommendedOnly: boolean }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementCatalogueList(data: {
    pageNumber: number;
    perPage: number;
    filters: any;
    sortKey: string;
    lookup: string;
    isRecommendedOnly: boolean;
  }) {
    return this.http.post(
      this.priceAgreementCatalogueUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the price agreement catalogue list by price agreement id
   *
   * @param {string} priceAgreementId
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPriceAgreementCatalogueListForPa(priceAgreementId: string) {
    return this.http.get(
      `${this.priceAgreementCatalogueListUrl
      }?priceAgreementId=${encodeURIComponent(priceAgreementId)}`,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the delegated price agreement list
   *
   * @param {{ pageNumber: number; perPage: number; filters: any; sortKey: string; lookup: string; isRecommendedOnly: boolean }} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getDelegatedPriceAgreementList(data: {
    pageNumber: number;
    perPage: number;
    filters: any;
    sortKey: string;
    lookup: string;
    isRecommendedOnly: boolean;
  }) {
    return this.http.post(
      this.delegatedPriceAgreementUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the approver history by line item id
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getApproverHistoryByItemId(data) {
    return this.http.post(
      this.itemApprovalHistory,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the synchronise history by line item id
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getSynchronizeHistoryByItemId(data) {
    return this.http.post(
      this.itemSynchronizeHistory,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the line item synchronise history for display
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getItemSynchronizeHistoryIconDisplay(data) {
    return this.http.post(
      this.itemSynchronizeHistoryButton,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to get the all line items change history
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getPAAllItemsChangeHistory(data) {
    return this.http.post(
      this.allFieldChangeHistory,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to trigger the SCP synchronise status
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public triggerScpSync(data) {
    return this.http.post(
      this.triggerScpSyncUrl,
      data,
      this.authService.getAccessHeaders2()
    );
  }

  /**
   *Api function to get the last synchronise version for price agreement
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getLastSyncedVersionPA(data) {
    return this.http.post(
      this.lastSyncedVersionPAUrl,
      data,
      this.authService.getAccessHeaders2()
    );
  }

  /**
   *Api function to get the catalogue settings details
   *
   * @param {string} l1Code
   * @param {string} tradingModel
   * @param {string} opcoCode
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getCatalogueSettings(
    l1Code: string,
    tradingModel: string,
    opcoCode: string
  ) {
    return this.http.get(
      `${this.catalogueSettingUrl}?l1Code=${encodeURIComponent(
        l1Code
      )}&tradingModel=${encodeURIComponent(
        tradingModel
      )}&opcoCode=${encodeURIComponent(opcoCode)}`,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to update the catalogue settings details
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public updateCatalogueSettings(data) {
    return this.http.post(
      this.updateCatalogueSettingUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Api function to restore the archived catalogues
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public restoreArchiveCatalogue(data) {
    return this.http.post(
      this.restoreArchiveCatalogueUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   *Function returns clicked on change button
   *
   * @return {*}  {boolean}
   * @memberof AuthServices
   */
  public isChangeFlag: boolean = false;
  public getIsChange(): boolean {
    return this.isChangeFlag;
  }

  /**
   *Function for set the flag clicked on change button
   *
   * @param {boolean} isChange
   * @memberof AuthServices
   */
  public setIsChange(isChange: boolean): void {
    this.isChangeFlag = isChange;
  }

  public checkMaterialProjIdLock(data) {
    return this.http.post(
      this.materialProjIdLockUrl,
      data,
      this.authService.getAccessHeaders()
    );
  }

  /**
   * function to set the material pop up data to save as draft
   * @param {*} data
   * @memberof AuthServices
   */
  public setFormData(data: any) {
    this.formDataList = data;
  }

  /**
   * function to get the material pop up data to save as draft
   * @return {*}  {*}
   * @memberof AuthServices
   */
  public getFormData(): any {
    return this.formDataList;
  }

  /**
   *Api function to get the user details for project owner change
   *
   * @param {*} data
   * @return {*}
   * @memberof PriceManagerServices
   */
  public getUsersForProjectOwnerChange(data) {
    return this.http.post(
      this.getUserIdsForProjectOwnerChange,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public downloadDmsApprovalDoc(fileName) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.downloadDmsApprovalDocUrl + fileName, { ...this.authService.getAccessHeaders(), responseType: 'blob' });
  }
  /** API for Rejection content  material */
  public getRejectionContentMsg(data) {
    return this.http.post(
      this.mdsRejectionContentMsg,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public unlockProjectId(data) {
    return this.http.post(
      this.unlockPid,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public checkPaApaWorkflow(data) {
    return this.http.post(
      this.paApaWorkFlowCheck,
      data,
      this.authService.getAccessHeaders()
    );
  }

  public setMatBomGridData(data) {
    localStorage.setItem("matBomGridData", JSON.stringify(data));
  }

  public getMatBomGridData() {
    return JSON.parse(localStorage.getItem("matBomGridData"));
  }
}
