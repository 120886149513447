import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleItemService {
  // tslint:disable-next-line: no-empty
  private tableColumnShowHide = new BehaviorSubject<any>(false);
  public buttonName1: any = '+ Minimize';
  public show = false;
  // tslint:disable-next-line:no-empty
  constructor() { }
  public statusPanelVal = true;
  public headerPanelVal = false;
  public itemsPanelVal = true;
  public mandatoryPanelVal = false;
  public versionHistoryVal = false;
  public taskId = null;
  public isEditable = false;
  public isComingFromConfirmPage = false;
  public priceAgreementShow: any;
  public isConfigEditable = false;
  public pageSize = 10;
  public selectedCustomGroup = null;
  public hideDeletedItems = false;
  public showMyPendingItemOnly = false;
  public showMyLineItemOnly = false;
  public showMyLineItemBlocked = false;
  public hideStatusInfo = false;
  public showEditedItemOnly = false;
  public showAssociatedCatalogue = null;
  public showExpiringItems = false;
  public notLinkedWithPA = false;
  public gCommentAccess = false;
  public hidePaFooter = false;   // VOD-1748 
  public hideMatFooter = false;

  /**
   *Function that set footer variable true | false 
   *
   * @memberof ToggleItemService
   */
  public hidePaFooterSection() {
    this.hidePaFooter = !this.hidePaFooter;
  }

  /**
  *Function that set footer variable true | false 
  *
  * @memberof ToggleItemService
  */
  public hideMatFooterSection() {
    this.hideMatFooter = !this.hideMatFooter;
  }
  /* VOD-1748 end */

  /**
   *Function that set status info variable to true | false
   *
   * @memberof ToggleItemService
   */
  public showHideStatusInfo() {
    this.hideStatusInfo = !this.hideStatusInfo;
  }


  /**
   *Function that set status panel variable true | false
   *
   * @memberof ToggleItemService
   */
  public statusPanelShowHide() {
    this.statusPanelVal = !this.statusPanelVal;
  }

  /**
   *Function that show/hide the header panel
   *
   * @memberof ToggleItemService
   */
  public headerPanelShowHide() {
    this.headerPanelVal = !this.headerPanelVal;
    if (this.headerPanelVal) {
      this.buttonName1 = '- Minimize';
    } else {
      this.buttonName1 = '+ Maximize';
    }
  }

  /**
   *Function that reset the status bar values
   *
   * @memberof ToggleItemService
   */
  public resetStatusBarValues() {
    this.headerPanelVal = false;
    this.buttonName1 = '- Maximize';
  }

  /**
   *Function that show/hide the item panel
   *
   * @memberof ToggleItemService
   */
  public itemsPanelShowHide() {
    this.itemsPanelVal = !this.itemsPanelVal;
  }
  /**
   *Function that enable/disable the action button
   *
   * @memberof ToggleItemService
   */
  public actionBtnToggle() {
    this.isEditable = !this.isEditable;
  }

  /**
   *Function that set the configuration data
   *
   * @param {*} data
   * @memberof ToggleItemService
   */
  public sendConfiguration(data: any) {
    this.tableColumnShowHide.next({ result: data });
    this.priceAgreementShow = { result: data };
  }

  /**
   *Function that return the configuration data as Observable
   *
   * @return {*}  {Observable<any>}
   * @memberof ToggleItemService
   */
  public getPostedConfiguration(): Observable<any> {
    return this.tableColumnShowHide.asObservable();
  }

  /**
   *Function that return the price agreement tab show/hide details
   *
   * @return {*}  {*}
   * @memberof ToggleItemService
   */
  public getPostedShowHide(): any {
    return this.priceAgreementShow;
  }

  /**
   *Function that return the history tab show/hide details
   *
   * @return {*}  {*}
   * @memberof ToggleItemService
   */
  public getHistoryShowHide(): any {
    return this.tableColumnShowHide;
  }

  /**
   *Function to set the not linked with PA status to true/false
   *
   * @memberof ToggleItemService
   */
  public NotLinkedPA() {
    this.notLinkedWithPA = !this.notLinkedWithPA;
  }

  /**
   *Function to set the global comment access status to true/false
   *
   * @memberof ToggleItemService
   */
  public globalCommentAccess() {
    this.gCommentAccess = !this.gCommentAccess;
  }
}
