import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { ConfigurationServices } from "./../../_services/configuration.service";
import { PriceManagerServices } from "../../_services/price-manager.service";
import { AppConfig } from "../../_config";
import * as moment from "moment";
import { MasterDataServices } from "../../_services/masterdata.service";
// import { WarningComponent } from '../../../../global-module/warning/warning.component';
import { AuthServices } from "../../_services/auth.service";
import { TaskServices } from "../../_services/task.service";
import { LoaderService } from "../../_services/loader.service";
import { customSort } from "../../_helpers/functions/sort-func";
import { filter } from "lodash";

/**
 *Component for common filter actions for all pages
 *
 * @export
 * @class DropdownFilterComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-dropdown-filter",
  templateUrl: "./dropdown-filter.component.html",
  styleUrls: ["./dropdown-filter.component.scss"],
})
export class DropdownFilterComponent implements OnInit {
  public tradingModelDropdown: any = [];

  public paSsearchText: any[] = [];
  public emptyText = false;
  public selectedFilterType: any[] = [];

  public dropdownType: string;
  public dropdownNumber: any = 0;
  public dropdownTempData: any;

  public counts: any = 0;
  public supplierCount: any = 0;
  public opcoCount: any = 0;
  public catalogueCount: any = 0;
  public materialCount: any = 0;
  public projectIdCount: any = 0;
  public olaIdCount: any = 0;
  public tradingCount: any = 0;
  public catalogueStatusCount: any = 0;
  public paStatusCount: any = 0;
  public taskTypeCount: any = 0;
  public taskStatusCount: any = 0;
  public parentSupplierCount: any = 0;
  public l1CategoryCount: any = 0;
  public l4GroupCount: any = 0;
  public currencyCount: any = 0;
  public amountCount: any = 0;
  public createdByCount: any = 0;
  public companyCodeCount: any = 0;
  public manufacturerCount: any = 0;
  public materialSupplierCount: any = 0;
  public inProgressMpnSpnCount: any = 0;
  public completeMpnSpnCount: any = 0;
  public completeSAPMatrilIdCount: any = 0;
  public inProgressSAPMatrilIdCount: any = 0;
  public physicalNonphysicalCount: any = 0; //vp3-3
  public supplierId: any = [];
  public opcoId: any = [];
  public catalogueId: any = [];
  public materialId: any = [];
  public tradingId: any = [];
  public paStatusId: any = [];
  public projectIdStatusId: any = [];
  public catalogueStatusId: any = [];
  public taskTypeId: any = [];
  public taskStatusId: any = [];
  public parentSupplierId: any = [];
  public integrationStatusId: any = [];
  public currencyId: any = [];
  public amountId: any = [];
  public createdById: any = [];
  public companyCodeId: any = [];
  public manufacturerId: any = [];
  public manufacturerColId: any = [];
  public materialSupplierId: any = [];
  public inProgressMpnSpnId: any = [];
  public completeMpnSpnId: any = [];
  public completeSAPMatrilIdId: any = [];
  public inProgressSAPMatrilIdId: any = [];
  public projectIds: any = [];
  public olaIds: any = [];
  public projectIdCol: any = [];
  public projectDescriptionCol: any = [];
  public projectOwnerCol: any = [];
  /* VP3-3 start */
  public physicalNonphysicalId: any = [];
  public masterAgreementNameId: any = [];
  public primaryDelegatorId: any = [];
  public primaryDelegateId: any = [];
  public secondaryDelegatorId: any = [];
  public secondaryDelegateId: any = [];
  public supplierNameId: any = [];
  public opcoNameId: any = [];
  public tradingCurrencyKeyId: any = [];
  public validFromDateId: any = [];
  public validToDateId: any = [];
  public secondaryStartDateId: any = [];
  public secondaryEndDateId: any = [];
  public vpcOwnerId: any = [];
  public vodafoneApproverId: any = [];
  public supplierOwnerId: any = [];
  public createdAtId: any = [];
  //public createdById: any = [];
  public updatedAtId: any = [];
  public updatedById: any = [];
  public isVisibleId: any = [];
  public isSupplierOnboardedId: any = [];
  public paStatusFilterId: any = [];
  public projectIdStatusFilterId: any = [];
  public lineItemStatusFilterId: any = [];
  public projectIdFilter: any = [];
  public projectDescriptionFilter: any = [];
  public projectOwnerFilter: any = [];
  public integrationStatusFilterId: any = [];
  public supplierIdVal: any = [];
  public outlineAgreementId: any = [];
  public projectIdColPa: any = [];
  public companyCodeColPa: any = [];
  public contractId: any = [];
  public olaId: any = [];

  /* VP3-3 end */
  public monthId: any;
  public tradingModels: any = [];
  public suppliersDetails: any = [];
  public opcoDetails: any = [];
  public olaDetails: any = [];
  public catalogueType: any = [];
  public materialGroups: any = [];
  public parentSupplierDetails: any = [];
  public organizationIdNameMapping: any = {};
  public parentSupplierArr: any = [];
  public currencyDetails: any = [];
  isSupplierUser = false;
  //public createdByList: any = [];
  //public companyCodeList: any = [];
  public manufacturerNameList: any = [];
  public completeMpnSpnList: any = [];
  public inProgressMpnSpnList: any = [];
  public dropDownData: any = [];
  public proDropDownData: any = [];
  public projectIdDropDownValues: any = [];
  public projectIdDetails: any = [];
  public amountArr: any = [
    {
      display: "Less than €100",
      key: "lessThan-100",
      value: "lessThan-100",
      selected: false,
    },
    {
      display: "€100 - €1,000",
      key: "100-1000",
      value: "100-1000",
      selected: false,
    },
    {
      display: "€1,001 - €5,000",
      key: "1001-5000",
      value: "1001-5000",
      selected: false,
    },
    {
      display: "€5,001 - €10,000",
      key: "5001-10000",
      value: "5001-10000",
      selected: false,
    },
    {
      display: "€10,001 - €50,000",
      key: "10001-50000",
      value: "10001-50000",
      selected: false,
    },
    {
      display: "€50,001 - €100,000",
      key: "50001-100000",
      value: "50001-100000",
      selected: false,
    },
    {
      display: "More than €100,000",
      key: "moreThan-100000",
      value: "moreThan-100000",
      selected: false,
    },
  ];

  public physicalNonphysicalArr = [
    {
      display: "Physical",
      key: "physical",
      value: "physical",
      selected: false,
    },
    {
      display: "Non Physical",
      key: "non-physical",
      value: "non-physical",
      selected: false,
    },
  ];

  public itemStatusArr = {
    pendingSynchronization: "PENDING_SYNCHRONIZATION",
    catalougeSynchronized: "CATALOGUE_SYNCHRONIZED",
    catalogueFinalized: "CATALOGUE_FINALIZED", // [VPC-103]
    // VOD-1613 fixes
    synchronizationError: "SYNCHRONIZATION_ERROR",
  };

  public trading = false;
  public material = false;
  public supplier = false;
  public opco = false;
  public catalogue = false;
  public paStatusF = false;
  public catalogueStatusF = false;
  public taskType = false;
  public taskStatus = false;
  public validityShow: false;
  public parentSupplier = false;
  public l1Category = false;
  public l4Group = false;
  public allTime = false;
  public monthFilter = false;
  public currency = false;
  public taskPage = false;
  public amountFilter = false;
  public contractFilter = false;
  public olaFilter = false;
  public isAllPriceAgreementPage = false;
  public createdBy = false;
  public companyCode = false;
  public manufacturer = false;
  public materialSupplier = false;
  public inProgressMpnSpn = false;
  public completeMpnSpn = false;
  public physicalNonphysical = false; // vp3-3
  public creationDateRange = false;
  public projectId = false;
  public projectDescription = false;
  public projectOwner = false;
  public completeSAPMatrilId = false;
  public inProgressSAPMatrilId = false;
  paPageSupplierCase = false;
  /* project Id header search start*/
  public proProjectId = false;
  public proSupplierID = false;
  public proMGOLA = false;
  public proProjectDescription = false;
  public proProjectIdType: any;
  public proSupplierIDType: any;
  public proMGOLAType: any;
  public proProjectDescriptionType: any;
  public proProjectIdId: any = [];
  public proSupplierIDId: any = [];
  public proMGOLAId: any = [];
  public proProjectDescriptionId: any = [];
  public proProjectIdCount: any = 0;
  public proSupplierIDCount: any = 0;
  public proMGOLACount: any = 0;
  public proProjectDescriptionCount: any = 0;
  public proProjectIdList: any = [];
  public proSupplierIDList: any = [];
  public proMGOLAList: any = [];
  public proProjectDescriptionList: any = [];
  public projectIdColId: any = [];
  public projectDescColId: any = [];
  public projectOwnerColId: any = [];
  public proMGOLAColId: any = [];
  public proTradingModelColId: any = [];
  public proSupplierIdColId: any = [];
  public proSupplierNameColId: any = [];
  public proCompanyCodeColId: any = [];
  public proCurrencyColId: any = [];
  public proPaymentTermsColId: any = [];
  public proValidFromColId: any = [];
  public proValidToColId: any = [];
  public proWarrantyColId: any = [];
  public mmolaColId: any = [];
  public projectIdColTypeCol: any;
  public projectDescColType: any;
  public projectOwnerColType: any;
  public proMGOLAColType: any;
  public proTradingModelColType: any;
  public proSupplierIdColType: any;
  public proSupplierNameColType: any;
  public proCompanyCodeColType: any;
  public proCurrencyColType: any;
  public proPaymentTermsColType: any;
  public proValidFromColType: any;
  public proValidToColType: any;
  public proWarrantyColType: any;
  public mmolaColType: any;
  /* project Id end*/

  /* VP3-3 start*/

  public masterAgreementNameType: any;
  public supplierNameType: any;
  public primaryDelegatorType: any;
  public primaryDelegateType: any;
  public secondaryDelegatorType: any;
  public secondaryDelegateType: any;
  public opcoNameType: any;
  public currencyType: any;
  public tradingCurrencyKeyType: any;
  public validFromDateType: any;
  public validToDateType: any;
  public secondaryStartDateType: any;
  public secondaryEndDateType: any;
  public vpcOwnerType: any;
  public vodafoneApproverType: any;
  public supplierOwnerType: any;
  public createdAtType: any;
  public createdByType: any;
  public companyCodeType: any;
  public manufacturerType: any;
  public manufacturerTypeCol: any;
  public materialSupplierType: any;
  public inProgressMpnSpnType: any;
  public completeMpnSpnType: any;
  public completeSAPMatrilIdType: any;
  public inProgressSAPMatrilIdType: any;
  public updatedAtType: any;
  public updatedByType: any;
  public isVisibleType: any;
  public isSupplierOnboardedType: any;
  public paStatusFilterType: any;
  public projectIdStatusFilterType: any;
  public lineItemStatusFilterType: any;
  public projectIdType: any;
  public olaType: any;
  public projectIdColType: any;
  public projectDescriptionType: any;
  public projectOwnerType: any;
  public integrationStatusFilterType: any;
  public lastIntegratedVersionId: any = [];
  public lastIntegratedVersionFilterType: any;
  public supplierIdType: any;
  public outlineAgreementNumberType: any;
  public projectIdColPaType: any;
  companyCodeColPaType: any;
  public catalogueIdentifier: any = [];
  public catalogueIdentifierFilterType: any;
  public catalogueName: any = [];
  public catalogueNameFilterType: any;
  public physicalTypeCol: any = [];
  public endOfLifeType: any;
  public endOfSupportType: any;

  // materialAndBomID
  public itemStatusId: any = [];
  public sapMaterialId: any = [];
  public supplierPartNumberId: any = [];
  public manufacturerPartNumberId: any = [];
  public materialDescriptionId: any = [];
  public materialLongDescriptionId: any = [];
  public oldMaterialId: any = [];
  public boumId: any = [];
  public materialGroupId: any = [];
  public commodityCodeId: any = [];
  public grossWeightId: any = [];
  public netWeightId: any = [];
  public characteristicValueId: any = [];
  public subCharacteristicValueId: any = [];
  public lengthId: any = [];
  public widthId: any = [];
  public heightId: any = [];
  public hlwUnitId: any = [];
  public volumeId: any = [];
  public volumeUnitId: any = [];
  public natureId: any = [];
  public serializationId: any = [];
  public usefulLifeId: any = [];
  public eanId: any = [];
  public eccnId: any = [];
  public gamId: any = [];
  public auom1Id: any = [];
  public unitConversion1Id: any = [];
  public auomqty1Id: any = [];
  public auom2Id: any = [];
  public unitConversion2Id: any = [];
  public auomqty2Id: any = [];
  public auom3Id: any = [];
  public unitConversion3Id: any = [];
  public auomqty3Id: any = [];
  public stackingNormsId: any = [];
  public stackingNumberId: any = [];
  public productFamilyId: any = [];
  public physicalTypeColId: any = [];
  public endOfLifeId: any = [];
  public endOfSupportId: any = [];
  // materialAndBom
  public itemStatusType: any;
  public sapMaterialIdType: any;
  public manufacturerPartNumberType: any;
  public supplierPartNumberType: any;
  public materialDescriptionType: any;
  public materialLongDescriptionType: any;
  public oldMaterialType: any;
  public boumType: any;
  public materialGroupType: any;
  public commodityCodeType: any;
  public grossWeightType: any;
  public netWeightType: any;
  public characteristicValueType: any;
  public subCharacteristicValueType: any;
  public lengthType: any;
  public widthType: any;
  public heightType: any;
  public hlwUnitType: any;
  public volumeType: any;
  public volumeUnitType: any;
  public natureType: any;
  public serializationType: any;
  public usefulLifeType: any;
  public eanType: any;
  public eccnType: any;
  public gamType: any;
  public auom1Type: any;
  public unitConversion1Type: any;
  public auomqty1Type: any;
  public auom2Type: any;
  public unitConversion2Type: any;
  public auomqty2Type: any;
  public auom3Type: any;
  public unitConversion3Type: any;
  public auomqty3Type: any;
  public stackingNormsType: any;
  public stackingNumberType: any;
  public productFamilyType: any;
  /* VP3-3 end*/

  public searchText: any = "";
  public dropDownObjArray: any[] = [];
  public monthFilterObjArray: any[] = [];
  public responseObj: object;

  public paStatusDetail: any = [];
  public projectIdStatusDetail: any = [];
  public catalogueStatusDetail: any = [];
  public taskTypeDetail: any = [];
  public taskStatusDetail: any = [];

  public statusArr: any = [];

  public supDet: any = {};
  public filterSupplier: any = [];

  public date: any;
  public roomsFilter: {
    date: any;
    begin: string | number | Date;
    end: string | number | Date;
  };
  public startDate: Date;
  public endDate: Date;
  public isBuyer: boolean;

  //Ankita added for task start
  public referenceNameType: any;
  public referenceNameId: any = [];
  public taskObjectType: any;
  public taskObjectId: any = [];
  public taskTypeType: any;
  public taskType_Id: any = [];
  public taskGeneratedType: any;
  public taskGeneratedId: any = [];
  public taskSupplierType: any;
  public taskSupplierId: any = [];
  public taskOwnerType: any;
  public taskOwnerId: any = [];
  public agreementStatusType: any;
  public agreementStatusId: any = [];
  public incotermsType: any;;
  public incotermsId: any = [];
  //Task end

  @Output() public OutputToParent = new EventEmitter<any>();
  @Input() public childname: string;
  @Input() isDisableButton = false;
  @Input() public filterDisplay: any;
  delegationTypeType: any;
  delegationTypeId: any = [];
  primaryDelegatedFromType: any;
  primaryDelegatedFromId: any = [];
  secondaryDelegatedFromType: any;
  secondaryDelegatedFromId: any = [];
  materialCategoryId: any = [];
  materialCategoryType: any;
  contractIdType: any;
  contractIdId: any = [];
  paymentTermsId: any = [];
  paymentTermsType: any;
  public paStatusArr = [
    {
      key: "COMPLETED",
      value: "Completed Price Agreement",
      type: "paStatus",
      selected: false,
    },
    {
      key: "DRAFT",
      value: "In-Progress Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "PENDING",
      value: "Pending Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ITEMS_EXPIRED",
      value: "All Items Expired",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ITEMS_DELETED",
      value: "All Items Deleted",
      selected: false,
      type: "paStatus",
    },
    {
      key: "INACTIVE",
      value: "All Items Inactive",
      selected: false,
      type: "paStatus",
    },
    {
      key: "OLA_EXPIRED",
      value: "OLA Expired",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ARCHIVED",
      value: "Archived Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "DELETED",
      value: "Deleted Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "SYNCHRONIZATION_ERROR",
      value: "Sync Failed",
      selected: false,
      type: "paStatus",
    },
  ];
  public projectIdStatusArr = [
    {
      key: "APPROVED",
      value: "Completed Price Agreement",
      type: "paStatus",
      selected: false,
    },
    {
      key: "DRAFT",
      value: "In-Progress Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "PENDING",
      value: "Pending Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ITEMS_EXPIRED",
      value: "All Items Expired",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ITEMS_DELETED",
      value: "All Items Deleted",
      selected: false,
      type: "paStatus",
    },
    {
      key: "INACTIVE",
      value: "All Items Inactive",
      selected: false,
      type: "paStatus",
    },
    {
      key: "OLA_EXPIRED",
      value: "OLA Expired",
      selected: false,
      type: "paStatus",
    },
    {
      key: "ARCHIVED",
      value: "Archived Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "DELETED",
      value: "Deleted Price Agreement",
      selected: false,
      type: "paStatus",
    },
    {
      key: "SYNCHRONIZATION_ERROR",
      value: "Sync Failed",
      selected: false,
      type: "paStatus",
    },
  ];
  role;
  filteredData: any;
  filteredDataCpmplete: any;
  filteredDatainProgressSAPMatrilIdType: any;
  filteredDataCompleteSAPMatrilIdType: any;
  constructor(
    private configurationServices: ConfigurationServices,
    public priceManagerServices: PriceManagerServices,
    public masterDataServices: MasterDataServices,
    public taskTypeService: TaskServices,
    public authServices: AuthServices,
    public pricemanagerServices: PriceManagerServices,
    public loaderService: LoaderService,
  ) { }

  public ngOnInit() {
    this.isBuyer = this.authServices.getIsBuyer();
    this.role = this.authServices.getRole();
    if (
      this.role.includes("SUPPLIER_ADMIN") ||
      this.role.includes("SUPPLIER_MANAGER")
    ) {
      this.isSupplierUser = true;
    }
    if (this.filterDisplay) {
      this.trading = this.filterDisplay.trading;
      this.material = this.filterDisplay.material;
      this.supplier = this.filterDisplay.supplier;
      this.opco = this.filterDisplay.opco;
      this.catalogue = this.filterDisplay.catalogue;
      this.catalogueStatusF = this.filterDisplay.catalogueStatus;
      this.paStatusF = this.filterDisplay.paStatus;
      this.taskType = this.filterDisplay.taskType;
      this.taskStatus = this.filterDisplay.taskStatus;
      this.validityShow = this.filterDisplay.validityShow;
      this.parentSupplier = this.filterDisplay.parentSupplier;
      this.l1Category = this.filterDisplay.l1Category;
      this.l4Group = this.filterDisplay.l4Group;
      this.allTime = this.filterDisplay.allTime;
      this.currency = this.filterDisplay.currency;
      this.taskPage = this.filterDisplay.taskPage;
      this.amountFilter = this.filterDisplay.amountFilter;
      this.monthFilter = this.filterDisplay.monthFilter;
      this.contractFilter = this.filterDisplay.contractFilter;
      this.olaFilter = this.filterDisplay.olaFilter;
      this.isAllPriceAgreementPage = this.filterDisplay.isAllPriceAgreementPage;
      this.paPageSupplierCase = this.filterDisplay.paPage || false;

      this.physicalNonphysical = this.filterDisplay.physicalNonphysical; //vp3-3
      this.createdBy = this.filterDisplay.createdBy;
      this.companyCode = this.filterDisplay.companyCode;
      this.creationDateRange = this.filterDisplay.creationDateRange;
      this.manufacturer = this.filterDisplay.manufacturer;
      this.materialSupplier = this.filterDisplay.materialSupplier;
      this.inProgressMpnSpn = this.filterDisplay.inProgressMpnSpn;
      this.completeMpnSpn = this.filterDisplay.completeMpnSpn;
      this.completeSAPMatrilId = this.filterDisplay.completeSAPMatrilId;
      this.inProgressSAPMatrilId = this.filterDisplay.inProgressSAPMatrilId;

      this.projectId = this.filterDisplay.projectId;
      this.projectDescription = this.filterDisplay.projectDescription;
      this.projectOwner = this.filterDisplay.projectOwner;

      this.proProjectId = this.filterDisplay.proProjectId;
      this.proSupplierID = this.filterDisplay.proSupplierID;
      this.proMGOLA = this.filterDisplay.proMGOLA;
      this.proProjectDescription = this.filterDisplay.proProjectDescription;
    }
    this.getPriceAgreementStatus();

    this.getFilterValues();
    this.getCommodityCodeValues();
    this.getDropDownValues();
  }

  public getUnique(array) {
    const uniqueArray = [];
    let i = 0;
    for (i; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  /**
   *Function to get the price agreement status list (agreement status, catalogue status, item status)
   *
   * @memberof DropdownFilterComponent
   */
  public async getPriceAgreementStatus() {
    const response = await this.priceManagerServices.getPriceAgreementStatus();
    // For Price Agreement Status Details
    let catlougeSta = {};
    let itemStatus = {};
    if (this.catalogueStatusF) {
      catlougeSta = response["result"].catalogueStatus;
      if (this.isBuyer) {
        itemStatus = this.itemStatusArr;
      }
    }
    this.statusArr = {
      ...response["result"].agreementStatus,
      ...catlougeSta,
      ...itemStatus,
    };
    // this.paStatusDetail = this.modifyPaStatus(this.statusArr);
    this.paStatusDetail = this.paStatusArr;
    this.projectIdStatusDetail = this.projectIdStatusArr;

    // For Catalogue Status Details
    this.catalogueStatusDetail = Object.keys(
      response["result"].catalogueStatus
    ).map((k) => {
      return {
        key: response["result"].catalogueStatus[k],
        value: response["result"].catalogueStatus[k],
        selected: false,
      };
    });

    // For Task Type Status Details
    this.taskTypeDetail = Object.keys(response["result"].taskType).map((k) => {
      return {
        key: response["result"].taskType[k],
        value: response["result"].taskType[k],
        selected: false,
      };
    });

    // For Task Status Details
    this.taskStatusDetail = Object.keys(response["result"].taskStatus).map(
      (k) => {
        return {
          key: response["result"].taskStatus[k],
          value: response["result"].taskStatus[k],
          selected: false,
        };
      }
    );
  }

  /**
   *Function to get the commodity code values
   *
   * @memberof DropdownFilterComponent
   */
  public async getCommodityCodeValues() {
    await this.configurationServices.getCommodityCodeListConfiguration();
  }

  /**
   *Function to get the gloable filter option list
   *
   * @memberof DropdownFilterComponent
   */
  getMaterialDropDownApi() {
    return new Promise((resolve) => {
      this.pricemanagerServices.getMaterialDropdowns().subscribe(
        (data) => {
          resolve(data);
          this.pricemanagerServices.setMaterialDropDown(data);

        },
        (error) => { }
      );
    })
  }
  public async getFilterValues() {
    if (
      this.createdBy == true ||
      this.companyCode == true ||
      this.manufacturer == true ||
      this.materialSupplier == true ||
      this.inProgressMpnSpn == true ||
      this.completeMpnSpn == true ||
      this.proSupplierID == true
    ) {
      this.loaderService.startLoading();
      let materialData;
      if (this.pricemanagerServices.getMaterialDropDown()) {
        materialData = this.pricemanagerServices.getMaterialDropDown();
      } else {
        materialData = await this.getMaterialDropDownApi();
      }
      this.dropDownData = materialData["result"];

      /*   this.GroupL4 = data["result"].l4;
         this.buomDataList = data["result"].boum;
         this.productFamilyCodeList = data["result"].productFamilyCode;
         this.hlwUnitList = data["result"].hlwUnit;
         this.volumeUnitList = data["result"].volumeUnit;*/
      //this.companyCodeList = data["result"].companyCodes;
      this.manufacturerNameList = materialData["result"].manufacturer;
      this.completeMpnSpnList = materialData["result"].completeMpnSpn;
      this.inProgressMpnSpnList = materialData["result"].inProgressMpnSpn;
      // materialPartNo supplier
      //this.catalogueType
      this.companyCodeType = materialData["result"].companyCodes.map((cType) => {
        return {
          key: cType.companyCode,
          value: cType.companyCode,
          selected: false,
        };
      });

      this.materialSupplierType = materialData["result"].supplier.map((cType) => {
        return {
          key: cType.supplierId,
          value: cType.supplierName,
          selected: false,
        };
      });
      this.proSupplierIDType = materialData["result"].supplier.map((cType) => {
        return {
          key: cType.supplierId,
          value: cType.supplierName,
          selected: false,
        };
      });
      this.manufacturerType = materialData["result"].manufacturer.map((cType) => {
        return {
          key: cType.manufacturerName,
          value: cType.manufacturerName,
          selected: false,
        };
      });
      this.inProgressMpnSpnType = materialData["result"].inProgressMpnSpn.map(
        (cType) => {
          return {
            key: cType.materialPartNo,
            value: cType.materialPartNo,
            selected: false,
          };
        }
      );

      this.completeMpnSpnType = materialData["result"].completeMpnSpn.map(
        (cType) => {
          return {
            key: cType.materialPartNo,
            value: cType.materialPartNo,
            selected: false,
          };
        }
      );
      this.createdByType = materialData["result"].allUsers.map((cType) => {
        return {
          key: cType.uuid,
          value: cType.login_id,
          selected: false,
        };
      });
      this.loaderService.stopLoading();
      if (this.companyCodeType) customSort(this.companyCodeType, 'key');
      if (this.materialSupplierType) customSort(this.materialSupplierType, 'key');
      if (this.proSupplierIDType) customSort(this.proSupplierIDType, 'key');
      if (this.manufacturerType) customSort(this.manufacturerType, 'key');
      if (this.inProgressMpnSpnType) customSort(this.inProgressMpnSpnType, 'key');
      if (this.completeMpnSpnType) customSort(this.completeMpnSpnType, 'key');
      if (this.createdByType) customSort(this.createdByType, 'value');

    } else {
    }
    if (
      this.proProjectId == true ||
      this.proMGOLA == true ||
      this.proProjectDescription == true
    ) {
      this.priceManagerServices.getAllProjectIdDrpdwnlList().subscribe(
        (data) => {
          this.proDropDownData = data["result"];
          this.proProjectIdType = data["result"].projectId.map((cType) => {
            return {
              key: cType.projectId,
              value: cType.projectId,
              selected: false,
            };
          });
          this.proMGOLAType = data["result"]["MGOLA"].map((cType) => {
            return {
              key: cType.MGOLAList,
              value: cType.MGOLAList,
              selected: false,
            };
          });
          this.proProjectDescriptionType = data[
            "result"
          ].projectDescription.map((cType) => {
            return {
              key: cType.projectDescription,
              value: cType.projectDescription,
              selected: false,
            };
          });
          if (this.proProjectIdType) customSort(this.proProjectIdType, 'key')
          if (this.proMGOLAType) customSort(this.proMGOLAType, 'key')
          if (this.proProjectDescriptionType) customSort(this.proProjectDescriptionType, 'key')

        },
        (error) => { }
      );
    } else {
    }
    if (this.completeSAPMatrilId === true) {
      let payload: any = {};
      payload = { tableStatus: "complete" };

      this.priceManagerServices.getSAPMaterialIdList(payload).subscribe(
        (data) => {
          this.completeSAPMatrilIdType = data["result"].map((cType) => {
            return {
              key: cType.sapMaterialId,
              value: cType.sapMaterialId,
              selected: false,
            };
          });
        },
        (error) => { }
      );
    }
    if (this.inProgressSAPMatrilId === true) {
      let payload: any = {};
      payload = { tableStatus: "inProgress" };
      this.priceManagerServices.getSAPMaterialIdList(payload).subscribe(
        (data) => {
          this.inProgressSAPMatrilIdType = data["result"].map((cType) => {
            return {
              key: cType.sapMaterialId,
              value: cType.sapMaterialId,
              selected: false,
            };
          });
        },
        (error) => { }
      );
    }
    ///////////

    const filterRes =
      await this.configurationServices.getGlobalFilterConfiguration();
    this.responseObj = filterRes;

    // For Trading Model Details
    this.tradingModels = filterRes.tradingModels.map((tradingM) => {
      return {
        key: tradingM,
        value: tradingM,
        selected: false,
      };
    });

    // child details api fetch
    const filterChildSuppliers =
      await this.configurationServices.getChildSupplierFilterConfiguration();
    this.parentSupplierArr = filterChildSuppliers.supplierChildDetails;
    if (this.parentSupplierArr !== null) {
      this.organizationIdNameMapping = filterChildSuppliers.supplierChildDetails.reduce(
        (acc, ps) => {
          const { organizationId, organizationName } = ps;
          acc[organizationId] = organizationName;
          return acc;
        },
        {}
      );
      this.suppliersDetails = Object.keys(filterChildSuppliers.supplierChildDetails).map(
        (k) => {
          return {
            key: filterChildSuppliers.supplierChildDetails[k].organizationId,
            value: filterChildSuppliers.supplierChildDetails[k].organizationName,
            selected: false,
          };
        }
      );
    }


    // parent supplier api fetch
    const filterParentSupplier: any =
      await this.configurationServices.getSupplierListConfiguration();
    this.filterSupplier = filterParentSupplier.suppliersDetails;
    let tempSupList = [];
    for (var key in this.filterSupplier) {
      const obj = { id: key, value: this.filterSupplier[key] };
      tempSupList.push(obj);
    }
    this.parentSupplierDetails = await tempSupList.map((k: any) => {
      return {
        key: k.id,
        value: k.value,
        selected: false,
      };
    });

    // using olalist api 
    const filterResOla =
      await this.configurationServices.getOlaListFilterConfiguration();
    this.olaDetails = Object.keys(filterResOla.olaList).map((k) => {
      return {
        key: k,
        value: k,
        selected: false,
      };
    });

    // For Local market Details
    this.opcoDetails = Object.keys(filterRes.opcoDetails).map((k) => {
      return {
        key: k,
        value: filterRes.opcoDetails[k],
        selected: false,
      };
    });

    // For Catalogue Type Details
    this.catalogueType = filterRes.catalogueType.map((cType) => {
      return {
        key: cType,
        value: cType,
        selected: false,
      };
    });

    // For Material Groups Details
    this.materialGroups = Object.keys(filterRes.materialGroups).map((k) => {
      return {
        key: k,
        value: filterRes.materialGroups[k],
        selected: false,
      };
    });

    // For Currency Details
    this.currencyDetails = Object.keys(filterRes.currencyList).map((k) => {
      return {
        key: k,
        value: filterRes.currencyList[k],
        selected: false,
      };
    });
  }

  /**
   *Function to open/close the filter dropdown
   *
   * @param {*} type
   * @param {*} num
   * @memberof DropdownFilterComponent
   */
  public dropdownToggle(type, num) {

    this.searchText = "";
    this.filteredDataCpmplete = []
    this.filteredData = []
    this.filteredDataCompleteSAPMatrilIdType = []
    this.filteredDatainProgressSAPMatrilIdType = []
    this.dropdownType = type;
    if (this.dropdownNumber === 0 || this.dropdownTempData !== type) {
      this.dropdownNumber += num;
      if (this.dropdownNumber > 1) {
        this.dropdownNumber--;
      }
      this.dropdownTempData = type;
    } else {
      this.dropdownNumber = 0;
    }
  }

  /**
   *Delete the values from the filter array
   *
   * @param {*} arrayData
   * @param {*} searchIds
   * @param {*} listArr
   * @memberof DropdownFilterComponent
   */
  public deleteValueFromArr(arrayData, searchIds, listArr) {
    if (listArr) {
      listArr.map((element) => {
        if (element.key === searchIds) {
          element["selected"] = false;
        }
      });
    }

    if (searchIds === "ITEM_DELETED") {
      searchIds = "ARCHIVED";
    } else if (searchIds === "ITEM_EXPIRED") {
      searchIds = "EXPIRED";
    }
    // tslint:disable-next-line:prefer-const
    const index = arrayData.indexOf(searchIds);
    if (searchIds === "ITEM_EXPIRED") {
      arrayData.splice(index, 1);
    }
    if (searchIds === "ITEM_DELETED") {
      arrayData.splice(index, 1);
    }
    if (index !== -1) {
      arrayData.splice(index, 1);
    }
  }

  /**
   *Function to delete filter values from the drop down filter object for show the
   * selected values in the ui dropdown list
   *
   * @param {*} type
   * @param {*} key
   * @memberof DropdownFilterComponent
   */
  public deleteFromDropDownObj(type, key) {

    const index = this.dropDownObjArray.findIndex((obj) => {
      return obj.type === type && obj.key === key;
    });
    if (index >= 0) {
      if (type === "validity") {
        this.startDate = null;
        this.endDate = null;
        this.roomsFilter = undefined;
      }
      this.dropDownObjArray.splice(index, 1);
    }
  }

  /**
   *Function to select the values based on the give search value
   *
   * @param {*} searchIds
   * @param {*} listArr
   * @memberof DropdownFilterComponent
   */
  public autoSelect(searchIds, listArr) {
    if (listArr) {
      listArr.map((element) => {
        if (element.key === searchIds) {
          element["selected"] = true;
        }
      });
    }

  }

  /**
   *Function to create a filter objects like filter type,value,key for the filter
   *Based on the status add / remove the selected filter values from the filter objects
   * @param {*} type
   * @param {*} status
   * @param {*} searchIds
   * @param {boolean} [remStatus=false]
   * @param {string} [filterType='equal']
   * @memberof DropdownFilterComponent
   */
  public GetOutputVal(
    type,
    status,
    searchIds,
    remStatus = false,
    filterType = "equal"
  ) {
    if (type === "monthFilter" && !status) {
      this.monthId = "";
    }
    if (type === "parentSupplier") {
      this.supplierDetails(searchIds, "add", remStatus); /* VP2-108 */
      if (status === true) {
        if (this.parentSupplierId.includes(searchIds)) {
          this.parentSupplierCount = this.parentSupplierCount;
          this.parentSupplierId = this.parentSupplierId;
        } else {
          this.parentSupplierCount += 1;
          this.parentSupplierId.push(searchIds);
          const obj = {
            type,
            value: this.filterSupplier[searchIds],
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.parentSupplierDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.parentSupplierCount > 0) {
          /* [VOD-1641] */
          this.parentSupplierCount -= 1;
        }
        this.deleteValueFromArr(
          this.parentSupplierId,
          searchIds,
          this.parentSupplierDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "projectId") {
      if (status === true) {
        this.projectIdType = filterType;
        if (this.projectIds.includes(searchIds)) {
          this.projectIdCount = this.projectIdCount;
          this.projectIds = this.projectIds;
        } else {
          this.projectIdCount += 1;
          this.projectIds.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.projectIdDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.projectIdCount > 0) {
          /* [VOD-1641] */
          this.projectIdCount -= 1;
        }
        this.deleteValueFromArr(
          this.projectIds,
          searchIds,
          this.projectIdDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "olaId") {
      if (status === true) {
        this.olaType = filterType;
        if (this.olaDetails.includes(searchIds)) {
          this.olaIdCount = this.olaIdCount;
          this.olaIds = this.olaIds;
        } else {
          this.olaIdCount += 1;
          this.olaIds.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.olaDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.olaIdCount > 0) {
          /* [VOD-1641] */
          this.olaIdCount -= 1;
        }
        this.deleteValueFromArr(
          this.olaIds,
          searchIds,
          this.olaDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "projectIdCol") {
      if (status === true) {
        if (this.projectIdCol.includes(searchIds)) {
          this.projectIdCol = this.projectIdCol;
        } else {
          this.projectIdCol.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.projectIdDetails);
        }
      } else {
        this.deleteValueFromArr(
          this.projectIdCol,
          searchIds,
          this.projectIdDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "projectDescription") {
      if (status === true) {
        if (this.projectDescriptionCol.includes(searchIds)) {
          this.projectDescriptionCol = this.projectDescriptionCol;
        } else {
          this.projectDescriptionCol.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.projectDescriptionCol = this.projectDescriptionCol.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "projectOwner") {
      if (status === true) {
        if (this.projectOwnerCol.includes(searchIds)) {
          this.projectOwnerCol = this.projectOwnerCol;
        } else {
          this.projectOwnerCol.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.projectOwnerCol = this.projectOwnerCol.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "supplier") {
      if (status === true) {
        if (this.supplierId.includes(searchIds)) {
          this.supplierCount = this.supplierCount;
          this.supplierId = this.supplierId;
        } else {
          this.supplierCount += 1;
          this.supplierId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.suppliersDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.supplierCount > 0) {
          /* [VOD-1641] */
          this.supplierCount -= 1;
        }
        this.deleteValueFromArr(
          this.supplierId,
          searchIds,
          this.suppliersDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "opco") {
      if (status === true) {
        if (this.opcoId.includes(searchIds)) {
          this.opcoCount = this.opcoCount;
          this.opcoId = this.opcoId;
        } else {
          this.opcoCount += 1;
          this.opcoId.push(searchIds);
          const obj = {
            type,
            value: `${searchIds}-${this.responseObj["opcoDetails"][searchIds]}`,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.opcoDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.opcoCount > 0) {
          /* [VOD-1641] */
          this.opcoCount -= 1;
        }
        this.deleteValueFromArr(this.opcoId, searchIds, this.opcoDetails);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "catalogue") {
      if (status === true) {
        if (this.catalogueId.includes(searchIds)) {
          this.catalogueCount = this.catalogueCount;
          this.catalogueId = this.catalogueId;
        } else {
          this.catalogueCount += 1;
          this.catalogueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catalogueType);
        }
      } else {
        /* [VOD-1641] */
        if (this.catalogueCount > 0) {
          /* [VOD-1641] */
          this.catalogueCount -= 1;
        }
        this.deleteValueFromArr(
          this.catalogueId,
          searchIds,
          this.catalogueType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "createdBy") {
      if (status === true) {
        if (this.createdById.includes(searchIds)) {
          this.createdById = this.createdById;
        } else {
          this.createdById.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.createdById = this.createdById.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "companyCode") {
      if (status === true) {
        if (this.companyCodeId.includes(searchIds)) {
          this.companyCodeCount = this.companyCodeCount;
          this.companyCodeId = this.companyCodeId;
        } else {
          this.companyCodeCount += 1;
          this.companyCodeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.companyCodeType);
        }
      } else {
        /* [VOD-1641] */
        if (this.companyCodeCount > 0) {
          /* [VOD-1641] */
          this.companyCodeCount -= 1;
        }
        this.deleteValueFromArr(
          this.companyCodeId,
          searchIds,
          this.companyCodeType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "manufacturer") {
      if (status === true) {
        if (this.manufacturerId.includes(searchIds)) {
          this.manufacturerCount = this.manufacturerCount;
          this.manufacturerId = this.manufacturerId;
        } else {
          this.manufacturerCount += 1;
          this.manufacturerId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.manufacturerType);
        }
      } else {
        /* [VOD-1641] */
        if (this.manufacturerCount > 0) {
          /* [VOD-1641] */
          this.manufacturerCount -= 1;
        }
        this.deleteValueFromArr(
          this.manufacturerId,
          searchIds,
          this.manufacturerType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "materialSupplier") {
      if (status === true) {
        if (this.materialSupplierId.includes(searchIds)) {
          this.materialSupplierCount = this.materialSupplierCount;
          this.materialSupplierId = this.materialSupplierId;
        } else {
          this.materialSupplierCount += 1;
          this.materialSupplierId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.materialSupplierType);
        }
      } else {
        /* [VOD-1641] */
        if (this.materialSupplierCount > 0) {
          /* [VOD-1641] */
          this.materialSupplierCount -= 1;
        }
        this.deleteValueFromArr(
          this.materialSupplierId,
          searchIds,
          this.materialSupplierType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "inProgressMpnSpn") {
      if (status === true) {
        if (this.inProgressMpnSpnId.includes(searchIds)) {
          this.inProgressMpnSpnCount = this.inProgressMpnSpnCount;
          this.inProgressMpnSpnId = this.inProgressMpnSpnId;
        } else {
          this.inProgressMpnSpnCount += 1;
          this.inProgressMpnSpnId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.inProgressMpnSpnType);
        }
      } else {
        /* [VOD-1641] */
        if (this.inProgressMpnSpnCount > 0) {
          /* [VOD-1641] */
          this.inProgressMpnSpnCount -= 1;
        }
        this.deleteValueFromArr(
          this.inProgressMpnSpnId,
          searchIds,
          this.inProgressMpnSpnType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "completeMpnSpn") {
      if (status === true) {
        if (this.completeMpnSpnId.includes(searchIds)) {
          this.completeMpnSpnCount = this.completeMpnSpnCount;
          this.completeMpnSpnId = this.completeMpnSpnId;
        } else {
          this.completeMpnSpnCount += 1;
          this.completeMpnSpnId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.completeMpnSpnType);
        }
      } else {
        /* [VOD-1641] */
        if (this.completeMpnSpnCount > 0) {
          /* [VOD-1641] */
          this.completeMpnSpnCount -= 1;
        }
        this.deleteValueFromArr(
          this.completeMpnSpnId,
          searchIds,
          this.completeMpnSpnType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "completeSAPMatrilId") {
      if (status === true) {
        if (this.completeSAPMatrilIdId.includes(searchIds)) {
          this.completeSAPMatrilIdCount = this.completeSAPMatrilIdCount;
          this.completeSAPMatrilIdId = this.completeSAPMatrilIdId;
        } else {
          this.completeSAPMatrilIdCount += 1;
          this.completeSAPMatrilIdId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.completeSAPMatrilIdType);
        }
      } else {
        /* [VOD-1641] */
        if (this.completeSAPMatrilIdCount > 0) {
          /* [VOD-1641] */
          this.completeSAPMatrilIdCount -= 1;
        }
        this.deleteValueFromArr(
          this.completeSAPMatrilIdId,
          searchIds,
          this.completeSAPMatrilIdType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "inProgressSAPMatrilId") {
      if (status === true) {
        if (this.inProgressSAPMatrilIdId.includes(searchIds)) {
          this.inProgressSAPMatrilIdCount = this.inProgressSAPMatrilIdCount;
          this.inProgressSAPMatrilIdId = this.inProgressSAPMatrilIdId;
        } else {
          this.inProgressSAPMatrilIdCount += 1;
          this.inProgressSAPMatrilIdId.push(searchIds);
          if (filterType === 'equal') filterType = 'equals'
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.inProgressSAPMatrilIdType);
        }
      } else {
        /* [VOD-1641] */
        if (this.inProgressSAPMatrilIdCount > 0) {
          /* [VOD-1641] */
          this.inProgressSAPMatrilIdCount -= 1;
        }
        this.deleteValueFromArr(
          this.inProgressSAPMatrilIdId,
          searchIds,
          this.inProgressSAPMatrilIdType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "l1Category") {
      if (status === true) {
        if (this.catalogueId.includes(searchIds)) {
          this.l1CategoryCount = this.l1CategoryCount;
          this.catalogueId = this.catalogueId;
        } else {
          this.l1CategoryCount += 1;
          this.catalogueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.catalogueType);
        }
      } else {
        /* [VOD-1641] */
        if (this.l1CategoryCount > 0) {
          /* [VOD-1641] */
          this.l1CategoryCount -= 1;
        }
        this.deleteValueFromArr(
          this.catalogueId,
          searchIds,
          this.catalogueType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "material") {
      if (status === true) {
        if (this.materialId.includes(searchIds)) {
          this.materialCount = this.materialCount;
          this.materialId = this.materialId;
        } else {
          this.materialCount += 1;
          this.materialId.push(searchIds);
          const obj = {
            type,
            value: `${searchIds}-${this.responseObj["materialGroups"][searchIds]}`,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.materialGroups);
        }
      } else {
        /* [VOD-1641] */
        if (this.materialCount > 0) {
          /* [VOD-1641] */
          this.materialCount -= 1;
        }
        this.deleteValueFromArr(
          this.materialId,
          searchIds,
          this.materialGroups
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "l4Group") {
      if (status === true) {
        if (this.materialId.includes(searchIds)) {
          this.l4GroupCount = this.l4GroupCount;
          this.materialId = this.materialId;
        } else {
          this.l4GroupCount += 1;
          this.materialId.push(searchIds);
          const obj = {
            type,
            value: `${searchIds}-${this.responseObj["materialGroups"][searchIds]}`,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.materialGroups);
        }
      } else {
        /* [VOD-1641] */
        if (this.l4GroupCount > 0) {
          /* [VOD-1641] */
          this.l4GroupCount -= 1;
        }
        this.deleteValueFromArr(
          this.materialId,
          searchIds,
          this.materialGroups
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "trading") {
      if (status === true) {
        if (this.tradingId.includes(searchIds)) {
          this.tradingCount = this.tradingCount;
          this.tradingId = this.tradingId;
        } else {
          this.tradingCount += 1;
          this.tradingId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.tradingModels);
        }
      } else {
        /* [VOD-1641] */
        if (this.tradingCount > 0) {
          /* [VOD-1641] */
          this.tradingCount -= 1;
        }
        this.deleteValueFromArr(this.tradingId, searchIds, this.tradingModels);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "paStatus") {
      const statusVal = this.paStatusDetail.find((k) => {
        if (k.key === searchIds) {
          return k.value;
        }
      });
      if (status === true) {
        this.paStatusCount += 1;
        this.paStatusId.push(searchIds);
        const obj = {
          type,
          value: statusVal.value,
          key: searchIds,
          filterType: filterType,
        };
        this.dropDownObjArray.push(obj);
        this.autoSelect(searchIds, this.paStatusDetail);
      } else {
        /* [VOD-1641] */
        if (this.paStatusCount > 0) {
          /* [VOD-1641] */
          this.paStatusCount -= 1;
        }
        this.deleteValueFromArr(
          this.paStatusId,
          searchIds,
          this.paStatusDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "projectIdStatusFilter") {
      const statusVal = this.projectIdStatusDetail.find((k) => {
        if (k.key === searchIds) {
          return k.value;
        }
      });
      if (status === true) {
        this.projectIdStatusId.push(searchIds);
        const obj = {
          type,
          value: statusVal.value,
          key: searchIds,
          filterType: filterType,
        };
        this.dropDownObjArray.push(obj);
        this.autoSelect(searchIds, this.projectIdStatusDetail);
      } else {
        this.deleteValueFromArr(
          this.projectIdStatusId,
          searchIds,
          this.projectIdStatusDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "integrationStatus") {
      const statusVal = this.paStatusDetail.find((k) => {
        if (k.key === searchIds) {
          return k.value;
        }
      });
      if (status === true) {
        if (this.integrationStatusId.includes(searchIds)) {
          this.paStatusCount = this.paStatusCount;
          this.integrationStatusId = this.integrationStatusId;
        } else {
          this.paStatusCount += 1;
          this.integrationStatusId.push(searchIds);
          const obj = {
            type,
            value: statusVal.value,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.paStatusDetail);
        }
      } else {
        /* [VOD-1641] */
        if (this.paStatusCount > 0) {
          /* [VOD-1641] */
          this.paStatusCount -= 1;
        }
        this.deleteValueFromArr(
          this.integrationStatusId,
          searchIds,
          this.paStatusDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "catalogueStatus") {
      const statusVal = this.paStatusDetail.find((k) => {
        if (k.key === searchIds) {
          return k.value;
        }
      });
      if (status === true) {
        this.paStatusCount += 1;
        this.catalogueStatusId.push(searchIds);
        const obj = {
          type,
          value: statusVal.value,
          key: searchIds,
          filterType: filterType,
        };
        this.dropDownObjArray.push(obj);
        this.autoSelect(searchIds, this.paStatusDetail);
      } else {
        /* [VOD-1641] */
        if (this.paStatusCount > 0) {
          /* [VOD-1641] */
          this.paStatusCount -= 1;
        }
        this.deleteValueFromArr(
          this.catalogueStatusId,
          searchIds,
          this.paStatusDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "taskType") {
      if (status === true) {
        if (this.taskTypeId.includes(searchIds)) {
          this.taskTypeCount = this.taskTypeCount;
          this.taskTypeId = this.taskTypeId;
        } else {
          this.taskTypeCount += 1;
          this.taskTypeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.taskTypeDetail);
        }
      } else {
        /* [VOD-1641] */
        if (this.taskTypeCount > 0) {
          /* [VOD-1641] */
          this.taskTypeCount -= 1;
        }
        this.deleteValueFromArr(
          this.taskTypeId,
          searchIds,
          this.taskTypeDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "amount") {
      if (status === true) {
        if (this.amountId.includes(searchIds)) {
          this.amountCount = this.amountCount;
          this.amountId = this.amountId;
        } else {
          this.amountCount += 1;
          this.amountId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.amountArr);
        }
      } else {
        /* [VOD-1641] */
        if (this.amountCount > 0) {
          /* [VOD-1641] */
          this.amountCount -= 1;
        }
        this.deleteValueFromArr(this.amountId, searchIds, this.amountArr);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "physicalStatus") {
      if (status === true) {
        if (this.physicalNonphysicalId.includes(searchIds)) {
          this.physicalNonphysicalCount = this.physicalNonphysicalCount;
          this.physicalNonphysicalId = this.physicalNonphysicalId;
        } else {
          this.physicalNonphysicalCount += 1;
          this.physicalNonphysicalId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.physicalNonphysicalArr);
        }
      } else {
        if (this.physicalNonphysicalCount > 0) {
          this.physicalNonphysicalCount -= 1;
        }
        this.deleteValueFromArr(
          this.physicalNonphysicalId,
          searchIds,
          this.physicalNonphysicalArr
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "validity") {
      if (status === false) {
        this.roomsFilter = undefined;
        this.startDate = null;
        this.endDate = null;
        this.deleteFromDropDownObj(type, searchIds);
      } else {
        const datesFilt = searchIds.split(" - ");
        const start = datesFilt[0].split("/");
        const startDay = start[0];
        const startMonth = start[1] - 1;
        const startYear = start[2];

        const end = datesFilt[1].split("/");
        const endDay = end[0];
        const endMonth = end[1] - 1;
        const endYear = end[2];
        this.startDate = new Date(startYear, startMonth, startDay);
        this.endDate = new Date(endYear, endMonth, endDay);
      }
    } else if (type === "taskStatus") {
      if (status === true) {
        if (this.taskStatusId.includes(searchIds)) {
          this.taskStatusCount = this.taskStatusCount;
          this.taskStatusId = this.taskStatusId;
        } else {
          this.taskStatusCount += 1;
          this.taskStatusId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.taskStatusDetail);
        }
      } else {
        /* [VOD-1641] */
        if (this.taskStatusCount > 0) {
          /* [VOD-1641] */
          this.taskStatusCount -= 1;
        }
        this.deleteValueFromArr(
          this.taskStatusId,
          searchIds,
          this.taskStatusDetail
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "currency") {
      if (status === true) {
        if (this.currencyId.includes(searchIds)) {
          this.currencyCount = this.currencyCount;
          this.currencyId = this.currencyId;
        } else {
          this.currencyCount += 1;
          this.currencyId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.currencyDetails);
        }
      } else {
        /* [VOD-1641] */
        if (this.currencyCount > 0) {
          /* [VOD-1641] */
          this.currencyCount -= 1;
        }
        this.deleteValueFromArr(
          this.currencyId,
          searchIds,
          this.currencyDetails
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "referenceName") {
      if (status === true) {
        if (this.masterAgreementNameId.includes(searchIds)) {
          this.masterAgreementNameId = this.masterAgreementNameId;
        } else {
          this.masterAgreementNameId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.masterAgreementNameId = this.masterAgreementNameId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "supplierName") {
      if (status === true) {
        if (this.supplierNameId.includes(searchIds)) {
          this.supplierNameId = this.supplierNameId;
        } else {
          if (typeof searchIds === 'object') {
            this.supplierNameId.push(searchIds[0]);
          } else {
            this.supplierNameId.push(searchIds);
          }
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.supplierNameId = this.supplierNameId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "opcoName") {
      if (status === true) {
        if (this.opcoNameId.includes(searchIds)) {
          this.opcoNameId = this.opcoNameId;
        } else {
          this.opcoNameId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.opcoNameId = this.opcoNameId.filter((data) => data !== searchIds);
      }
    } else if (type === "tradingCurrencyKey") {
      if (status === true) {
        if (this.tradingCurrencyKeyId.includes(searchIds)) {
          this.tradingCurrencyKeyId = this.tradingCurrencyKeyId;
        } else {
          this.tradingCurrencyKeyId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.tradingCurrencyKeyId = this.tradingCurrencyKeyId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "validFrom" || type === "validFromDate") {
      if (status === true) {
        if (this.validFromDateId.includes(searchIds)) {
          this.validFromDateId = this.validFromDateId;
        } else {
          this.validFromDateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.validFromDateId = [];
      }
    } else if (type === "validTo" || type === "validToDate") {
      if (status === true) {
        if (this.validToDateId.includes(searchIds)) {
          this.validToDateId = this.validToDateId;
        } else {
          this.validToDateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.validToDateId = [];
      }
    } else if (type === "secondaryStartDate") {
      if (status === true) {
        if (this.secondaryStartDateId.includes(searchIds)) {
          this.secondaryStartDateId = this.secondaryStartDateId;
        } else {
          this.secondaryStartDateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.secondaryStartDateId = [];
      }
    } else if (type === "secondaryEndDate") {
      if (status === true) {
        if (this.secondaryEndDateId.includes(searchIds)) {
          this.secondaryEndDateId = this.secondaryEndDateId;
        } else {
          this.secondaryEndDateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.secondaryEndDateId = [];
      }
    } else if (type === "vpcOwner") {
      if (status === true) {
        if (this.vpcOwnerId.includes(searchIds)) {
          this.vpcOwnerId = this.vpcOwnerId;
        } else {
          this.vpcOwnerId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.vpcOwnerId = this.vpcOwnerId.filter((data) => data !== searchIds);
      }
    } else if (type === "vodafoneApprover") {
      if (status === true) {
        if (this.vodafoneApproverId.includes(searchIds)) {
          this.vodafoneApproverId = this.vodafoneApproverId;
        } else {
          this.vodafoneApproverId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.vodafoneApproverId = this.vodafoneApproverId.filter((data) => data !== searchIds);
      }
    } else if (type === "supplierOwner") {
      if (status === true) {
        if (this.supplierOwnerId.includes(searchIds)) {
          this.supplierOwnerId = this.supplierOwnerId;
        } else {
          this.supplierOwnerId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.supplierOwnerId = this.supplierOwnerId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "createdAt") {
      if (status === true) {
        if (this.createdAtId.includes(searchIds)) {
          this.createdAtId = this.createdAtId;
        } else {
          this.createdAtId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.createdAtId = [];
      }
    } else if (type === "createdBy") {
      if (status === true) {
        if (this.createdById.includes(searchIds)) {
          this.createdById = this.createdById;
        } else {
          this.createdById.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.createdById = this.createdById.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "companyCode") {
      if (status === true) {
        if (this.companyCodeId.includes(searchIds)) {
          this.companyCodeId = this.companyCodeId;
        } else {
          this.companyCodeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.companyCodeId = this.companyCodeId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "manufacturer") {
      if (status === true) {
        if (this.manufacturerId.includes(searchIds)) {
          this.manufacturerId = this.manufacturerId;
        } else {
          this.manufacturerId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.manufacturerId = this.manufacturerId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "materialSupplier") {
      if (status === true) {
        if (this.materialSupplierId.includes(searchIds)) {
          this.materialSupplierId = this.materialSupplierId;
        } else {
          this.materialSupplierId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialSupplierId = this.materialSupplierId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "inProgressMpnSpn") {
      if (status === true) {
        if (this.inProgressMpnSpnId.includes(searchIds)) {
          this.inProgressMpnSpnId = this.inProgressMpnSpnId;
        } else {
          this.inProgressMpnSpnId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.inProgressMpnSpnId = this.inProgressMpnSpnId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "completeMpnSpn") {
      if (status === true) {
        if (this.completeMpnSpnId.includes(searchIds)) {
          this.completeMpnSpnId = this.completeMpnSpnId;
        } else {
          this.completeMpnSpnId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.completeMpnSpnId = this.completeMpnSpnId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "completeSAPMatrilId") {
      if (status === true) {
        if (this.completeSAPMatrilIdId.includes(searchIds)) {
          this.completeSAPMatrilIdId = this.completeSAPMatrilIdId;
        } else {
          this.completeSAPMatrilIdId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.completeSAPMatrilIdId = this.completeSAPMatrilIdId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "inProgressSAPMatrilId") {
      if (status === true) {
        if (this.inProgressSAPMatrilIdId.includes(searchIds)) {
          this.inProgressSAPMatrilIdId = this.inProgressSAPMatrilIdId;
        } else {
          this.inProgressSAPMatrilIdId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.inProgressSAPMatrilIdId = this.inProgressSAPMatrilIdId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "updatedAt") {
      if (status === true) {
        if (this.updatedAtId.includes(searchIds)) {
          this.updatedAtId = this.updatedAtId;
        } else {
          this.updatedAtId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.updatedAtId = [];
      }
    } else if (type === "updatedBy") {
      if (status === true) {
        if (this.updatedById.includes(searchIds)) {
          this.updatedById = this.updatedById;
        } else {
          this.updatedById.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.updatedById = this.updatedById.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "isVisible") {
      if (status === true) {
        if (this.isVisibleId.includes(searchIds)) {
          this.isVisibleId = this.isVisibleId;
        } else {
          this.isVisibleId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.isVisibleId = this.isVisibleId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "isSupplierOnboarded") {
      if (status === true) {
        if (this.isSupplierOnboardedId.includes(searchIds)) {
          this.isSupplierOnboardedId = this.isSupplierOnboardedId;
        } else {
          this.isSupplierOnboardedId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.isSupplierOnboardedId = this.isSupplierOnboardedId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "paStatusFilter") {
      if (status === true) {
        if (this.paStatusFilterId.includes(searchIds)) {
          this.paStatusFilterId = this.paStatusFilterId;
        } else {
          this.paStatusFilterId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.paStatusFilterId = this.paStatusFilterId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "lineItemStatusFilter") {
      if (status === true) {
        if (this.lineItemStatusFilterId.includes(searchIds)) {
          this.lineItemStatusFilterId = this.lineItemStatusFilterId;
        } else {
          this.lineItemStatusFilterId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.lineItemStatusFilterId = this.lineItemStatusFilterId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "integrationStatusFilter") {
      if (status === true) {
        if (this.integrationStatusFilterId.includes(searchIds)) {
          this.integrationStatusFilterId = this.integrationStatusFilterId;
        } else {
          this.integrationStatusFilterId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.integrationStatusFilterId = this.integrationStatusFilterId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "lastIntegratedVersion") {
      if (status === true) {
        if (this.lastIntegratedVersionId.includes(searchIds)) {
          this.lastIntegratedVersionId = this.lastIntegratedVersionId;
        } else {
          this.lastIntegratedVersionId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.lastIntegratedVersionId = this.lastIntegratedVersionId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "supplierId") {
      if (status === true) {
        if (this.supplierIdVal.includes(searchIds)) {
          this.supplierIdVal = this.supplierIdVal;
        } else {
          this.supplierIdVal.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.supplierIdVal = this.supplierIdVal.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "outlineAgreementNumber") {
      if (status === true) {
        if (this.outlineAgreementId.includes(searchIds)) {
          this.outlineAgreementId = this.outlineAgreementId;
        } else {
          this.outlineAgreementId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.outlineAgreementId = this.outlineAgreementId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "projectIdColPa") {
      if (status === true) {
        if (this.projectIdColPa.includes(searchIds)) {
          this.projectIdColPa = this.projectIdColPa;
        } else {
          this.projectIdColPa.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.projectIdColPa = this.projectIdColPa.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "companyCodeColPa") {
      if (status === true) {
        if (this.companyCodeColPa.includes(searchIds)) {
          this.companyCodeColPa = this.companyCodeColPa;
        } else {
          this.companyCodeColPa.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.companyCodeColPa = this.companyCodeColPa.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "contractId") {
      if (status === true) {
        if (this.contractId.includes(searchIds)) {
          this.contractId = this.contractId;
        } else {
          this.contractId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.contractId = this.contractId.filter((data) => data !== searchIds);
      }
    } else if (type === "olaId") {
      if (status === true) {
        if (this.outlineAgreementId.includes(searchIds)) {
          this.outlineAgreementId = this.outlineAgreementId;
        } else {
          this.outlineAgreementId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.outlineAgreementId = this.outlineAgreementId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "catalogueIdentifier") {
      if (status === true) {
        if (this.catalogueIdentifier.includes(searchIds)) {
          this.catalogueIdentifier = this.catalogueIdentifier;
        } else {
          this.catalogueIdentifier.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.catalogueIdentifier = this.catalogueIdentifier.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "catalogueName") {
      if (status === true) {
        if (this.catalogueName.includes(searchIds)) {
          this.catalogueName = this.catalogueName;
        } else {
          this.catalogueName.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.catalogueName = this.catalogueName.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "delegatedByUser") {
      if (status === true) {
        if (this.primaryDelegatorId.includes(searchIds)) {
          this.primaryDelegatorId = this.primaryDelegatorId;
        } else {
          this.primaryDelegatorId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.primaryDelegatorId = this.primaryDelegatorId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "delegatedToUser") {
      if (status === true) {
        if (this.primaryDelegateId.includes(searchIds)) {
          this.primaryDelegateId = this.primaryDelegateId;
        } else {
          this.primaryDelegateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.primaryDelegateId = this.primaryDelegateId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "secondaryDelegatedBy") {
      if (status === true) {
        if (this.secondaryDelegatorId.includes(searchIds)) {
          this.secondaryDelegatorId = this.secondaryDelegatorId;
        } else {
          this.secondaryDelegatorId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.secondaryDelegatorId = this.secondaryDelegatorId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "secondaryDelegatedTo") {
      if (status === true) {
        if (this.secondaryDelegateId.includes(searchIds)) {
          this.secondaryDelegateId = this.secondaryDelegateId;
        } else {
          this.secondaryDelegateId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.secondaryDelegateId = this.secondaryDelegateId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "delegationType") {
      if (status === true) {
        if (this.delegationTypeId.includes(searchIds)) {
          this.delegationTypeId = this.delegationTypeId;
        } else {
          this.delegationTypeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.delegationTypeId = this.delegationTypeId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "primaryDelegatedFrom") {
      if (status === true) {
        if (this.primaryDelegatedFromId.includes(searchIds)) {
          this.primaryDelegatedFromId = this.primaryDelegatedFromId;
        } else {
          this.primaryDelegatedFromId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.primaryDelegatedFromId = this.primaryDelegatedFromId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "secondaryDelegatedFrom") {
      if (status === true) {
        if (this.secondaryDelegatedFromId.includes(searchIds)) {
          this.secondaryDelegatedFromId = this.secondaryDelegatedFromId;
        } else {
          this.secondaryDelegatedFromId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.secondaryDelegatedFromId = this.secondaryDelegatedFromId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "materialCategory") {
      if (status === true) {
        if (this.materialCategoryId.includes(searchIds)) {
          this.materialCategoryId = this.materialCategoryId;
        } else {
          this.materialCategoryId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialCategoryId = this.materialCategoryId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "paymentTerms") {
      if (status === true) {
        if (this.paymentTermsId.includes(searchIds)) {
          this.paymentTermsId = this.paymentTermsId;
        } else {
          this.paymentTermsId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.paymentTermsId = this.paymentTermsId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "contractIdId") {
      if (status === true) {
        if (this.contractIdId.includes(searchIds)) {
          this.contractIdId = this.paymentTermsId;
        } else {
          this.contractIdId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.contractIdId = this.contractIdId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "itemStatus") {
      if (status === true) {
        if (this.itemStatusId.includes(searchIds)) {
          //   this.manufacturerCount = this.manufacturerCount;
          this.itemStatusId = this.itemStatusId;
        } else {
          //   this.manufacturerCount += 1;
          this.itemStatusId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.itemStatusId = this.itemStatusId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "sapMaterialId") {
      if (status === true) {
        if (this.sapMaterialId.includes(searchIds)) {
          //   this.manufacturerCount = this.manufacturerCount;
          this.sapMaterialId = this.sapMaterialId;
        } else {
          //   this.manufacturerCount += 1;
          this.sapMaterialId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.sapMaterialId = this.sapMaterialId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "materialCategory") {
      if (status === true) {
        if (this.materialCategoryId.includes(searchIds)) {
          //this.manufacturerCount = this.manufacturerCount;
          this.materialCategoryId = this.materialCategoryId;
        } else {
          //this.manufacturerCount += 1;
          this.materialCategoryId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialCategoryId = this.materialCategoryId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "manufacturerPartNumber") {
      if (status === true) {
        if (this.manufacturerPartNumberId.includes(searchIds)) {
          //this.manufacturerCount = this.manufacturerCount;
          this.manufacturerPartNumberId = this.manufacturerPartNumberId;
        } else {
          //this.manufacturerCount += 1;
          this.manufacturerPartNumberId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.manufacturerPartNumberId = this.manufacturerPartNumberId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "supplierPartNumber") {
      if (status === true) {
        if (this.supplierPartNumberId.includes(searchIds)) {
          //this.manufacturerCount = this.manufacturerCount;
          this.supplierPartNumberId = this.supplierPartNumberId;
        } else {
          // this.manufacturerCount += 1;
          this.supplierPartNumberId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.supplierPartNumberId = this.supplierPartNumberId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "materialDescription") {
      if (status === true) {
        if (this.materialDescriptionId.includes(searchIds)) {
          //this.manufacturerCount = this.manufacturerCount;
          this.materialDescriptionId = this.materialDescriptionId;
        } else {
          //this.manufacturerCount += 1;
          this.materialDescriptionId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialDescriptionId = this.materialDescriptionId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "materialLongDescription") {
      if (status === true) {
        if (this.materialLongDescriptionId.includes(searchIds)) {
          //  this.manufacturerCount = this.manufacturerCount;
          this.materialLongDescriptionId = this.materialLongDescriptionId;
        } else {
          // this.manufacturerCount += 1;
          this.materialLongDescriptionId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialLongDescriptionId = this.materialLongDescriptionId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "oldMaterial") {
      if (status === true) {
        if (this.oldMaterialId.includes(searchIds)) {
          // this.manufacturerCount = this.manufacturerCount;
          this.oldMaterialId = this.oldMaterialId;
        } else {
          //  this.manufacturerCount += 1;
          this.oldMaterialId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.oldMaterialId = this.oldMaterialId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "buom") {
      if (status === true) {
        if (this.boumId.includes(searchIds)) {
          //   this.manufacturerCount = this.manufacturerCount;
          this.boumId = this.boumId;
        } else {
          //  this.manufacturerCount += 1;
          this.boumId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.boumId = this.boumId.filter((data) => data !== searchIds);
      }
    } else if (type === "materialGroup") {
      if (status === true) {
        if (this.materialGroupId.includes(searchIds)) {
          //   this.manufacturerCount = this.manufacturerCount;
          this.materialGroupId = this.materialGroupId;
        } else {
          //   this.manufacturerCount += 1;
          this.materialGroupId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.materialGroupId = this.materialGroupId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "commodityCode") {
      if (status === true) {
        if (this.commodityCodeId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.commodityCodeId = this.commodityCodeId;
        } else {
          //  this.manufacturerCount += 1;
          this.commodityCodeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.commodityCodeId = this.commodityCodeId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "grossWeight") {
      if (status === true) {
        if (this.grossWeightId.includes(searchIds)) {
          //     this.manufacturerCount = this.manufacturerCount;
          this.grossWeightId = this.grossWeightId;
        } else {
          //     this.manufacturerCount += 1;
          this.grossWeightId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.grossWeightId = this.grossWeightId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "netWeight") {
      if (status === true) {
        if (this.netWeightId.includes(searchIds)) {
          //   this.manufacturerCount = this.manufacturerCount;
          this.netWeightId = this.netWeightId;
        } else {
          //   this.manufacturerCount += 1;
          this.netWeightId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.netWeightId = this.netWeightId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "characteristicValue") {
      if (status === true) {
        if (this.characteristicValueId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.characteristicValueId = this.characteristicValueId;
        } else {
          //    this.manufacturerCount += 1;
          this.characteristicValueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.characteristicValueId = this.characteristicValueId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "subCharacteristicValue") {
      if (status === true) {
        if (this.subCharacteristicValueId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.subCharacteristicValueId = this.subCharacteristicValueId;
        } else {
          //    this.manufacturerCount += 1;
          this.subCharacteristicValueId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.subCharacteristicValueId = this.subCharacteristicValueId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "length") {
      if (status === true) {
        if (this.lengthId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.lengthId = this.lengthId;
        } else {
          //   this.manufacturerCount += 1;
          this.lengthId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.lengthId = this.lengthId.filter((data) => data !== searchIds);
      }
    } else if (type === "width") {
      if (status === true) {
        if (this.widthId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.widthId = this.widthId;
        } else {
          //    this.manufacturerCount += 1;
          this.widthId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.widthId = this.widthId.filter((data) => data !== searchIds);
      }
    } else if (type === "height") {
      if (status === true) {
        if (this.heightId.includes(searchIds)) {
          //      this.manufacturerCount = this.manufacturerCount;
          this.heightId = this.heightId;
        } else {
          //    this.manufacturerCount += 1;
          this.heightId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.heightId = this.heightId.filter((data) => data !== searchIds);
      }
    } else if (type === "hlwUnit") {
      if (status === true) {
        if (this.hlwUnitId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.hlwUnitId = this.hlwUnitId;
        } else {
          //   this.manufacturerCount += 1;
          this.hlwUnitId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.hlwUnitId = this.hlwUnitId.filter((data) => data !== searchIds);
      }
    } else if (type === "volume") {
      if (status === true) {
        if (this.volumeId.includes(searchIds)) {
          //     this.manufacturerCount = this.manufacturerCount;
          this.volumeId = this.volumeId;
        } else {
          //   this.manufacturerCount += 1;
          this.volumeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.volumeId = this.volumeId.filter((data) => data !== searchIds);
      }
    } else if (type === "volumeUnit") {
      if (status === true) {
        if (this.volumeUnitId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.volumeUnitId = this.volumeUnitId;
        } else {
          //    this.manufacturerCount += 1;
          this.volumeUnitId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.volumeUnitId = this.volumeUnitId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "nature") {
      if (status === true) {
        if (this.natureId.includes(searchIds)) {
          //     this.manufacturerCount = this.manufacturerCount;
          this.natureId = this.natureId;
        } else {
          //      this.manufacturerCount += 1;
          this.natureId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.natureId = this.natureId.filter((data) => data !== searchIds);
      }
    } else if (type === "serializationProfile") {
      if (status === true) {
        if (this.serializationId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.serializationId = this.serializationId;
        } else {
          //   this.manufacturerCount += 1;
          this.serializationId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.serializationId = this.serializationId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "usefulLife") {
      if (status === true) {
        if (this.usefulLifeId.includes(searchIds)) {
          //     this.manufacturerCount = this.manufacturerCount;
          this.usefulLifeId = this.usefulLifeId;
        } else {
          //       this.manufacturerCount += 1;
          this.usefulLifeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.usefulLifeId = this.usefulLifeId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "ean") {
      if (status === true) {
        if (this.eanId.includes(searchIds)) {
          //     this.manufacturerCount = this.manufacturerCount;
          this.eanId = this.eanId;
        } else {
          //    this.manufacturerCount += 1;
          this.eanId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.eanId = this.eanId.filter((data) => data !== searchIds);
      }
    } else if (type === "eccn") {
      if (status === true) {
        if (this.eccnId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.eccnId = this.eccnId;
        } else {
          //     this.manufacturerCount += 1;
          this.eccnId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.eccnId = this.eccnId.filter((data) => data !== searchIds);
      }
    } else if (type === "gam") {
      if (status === true) {
        if (this.gamId.includes(searchIds)) {
          //    this.manufacturerCount = this.manufacturerCount;
          this.gamId = this.gamId;
        } else {
          //     this.manufacturerCount += 1;
          this.gamId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.gamId = this.gamId.filter((data) => data !== searchIds);
      }
    } else if (type === "manufacturerCol") {
      if (status === true) {
        if (this.manufacturerColId.includes(searchIds)) {
          this.manufacturerColId = this.manufacturerColId;
        } else {
          this.manufacturerColId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.manufacturerColId = this.manufacturerColId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "auom1") {
      if (status === true) {
        if (this.auom1Id.includes(searchIds)) {
          this.auom1Id = this.auom1Id;
        } else {
          this.auom1Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auom1Id = this.auom1Id.filter((data) => data !== searchIds);
      }
    } else if (type === "unitConversion1") {
      if (status === true) {
        if (this.unitConversion1Id.includes(searchIds)) {
          this.unitConversion1Id = this.unitConversion1Id;
        } else {
          this.unitConversion1Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.unitConversion1Id = this.unitConversion1Id.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "auomQty1") {
      if (status === true) {
        if (this.auomqty1Id.includes(searchIds)) {
          this.auomqty1Id = this.auomqty1Id;
        } else {
          this.auomqty1Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auomqty1Id = this.auomqty1Id.filter((data) => data !== searchIds);
      }
    } else if (type === "auom2") {
      if (status === true) {
        if (this.auom2Id.includes(searchIds)) {
          this.auom2Id = this.auom2Id;
        } else {
          this.auom2Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auom2Id = this.auom2Id.filter((data) => data !== searchIds);
      }
    } else if (type === "unitConversion2") {
      if (status === true) {
        if (this.unitConversion2Id.includes(searchIds)) {
          this.unitConversion2Id = this.unitConversion2Id;
        } else {
          this.unitConversion2Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.unitConversion2Id = this.unitConversion2Id.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "auomQty2") {
      if (status === true) {
        if (this.auomqty2Id.includes(searchIds)) {
          this.auomqty2Id = this.auomqty2Id;
        } else {
          this.auomqty2Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auomqty2Id = this.auomqty2Id.filter((data) => data !== searchIds);
      }
    } else if (type === "auom3") {
      if (status === true) {
        if (this.auom3Id.includes(searchIds)) {
          this.auom3Id = this.auom3Id;
        } else {
          this.auom3Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auom3Id = this.auom3Id.filter((data) => data !== searchIds);
      }
    } else if (type === "unitConversion3") {
      if (status === true) {
        if (this.unitConversion3Id.includes(searchIds)) {
          this.unitConversion3Id = this.unitConversion3Id;
        } else {
          this.unitConversion3Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.unitConversion3Id = this.unitConversion3Id.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "auomQty3") {
      if (status === true) {
        if (this.auomqty3Id.includes(searchIds)) {
          this.auomqty3Id = this.auomqty3Id;
        } else {
          this.auomqty3Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.auomqty3Id = this.auomqty3Id.filter((data) => data !== searchIds);
      }
    } else if (type === "stackingNorms") {
      if (status === true) {
        if (this.stackingNormsId.includes(searchIds)) {
          this.stackingNormsId = this.stackingNormsId;
        } else {
          this.stackingNormsId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.stackingNormsId = this.stackingNormsId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "stackingNumber") {
      if (status === true) {
        if (this.stackingNumberId.includes(searchIds)) {
          this.stackingNumberId = this.stackingNumberId;
        } else {
          this.stackingNumberId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.stackingNumberId = this.stackingNumberId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "productFamilyCode") {
      if (status === true) {
        if (this.productFamilyId.includes(searchIds)) {
          this.productFamilyId = this.productFamilyId;
        } else {
          this.productFamilyId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.productFamilyId = this.productFamilyId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "physicalTypeCol") {
      if (status === true) {
        if (this.physicalTypeColId.includes(searchIds)) {
          this.physicalTypeColId = this.physicalTypeColId;
        } else {
          this.physicalTypeColId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.physicalTypeColId = this.physicalTypeColId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "endOfLife") {
      if (status === true) {
        if (this.endOfLifeId.includes(searchIds)) {
          this.endOfLifeId = this.endOfLifeId;
        } else {
          this.endOfLifeId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.endOfLifeId = this.endOfLifeId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "endOfSupport") {
      if (status === true) {
        if (this.endOfSupportId.includes(searchIds)) {
          this.endOfSupportId = this.endOfSupportId;
        } else {
          this.endOfSupportId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.endOfSupportId = this.endOfSupportId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "agreementStatus") {
      if (status === true) {
        if (this.agreementStatusId.includes(searchIds)) {
          this.agreementStatusId = this.agreementStatusId;
        } else {
          this.agreementStatusId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.agreementStatusId = this.agreementStatusId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "referenceName") {
      if (status === true) {
        if (this.referenceNameId.includes(searchIds)) {
          this.referenceNameId = this.referenceNameId;
        } else {
          this.referenceNameId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.referenceNameId = this.referenceNameId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "taskObject") {
      if (status === true) {
        if (this.taskObjectId.includes(searchIds)) {
          this.taskObjectId = this.taskObjectId;
        } else {
          this.taskObjectId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.taskObjectId = this.taskObjectId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "type") {
      if (status === true) {
        if (this.taskType_Id.includes(searchIds)) {
          this.taskType_Id = this.taskType_Id;
        } else {
          this.taskType_Id.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.taskType_Id = this.taskType_Id.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "taskGenerated") {
      if (status === true) {
        if (this.taskGeneratedId.includes(searchIds)) {
          this.taskGeneratedId = this.taskGeneratedId;
        } else {
          this.taskGeneratedId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.taskGeneratedId = this.taskGeneratedId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "taskSupplier") {
      if (status === true) {
        if (this.taskSupplierId.includes(searchIds)) {
          this.taskSupplierId = this.taskSupplierId;
        } else {
          this.taskSupplierId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.taskSupplierId = this.taskSupplierId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "taskOwner") {
      if (status === true) {
        if (this.taskOwnerId.includes(searchIds)) {
          this.taskOwnerId = this.taskOwnerId;
        } else {
          this.taskOwnerId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.taskOwnerId = this.taskOwnerId.filter(
          (data) => data !== searchIds
        );
      }
    }
    ///
    else if (type === "proProjectId") {
      if (status === true) {
        if (this.proProjectIdId.includes(searchIds)) {
          this.proProjectIdCount = this.proProjectIdCount;
          this.proProjectIdId = this.proProjectIdId;
        } else {
          this.proProjectIdCount += 1;
          this.proProjectIdId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.proProjectIdType);
        }
      } else {
        /* [VOD-1641] */
        if (this.proProjectIdCount > 0) {
          /* [VOD-1641] */
          this.proProjectIdCount -= 1;
        }
        this.deleteValueFromArr(
          this.proProjectIdId,
          searchIds,
          this.proProjectIdType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "proSupplierID") {
      if (status === true) {
        if (this.proSupplierIDId.includes(searchIds)) {
          this.proSupplierIDCount = this.proSupplierIDCount;
          this.proSupplierIDId = this.proSupplierIDId;
        } else {
          this.proSupplierIDCount += 1;
          this.proSupplierIDId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.proSupplierIDType);
        }
      } else {
        /* [VOD-1641] */
        if (this.proSupplierIDCount > 0) {
          /* [VOD-1641] */
          this.proSupplierIDCount -= 1;
        }
        this.deleteValueFromArr(
          this.proSupplierIDId,
          searchIds,
          this.proSupplierIDType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "proMGOLA") {
      if (status === true) {
        if (this.proMGOLAId.includes(searchIds)) {
          this.proMGOLACount = this.proMGOLACount;
          this.proMGOLAId = this.proMGOLAId;
        } else {
          this.proMGOLACount += 1;
          this.proMGOLAId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.proMGOLAType);
        }
      } else {
        /* [VOD-1641] */
        if (this.proMGOLACount > 0) {
          /* [VOD-1641] */
          this.proMGOLACount -= 1;
        }
        this.deleteValueFromArr(this.proMGOLAId, searchIds, this.proMGOLAType);
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "proProjectDescription") {
      if (status === true) {
        if (this.proProjectDescriptionId.includes(searchIds)) {
          this.proProjectDescriptionCount = this.proProjectDescriptionCount;
          this.proProjectDescriptionId = this.proProjectDescriptionId;
        } else {
          this.proProjectDescriptionCount += 1;
          this.proProjectDescriptionId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
          this.autoSelect(searchIds, this.proProjectDescriptionType);
        }
      } else {
        /* [VOD-1641] */
        if (this.proProjectDescriptionCount > 0) {
          /* [VOD-1641] */
          this.proProjectDescriptionCount -= 1;
        }
        this.deleteValueFromArr(
          this.proProjectDescriptionId,
          searchIds,
          this.proProjectDescriptionType
        );
        this.deleteFromDropDownObj(type, searchIds);
      }
    } else if (type === "projectIdCol") {
      if (status === true) {
        if (this.projectIdColId.includes(searchIds)) {
          this.projectIdColId = this.projectIdColId;
        } else {
          this.projectIdColId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.projectIdColId = this.projectIdColId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "mmola") {
      if (status === true) {
        if (this.mmolaColId.includes(searchIds)) {
          this.mmolaColId = this.mmolaColId;
        } else {
          this.mmolaColId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.mmolaColId = this.mmolaColId.filter(
          (data) => data !== searchIds
        );
      }
    } else if (type === "incoterms1") {
      if (status === true) {
        if (this.incotermsId.includes(searchIds)) {
          this.incotermsId = this.incotermsId;
        } else {
          this.incotermsId.push(searchIds);
          const obj = {
            type,
            value: searchIds,
            key: searchIds,
            filterType: filterType,
          };
          this.dropDownObjArray.push(obj);
        }
      } else {
        this.incotermsId = this.incotermsId.filter(
          (data) => data !== searchIds
        );
      }
    }
  }

  /**
   *Function called when filter selected in the dropdown
   *
   * @param {*} $event
   * @param {*} types
   * @memberof DropdownFilterComponent
   */
  public onButtonGroupClick($event, types) {
    const clickedElement = $event.target || $event.srcElement;
    const text = clickedElement.innerText;
    const searchIds = clickedElement.id;
    if (types === "catalogue") {
      const cType = this.catalogueType.find((obj) => obj.value === text);
      cType.selected = !cType.selected;
    }

    if (types === "trading") {
      const tradingM = this.tradingModels.find((trade) => trade.value === text);
      tradingM.selected = !tradingM.selected;
    }
    if (types === "supplier") {
      const supplierD = this.suppliersDetails.find(
        (supplier) =>
          supplier.value && supplier.value.trim() === text && text.trim()
      );
      supplierD.selected = !supplierD.selected;
    }
    if (types === "opco") {
      const data = text.split("-");
      const textSearch = data.slice(1).join("-").trim();
      // tslint:disable-next-line: restrict-plus-operands
      const opcoD = this.opcoDetails.find(
        (opco) => opco.key + "-" + opco.value === text
      );
      opcoD.selected = !opcoD.selected;
    }
    if (types === "material") {
      const data = text.split("-");
      const textSearch = data.slice(1).join("-").trim();
      const materialD = this.materialGroups.find(
        (material) => material.value === textSearch
      );
      materialD.selected = !materialD.selected;
    }
    if (types === "paStatus") {
      const paStatusD = this.paStatusDetail.find(
        (paStatus) => paStatus.value === text
      );
      paStatusD.selected = !paStatusD.selected;
    }
    if (types === "projectId") {
      const projectIdD = this.projectIdDetails.find(
        (projectIdData) => projectIdData.value === text
      );
      projectIdD.selected = !projectIdD.selected;
    }
    if (types === "integrationStatus") {
      const itemStatusD = this.paStatusDetail.find(
        (itemStatus) => itemStatus.value === text
      );
      itemStatusD.selected = !itemStatusD.selected;
    }
    if (types === "catalogueStatus") {
      const catalogueStatusD = this.paStatusDetail.find(
        (catalogueStatus) => catalogueStatus.value === text
      );
      catalogueStatusD.selected = !catalogueStatusD.selected;
    }
    if (types === "taskType") {
      const taskTypeD = this.taskTypeDetail.find(
        (taskType) => taskType.value === text.replace(" ", "_")
      );
      taskTypeD.selected = !taskTypeD.selected;
    }
    if (types === "taskStatus") {
      const taskStatusD = this.taskStatusDetail.find(
        (taskStatus) => taskStatus.value === text
      );
      taskStatusD.selected = !taskStatusD.selected;
    }
    if (types === "parentSupplier") {
      const pSupplierD = this.parentSupplierDetails.find(
        (parentsupplier) => parentsupplier.value === text
      );
      pSupplierD.selected = !pSupplierD.selected;
    }
    if (types === "l4Group") {
      const data = text.split("-");
      const textSearch = data.slice(1).join("-").trim();
      const materialD = this.materialGroups.find(
        (material) => material.value === textSearch
      );
      materialD.selected = !materialD.selected;
    }
    if (types === "l1Category") {
      const l1CategoryType = this.catalogueType.find(
        (obj) => obj.value === text
      );
      l1CategoryType.selected = !l1CategoryType.selected;
    }
    if (types === "currency") {
      const currencyD = this.currencyDetails.find((obj) => obj.value === text);
      currencyD.selected = !currencyD.selected;
    }
    if (types === "amount") {
      const amountD = this.amountArr.find((obj) => obj.display === text);
      amountD.selected = !amountD.selected;
    }
    if (types === "createdBy") {
      const createdByD = this.createdByType.find((obj) => obj.value === text);
      createdByD.selected = !createdByD.selected;
    }
    if (types === "companyCode") {
      const companyCodeD = this.companyCodeType.find(
        (obj) => obj.value === text
      );
      companyCodeD.selected = !companyCodeD.selected;
    }
    if (types === "manufacturer") {
      const manufacturerD = this.manufacturerType.find(
        (obj) => obj.value === text
      );
      manufacturerD.selected = !manufacturerD.selected;
    }
    if (types === "materialSupplier") {
      const materialSupplierD = this.materialSupplierType.find(
        (obj) => obj.value === text
      );
      materialSupplierD.selected = !materialSupplierD.selected;
    }
    if (types === "inProgressMpnSpn") {
      const inProgressMpnSpnD = this.inProgressMpnSpnType.find(
        (obj) => obj.value === text
      );
      inProgressMpnSpnD.selected = !inProgressMpnSpnD.selected;
    }
    if (types === "completeMpnSpn") {
      const completeMpnSpnD = this.completeMpnSpnType.find(
        (obj) => obj.value === text
      );
      completeMpnSpnD.selected = !completeMpnSpnD.selected;
    }
    if (types === "completeSAPMatrilId") {
      const completeSAPMatrilIdD = this.completeSAPMatrilIdType.find(
        (obj) => obj.value === text
      );
      completeSAPMatrilIdD.selected = !completeSAPMatrilIdD.selected;
    }
    if (types === "inProgressSAPMatrilId") {
      const inProgressSAPMatrilIdD = this.inProgressSAPMatrilIdType.find(
        (obj) => obj.value === text
      );
      inProgressSAPMatrilIdD.selected = !inProgressSAPMatrilIdD.selected;
    }
    if (types === "physicalStatus") {
      const PhysicalID = this.physicalNonphysicalArr.find(
        (obj) => obj.display === text
      );
      PhysicalID.selected = !PhysicalID.selected;
    }
    if (types === "proProjectId") {
      const proProjectIdD = this.proProjectIdType.find(
        (obj) => obj.value === text
      );
      proProjectIdD.selected = !proProjectIdD.selected;
    }
    if (types === "proSupplierID") {
      const proSupplierIDD = this.proSupplierIDType.find(
        (obj) => obj.value === text
      );
      proSupplierIDD.selected = !proSupplierIDD.selected;
    }
    if (types === "proMGOLA") {
      const proMGOLADD = this.proMGOLAType.find(
        (obj) => String(obj.value) === text
      );
      proMGOLADD.selected = !proMGOLADD.selected;
    }
    if (types === "proProjectDescription") {
      const proProjectDescriptionD = this.proProjectDescriptionType.find(
        (obj) => obj.value === text
      );
      proProjectDescriptionD.selected = !proProjectDescriptionD.selected;
    }
    if (types === "olaId") {
      const olaDtls = this.olaDetails.find(
        (olaData) => olaData.value === text
      );
      olaDtls.selected = !olaDtls.selected;
    }
    // tslint:disable-next-line:max-line-length
    if (
      ((clickedElement && clickedElement.className === "active") ||
        clickedElement.className === "active ng-star-inserted") &&
      types !== "date"
    ) {
      clickedElement.classList.remove("active");
      clickedElement.classList.remove("ng-star-inserted");
      clickedElement.value = "";
      this.GetOutputVal(types, false, searchIds);
      if (types === "parentSupplier") {
        this.supplierDetails(searchIds, "remove");
      }
    } else if (clickedElement) {
      clickedElement.className = "active";
      clickedElement.value = searchIds;
      this.GetOutputVal(types, true, searchIds);
      if (types === "parentSupplier") {
        this.supplierDetails(searchIds, "add");
      }
    }
    // tslint:disable-next-line:max-line-length
    this.OutputToParent.emit({
      type: types,
      filterName: text,
      suplierId: this.supplierId,
      opcoId: this.opcoId,
      catalogueId: this.catalogueId,
      amountId: this.amountId,
      materialId: this.materialId,
      tradingId: this.tradingId,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      startDate: this.startDate,
      endDate: this.endDate,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      monthID: this.monthId,
      /* VP3-3 start*/
      physicalNonphysicalId: this.physicalNonphysicalId,
      masterAgreementNameId: this.masterAgreementNameId,
      masterAgreementNameType: this.masterAgreementNameType,
      primaryDelegatorId: this.primaryDelegatorId,
      primaryDelegatorType: this.primaryDelegatorType,
      primaryDelegateId: this.primaryDelegateId,
      primaryDelegateType: this.primaryDelegateType,
      secondaryDelegatorId: this.secondaryDelegatorId,
      secondaryDelegatorType: this.secondaryDelegatorType,
      secondaryDelegateId: this.secondaryDelegateId,
      secondaryDelegateType: this.secondaryDelegateType,
      supplierNameId: this.supplierNameId,
      supplierNameType: this.supplierNameType,
      projectIdColPa: this.projectIdColPa,
      projectIdColPaType: this.projectIdColPaType,
      companyCodeColPa: this.companyCodeColPa,
      companyCodeColPaType: this.companyCodeColPaType,
      opcoNameId: this.opcoNameId,
      opcoNameType: this.opcoNameType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      validFromDateId: this.validFromDateId,
      validFromDateType: this.validFromDateType,
      validToDateId: this.validToDateId,
      validToDateType: this.validToDateType,
      secondaryStartDateId: this.secondaryStartDateId,
      secondaryStartDateType: this.secondaryStartDateType,
      secondaryEndDateId: this.secondaryEndDateId,
      secondaryEndDateType: this.secondaryEndDateType,
      vpcOwnerId: this.vpcOwnerId,
      vodafoneApproverId: this.vodafoneApproverId,
      vodafoneApproverType: this.vodafoneApproverType,
      vpcOwnerType: this.vpcOwnerType,
      materialCategoryId: this.materialCategoryId,
      materialCategoryType: this.materialCategoryType,
      paymentTermsId: this.paymentTermsId,
      paymentTermsType: this.paymentTermsType,
      contractIdId: this.contractIdId,
      contractIdType: this.contractIdType,
      supplierOwnerId: this.supplierOwnerId,
      supplierOwnerType: this.supplierOwnerType,
      createdAtId: this.createdAtId,
      createdAtType: this.createdAtType,
      createdById: this.createdById,
      createdByType: this.createdByType,
      companyCodeId: this.companyCodeId,
      companyCodeType: this.companyCodeType,
      manufacturerId: this.manufacturerId,
      manufacturerType: this.manufacturerType,
      materialSupplierId: this.materialSupplierId,
      materialSupplierType: this.materialSupplierType,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      inProgressMpnSpnType: this.inProgressMpnSpnType,
      completeMpnSpnId: this.completeMpnSpnId,
      completeMpnSpnType: this.completeMpnSpnType,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      completeSAPMatrilIdType: this.completeSAPMatrilIdType,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      inProgressSAPMatrilIdType: this.inProgressSAPMatrilIdType,
      updatedAtId: this.updatedAtId,
      updatedAtType: this.updatedAtType,
      updatedById: this.updatedById,
      updatedByType: this.updatedByType,
      isVisibleId: this.isVisibleId,
      isVisibleType: this.isVisibleType,
      isSupplierOnboardedId: this.isSupplierOnboardedId,
      isSupplierOnboardedType: this.isSupplierOnboardedType,
      paStatusFilterId: this.paStatusFilterId,
      paStatusFilterType: this.paStatusFilterType,
      lineItemStatusFilterId: this.lineItemStatusFilterId,
      lineItemStatusFilterType: this.lineItemStatusFilterType,
      integrationStatusFilterId: this.integrationStatusFilterId,
      integrationStatusFilterType: this.integrationStatusFilterType,
      lastIntegratedVersionFilterType: this.lastIntegratedVersionFilterType,
      lastIntegratedVersionId: this.lastIntegratedVersionId,
      outlineAgreementId: this.outlineAgreementId,
      outlineAgreementNumberType: this.outlineAgreementNumberType,
      supplierIdVal: this.supplierIdVal,
      supplierIdType: this.supplierIdType,
      catalogueName: this.catalogueName,
      catalogueNameFilterType: this.catalogueNameFilterType,
      catalogueIdentifier: this.catalogueIdentifier,
      catalogueIdentifierFilterType: this.catalogueIdentifierFilterType,
      itemStatusId: this.itemStatusId,
      sapMaterialId: this.sapMaterialId,
      supplierPartNumberId: this.supplierPartNumberId,
      manufacturerPartNumberId: this.manufacturerPartNumberId,
      materialDescriptionId: this.materialDescriptionId,
      materialLongDescriptionId: this.materialLongDescriptionId,
      oldMaterialId: this.oldMaterialId,
      boumId: this.boumId,
      materialGroupId: this.materialGroupId,
      commodityCodeId: this.commodityCodeId,
      grossWeightId: this.grossWeightId,
      netWeightId: this.netWeightId,
      characteristicValueId: this.characteristicValueId,
      subCharacteristicValueId: this.subCharacteristicValueId,
      lengthId: this.lengthId,
      widthId: this.widthId,
      heightId: this.heightId,
      hlwUnitId: this.hlwUnitId,
      volumeId: this.volumeId,
      volumeUnitId: this.volumeUnitId,
      natureId: this.natureId,
      serializationId: this.serializationId,
      usefulLifeId: this.usefulLifeId,
      eanId: this.eanId,
      eccnId: this.eccnId,
      gamId: this.gamId,
      manufacturerColId: this.manufacturerColId,
      auom1Id: this.auom1Id,
      unitConversion1Id: this.unitConversion1Id,
      auomqty1Id: this.auomqty1Id,
      auom2Id: this.auom2Id,
      unitConversion2Id: this.unitConversion2Id,
      auomqty2Id: this.auomqty2Id,
      auom3Id: this.auom3Id,
      unitConversion3Id: this.unitConversion3Id,
      auomqty3Id: this.auomqty3Id,
      stackingNormsId: this.stackingNormsId,
      stackingNumberId: this.stackingNumberId,
      productFamilyId: this.productFamilyId,
      physicalTypeColId: this.physicalTypeColId,
      endOfSupportId: this.endOfSupportId,
      endOfLifeId: this.endOfLifeId,
      projectId: this.projectIds,
      projectIdCol: this.projectIdCol,
      projectIdStatusId: this.projectIdStatusId,
      agreementStatusId: this.agreementStatusId,
      referenceNameId: this.referenceNameId,
      taskObjectId: this.taskObjectId,
      taskType_Id: this.taskType_Id,
      taskGeneratedId: this.taskGeneratedId,
      taskSupplierId: this.taskSupplierId,
      taskOwnerId: this.taskOwnerId,
      proProjectIdId: this.proProjectIdId,
      projectIdType: this.projectIdType,
      proProjectIdType: this.proProjectIdType,
      proSupplierIDId: this.proSupplierIDId,
      proSupplierIDType: this.proSupplierIDType,
      proMGOLAId: this.proMGOLAId,
      proMGOLAType: this.proMGOLAType,
      proProjectDescriptionId: this.proProjectDescriptionId,
      proProjectDescriptionType: this.proProjectDescriptionType,
      projectIdColId: this.projectIdColId,
      olaIds: this.olaIds,
      olaType: this.olaType
      /* VP3-3 end*/
    });
  }

  /**
   *Function to create filter objects for Table level filters
   *
   * @param {*} filterName
   * @param {*} type
   * @param {*} value
   * @memberof DropdownFilterComponent
   */
  public async fromTableFilter(filterName, type, value) {
    if (filterName === "referenceName") {
      this.masterAgreementNameType = type;
      this.masterAgreementNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "referenceName"
      ); //removeing previous filter value
    } else if (filterName === "supplierName") {
      this.supplierNameType = type;
      this.supplierNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "supplierName"
      );
    } else if (filterName === "delegatedByUser") {
      this.primaryDelegatorType = type;
      this.primaryDelegatorId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "delegatedByUser"
      );
    } else if (filterName === "delegatedToUser") {
      this.primaryDelegateType = type;
      this.primaryDelegateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "delegatedToUser"
      );
    } else if (filterName === "delegationType") {
      this.delegationTypeType = type;
      this.delegationTypeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "delegationType"
      );
    } else if (filterName === "primaryDelegatedFrom") {
      this.primaryDelegatedFromType = type;
      this.primaryDelegatedFromId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "primaryDelegatedFrom"
      );
    } else if (filterName === "secondaryDelegatedFrom") {
      this.secondaryDelegatedFromType = type;
      this.secondaryDelegatedFromId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "secondaryDelegatedFrom"
      );
    } else if (filterName === "secondaryDelegatedBy") {
      this.secondaryDelegatorType = type;
      this.secondaryDelegatorId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "secondaryDelegatedBy"
      );
    } else if (filterName === "secondaryDelegatedTo") {
      this.secondaryDelegateType = type;
      this.secondaryDelegateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "secondaryDelegatedTo"
      );
    } else if (filterName === "opcoName") {
      this.opcoNameType = type;
      this.opcoNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "opcoName"
      );
    } else if (filterName === "tradingCurrencyKey") {
      this.tradingCurrencyKeyType = type;
      this.tradingCurrencyKeyId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "tradingCurrencyKey"
      );
    } else if (filterName === "validFrom") {
      this.validFromDateType = type;
      this.validFromDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "validFrom"
      );
    } else if (filterName === "validTo") {
      this.validToDateType = type;
      this.validToDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "validTo"
      );
    } else if (filterName === "secondaryStartDate") {
      this.secondaryStartDateType = type;
      this.secondaryStartDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "secondaryStartDate"
      );
    } else if (filterName === "secondaryEndDate") {
      this.secondaryEndDateType = type;
      this.secondaryEndDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "secondaryEndDate"
      );
    } else if (filterName === "vpcOwner") {
      this.vpcOwnerType = type;
      this.vpcOwnerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "vpcOwner"
      );

    } else if (filterName === "vodafoneApprover") {
      this.vodafoneApproverType = type;
      this.vodafoneApproverId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "vodafoneApprover"
      );
    } else if (filterName === "supplierOwner") {
      this.supplierOwnerType = type;
      this.supplierOwnerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "supplierOwner"
      );
    } else if (filterName === "createdAt") {
      this.createdAtType = type;
      this.createdAtId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "createdAt"
      );
    } else if (filterName === "createdBy") {
      this.createdByType = type;
      this.createdById = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "createdBy"
      );
    } else if (filterName === "companyCode") {
      this.companyCodeType = type;
      this.companyCodeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "companyCode"
      );
    } else if (filterName === "manufacturer") {
      this.manufacturerType = type;
      this.manufacturerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "manufacturer"
      );
    } else if (filterName === "materialSupplier") {
      this.materialSupplierType = type;
      this.materialSupplierId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialSupplier"
      );
    } else if (filterName === "inProgressMpnSpn") {
      this.inProgressMpnSpnType = type;
      this.inProgressMpnSpnId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "inProgressMpnSpn"
      );
    } else if (filterName === "completeMpnSpn") {
      this.completeMpnSpnType = type;
      this.completeMpnSpnId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "completeMpnSpn"
      );
    } else if (filterName === "completeSAPMatrilId") {
      this.completeSAPMatrilIdType = type;
      this.completeSAPMatrilIdId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "completeSAPMatrilId"
      );
    } else if (filterName === "inProgressSAPMatrilId") {
      this.inProgressSAPMatrilIdType = type;
      this.inProgressSAPMatrilIdId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "inProgressSAPMatrilId"
      );
    } else if (filterName === "updatedAt") {
      this.updatedAtType = type;
      this.updatedAtId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "updatedAt"
      );
    } else if (filterName === "updatedBy") {
      this.updatedByType = type;
      this.updatedById = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "updatedBy"
      );
    } else if (filterName === "isVisible") {
      this.isVisibleType = type;
      this.isVisibleId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "isVisible"
      );
    } else if (filterName === "isSupplierOnboarded") {
      this.isSupplierOnboardedType = type;
      this.isSupplierOnboardedId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "isSupplierOnboarded"
      );
    } else if (filterName === "paStatusFilter") {
      this.paStatusFilterType = type;
      this.paStatusFilterId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "paStatusFilter"
      );
    } else if (filterName === "projectIdStatusFilter") {
      this.projectIdStatusFilterType = type;
      this.projectIdStatusFilterId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectIdStatusFilter"
      );
    } else if (filterName === "lineItemStatusFilter") {
      this.lineItemStatusFilterType = type;
      this.lineItemStatusFilterId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "lineItemStatusFilter"
      );
    } else if (filterName === "integrationStatusFilter") {
      this.integrationStatusFilterType = type;
      this.integrationStatusFilterId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "integrationStatusFilter"
      );
    } else if (filterName === "lastIntegratedVersion") {
      this.lastIntegratedVersionFilterType = type;
      this.lastIntegratedVersionId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "lastIntegratedVersion"
      );
    } else if (filterName === "outlineAgreementNumber") {
      this.outlineAgreementNumberType = type;
      this.outlineAgreementId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "outlineAgreementNumber"
      );
    } else if (filterName === "projectIdColPa") {
      this.projectIdColPaType = type;
      this.projectIdColPa = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectIdColPa"
      );
    } else if (filterName === "companyCodeColPa") {
      this.companyCodeColPaType = type;
      this.companyCodeColPa = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "companyCodeColPa"
      );
    }
    else if (filterName === "supplierId") {
      this.supplierIdType = type;
      this.supplierIdVal = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "supplierId"
      );
    } else if (filterName === "catalogueIdentifier") {
      this.catalogueIdentifierFilterType = type;
      this.catalogueIdentifier = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "catalogueIdentifier"
      );
    } else if (filterName === "catalogueName") {
      this.catalogueNameFilterType = type;
      this.catalogueName = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "catalogueName"
      );
    } else if (filterName === "trading") {
      this.tradingId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "trading"
      );
    } else if (filterName === "materialCategory") {
      this.materialCategoryId = [];
      this.materialCategoryType = type;
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialCategory"
      );
    } else if (filterName === "paymentTerms") {
      this.paymentTermsId = [];
      this.paymentTermsType = type;
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "paymentTerms"
      );
    } else if (filterName === "contractIdId") {
      this.contractIdId = [];
      this.contractIdType = type;
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "contractIdId"
      );
    } else if (filterName === "projectId") {
      this.projectIdType = type;
      this.projectIds = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectId"
      );
    } else if (filterName === "projectIdCol") {
      this.projectIdColType = type;
      this.projectIdCol = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectIdCol"
      );
    } else if (filterName === "projectDescription") {
      this.projectDescriptionType = type;
      this.projectDescriptionCol = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectDescription"
      );
    } else if (filterName === "projectOwner") {
      this.projectOwnerType = type;
      this.projectOwnerCol = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectOwner"
      );
    } else if (filterName === "referenceName") {
      this.referenceNameType = type;
      this.referenceNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "referenceName"
      ); //removeing previous filter value
    } else if (filterName === "taskObject") {
      this.taskObjectType = type;
      this.taskObjectId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskObject"
      );
    } else if (filterName === "type") {
      this.taskTypeType = type;
      this.taskType_Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "type"
      );
    } else if (filterName === "taskGenerated") {
      this.taskGeneratedType = type;
      this.taskGeneratedId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskGenerated"
      );
    } else if (filterName === "taskSupplier") {
      this.taskSupplierType = type;
      this.taskSupplierId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskSupplier"
      );
    } else if (filterName === "taskOwner") {
      this.taskOwnerType = type;
      this.taskOwnerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskOwner"
      );
    } else if (filterName === "agreementStatus") {
      this.agreementStatusType = type;
      this.agreementStatusId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "agreementStatus"
      );
    } else if (filterName === "proProjectId") {
      this.proProjectIdType = type;
      this.proProjectIdId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "proProjectId"
      );
    } else if (filterName === "proSupplierID") {
      this.proSupplierIDType = type;
      this.proSupplierIDId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "proSupplierID"
      );
    } else if (filterName === "proMGOLA") {
      this.proMGOLAType = type;
      this.proMGOLAId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "proMGOLA"
      );
    } else if (filterName === "proProjectDescription") {
      this.proProjectDescriptionType = type;
      this.proProjectDescriptionId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "proProjectDescription"
      );
    } else if (filterName === "projectIdCol") {
      this.projectIdColTypeCol = type;
      this.projectIdColId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "projectIdCol"
      );
    } else if (filterName === "mmola") {
      this.mmolaColType = type;
      this.mmolaColId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "mmola"
      );
    } else if (filterName === "incoterms1") {
      this.incotermsType = type;
      this.incotermsId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "incoterms1"
      );
    } else {
    }
    if (
      filterName === "secondaryEndDate" ||
      filterName === "secondaryStartDate" ||
      filterName === "validFrom" ||
      filterName === "isVisible" ||
      filterName === "isSupplierOnboarded" ||
      filterName === "validTo" ||
      filterName === "createdAt" ||
      filterName === "updatedAt" ||
      filterName === "validity" ||
      filterName === "taskGenerated"
    ) {
      if (value !== "") {
        await this.GetOutputVal(filterName, true, value, false, type);
      }
    } else {
      if (value && value.length) {
        value.forEach(async (val) => {
          await this.GetOutputVal(filterName, true, val, false, type);
        });
      }
    }

    this.OutputToParent.emit({
      type: filterName,
      filterName: value,
      suplierId: this.supplierId,
      opcoId: this.opcoId,
      catalogueId: this.catalogueId,
      amountId: this.amountId,
      materialId: this.materialId,
      tradingId: this.tradingId,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      startDate: this.startDate,
      endDate: this.endDate,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      monthID: this.monthId,
      /* VP3-3 start*/
      physicalNonphysicalId: this.physicalNonphysicalId,
      masterAgreementNameId: this.masterAgreementNameId,
      masterAgreementNameType: this.masterAgreementNameType,
      primaryDelegatorId: this.primaryDelegatorId,
      primaryDelegatorType: this.primaryDelegatorType,
      primaryDelegateId: this.primaryDelegateId,
      primaryDelegateType: this.primaryDelegateType,
      secondaryDelegatorId: this.secondaryDelegatorId,
      secondaryDelegatorType: this.secondaryDelegatorType,
      secondaryDelegateId: this.secondaryDelegateId,
      secondaryDelegateType: this.secondaryDelegateType,
      delegationTypeId: this.delegationTypeId,
      delegationTypeType: this.delegationTypeType,
      primaryDelegatedFromId: this.primaryDelegatedFromId,
      primaryDelegatedFromType: this.primaryDelegatedFromType,
      secondaryDelegatedFromId: this.secondaryDelegatedFromId,
      secondaryDelegatedFromType: this.secondaryDelegatedFromType,
      supplierNameId: this.supplierNameId,
      supplierNameType: this.supplierNameType,
      opcoNameId: this.opcoNameId,
      opcoNameType: this.opcoNameType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      validFromDateId: this.validFromDateId,
      validFromDateType: this.validFromDateType,
      validToDateId: this.validToDateId,
      validToDateType: this.validToDateType,
      secondaryStartDateId: this.secondaryStartDateId,
      secondaryStartDateType: this.secondaryStartDateType,
      secondaryEndDateId: this.secondaryEndDateId,
      secondaryEndDateType: this.secondaryEndDateType,
      vpcOwnerId: this.vpcOwnerId,
      vpcOwnerType: this.vpcOwnerType,
      vodafoneApproverId: this.vodafoneApproverId,
      vodafoneApproverType: this.vodafoneApproverType,
      materialCategoryId: this.materialCategoryId,
      materialCategoryType: this.materialCategoryType,
      paymentTermsId: this.paymentTermsId,
      paymentTermsType: this.paymentTermsType,
      contractIdId: this.contractIdId,
      contractIdType: this.contractIdType,
      supplierOwnerId: this.supplierOwnerId,
      supplierOwnerType: this.supplierOwnerType,
      createdAtId: this.createdAtId,
      createdAtType: this.createdAtType,
      createdById: this.createdById,
      createdByType: this.createdByType,
      companyCodeId: this.companyCodeId,
      companyCodeType: this.companyCodeType,
      manufacturerId: this.manufacturerId,
      manufacturerType: this.manufacturerType,
      materialSupplierId: this.materialSupplierId,
      materialSupplierType: this.materialSupplierType,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      inProgressMpnSpnType: this.inProgressMpnSpnType,
      completeMpnSpnId: this.completeMpnSpnId,
      completeMpnSpnType: this.completeMpnSpnType,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      completeSAPMatrilIdType: this.completeSAPMatrilIdType,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      inProgressSAPMatrilIdType: this.inProgressSAPMatrilIdType,
      updatedAtId: this.updatedAtId,
      updatedAtType: this.updatedAtType,
      updatedById: this.updatedById,
      updatedByType: this.updatedByType,
      isVisibleId: this.isVisibleId,
      isVisibleType: this.isVisibleType,
      isSupplierOnboardedId: this.isSupplierOnboardedId,
      isSupplierOnboardedType: this.isSupplierOnboardedType,
      paStatusFilterId: this.paStatusFilterId,
      paStatusFilterType: this.paStatusFilterType,
      lineItemStatusFilterId: this.lineItemStatusFilterId,
      lineItemStatusFilterType: this.lineItemStatusFilterType,
      integrationStatusFilterId: this.integrationStatusFilterId,
      integrationStatusFilterType: this.integrationStatusFilterType,
      lastIntegratedVersionId: this.lastIntegratedVersionId,
      lastIntegratedVersionFilterType: this.lastIntegratedVersionFilterType,
      outlineAgreementId: this.outlineAgreementId,
      outlineAgreementNumberType: this.outlineAgreementNumberType,
      projectIdColPa: this.projectIdColPa,
      projectIdColPaType: this.projectIdColPaType,
      companyCodeColPa: this.companyCodeColPa,
      companyCodeColPaType: this.companyCodeColPaType,
      supplierIdVal: this.supplierIdVal,
      supplierIdType: this.supplierIdType,
      catalogueIdentifier: this.catalogueIdentifier,
      catalogueIdentifierFilterType: this.catalogueIdentifierFilterType,
      catalogueName: this.catalogueName,
      catalogueNameFilterType: this.catalogueNameFilterType,
      itemStatusId: this.itemStatusId,
      sapMaterialId: this.sapMaterialId,
      supplierPartNumberId: this.supplierPartNumberId,
      manufacturerPartNumberId: this.manufacturerPartNumberId,
      materialDescriptionId: this.materialDescriptionId,
      materialLongDescriptionId: this.materialLongDescriptionId,
      oldMaterialId: this.oldMaterialId,
      boumId: this.boumId,
      materialGroupId: this.materialGroupId,
      commodityCodeId: this.commodityCodeId,
      grossWeightId: this.grossWeightId,
      netWeightId: this.netWeightId,
      characteristicValueId: this.characteristicValueId,
      subCharacteristicValueId: this.subCharacteristicValueId,
      lengthId: this.lengthId,
      widthId: this.widthId,
      heightId: this.heightId,
      hlwUnitId: this.hlwUnitId,
      volumeId: this.volumeId,
      volumeUnitId: this.volumeUnitId,
      natureId: this.natureId,
      serializationId: this.serializationId,
      usefulLifeId: this.usefulLifeId,
      eanId: this.eanId,
      eccnId: this.eccnId,
      gamId: this.gamId,
      manufacturerColId: this.manufacturerColId,
      auom1Id: this.auom1Id,
      unitConversion1Id: this.unitConversion1Id,
      auomqty1Id: this.auomqty1Id,
      auom2Id: this.auom2Id,
      unitConversion2Id: this.unitConversion2Id,
      auomqty2Id: this.auomqty2Id,
      auom3Id: this.auom3Id,
      unitConversion3Id: this.unitConversion3Id,
      auomqty3Id: this.auomqty3Id,
      stackingNormsId: this.stackingNormsId,
      stackingNumberId: this.stackingNumberId,
      productFamilyId: this.productFamilyId,
      physicalTypeColId: this.physicalTypeColId,
      endOfLifeId: this.endOfLifeId,
      endOfSupportId: this.endOfSupportId,
      projectId: this.projectIds,
      projectIdCol: this.projectIdCol,
      projectIdColType: this.projectIdColType,
      projectIdType: this.projectIdType,
      projectDescription: this.projectDescriptionCol,
      projectDescriptionType: this.projectDescriptionType,
      projectOwner: this.projectOwnerCol,
      projectOwnerType: this.projectOwnerType,
      projectIdStatusId: this.projectIdStatusId,
      /* VP3-3 end*/
      //Task Filter
      referenceNameId: this.referenceNameId,
      taskObjectId: this.taskObjectId,
      taskType_Id: this.taskType_Id,
      taskGeneratedId: this.taskGeneratedId,
      taskGeneratedType: this.taskGeneratedType,
      taskSupplierId: this.taskSupplierId,
      taskOwnerId: this.taskOwnerId,
      agreementStatusId: this.agreementStatusId,
      proProjectIdId: this.proProjectIdId,
      proProjectIdType: this.proProjectIdType,
      proSupplierIDId: this.proSupplierIDId,
      proSupplierIDType: this.proSupplierIDType,
      proMGOLAId: this.proMGOLAId,
      proMGOLAType: this.proMGOLAType,
      proProjectDescriptionId: this.proProjectDescriptionId,
      proProjectDescriptionType: this.proProjectDescriptionType,
      projectIdColId: this.projectIdColId,
      mmolaColType: this.mmolaColType,
      mmolaColId: this.mmolaColId,
      olaIds: this.olaIds,
      olaType: this.olaType,
      incotermsType: this.incotermsType,
      incotermsId: this.incotermsId,
    });
  }

  public async fromTableFilterMatBom(filterName, type, value) {
    if (filterName === "referenceName") {
      this.masterAgreementNameType = type;
      this.masterAgreementNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "referenceName"
      ); //removeing previous filter value
    } else if (filterName === "itemStatus") {
      this.itemStatusType = type;
      this.itemStatusId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "itemStatus"
      );
    } else if (filterName === "sapMaterialId") {
      this.sapMaterialIdType = type;
      this.sapMaterialId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "sapMaterialId"
      );
    } else if (filterName === "materialCategory") {
      this.materialCategoryType = type;
      this.materialCategoryId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialCategory"
      );
    } else if (filterName === "manufacturerPartNumber") {
      this.manufacturerPartNumberType = type;
      this.manufacturerPartNumberId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "manufacturerPartNumber"
      );
    } else if (filterName === "supplierPartNumber") {
      this.supplierPartNumberType = type;
      this.supplierPartNumberId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "supplierPartNumber"
      );
    } else if (filterName === "manufacturerCol") {
      this.manufacturerTypeCol = type;
      this.manufacturerColId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "manufacturerCol"
      );
    } else if (filterName === "materialDescription") {
      this.materialDescriptionType = type;
      this.materialDescriptionId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialDescription"
      );
    } else if (filterName === "materialLongDescription") {
      this.materialLongDescriptionType = type;
      this.materialLongDescriptionId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialLongDescription"
      );
    } else if (filterName === "oldMaterial") {
      this.oldMaterialType = type;
      this.oldMaterialId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "oldMaterial"
      );
    } else if (filterName === "buom") {
      this.boumType = type;
      this.boumId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "buom"
      );
    } else if (filterName === "materialGroup") {
      this.materialGroupType = type;
      this.materialGroupId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "materialGroup"
      );
    } else if (filterName === "commodityCode") {
      this.commodityCodeType = type;
      this.commodityCodeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "commodityCode"
      );
    } else if (filterName === "grossWeight") {
      this.grossWeightType = type;
      this.grossWeightId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "grossWeight"
      );
    } else if (filterName === "netWeight") {
      this.netWeightType = type;
      this.netWeightId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "netWeight"
      );
    } else if (filterName === "characteristicValue") {
      this.characteristicValueType = type;
      this.characteristicValueId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "characteristicValue"
      );
    } else if (filterName === "subCharacteristicValue") {
      this.subCharacteristicValueType = type;
      this.subCharacteristicValueId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "subCharacteristicValue"
      );
    } else if (filterName === "length") {
      this.lengthType = type;
      this.lengthId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "length"
      );
    } else if (filterName === "width") {
      this.widthType = type;
      this.widthId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "width"
      );
    } else if (filterName === "height") {
      this.heightType = type;
      this.heightId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "height"
      );
    } else if (filterName === "hlwUnit") {
      this.hlwUnitType = type;
      this.hlwUnitId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "hlwUnit"
      );
    } else if (filterName === "volume") {
      this.volumeType = type;
      this.volumeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "volume"
      );
    } else if (filterName === "volumeUnit") {
      this.volumeUnitType = type;
      this.volumeUnitId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "volumeUnit"
      );
    } else if (filterName === "nature") {
      this.natureType = type;
      this.natureId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "nature"
      );
    } else if (filterName === "serializationProfile") {
      this.serializationType = type;
      this.serializationId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "serializationProfile"
      );
    } else if (filterName === "usefulLife") {
      this.usefulLifeType = type;
      this.usefulLifeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "usefulLife"
      );
    } else if (filterName === "ean") {
      this.eanType = type;
      this.eanId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "ean"
      );
    } else if (filterName === "eccn") {
      this.eccnType = type;
      this.eccnId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "eccn"
      );
    } else if (filterName === "gam") {
      this.gamType = type;
      this.gamId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "gam"
      );
    } else if (filterName === "auom1") {
      this.auom1Type = type;
      this.auom1Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auom1"
      );
    } else if (filterName === "unitConversion1") {
      this.unitConversion1Type = type;
      this.unitConversion1Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "unitConversion1"
      );
    } else if (filterName === "auomQty1") {
      this.auomqty1Type = type;
      this.auomqty1Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auomQty1"
      );
    } else if (filterName === "auom2") {
      this.auom2Type = type;
      this.auom2Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auom2"
      );
    } else if (filterName === "unitConversion2") {
      this.unitConversion2Type = type;
      this.unitConversion2Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "unitConversion2"
      );
    } else if (filterName === "auomQty2") {
      this.auomqty2Type = type;
      this.auomqty2Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auomQty2"
      );
    } else if (filterName === "auom3") {
      this.auom3Type = type;
      this.auom3Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auom3"
      );
    } else if (filterName === "unitConversion3") {
      this.unitConversion3Type = type;
      this.unitConversion3Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "unitConversion3"
      );
    } else if (filterName === "auomQty3") {
      this.auomqty3Type = type;
      this.auomqty3Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "auomQty3"
      );
    } else if (filterName === "stackingNorms") {
      this.stackingNormsType = type;
      this.stackingNormsId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "stackingNorms"
      );
    } else if (filterName === "stackingNumber") {
      this.stackingNumberType = type;
      this.stackingNumberId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "stackingNumber"
      );
    } else if (filterName === "productFamilyCode") {
      this.productFamilyType = type;
      this.productFamilyId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "productFamilyCode"
      );
    } else if (filterName === "physicalTypeCol") {
      this.physicalTypeCol = type;
      this.physicalTypeColId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "physicalTypeCol"
      );
    } else if (filterName === "endOfLife") {
      this.endOfLifeType = type;
      this.endOfLifeId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "endOfLife"
      );
    } else if (filterName === "endOfSupport") {
      this.endOfSupportType = type;
      this.endOfSupportId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "endOfSupport"
      );
    } else {
    }
    if (
      filterName === "validFrom" ||
      filterName === "isVisible" ||
      filterName === "isSupplierOnboarded" ||
      filterName === "validTo" ||
      filterName === "createdAt" ||
      filterName === "updatedAt"
    ) {
      if (value !== "") {
        await this.GetOutputVal(filterName, true, value, false, type);
      }
    } else {
      if (value && value.length) {
        value.forEach(async (val) => {
          await this.GetOutputVal(filterName, true, val, false, type);
        });
      }
    }

    this.OutputToParent.emit({
      type: filterName,
      filterName: value,
      filterObj: this.dropDownObjArray,
      clicked: true,
      manufacturerId: this.manufacturerId,
      itemStatusId: this.itemStatusId,
      sapMaterialId: this.sapMaterialId,
      materialCategoryId: this.materialCategoryId,
      supplierPartNumberId: this.supplierPartNumberId,
      manufacturerPartNumberId: this.manufacturerPartNumberId,
      materialDescriptionId: this.materialDescriptionId,
      materialLongDescriptionId: this.materialLongDescriptionId,
      oldMaterialId: this.oldMaterialId,
      boumId: this.boumId,
      materialGroupId: this.materialGroupId,
      commodityCodeId: this.commodityCodeId,
      grossWeightId: this.grossWeightId,
      netWeightId: this.netWeightId,
      characteristicValueId: this.characteristicValueId,
      subCharacteristicValueId: this.subCharacteristicValueId,
      lengthId: this.lengthId,
      widthId: this.widthId,
      heightId: this.heightId,
      hlwUnitId: this.hlwUnitId,
      volumeId: this.volumeId,
      volumeUnitId: this.volumeUnitId,
      natureId: this.natureId,
      serializationId: this.serializationId,
      usefulLifeId: this.usefulLifeId,
      eanId: this.eanId,
      eccnId: this.eccnId,
      gamId: this.gamId,
      manufacturerColId: this.manufacturerColId,
      auom1Id: this.auom1Id,
      unitConversion1Id: this.unitConversion1Id,
      auomqty1Id: this.auomqty1Id,
      auom2Id: this.auom2Id,
      unitConversion2Id: this.unitConversion2Id,
      auomqty2Id: this.auomqty2Id,
      auom3Id: this.auom3Id,
      unitConversion3Id: this.unitConversion3Id,
      auomqty3Id: this.auomqty3Id,
      stackingNormsId: this.stackingNormsId,
      stackingNumberId: this.stackingNumberId,
      productFamilyId: this.productFamilyId,
      physicalTypeColId: this.physicalTypeColId,
      endOfLifeId: this.endOfLifeId,
      endOfSupportId: this.endOfSupportId,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      completeMpnSpnId: this.completeMpnSpnId,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      /* VP3-3 end*/
    });
  }
  /**
   *Create price agreement status filter objects
   *
   * @param {*} arrDetails
   * @return {*}
   * @memberof DropdownFilterComponent
   */
  public modifyPaStatus(arrDetails) {
    let details = {};
    const paDetails = Object.keys(arrDetails).map((k) => {
      if (arrDetails[k] === "DRAFT") {
        details = {
          key: arrDetails[k],
          value: "Draft Price Agreement",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "UNDER_REVIEW") {
        details = {
          key: arrDetails[k],
          value: "Price Agreement Under Review",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "APPROVED") {
        details = {
          key: arrDetails[k],
          value: "Approved Price Agreement",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "EXPIRED") {
        details = {
          key: arrDetails[k],
          value: "Expired Price Agreement",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "ARCHIVED") {
        details = {
          key: arrDetails[k],
          value: "Archived Price Agreement",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "ACTIVE") {
        details = {
          key: arrDetails[k],
          value: "Review Complete",
          selected: false,
          type: "catalogueStatus",
        };
      } else if (arrDetails[k] === "INACTIVE") {
        details = {
          key: arrDetails[k],
          value: "Catalogue Inactive",
          selected: false,
          type: "catalogueStatus",
        };
      } else if (arrDetails[k] === "CATALOGUE_PENDING") {
        details = {
          key: arrDetails[k],
          value: "Catalogue Details Pending",
          selected: false,
          type: "catalogueStatus",
        };
      } else if (arrDetails[k] === "COMMERCIAL_PENDING") {
        details = {
          key: arrDetails[k],
          value: "Commercial Details Pending",
          selected: false,
          type: "catalogueStatus",
        };
      } else if (arrDetails[k] === "PENDING_REVIEW") {
        details = {
          key: arrDetails[k],
          value: "Pending Review",
          selected: false,
          type: "catalogueStatus",
        };
      } else if (arrDetails[k] === "PENDING_SYNCHRONIZATION") {
        details = {
          key: arrDetails[k],
          value: "Sync In Progress",
          selected: false,
          type: "integrationStatus",
        };
      } else if (arrDetails[k] === "CATALOGUE_SYNCHRONIZED") {
        details = {
          key: arrDetails[k],
          value: "Catalogue Synchronised",
          selected: false,
          type: "integrationStatus",
        };
      } else if (arrDetails[k] === "CATALOGUE_FINALIZED") {
        /* [VPC-103] start */
        details = {
          key: arrDetails[k],
          value: "Catalogue Finalized",
          selected: false,
          type: "integrationStatus",
        };
      } /* [VPC-103] end */ else if (
        arrDetails[k] === "SYNCHRONIZATION_ERROR"
      ) {
        // VOD-1613 fixes
        details = {
          key: arrDetails[k],
          value: "Sync Failed",
          selected: false,
          type: "integrationStatus",
        };
      } else if (arrDetails[k] === "ITEM_DELETED") {
        details = {
          key: arrDetails[k],
          value: "Items Deleted",
          selected: false,
          type: "paStatus",
        };
      } else if (arrDetails[k] === "ITEM_EXPIRED") {
        details = {
          key: arrDetails[k],
          value: "Items Expired",
          selected: false,
          type: "paStatus",
        };
      }
      return details;
    });
    return paDetails;
  }
  /* VP2-108 */
  public supplierDetails(searchIds, addRemove, remStatus = false) {
    this.parentSupplierArr.find((suppliers) => {
      if (suppliers.organizationName === searchIds) {
        /* VP2-108 */
        if (addRemove === "add" && !remStatus) {
          this.supDet = { ...this.supDet, ...suppliers.childSupplierDetails };
          this.suppliersDetails = Object.keys(this.supDet).map((k) => {
            return {
              key: k,
              value: this.supDet[k],
              selected: false,
            };
          });
        } else {
          Object.keys(suppliers.childSupplierDetails).map((k) => {
            delete this.supDet[k];
            this.suppliersDetails = Object.keys(this.supDet).map((t) => {
              return {
                key: t,
                value: this.supDet[t],
                selected: false,
              };
            });
          });

          if (this.suppliersDetails.length === 0) {
            this.suppliersDetails = Object.keys(this.filterSupplier).map(
              (k) => {
                return {
                  key: k,
                  value: this.filterSupplier[k],
                  selected: false,
                };
              }
            );
          }
        }
      }
    });
  }

  /**
   *Function to clear all filter
   *
   * @memberof DropdownFilterComponent
   */
  public clearFilter() {
    this.paSsearchText = [];
    this.counts = 0;
    this.supplierCount = 0;
    this.opcoCount = 0;
    this.catalogueCount = 0;
    this.materialCount = 0;
    this.tradingCount = 0;
    this.catalogueStatusCount = 0;
    this.paStatusCount = 0;
    this.taskTypeCount = 0;
    this.startDate = null;
    this.endDate = null;
    this.taskStatusCount = 0;
    this.parentSupplierCount = 0;
    this.currencyCount = 0;
    this.amountCount = 0;
    this.physicalNonphysicalCount = 0; //VP3-3
    // VOD-1652
    this.l1CategoryCount = 0;
    this.l4GroupCount = 0;
    this.completeMpnSpnCount = 0;
    this.inProgressSAPMatrilIdCount = 0;
    this.completeSAPMatrilIdCount = 0;
    this.inProgressMpnSpnCount = 0;
    this.materialSupplierCount = 0;
    this.createdByCount = 0;
    this.manufacturerCount = 0;
    this.companyCodeCount = 0;
    this.projectIdCount = 0;
    this.olaIdCount = 0
    // VOD-1652
    this.supplierId = [];
    this.amountId = [];
    this.opcoId = [];
    this.catalogueId = [];
    this.materialId = [];
    this.tradingId = [];
    this.paStatusId = [];
    this.catalogueStatusId = [];
    this.taskTypeId = [];
    this.taskType_Id = [];
    this.taskGeneratedId = [];
    this.taskObjectId = [];
    this.dropDownObjArray = [];
    this.taskStatusId = [];
    this.parentSupplierId = [];
    this.integrationStatusId = [];
    this.currencyId = [];
    /* VP3-3 start */
    this.physicalNonphysicalId = [];
    this.masterAgreementNameId = [];
    this.masterAgreementNameType = "";
    this.primaryDelegatorId = [];
    this.primaryDelegatorType = "";
    this.primaryDelegateId = [];
    this.primaryDelegateType = "";
    this.secondaryDelegatorId = [];
    this.secondaryDelegatorType = "";
    this.secondaryDelegateId = [];
    this.secondaryDelegateType = "";
    this.delegationTypeId = [];
    this.delegationTypeType = "";
    this.primaryDelegatedFromId = [];
    this.primaryDelegatedFromType = "";
    this.secondaryDelegatedFromId = [];
    this.secondaryDelegatedFromType = "";
    this.supplierNameId = [];
    this.supplierNameType = "";
    this.opcoNameId = [];
    this.opcoNameType = "";
    this.tradingCurrencyKeyId = [];
    this.tradingCurrencyKeyType = "";
    this.validFromDateId = [];
    this.validFromDateType = "";
    this.validToDateId = [];
    this.validToDateType = "";
    this.secondaryStartDateId = [];
    this.secondaryStartDateType = "";
    this.secondaryEndDateId = [];
    this.secondaryEndDateType = "";
    this.vpcOwnerId = [];
    this.vpcOwnerType = "";
    this.vodafoneApproverId = [];
    this.vodafoneApproverType = "";
    this.materialCategoryId = [];
    this.materialCategoryType = "";
    this.contractIdId = [];
    this.contractIdType = "";
    this.paymentTermsId = [];
    this.paymentTermsType = "";
    this.supplierOwnerId = [];
    this.supplierOwnerType = "";
    this.createdAtId = [];
    this.createdAtType = "";
    this.createdById = [];
    this.createdByType = "";
    this.companyCodeId = [];
    this.companyCodeType = "";
    this.manufacturerId = [];
    this.materialSupplierId = [];
    this.inProgressMpnSpnId = [];
    this.completeMpnSpnId = [];
    this.manufacturerType = "";
    this.materialSupplierType = "";
    this.inProgressMpnSpnType = "";
    this.completeMpnSpnType = "";
    this.completeSAPMatrilIdId = [];
    this.completeSAPMatrilIdType = "";
    this.inProgressSAPMatrilIdId = [];
    this.inProgressSAPMatrilIdType = "";
    this.updatedAtId = [];
    this.updatedAtType = "";
    this.updatedById = [];
    this.updatedByType = "";
    this.isVisibleId = [];
    this.isVisibleType = "";
    this.isSupplierOnboardedId = [];
    this.isSupplierOnboardedType = "";
    this.paStatusFilterId = [];
    this.paStatusFilterType = "";
    this.lineItemStatusFilterId = [];
    this.lineItemStatusFilterType = "";
    this.integrationStatusFilterId = [];
    this.integrationStatusFilterType = "";
    this.lastIntegratedVersionId = [];
    this.lastIntegratedVersionFilterType = "";
    this.outlineAgreementId = [];
    this.outlineAgreementNumberType = "";
    this.supplierIdVal = [];
    this.supplierIdType = "";
    this.catalogueName = [];
    this.catalogueNameFilterType = "";
    this.catalogueIdentifier = [];
    this.catalogueIdentifierFilterType = "";
    this.projectIds = [];
    this.proMGOLACount = 0;
    this.proProjectIdCount = 0;
    this.proSupplierIDCount = 0;
    this.proProjectDescriptionCount = 0;
    this.taskOwnerId = [];
    this.taskSupplierId = [];
    this.agreementStatusId = [];
    this.manufacturerPartNumberId = [];
    this.materialLongDescriptionId = [];
    this.materialDescriptionId = [];
    this.supplierPartNumberId = [];
    this.sapMaterialId = [];
    this.itemStatusId = [];
    this.oldMaterialId = [];
    this.materialGroupId = [];
    this.boumId = [];
    this.commodityCodeId = [];
    this.netWeightId = [];
    this.grossWeightId = [];
    this.characteristicValueId = [];
    this.subCharacteristicValueId = [];
    this.lengthId = [];
    this.widthId = [];
    this.heightId = [];
    this.hlwUnitId = [];
    this.volumeId = [];
    this.volumeUnitId = [];
    this.natureId = [];
    this.serializationId = [];
    this.usefulLifeId = [];
    this.eanId = [];
    this.eccnId = [];
    this.gamId = [];
    this.auom1Id = [];
    this.unitConversion1Id = [];
    this.auomqty1Id = [];
    this.auom2Id = [];
    this.unitConversion2Id = [];
    this.auomqty2Id = [];
    this.auom3Id = [];
    this.unitConversion3Id = [];
    this.auomqty3Id = [];
    this.stackingNormsId = [];
    this.stackingNumberId = [];
    this.productFamilyId = [];
    this.physicalTypeColId = [];
    this.endOfLifeId = [];
    this.endOfSupportId = [];
    /* VP3-3 end */
    /* VOD-1751 */
    this.getFilterValues();
    this.getDropDownValues();
    this.supDet = [];
    /* VOD-1751 */

    // tslint:disable-next-line:max-line-length
    const array = [
      this.tradingModels,
      this.suppliersDetails,
      this.opcoDetails,
      this.catalogueType,
      /*this.createdByType,
      this.companyCodeType,
      this.manufacturerType,
      this.materialSupplierType,
      this.inProgressMpnSpnType,
      this.completeMpnSpnType,*/
      this.materialGroups,
      this.paStatusDetail,
      this.catalogueStatusDetail,
      this.taskTypeDetail,
      this.taskStatusDetail,
      this.parentSupplierDetails,
      this.amountArr,
      this.physicalNonphysicalArr,
    ];
    array.forEach((nestedArray) => {
      nestedArray.forEach((element) => {
        if (element["selected"] === true) {
          element["selected"] = false;
        }
      });
    });
  }

  /**
   *Function called when date selected from date picker
   *
   * @param {*} event
   * @memberof DropdownFilterComponent
   */
  public onDate(event: any): void {
    this.roomsFilter.date = event;
    const isSame = this.compare_dates(
      new Date(this.roomsFilter.begin),
      new Date(this.roomsFilter.end)
    );
    if (isSame && !this.validityShow) {
      (this.startDate = moment(this.roomsFilter.begin).add(1, "days").toDate()), // new Date(this.roomsFilter.begin);
        (this.endDate = null);
    } else {
      (this.startDate = moment(this.roomsFilter.begin).add(1, "days").toDate()), // new Date(this.roomsFilter.begin);
        (this.endDate = moment(this.roomsFilter.end).add(1, "days").toDate()); // new Date(this.roomsFilter.end);
    }

    let index = -1;
    this.dropDownObjArray.forEach((element, i) => {
      if (element["type"] === "validity") {
        index = i;
      }
    });
    if (index !== -1) {
      this.dropDownObjArray.splice(index, 1);
    }
    this.dropDownObjArray.push({
      type: "validity",
      key: `${moment(this.roomsFilter.begin).format("DD/MM/YYYY")} - ${moment(
        this.roomsFilter.end
      ).format("DD/MM/YYYY")}`,
      value: `${moment(this.roomsFilter.begin).format("DD/MM/YYYY")} - ${moment(
        this.roomsFilter.end
      ).format("DD/MM/YYYY")}`,
    });
    this.onButtonGroupClick(event, "date");
  }

  public compare_dates(date1: Date, date2: Date) {
    if (date1.getTime() !== date2.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *Month calender filter function
   *
   * @param {*} e
   * @memberof DropdownFilterComponent
   */
  public async logRange(e) {
    this.monthId = e;
    const filterWithoutmonth = this.dropDownObjArray.filter(function (el) {
      return el.type != "monthFilter";
    });
    this.dropDownObjArray = await filterWithoutmonth;
    const m = e.split("&");
    const val = `${m[0]} to ${m[1]}`;
    const obj = {
      type: "monthFilter",
      value: val,
      key: val,
    };
    this.dropDownObjArray.push(obj);
    this.OutputToParent.emit({
      type: "monthFilter",
      filterName: "",
      suplierId: this.supplierId,
      opcoId: this.opcoId,
      catalogueId: this.catalogueId,
      amountId: this.amountId,
      materialId: this.materialId,
      tradingId: this.tradingId,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      startDate: this.startDate,
      endDate: this.endDate,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      monthID: this.monthId,
      /* VP3-3 start*/
      physicalNonphysicalId: this.physicalNonphysicalId,
      masterAgreementNameId: this.masterAgreementNameId,
      masterAgreementNameType: this.masterAgreementNameType,
      primaryDelegatorId: this.primaryDelegatorId,
      primaryDelegatorType: this.primaryDelegatorType,
      primaryDelegateId: this.primaryDelegateId,
      primaryDelegateType: this.primaryDelegateType,
      secondaryDelegatorId: this.secondaryDelegatorId,
      secondaryDelegatorType: this.secondaryDelegatorType,
      secondaryDelegateId: this.secondaryDelegateId,
      secondaryDelegateType: this.secondaryDelegateType,
      delegationTypeId: this.delegationTypeId,
      delegationTypeType: this.delegationTypeType,
      primaryDelegatedFromId: this.primaryDelegatedFromId,
      primaryDelegatedFromType: this.primaryDelegatedFromType,
      secondaryDelegatedFromId: this.secondaryDelegatedFromId,
      secondaryDelegatedFromType: this.secondaryDelegatedFromType,
      supplierNameId: this.supplierNameId,
      supplierNameType: this.supplierNameType,
      opcoNameId: this.opcoNameId,
      opcoNameType: this.opcoNameType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      validFromDateId: this.validFromDateId,
      validFromDateType: this.validFromDateType,
      validToDateId: this.validToDateId,
      validToDateType: this.validToDateType,
      secondaryStartDateId: this.secondaryStartDateId,
      secondaryStartDateType: this.secondaryStartDateType,
      secondaryEndDateId: this.secondaryEndDateId,
      secondaryEndDateType: this.secondaryEndDateType,
      vpcOwnerId: this.vpcOwnerId,
      vpcOwnerType: this.vpcOwnerType,
      vodafoneApproverId: this.vodafoneApproverId,
      vodafoneApproverType: this.vodafoneApproverType,
      materialCategoryId: this.materialCategoryId,
      materialCategoryType: this.materialCategoryType,
      paymentTermsId: this.paymentTermsId,
      paymentTermsType: this.paymentTermsType,
      contractIdId: this.contractIdId,
      contractIdType: this.contractIdType,
      supplierOwnerId: this.supplierOwnerId,
      supplierOwnerType: this.supplierOwnerType,
      createdAtId: this.createdAtId,
      createdAtType: this.createdAtType,
      createdById: this.createdById,
      createdByType: this.createdByType,
      companyCodeId: this.companyCodeId,
      companyCodeType: this.companyCodeType,
      manufacturerId: this.manufacturerId,
      manufacturerType: this.manufacturerType,
      materialSupplierId: this.materialSupplierId,
      materialSupplierType: this.materialSupplierType,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      inProgressMpnSpnType: this.inProgressMpnSpnType,
      completeMpnSpnId: this.completeMpnSpnId,
      completeMpnSpnType: this.completeMpnSpnType,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      completeSAPMatrilIdType: this.completeSAPMatrilIdType,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      inProgressSAPMatrilIdType: this.inProgressSAPMatrilIdType,
      updatedAtId: this.updatedAtId,
      updatedAtType: this.updatedAtType,
      updatedById: this.updatedById,
      updatedByType: this.updatedByType,
      isVisibleId: this.isVisibleId,
      isVisibleType: this.isVisibleType,
      isSupplierOnboardedId: this.isSupplierOnboardedId,
      isSupplierOnboardedType: this.isSupplierOnboardedType,
      paStatusFilterId: this.paStatusFilterId,
      paStatusFilterType: this.paStatusFilterType,
      lineItemStatusFilterId: this.lineItemStatusFilterId,
      lineItemStatusFilterType: this.lineItemStatusFilterType,
      integrationStatusFilterId: this.integrationStatusFilterId,
      integrationStatusFilterType: this.integrationStatusFilterType,
      lastIntegratedVersionId: this.lastIntegratedVersionId,
      lastIntegratedVersionFilterType: this.lastIntegratedVersionFilterType,
      outlineAgreementId: this.outlineAgreementId,
      outlineAgreementNumberType: this.outlineAgreementNumberType,
      supplierIdVal: this.supplierIdVal,
      supplierIdType: this.supplierIdType,
      catalogueIdentifier: this.catalogueIdentifier,
      catalogueIdentifierFilterType: this.catalogueIdentifierFilterType,
      catalogueName: this.catalogueName,
      catalogueNameFilterType: this.catalogueNameFilterType,
      proProjectIdId: this.proProjectIdId,
      proProjectIdType: this.proProjectIdType,
      proSupplierIDId: this.proSupplierIDId,
      proSupplierIDType: this.proSupplierIDType,
      proMGOLAId: this.proMGOLAId,
      proMGOLAType: this.proMGOLAType,
      proProjectDescriptionId: this.proProjectDescriptionId,
      proProjectDescriptionType: this.proProjectDescriptionType,
      /* VP3-3 end*/
    });
  }

  public checkText(filterName) {
    if (
      this.paSsearchText[filterName] &&
      this.paSsearchText[filterName].trim() == ""
    ) {
      this.emptyText = true;
    } else {
      this.emptyText = false;
    }
  }

  /**
   *Search function for dropdown options
   *
   * @param {*} filterName
   * @memberof DropdownFilterComponent
   */
  public searchPaFilter(filterName) {
    if (filterName === "contractId") {
      const search = this.paSsearchText[filterName].trim().split(","); // for multi search spliting (,) separated value
      const searchValue = search.filter((y) => y !== "").map((x) => x.trim());
      this.contractId = searchValue;
      this.dropDownObjArray.push({
        name: filterName,
        type: "contains",
        value: searchValue,
      });
    } else if (filterName === "olaId") {
      const search = this.paSsearchText[filterName].trim().split(","); // for multi search spliting (,) separated value
      const searchValue = search.filter((y) => y !== "").map((x) => x.trim());
      this.olaId = searchValue;
      this.dropDownObjArray.push({
        name: 'mmola',
        type: "contains",
        value: searchValue,
      });
    }
    this.OutputToParent.emit({
      type: "monthFilter",
      filterName: "",
      suplierId: this.supplierId,
      opcoId: this.opcoId,
      catalogueId: this.catalogueId,
      amountId: this.amountId,
      materialId: this.materialId,
      tradingId: this.tradingId,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      startDate: this.startDate,
      endDate: this.endDate,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      monthID: this.monthId,
      /* VP3-3 start*/
      physicalNonphysicalId: this.physicalNonphysicalId,
      masterAgreementNameId: this.masterAgreementNameId,
      masterAgreementNameType: this.masterAgreementNameType,
      primaryDelegatorId: this.primaryDelegatorId,
      primaryDelegatorType: this.primaryDelegatorType,
      primaryDelegateId: this.primaryDelegateId,
      primaryDelegateType: this.primaryDelegateType,
      secondaryDelegatorId: this.secondaryDelegatorId,
      secondaryDelegatorType: this.secondaryDelegatorType,
      secondaryDelegateId: this.secondaryDelegateId,
      secondaryDelegateType: this.secondaryDelegateType,
      delegationTypeId: this.delegationTypeId,
      delegationTypeType: this.delegationTypeType,
      primaryDelegatedFromId: this.primaryDelegatedFromId,
      primaryDelegatedFromType: this.primaryDelegatedFromType,
      secondaryDelegatedFromId: this.secondaryDelegatedFromId,
      secondaryDelegatedFromType: this.secondaryDelegatedFromType,
      supplierNameId: this.supplierNameId,
      supplierNameType: this.supplierNameType,
      opcoNameId: this.opcoNameId,
      opcoNameType: this.opcoNameType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      validFromDateId: this.validFromDateId,
      validFromDateType: this.validFromDateType,
      validToDateId: this.validToDateId,
      validToDateType: this.validToDateType,
      secondaryStartDateId: this.secondaryStartDateId,
      secondaryStartDateType: this.secondaryStartDateType,
      secondaryEndDateId: this.secondaryEndDateId,
      secondaryEndDateType: this.secondaryEndDateType,
      vpcOwnerId: this.vpcOwnerId,
      vpcOwnerType: this.vpcOwnerType,
      vodafoneApproverId: this.vodafoneApproverId,
      vodafoneApproverType: this.vodafoneApproverType,
      materialCategoryId: this.materialCategoryId,
      materialCategoryType: this.materialCategoryType,
      contractIdId: this.contractIdId,
      contractIdType: this.contractIdType,
      paymentTermsId: this.paymentTermsId,
      paymentTermsType: this.paymentTermsType,
      supplierOwnerId: this.supplierOwnerId,
      supplierOwnerType: this.supplierOwnerType,
      createdAtId: this.createdAtId,
      createdAtType: this.createdAtType,
      createdById: this.createdById,
      createdByType: this.createdByType,
      companyCodeId: this.companyCodeId,
      companyCodeType: this.companyCodeType,
      manufacturerId: this.manufacturerId,
      manufacturerType: this.manufacturerType,
      materialSupplierId: this.materialSupplierId,
      materialSupplierType: this.materialSupplierType,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      inProgressMpnSpnType: this.inProgressMpnSpnType,
      completeMpnSpnId: this.completeMpnSpnId,
      completeMpnSpnType: this.completeMpnSpnType,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      completeSAPMatrilIdType: this.completeSAPMatrilIdType,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      inProgressSAPMatrilIdType: this.inProgressSAPMatrilIdType,
      updatedAtId: this.updatedAtId,
      updatedAtType: this.updatedAtType,
      updatedById: this.updatedById,
      updatedByType: this.updatedByType,
      isVisibleId: this.isVisibleId,
      isVisibleType: this.isVisibleType,
      isSupplierOnboardedId: this.isSupplierOnboardedId,
      isSupplierOnboardedType: this.isSupplierOnboardedType,
      paStatusFilterId: this.paStatusFilterId,
      paStatusFilterType: this.paStatusFilterType,
      lineItemStatusFilterId: this.lineItemStatusFilterId,
      lineItemStatusFilterType: this.lineItemStatusFilterType,
      integrationStatusFilterId: this.integrationStatusFilterId,
      integrationStatusFilterType: this.integrationStatusFilterType,
      lastIntegratedVersionId: this.lastIntegratedVersionId,
      lastIntegratedVersionFilterType: this.lastIntegratedVersionFilterType,
      outlineAgreementId: this.outlineAgreementId,
      outlineAgreementNumberType: this.outlineAgreementNumberType,
      supplierIdVal: this.supplierIdVal,
      supplierIdType: this.supplierIdType,
      contractId: this.contractId,
      olaId: this.olaId,
      catalogueName: this.catalogueName,
      catalogueIdentifier: this.catalogueIdentifier,
      proProjectIdId: this.proProjectIdId,
      projectIdType: this.projectIdType,
      proProjectIdType: this.proProjectIdType,
      proSupplierIDId: this.proSupplierIDId,
      proSupplierIDType: this.proSupplierIDType,
      proMGOLAId: this.proMGOLAId,
      proMGOLAType: this.proMGOLAType,
      proProjectDescriptionId: this.proProjectDescriptionId,
      proProjectDescriptionType: this.proProjectDescriptionType,
      olaIds: this.olaIds,
      olaType: this.olaType
    });
  }

  /**
   *Function called when date selected from date picker
   *
   * @param {*} event
   * @memberof DropdownFilterComponent
   */
  public onDateRange(event: any): void {
    this.roomsFilter.date = event;
    const isSame = this.compare_dates(
      new Date(this.roomsFilter.begin),
      new Date(this.roomsFilter.end)
    );
    if (isSame && !this.validityShow) {
      (this.startDate = moment(this.roomsFilter.begin).toDate()), // new Date(this.roomsFilter.begin);
        (this.endDate = null);
    } else {
      (this.startDate = moment(this.roomsFilter.begin).toDate()), // new Date(this.roomsFilter.begin);
        (this.endDate = moment(this.roomsFilter.end).toDate()); // new Date(this.roomsFilter.end);
    }

    let index = -1;
    this.dropDownObjArray.forEach((element, i) => {
      if (element["type"] === "validity") {
        index = i;
      }
    });
    if (index !== -1) {
      this.dropDownObjArray.splice(index, 1);
    }
    this.dropDownObjArray.push({
      type: "validity",
      key: `${moment(this.roomsFilter.begin).format("DD/MM/YYYY")} - ${moment(
        this.roomsFilter.end
      ).format("DD/MM/YYYY")}`,
      value: `${moment(this.roomsFilter.begin).format("DD/MM/YYYY")} - ${moment(
        this.roomsFilter.end
      ).format("DD/MM/YYYY")}`,
    });
    this.onButtonGroupClick(event, "date");
  }
  async getDropDownValues() {
    let materialData;
    if (this.pricemanagerServices.getMaterialDropDown()) {
      materialData = this.pricemanagerServices.getMaterialDropDown();
    } else {
      materialData = await this.getMaterialDropDown();
    }
    this.projectIdDropDownValues = materialData["result"].projectIdDetails;
    const projectIdDetails = [];
    this.projectIdDropDownValues.forEach((res) => {
      let eachJson: any = {};
      eachJson.key = res.projectId;
      eachJson.value = res.projectId;
      eachJson.selected = false;
      projectIdDetails.push(eachJson);
    });
    this.projectIdDetails = projectIdDetails;
  }

  getMaterialDropDown() {
    return new Promise((resolve) => {
      this.pricemanagerServices.getMaterialDropdowns().subscribe(
        (data) => {
          resolve(data);
          this.pricemanagerServices.setMaterialDropDown(data);

        },
        (error) => { }
      );
    })
  }

  //Ankita Changes on 11_21_23
  public async fromTableFilterTask(filterName, type, value) {
    if (filterName === "referenceName") {
      this.referenceNameType = type;
      this.referenceNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "referenceName"
      ); //removeing previous filter value
    } else if (filterName === "taskObject") {
      this.taskObjectType = type;
      this.taskObjectId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskObject"
      );
    } else if (filterName === "type") {
      this.taskTypeType = type;
      this.taskType_Id = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "type"
      );
    } else if (filterName === "taskGenerated") {
      this.taskGeneratedType = type;
      this.taskGeneratedId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskGenerated"
      );
    } else if (filterName === "taskSupplier") {
      this.taskSupplierType = type;
      this.taskSupplierId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskSupplier"
      );
    } else if (filterName === "taskOwner") {
      this.taskOwnerType = type;
      this.taskOwnerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "taskOwner"
      );
    } else if (filterName === "agreementStatus") {
      this.agreementStatusType = type;
      this.agreementStatusId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "agreementStatus"
      );
    } else {
    }
    /* if (
      filterName === "validFrom" ||
      filterName === "isVisible" ||
      filterName === "isSupplierOnboarded" ||
      filterName === "validTo" ||
      filterName === "createdAt" ||
      filterName === "updatedAt"
    ) {
      if (value !== "") {
        await this.GetOutputVal(filterName, true, value, false, type);
      }
    } else {*/
    if (value && value.length) {
      value.forEach(async (val) => {
        await this.GetOutputVal(filterName, true, val, false, type);
      });
    }
    // }
    this.OutputToParent.emit({
      type: filterName,
      filterName: value,
      filterObj: this.dropDownObjArray,
      clicked: true,
      referenceNameId: this.referenceNameId,
      taskObjectId: this.taskObjectId,
      taskType_Id: this.taskType_Id,
      taskGeneratedId: this.taskGeneratedId,
      taskSupplierId: this.taskSupplierId,
      taskOwnerId: this.taskOwnerId,
      agreementStatusId: this.agreementStatusId,

      /* VP3-3 end*/
    });
  }

  public async fromTableFilterCreatePa(filterName, type, value) {
    if (filterName === "referenceName") {
      this.referenceNameType = type;
      this.masterAgreementNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "referenceName"
      );
    } else if (filterName === "validToDate") {
      this.validToDateType = type;
      this.validToDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "validToDate"
      );
    } else if (filterName === "validFromDate") {
      this.validFromDateType = type;
      this.validFromDateId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "validFromDate"
      );
    } else if (filterName === "paStatusFilter") {
      this.paStatusFilterType = type;
      this.paStatusFilterId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "paStatusFilter"
      );
    } else if (filterName === "vpcOwner") {
      this.vpcOwnerType = type;
      this.vpcOwnerId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "vpcOwner"
      );
    }
    else if (filterName === "vodafoneApprover") {
      this.vodafoneApproverType = type;
      this.vodafoneApproverId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "vodafoneApprover"
      );
    } else if (filterName === "opcoName") {
      this.opcoNameType = type;
      this.opcoNameId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "opcoName"
      );
    } else if (filterName === "tradingCurrencyKey") {
      this.tradingCurrencyKeyType = type;
      this.tradingCurrencyKeyId = [];
      this.dropDownObjArray = this.dropDownObjArray.filter(
        (item) => item.type !== "tradingCurrencyKey"
      );
    }
    else {
    }

    if (value && value.length) {
      value.forEach(async (val) => {
        await this.GetOutputVal(filterName, true, val, false, type);
      });
    }

    this.OutputToParent.emit({
      type: filterName,
      filterName: value,
      filterObj: this.dropDownObjArray,
      clicked: true,
      masterAgreementNameId: this.masterAgreementNameId,
      validToDateId: this.validToDateId,
      validFromDateId: this.validFromDateId,
      paStatusFilterId: this.paStatusFilterId,
      vpcOwnerId: this.vpcOwnerId,
      vpcOwnerType: this.vpcOwnerType,
      opcoNameType: this.opcoNameType,
      opcoNameId: this.opcoNameId,
      masterAgreementNameType: this.referenceNameType,
      validFromDateType: this.validFromDateType,
      validToDateType: this.validToDateType,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId
      /* VP3-3 end*/
    });
  }

  // inprogress
  public inProgressSearch(type) {
    if (type === "search" && this.searchText.length) {
      const search = this.searchText.trim().replaceAll(" ", ",");
      let searchValue = search.split(",").filter((data) => {
        if (data) {
          return data;
        }
      });
      this.filteredData = this.inProgressMpnSpnType.filter(item => searchValue.includes(item.value));
    }
  }
  // global search filterd complete
  public completeSearch(type) {
    if (type === "search" && this.searchText.length) {
      const search = this.searchText.trim().replaceAll(" ", ",");
      let searchValue = search.split(",").filter((data) => {
        if (data) {
          return data;
        }
      });
      this.filteredDataCpmplete = this.completeMpnSpnType.filter(item => searchValue.includes(item.value));
    }
  }
  //  
  completeSAPSearch(type) {
    // let sapIDsdata = [];
    // let datacompleteSAPMatrilIdType = [];
    // let filtervalue = [];
    if (type === "search" && this.searchText.length) {
      const search = this.searchText.trim().replaceAll(" ", ",");
      let searchValue = search.split(",").filter((data) => {
        if (data) {
          return data;
        }
      });
      this.filteredDataCompleteSAPMatrilIdType = this.completeSAPMatrilIdType.filter(item => searchValue.includes(item.value));

      //   const searchValues = new Set(searchValue);
      //   datacompleteSAPMatrilIdType = this.completeSAPMatrilIdType;
      //   filtervalue = this.completeSAPMatrilIdType.filter(item => searchValues.has(item.value))
      //     .map(item => ({ ...item, selected: true }));
      //   if (filtervalue.length > 0) {
      //     this.filteredDataCompleteSAPMatrilIdType = filtervalue;
      //   }
      //   sapIDsdata = this.filteredDataCompleteSAPMatrilIdType.map(item => item.value);
      // }
      // if (sapIDsdata.length > 0) {
      //   this.completeSAPMatrilIdType = filtervalue;
      //   this.completeSAPMatrilIdId = sapIDsdata;    
      //   this.sapcopyPastsearch('completeSAPMatrilId', this.filteredDataCompleteSAPMatrilIdType)  
      // } else {
      //   this.completeSAPMatrilIdType = datacompleteSAPMatrilIdType
    }

  }

  //  
  inProgressSAPSearch(type) {
    // let sapIDsdata = [];
    // let datacompleteSAPMatrilIdType = [];
    // let filtervalue = [];
    if (type === "search" && this.searchText.length) {
      const search = this.searchText.trim().replaceAll(" ", ",");
      let searchValue = search.split(",").filter((data) => {
        if (data) {
          return data;
        }
      });
      this.filteredDatainProgressSAPMatrilIdType = this.inProgressSAPMatrilIdType.filter(item => searchValue.includes(item.value));
      //   const searchValues = new Set(searchValue);
      //   datacompleteSAPMatrilIdType = this.inProgressSAPMatrilIdType;
      //   filtervalue = this.inProgressSAPMatrilIdType.filter(item => searchValues.has(item.value))
      //     .map(item => ({ ...item, selected: true }));
      //   if (filtervalue.length > 0) {
      //     this.filteredDatainProgressSAPMatrilIdType = filtervalue;
      //   }
      //   sapIDsdata = this.filteredDatainProgressSAPMatrilIdType.map(item => item.value);
      //   if(sapIDsdata.length > 0){
      //     this.inProgressSAPMatrilIdType = filtervalue;
      //     this.inProgressSAPMatrilIdId = sapIDsdata;
      //     this.sapcopyPastsearch('inProgressSAPMatrilId', this.filteredDatainProgressSAPMatrilIdType);
      //   }else {
      //     this.inProgressSAPMatrilIdType = datacompleteSAPMatrilIdType
      //   }  
    }
  }
  public sapcopyPastsearch(type, filterType, searchIds) {

    // if (type === "inProgressSAPMatrilId") {
    //   // if (status === true) {
    //     if (this.inProgressSAPMatrilIdId.includes(searchIds[0])) {
    //       this.inProgressSAPMatrilIdCount = this.inProgressSAPMatrilIdCount;
    //       this.inProgressSAPMatrilIdId = this.inProgressSAPMatrilIdId;
    //     } else {
    //       this.inProgressSAPMatrilIdCount += 1;
    //       this.inProgressSAPMatrilIdId.push(searchIds);

    //       this.autoSelect(searchIds, this.inProgressSAPMatrilIdType);
    //     }
    //     const obj = {
    //       type,
    //       value: searchIds,
    //       key: searchIds,
    //       filterType: 'equal',
    //     };
    //     this.dropDownObjArray.push(obj);
    // } else {
    //   /* [VOD-1641] */
    //   if (this.inProgressSAPMatrilIdCount > 0) {
    //     /* [VOD-1641] */
    //     this.inProgressSAPMatrilIdCount -= 1;
    //   }
    //   this.deleteValueFromArr(
    //     this.inProgressSAPMatrilIdId,
    //     searchIds,
    //     this.inProgressSAPMatrilIdType
    //   );
    //   this.deleteFromDropDownObj(type, searchIds);
    // }
    //}
    this.OutputToParent.emit({
      type: type,
      filterName: filterType.length > 0 ? filterType[0] : '',
      suplierId: this.supplierId,
      opcoId: this.opcoId,
      catalogueId: this.catalogueId,
      amountId: this.amountId,
      materialId: this.materialId,
      tradingId: this.tradingId,
      paStatusId: this.paStatusId,
      catalogueStatusId: this.catalogueStatusId,
      taskTypeId: this.taskTypeId,
      taskStatusId: this.taskStatusId,
      filterObj: this.dropDownObjArray,
      clicked: true,
      startDate: this.startDate,
      endDate: this.endDate,
      validity: null,
      parentSupplierId: this.parentSupplierId,
      integrationStatusId: this.integrationStatusId,
      currencyId: this.currencyId,
      monthID: this.monthId,
      /* VP3-3 start*/
      physicalNonphysicalId: this.physicalNonphysicalId,
      masterAgreementNameId: this.masterAgreementNameId,
      masterAgreementNameType: this.masterAgreementNameType,
      primaryDelegatorId: this.primaryDelegatorId,
      primaryDelegatorType: this.primaryDelegatorType,
      primaryDelegateId: this.primaryDelegateId,
      primaryDelegateType: this.primaryDelegateType,
      secondaryDelegatorId: this.secondaryDelegatorId,
      secondaryDelegatorType: this.secondaryDelegatorType,
      secondaryDelegateId: this.secondaryDelegateId,
      secondaryDelegateType: this.secondaryDelegateType,
      supplierNameId: this.supplierNameId,
      supplierNameType: this.supplierNameType,
      opcoNameId: this.opcoNameId,
      opcoNameType: this.opcoNameType,
      tradingCurrencyKeyId: this.tradingCurrencyKeyId,
      tradingCurrencyKeyType: this.tradingCurrencyKeyType,
      validFromDateId: this.validFromDateId,
      validFromDateType: this.validFromDateType,
      validToDateId: this.validToDateId,
      validToDateType: this.validToDateType,
      secondaryStartDateId: this.secondaryStartDateId,
      secondaryStartDateType: this.secondaryStartDateType,
      secondaryEndDateId: this.secondaryEndDateId,
      secondaryEndDateType: this.secondaryEndDateType,
      vpcOwnerId: this.vpcOwnerId,
      vodafoneApproverId: this.vodafoneApproverId,
      vodafoneApproverType: this.vodafoneApproverType,
      vpcOwnerType: this.vpcOwnerType,
      materialCategoryId: this.materialCategoryId,
      materialCategoryType: this.materialCategoryType,
      paymentTermsId: this.paymentTermsId,
      paymentTermsType: this.paymentTermsType,
      contractIdId: this.contractIdId,
      contractIdType: this.contractIdType,
      supplierOwnerId: this.supplierOwnerId,
      supplierOwnerType: this.supplierOwnerType,
      createdAtId: this.createdAtId,
      createdAtType: this.createdAtType,
      createdById: this.createdById,
      createdByType: this.createdByType,
      companyCodeId: this.companyCodeId,
      companyCodeType: this.companyCodeType,
      manufacturerId: this.manufacturerId,
      manufacturerType: this.manufacturerType,
      materialSupplierId: this.materialSupplierId,
      materialSupplierType: this.materialSupplierType,
      inProgressMpnSpnId: this.inProgressMpnSpnId,
      inProgressMpnSpnType: this.inProgressMpnSpnType,
      completeMpnSpnId: this.completeMpnSpnId,
      completeMpnSpnType: this.completeMpnSpnType,
      completeSAPMatrilIdId: this.completeSAPMatrilIdId,
      completeSAPMatrilIdType: this.completeSAPMatrilIdType,
      inProgressSAPMatrilIdId: this.inProgressSAPMatrilIdId,
      inProgressSAPMatrilIdType: this.inProgressSAPMatrilIdType,
      updatedAtId: this.updatedAtId,
      updatedAtType: this.updatedAtType,
      updatedById: this.updatedById,
      updatedByType: this.updatedByType,
      isVisibleId: this.isVisibleId,
      isVisibleType: this.isVisibleType,
      isSupplierOnboardedId: this.isSupplierOnboardedId,
      isSupplierOnboardedType: this.isSupplierOnboardedType,
      paStatusFilterId: this.paStatusFilterId,
      paStatusFilterType: this.paStatusFilterType,
      lineItemStatusFilterId: this.lineItemStatusFilterId,
      lineItemStatusFilterType: this.lineItemStatusFilterType,
      integrationStatusFilterId: this.integrationStatusFilterId,
      integrationStatusFilterType: this.integrationStatusFilterType,
      lastIntegratedVersionFilterType: this.lastIntegratedVersionFilterType,
      lastIntegratedVersionId: this.lastIntegratedVersionId,
      outlineAgreementId: this.outlineAgreementId,
      outlineAgreementNumberType: this.outlineAgreementNumberType,
      supplierIdVal: this.supplierIdVal,
      supplierIdType: this.supplierIdType,
      catalogueName: this.catalogueName,
      catalogueNameFilterType: this.catalogueNameFilterType,
      catalogueIdentifier: this.catalogueIdentifier,
      catalogueIdentifierFilterType: this.catalogueIdentifierFilterType,
      itemStatusId: this.itemStatusId,
      sapMaterialId: this.sapMaterialId,
      supplierPartNumberId: this.supplierPartNumberId,
      manufacturerPartNumberId: this.manufacturerPartNumberId,
      materialDescriptionId: this.materialDescriptionId,
      materialLongDescriptionId: this.materialLongDescriptionId,
      oldMaterialId: this.oldMaterialId,
      boumId: this.boumId,
      materialGroupId: this.materialGroupId,
      commodityCodeId: this.commodityCodeId,
      grossWeightId: this.grossWeightId,
      netWeightId: this.netWeightId,
      characteristicValueId: this.characteristicValueId,
      subCharacteristicValueId: this.subCharacteristicValueId,
      lengthId: this.lengthId,
      widthId: this.widthId,
      heightId: this.heightId,
      hlwUnitId: this.hlwUnitId,
      volumeId: this.volumeId,
      volumeUnitId: this.volumeUnitId,
      natureId: this.natureId,
      serializationId: this.serializationId,
      usefulLifeId: this.usefulLifeId,
      eanId: this.eanId,
      eccnId: this.eccnId,
      gamId: this.gamId,
      manufacturerColId: this.manufacturerColId,
      auom1Id: this.auom1Id,
      unitConversion1Id: this.unitConversion1Id,
      auomqty1Id: this.auomqty1Id,
      auom2Id: this.auom2Id,
      unitConversion2Id: this.unitConversion2Id,
      auomqty2Id: this.auomqty2Id,
      auom3Id: this.auom3Id,
      unitConversion3Id: this.unitConversion3Id,
      auomqty3Id: this.auomqty3Id,
      stackingNormsId: this.stackingNormsId,
      stackingNumberId: this.stackingNumberId,
      productFamilyId: this.productFamilyId,
      physicalTypeColId: this.physicalTypeColId,
      endOfSupportId: this.endOfSupportId,
      endOfLifeId: this.endOfLifeId,
      projectId: this.projectIds,
      projectIdCol: this.projectIdCol,
      projectIdStatusId: this.projectIdStatusId,
      agreementStatusId: this.agreementStatusId,
      referenceNameId: this.referenceNameId,
      taskObjectId: this.taskObjectId,
      taskType_Id: this.taskType_Id,
      taskGeneratedId: this.taskGeneratedId,
      taskSupplierId: this.taskSupplierId,
      taskOwnerId: this.taskOwnerId,
      proProjectIdId: this.proProjectIdId,
      projectIdType: this.projectIdType,
      proProjectIdType: this.proProjectIdType,
      proSupplierIDId: this.proSupplierIDId,
      proSupplierIDType: this.proSupplierIDType,
      proMGOLAId: this.proMGOLAId,
      proMGOLAType: this.proMGOLAType,
      proProjectDescriptionId: this.proProjectDescriptionId,
      proProjectDescriptionType: this.proProjectDescriptionType,
      projectIdColId: this.projectIdColId,
      olaIds: this.olaIds,
      olaType: this.olaType
      /* VP3-3 end*/
    });
  }
}
